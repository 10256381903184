import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import axios from "axios";
import Preloader from "../CommonPage/Preloader";
import CryptoJS from "crypto-js";

function Mfa() {
  const email = sessionStorage.getItem("email");
  let types = localStorage.getItem("types");
  const [values, setValues] = useState({ mfa: "" });
  const dataMainId = sessionStorage.getItem("dataMainID");
  const [preloaderOn, setPreloaderOn] = useState(false);
  const secretKey = "Secured Key"; // Replace with your own secret key

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleResendMfa = (e) => {
    setPreloaderOn(true);
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}sendOtp`, {
        email: email,
        types,
      })
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message);
      });
  };

  const CheckMFA = async (e) => {
    e.preventDefault();
    try {
      const spinner =
        '<span className="spinner-border spinner-border-sm"></span> Loading.';
      const btns = $("#submitButton");
      btns.html(spinner);
      btns.attr("disabled", true);

      if (!values.mfa) {
        toast.warning("Please fill mfa code.");
        btns.html("submit");
        btns.attr("disabled", false);
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}login/verifyLogin`,
          { MFA: values.mfa, email: email, type: types }
        );
        if (res?.data?.status == 200) {
          // sessionStorage.setItem("token", JSON.stringify(res?.data?.token));
          const encryptedToken = CryptoJS.AES.encrypt(
            res?.data?.token,
            secretKey
          ).toString();
          sessionStorage.setItem("token", encryptedToken);
          sessionStorage.setItem("type", types);
          if (types == "admin") {
            window.location.href = "/dashboard";
            localStorage.setItem("menuItems", "dashboard");
            localStorage.setItem("menuType", "singleMenuBtn");
            localStorage.setItem("localTextColor", "blue");
          } else {
            localStorage.setItem("menuItems", "user-dashboard");
            localStorage.setItem("menuType", "singleMenuBtn");
            localStorage.setItem("localTextColor", "blue");
            if (dataMainId) {
              window.location.href = `/subscribe-template/${dataMainId}`;
              return;
            }
            window.location.href = "/user-dashboard";
          }
        }
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 1000 });
      const btns = $("#submitButton");
      btns.html("submit");
      btns.attr("disabled", false);
    }
  };

  useEffect(() => {
    document.title = "sleap: OTP";
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div id="layout" className="theme-cyan">
        <div id="wrapper">
          <div className="d-flex h100vh align-items-center auth-main w-100">
            <div className="auth-box">
              <div className="top mb-2">
                <div
                  className="logo"
                  style={{ padding: "0 8px", background: "#fff" }}
                >
                  <img src="logo.png" alt="iSleep" className="img-fluid" />
                </div>
              </div>
              <div className="card shadow p-lg-4">
                <div className="card-header">
                  <p className="fs-5 mb-0">
                    One Time Password (OTP) verification
                  </p>
                </div>
                <div className="card-body">
                  <p>
                    Please enter your OTP code received in your registered mail.
                  </p>
                  <form action="#">
                    <div className="form-group mb-3">
                      <strong>Enter OTP Code</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-key"></i>
                        </span>
                        <input
                          type="number"
                          name="mfa"
                          value={values.mfa}
                          max={6}
                          onInput={(e) => {
                            if (e.target.value.length > 6) {
                              e.target.value = e.target.value.slice(0, 6);
                            }
                          }}
                          onChange={handleChange}
                          className="form-control email"
                          placeholder="000000"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100 px-3 py-2"
                      onClick={CheckMFA}
                    >
                      Verify MFA
                    </button>
                    <div className="text-center mt-3">
                      <span className="helper-text">
                        Resend MFA Code? &nbsp;
                        <Link
                          onClick={handleResendMfa}
                          className="text-underline"
                        >
                          Click Here
                        </Link>
                      </span>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mfa;
