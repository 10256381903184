import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import UserDashboard from "../UserPage/Dashboard/UserDashboard";
import SingleTemplateDashboard from "../UserPage/Dashboard/SingleTemplateDashboard";
import Profile from "../UserPage/Profile";
import ProtectedRoute from "../ProtectedRoute";
import SettingUser from "../UserPage/SettingUser";
import SubscribeTemplate from "../UserPage/Dashboard/SubscribeTemplate";
import AddSubscribe from "../UserPage/Subscribe&View/AddSubscribe";
import AddView from "../UserPage/Subscribe&View/AddView";
import ClinicalDoc from "../UserPage/Dashboard/ClinicalDoc";
import PageLayout from "../PageLayout";
import { SharedDataSet } from "../CommonPage/SharedDataSet";

let type = sessionStorage.getItem("type");

function UserRoute() {
  return (
    <SharedDataSet>
      <Router>
        <Routes>
          {type === "user" && (
            <Route path="/" element={<PageLayout />}>
              <Route
                path="/user-dashboard"
                element={
                  <ProtectedRoute>
                    <UserDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subscribe-template/:dataId"
                element={
                  <ProtectedRoute>
                    <SubscribeTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/clinical/:dataId"
                element={
                  <ProtectedRoute>
                    <ClinicalDoc />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-dashboard/:dataId"
                element={
                  <ProtectedRoute>
                    <SingleTemplateDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/setting"
                element={
                  <ProtectedRoute>
                    <SettingUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add_subscribe"
                element={
                  <ProtectedRoute>
                    <AddSubscribe />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add_view"
                element={
                  <ProtectedRoute>
                    <AddView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="*"
                element={<Navigate to="/user-dashboard" replace />}
              />
            </Route>
          )}
        </Routes>
      </Router>
    </SharedDataSet>
  );
}

export default UserRoute;
