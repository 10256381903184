import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CryptoJS from "crypto-js";

const TempTop10 = (props) => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [tempIdArr, setTempIdArr] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.series)) {
      const tempIdArray = props.series.map((item) => ({
        id: item.tempId,
        name: item.name,
      }));
      setTempIdArr(tempIdArray);
    }
  }, [props.series]);

  const handleColumnClick = async (_, __, config) => {
    setTempIdArr((prevDatesData) => {
      const tempSelected = config?.globals?.labels[config.dataPointIndex];
      const tempId = prevDatesData.filter((item) => {
        if (item.name == tempSelected) {
          return item.id;
        }
      });
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}admin/getTemplateCount?template=true&templateId=${tempId[0]?.id}`,
            {
              headers: {
                Authorization: token,
              },
            }
          );
          const formattedData = res.data.aggregatedData.userData.map(
            (item, index) => ({
              sno: index + 1,
              name: `${item?.name}`,
              id: item?.id,
              count: item?.count,
            })
          );
          props.data(formattedData);
          props.open(true);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
      return prevDatesData;
    });
  };

  const [options, setOptions] = useState({
    series: [],
    chart: {
      type: "bar",
      events: {
        click: handleColumnClick,
      },
      height: 245,
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        click: handleColumnClick,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: [
      "var(--chart-color4)",
      "var(--chart-color1)",
      "var(--chart-color2)",
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 0,
    },
  });

  useEffect(() => {
    if (Array.isArray(props?.series)) {
      const newData = props.series.map((item) => item.count);
      const newCategories = props.series.map((item) => item.name);

      setOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: "Count",
            data: newData,
          },
        ],
        xaxis: {
          ...prevOptions.xaxis,
          categories:
            newCategories.length > 0
              ? newCategories
              : prevOptions.xaxis.categories,
        },
      }));
    }
  }, [props.series]);

  return (
    <div id="Salary_Statistics">
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={245}
      />
    </div>
  );
};

export default TempTop10;
