import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import { useSharedContext } from "../../CommonPage/SharedDataSet";
import CryptoJS from "crypto-js";

function NewRequestUser() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newApprovedData, setNewApprovedData] = useState();
  const { handleChangeNumbers } = useSharedContext();
  const [preloaderOn, setPreloaderOn] = useState(false);

  const closeModalBox = () => {
    setOpenModal(false);
  };

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=pendingUser`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          name: item?.FirstName + " " + item?.LastName + " ",
          phone: item?.PhoneNumber,
          email: item?.E_mail,
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="view"
                onClick={() => handleEditClick(item.id)}
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ),
        }));
        setData(formattedData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const handleEditClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users?stage=newRequest&UserId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNewApprovedData(response?.data?.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePostData = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users?task=completed&id=${id}`,
        {
          ...newApprovedData,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 2000 });
        setOpenModal(false);
        handleChangeNumbers();
        fetchData();
        setPreloaderOn(false);
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message, { autoClose: 2000 });
      });
  };

  return (
    <>
      {preloaderOn == true ? (
        <Preloader text="Please wait while user is addding..." />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Account
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">New User Request</li>
              </ul>
            </div>
            <AllUserRecords user={data} />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
                {!data?.length && <p>No Records to Show</p>}
              </div>
            </div>
          </div>
        </div>

        {newApprovedData && (
          <div
            className={
              openModal === true
                ? "custom_modal open openModal"
                : "custom_modal"
            }
          >
            <div className="custom_content-wrapper tw-w-[90%]">
              <button className="custom_close" onClick={closeModalBox}></button>
              <header className="custom_modal-header">
                <h2>New User Request</h2>
              </header>
              <form className="form-box" id="basic-form">
                <div className="card">
                  <div className="card-header">
                    <h6 className="card-title">Demographic information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 ">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          First Name
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="FirstName"
                          autoComplete="FirstName"
                          disabled
                          defaultValue={newApprovedData?.FirstName || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Middle Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="MiddleName"
                          autoComplete="MiddleName"
                          disabled
                          defaultValue={newApprovedData?.MiddleName || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="LastName"
                          autoComplete="LastName"
                          disabled
                          defaultValue={newApprovedData?.LastName || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Sex</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Sex_Type"
                          autoComplete="Sex_Type"
                          disabled
                          defaultValue={newApprovedData?.Sex_Type?.type || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Date of Birth</label>
                        <input
                          type="text"
                          className="form-control"
                          name="DOB"
                          autoComplete="DOB"
                          defaultValue={newApprovedData?.DOB || "NA"}
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="PhoneNumber"
                          autoComplete="PhoneNumber"
                          defaultValue={newApprovedData?.PhoneNumber || "NA"}
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">E-mail</label>
                        <input
                          type="text"
                          className="form-control"
                          name="E_Mail"
                          autoComplete="E_Mail"
                          defaultValue={newApprovedData?.E_Mail || "NA"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                    <h6 className="card-title">Primary Contact Information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 ">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Practice Name
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="PracticeName"
                          autoComplete="PracticeName"
                          disabled
                          defaultValue={newApprovedData?.PracticeName || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Address Line 1</label>
                        <input
                          type="text"
                          className="form-control"
                          name="AddressLine1"
                          autoComplete="AddressLine1"
                          disabled
                          defaultValue={newApprovedData?.AddressLine1 || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Address Line 1</label>
                        <input
                          type="text"
                          className="form-control"
                          name="AddressLine2"
                          autoComplete="AddressLine2"
                          disabled
                          defaultValue={newApprovedData?.AddressLine2 || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          className="form-control"
                          name="City"
                          autoComplete="City"
                          disabled
                          defaultValue={newApprovedData?.City || "NA"}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">County</label>
                        <input
                          type="text"
                          className="form-control"
                          name="County"
                          autoComplete="County"
                          defaultValue={newApprovedData?.County || "NA"}
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">ZipCode</label>
                        <input
                          type="text"
                          className="form-control"
                          name="ZipCode"
                          autoComplete="ZipCode"
                          defaultValue={newApprovedData?.ZipCode || "NA"}
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Country"
                          autoComplete="Country"
                          defaultValue={newApprovedData?.Country || "NA"}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  type="submit"
                  onClick={() => handlePostData(newApprovedData?.id)}
                >
                  Accept
                </button>
              </footer>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NewRequestUser;
