import React, { useEffect, useState, useCallback } from "react";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "select2/dist/css/select2.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import _ from "lodash";
import CryptoJS from "crypto-js";

function AddUser() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sexData, setSexData] = useState();
  const [data, setData] = useState();
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [role, setRole] = useState([{ service: "" }]);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [validationState, setValidationState] = useState({
    SystemLogin: null,
  });

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setRole([{ service: "" }]);
    setRow({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      SexType: "",
      DOB: "",
      PhoneNumber: "",
      E_mail: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      County: "",
      ZipCode: "",
      Country: "",
      SystemLogin: "",
      LinkedAccount: "",
      CreatePassword: "",
    });
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
    return formattedDate;
  };

  // User Role Name
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userrole`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [row, setRow] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SexType: "",
    DOB: { selectedDate },
    PhoneNumber: "",
    E_mail: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    SystemLogin: "",
    LinkedAccount: "",
    CreatePassword: "",
  });

  // Sex Type
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=sex`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setSexData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Add More Role
  const addmoreRole = () => {
    setRole([...role, { service: "" }]);
  };

  const handleBlurPass = (e) => {
    const { name, value } = e.target;

    if (name === "CreatePassword") {
      if (
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,16}$/.test(
          value
        ) ||
        value === ""
      ) {
        setSubmitBtnDisable(false);
      } else {
        toast.warning(
          "Password must be between 8 to 16 characters with at least one uppercase letter, one numeric digit, and one special character.",
          { autoClose: 1000 }
        );
        setSubmitBtnDisable(true);
      }
    }
  };

  const handleLinkAccountKeyPress = async (e) => {
    const linkAccountValue = e.target.value;

    if (linkAccountValue.trim() !== "") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}users/getProvider?LinkedProviderId=${linkAccountValue}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        const searchData = response.data.data;
        const filteredDataUser = searchData?.ProviderAddresses?.filter(
          (user) => {
            return user?.Primary === true;
          }
        );
        const objMain = {
          FirstName: searchData?.FirstName,
          MiddleName: searchData?.MiddleName,
          LastName: searchData?.LastName,
          DOB: searchData?.DOB,
          PhoneNumber: filteredDataUser[0]?.PhoneNumber,
          E_mail: filteredDataUser[0]?.Email,
          AddressLine1: filteredDataUser[0]?.AddressLine1,
          AddressLine2: filteredDataUser[0]?.AddressLine2,
          City: filteredDataUser[0]?.City,
          State: filteredDataUser[0]?.State,
          County: filteredDataUser[0]?.County,
          ZipCode: filteredDataUser[0]?.ZipCode,
          Country: filteredDataUser[0]?.Country,
          SexType: searchData?.SexTypeId,
        };
        setRow((prevRow) => ({ ...prevRow, ...objMain }));
        setSelectedDate(new Date(searchData.DOB));
      } catch (error) {
        console.error("Error fetching linked account data:", error);
      }
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const newUserData = role.map((item, i) => {
      return { ...item };
    });

    // Check if any role is empty
    const emptyRole = newUserData.some((item) => item.service === "");

    if (
      row.FirstName === "" ||
      row.LastName === "" ||
      row.DOB === "" ||
      row.SystemLogin === "" ||
      row.ZipCode === "" ||
      row.State === "" ||
      row.County === "" ||
      emptyRole
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select user roles.",
      });
    } else {
      setPreloaderOn(true); // Set loading state to true

      Swal.fire({
        text: "You are about to Add a New User.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Add User.",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}users`,
              {
                newUserData,
                ...row,
                DOB: formatDate(selectedDate),
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              setPreloaderOn(false); // Set loading state to false
              Swal.fire({
                title: "Success!",
                text: res.data.message,
                icon: "success",
              });
              setRow({
                FirstName: "",
                MiddleName: "",
                LastName: "",
                SexType: "",
                DOB: null,
                PhoneNumber: "",
                E_mail: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                County: "",
                ZipCode: "",
                Country: "",
                SystemLogin: "",
                LinkedAccount: "",
                CreatePassword: "",
              });
              setRole([{ service: "" }]);
              setValidationState({});
            })
            .catch((error) => {
              setPreloaderOn(false); // Set loading state to false
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setPreloaderOn(false); // Set loading state to false
          Swal.fire({
            title: "Cancelled",
            text: "User information update has been cancelled.",
            icon: "error",
          });
        }
      });
    }
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...role];
    list[index][name] = value;
    setRole(list);
  };

  const handleDelete = (i) => {
    if (i !== 0) {
      const newArr = role.filter((item, index) => index !== i);
      setRole(newArr);
    }
  };

  const debouncedSave = useCallback(
    _.debounce((nextValue, type) => handleSearch(nextValue, type), 500),
    []
  );

  const handleSearch = (value, type) => {
    if (value.length >= 5) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checkNumber?${type}=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setValidationState((prevState) => ({
            ...prevState,
            [type]: true,
          }));
          setSubmitBtnDisable(false);
        })
        .catch((error) => {
          console.log("ER", error);
          setValidationState((prevState) => ({
            ...prevState,
            [type]: false,
          }));
          setSubmitBtnDisable(true);
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        [type]: "Invalid",
      }));
      setSubmitBtnDisable(true);
    }
  };

  const handleChangePost = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (name === "FirstName" || name === "MiddleName" || name === "LastName") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Accept Only Alphabet ", { autoClose: 1000 });
      }
    } else if (name === "E_mail") {
      setRow({ ...row, [name]: value });
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        setSubmitBtnDisable(true);
      } else {
        setSubmitBtnDisable(false);
      }
    } else if (["City", "State", "County", "Country"].includes(name)) {
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Accept Only Alphabet ", { autoClose: 1000 });
      }
    } else if (name === "SystemLogin") {
      debouncedSave(value, "SystemLogin");
      setRow({ ...row, [name]: value });
    } else if (name === "PhoneNumber" || name === "ZipCode") {
      if (isNaN(value)) {
        toast.warning(`${name} must be a number`, { autoClose: 1000 });
      } else {
        setRow({ ...row, [name]: value });
      }
    } else {
      setRow({ ...row, [name]: value });
    }
  };

  return (
    <>
      {preloaderOn === true ? (
        <Preloader text="Please wait while form is uploading..." />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Account
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add User Account</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-12">
            <form className="form-box" id="yourFormId" onSubmit={handleUpdate}>
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">Demographic Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FirstName"
                        autoComplete="FirstName"
                        value={row.FirstName}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        autoComplete="MiddleName"
                        value={row.MiddleName}
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="LastName"
                        autoComplete="LastName"
                        value={row.LastName}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexType"
                        autoComplete="SexType"
                        value={row.SexType}
                        onChange={handleChangePost}
                      >
                        <option>Select</option>
                        {sexData?.map((item, iT) => {
                          return (
                            <option key={iT} value={item?.id}>
                              {item?.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          selected={selectedDate}
                          yearDropdownItemNumber={2000}
                          onChange={handleChange}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="MM-DD-YYYY"
                          minDate={new Date(1800, 0, 1)}
                          maxDate={new Date()}
                          yearDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control date"
                          aria-label="date"
                          aria-describedby="basic-addon11"
                          value={row.DOB}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Phone Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon16">
                          <i className="fa fa-phone"></i>
                        </span>
                        <input
                          required
                          type="text"
                          name="PhoneNumber"
                          autoComplete="PhoneNumber"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          onChange={handleChangePost}
                          maxLength={10}
                          minLength={8}
                          value={row.PhoneNumber}
                          className="form-control phone-number"
                          placeholder="Phone Number"
                          aria-label="phone-number"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        E-mail<span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          className="form-control email"
                          placeholder="Ex: example@example.com"
                          required
                          aria-label="email"
                          aria-describedby="basic-addon20"
                          type="email"
                          name="E_mail"
                          autoComplete="E_mail"
                          value={row.E_mail}
                          onChange={handleChangePost}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header">
                  <h6 className="card-title">Contact Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Address Line 1<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="AddressLine1"
                        autoComplete="AddressLine1"
                        value={row.AddressLine1}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Address Line 2</label>
                      <input
                        className="form-control"
                        type="text"
                        name="AddressLine2"
                        autoComplete="AddressLine2"
                        value={row.AddressLine2}
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        City<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="City"
                        autoComplete="City"
                        value={row.City}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        State<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="State"
                        autoComplete="State"
                        onChange={handleChangePost}
                        value={row.State}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        County<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="County"
                        autoComplete="County"
                        value={row.County}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Zip Code<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="ZipCode"
                        autoComplete="ZipCode"
                        value={row.ZipCode}
                        onChange={handleChangePost}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        maxLength={8}
                        minLength={6}
                        onInput={(e) => {
                          if (e.target.value.length > 8) {
                            e.target.value = e.target.value.slice(0, 8);
                          }
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Country<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="Country"
                        autoComplete="Country"
                        value={row.Country}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header ">
                  <h6 className="card-title">User Security</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        System Login
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationState.SystemLogin === true
                            ? "is-valid"
                            : validationState.SystemLogin === false
                            ? "is-invalid"
                            : validationState.SystemLogin === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="SystemLogin"
                        autoComplete="SystemLogin"
                        value={row.SystemLogin}
                        onChange={handleChangePost}
                        minLength={8}
                        maxLength={20}
                        onInput={(e) => {
                          if (e.target.value.length > 20) {
                            e.target.value = e.target.value.slice(0, 20);
                          }
                        }}
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.SystemLogin === false ? (
                        <div className="invalid-feedback">
                          System Login Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          System Login Invalid.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Linked Account</label>
                      <input
                        className="form-control"
                        type="text"
                        name="LinkedAccount"
                        autoComplete="LinkedAccount"
                        value={row.LinkedAccount}
                        onChange={handleChangePost}
                        onKeyUp={handleLinkAccountKeyPress}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Create Password
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="CreatePassword"
                        autoComplete="current-password"
                        value={row.CreatePassword}
                        onChange={handleChangePost}
                        onBlur={handleBlurPass}
                        placeholder="Example@123"
                        className="form-control"
                        minLength={8}
                        maxLength={16}
                        onInput={(e) => {
                          if (e.target.value.length > 16) {
                            e.target.value = e.target.value.slice(0, 16);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">User Role</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 d-flex">
                    {role.map((singleService, index) => (
                      <div
                        className="col-sm-6 col-md-3 col-lg-4 position-relative"
                        key={index}
                      >
                        <label className="form-label mt-1">
                          Select User Role:
                        </label>
                        &nbsp;{" "}
                        <select
                          className="form-select add-role"
                          value={singleService.service}
                          onChange={(e) => handleServiceChange(e, index)}
                          required
                          name="service"
                        >
                          <option>Select Role</option>
                          {data?.map((item, idrfg) => {
                            return (
                              <option key={idrfg} value={item.id}>
                                {item.UserRoleName}
                              </option>
                            );
                          })}
                        </select>
                        <span>
                          <button
                            type="button"
                            title="Remove"
                            onClick={() => handleDelete(index)}
                            className="closemoreAddress tw-mt-[1px]"
                            style={{ height: "35px" }}
                          >
                            X
                          </button>
                        </span>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="add-more"
                      onClick={addmoreRole}
                      style={{ height: "28px" }}
                    >
                      Add More Role
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4 text-end">
                <button
                  type="reset"
                  className="btn submit-btn d-inline ms-3 "
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3 "
                  disabled={submitBtnDisable}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
