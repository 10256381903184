import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import Chart1 from "../../Pages/TemplateConfiguration/Chart/Chart1";
import Chart4 from "../../Pages/TemplateConfiguration/Chart/Chart4";
import Chart6 from "../../Pages/TemplateConfiguration/Chart/Chart6";
import Chart8 from "../../Pages/TemplateConfiguration/Chart/Chart8";
import Chart9 from "../../Pages/TemplateConfiguration/Chart/Chart9";
import Chart14 from "../../Pages/TemplateConfiguration/Chart/Chart14";
import Chart15 from "../../Pages/TemplateConfiguration/Chart/Chart15";
import Chart16 from "../../Pages/TemplateConfiguration/Chart/Chart16";
import Chart18 from "../../Pages/TemplateConfiguration/Chart/Chart18";
import Chart22 from "../../Pages/TemplateConfiguration/Chart/Chart22";
import Chart23 from "../../Pages/TemplateConfiguration/Chart/Chart23";
import Chart24 from "../../Pages/TemplateConfiguration/Chart/Chart24";
import Chart26 from "../../Pages/TemplateConfiguration/Chart/Chart26";
import Chart28 from "../../Pages/TemplateConfiguration/Chart/Chart28";
import Chart30 from "../../Pages/TemplateConfiguration/Chart/Chart30";
import Chart32 from "../../Pages/TemplateConfiguration/Chart/Chart32";
import Chart33 from "../../Pages/TemplateConfiguration/Chart/Chart33";
import Chart35 from "../../Pages/TemplateConfiguration/Chart/Chart35";
import Chart36 from "../../Pages/TemplateConfiguration/Chart/Chart36";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "../../Pages/TemplateConfiguration/template.css";
import { toast } from "react-toastify";
import Select from "react-select";
import Group from "../../Pages/TemplateConfiguration/Chart/Group";
import Preloader from "../../CommonPage/Preloader";
import CryptoJS from "crypto-js";

function SubscribeTemplate() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const tokenUser = bytes.toString(CryptoJS.enc.Utf8);
  const { dataId } = useParams();
  const [data, setData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [view, setView] = useState({
    Name: "",
    Description: "",
    filterType: "",
  });
  const [subscribe, setSubscribe] = useState({
    Name: "",
    Description: "",
    Schedule: "",
    View: "",
  });
  const [subView, setSubView] = useState([]);
  const [fSubscribe, setFSubscribe] = useState([]);
  const [filter, setFilter] = useState([]);
  const [fView, setFview] = useState([]);

  const handleMultiSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    const selectedLabels = selectedOptions
      .map((option) => option.label)
      .join(", ");
    setView((prevView) => ({
      ...prevView,
      filterType: selectedLabels,
    }));
  }, [selectedOptions]);

  const handleView = (e) => {
    setView({ ...view, [e.target.name]: e.target.value });
  };

  const handleAddView = (e) => {
    e.preventDefault();
    if (view.filterType === "" || view.Name === "" || view.Description === "") {
      toast.warning("Please fill all the fields");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}afterLogin/addSubscribe?check=view`,
          { ...view },
          {
            headers: {
              Authorization: tokenUser,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setView({
            Name: "",
            Description: "",
            filterType: "",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleSubscribe = (e) => {
    setSubscribe({ ...subscribe, [e.target.name]: e.target.value });
  };

  const handleOpenSubscribe = (e) => {
    e.preventDefault();
    setOpen2(true);
  };

  const handleAddSubscrib = (e) => {
    e.preventDefault();
    if (
      subscribe.Name === "" ||
      subscribe.Description === "" ||
      subscribe.View === "" ||
      subscribe.Schedule === ""
    ) {
      toast.warning("Please fill all the fields");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}afterLogin/addSubscribe?check=subscribe`,
          { ...subscribe },
          {
            headers: {
              Authorization: tokenUser,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setSubscribe({
            Name: "",
            Description: "",
            Schedule: "",
            View: "",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleOpenView = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const CloseSecondModal = (e) => {
    e.preventDefault();
    setOpen2(false);
  };

  const CloseSecondModal2 = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const options = [
    { value: "option1", label: "Age 55+" },
    { value: "option2", label: "Age more then 60+" },
    { value: "option3", label: "Date Range Last 12th Month" },
    { value: "option4", label: "Female" },
    { value: "option5", label: "Male" },
  ];

  useEffect(() => {
    setPreloaderOn(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}afterlogin/getSubscribe?task=view`,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        setSubView(res?.data?.date);
        setPreloaderOn(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setPreloaderOn(false);
      });
  }, []);

  useEffect(() => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin`, {
        headers: {
          Authorization: tokenUser,
        },
      })
      .then((res) => {
        const defaultUserole = res?.data?.subscribedTemplates?.findIndex(
          (template) => {
            return template.id == dataId;
          }
        );
        setData(res?.data?.subscribedTemplates[defaultUserole]);
        const security = res?.data?.userRole;
        const finalSecure = security[security.length - 1];
        const mainData = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "subscribe";
          }
        );
        const mainView = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "customView";
          }
        );
        const mainFilter = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "filter";
          }
        );
        setFSubscribe(mainData);
        setFilter(mainFilter);
        setFview(mainView);
        setPreloaderOn(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setPreloaderOn(false);
      });
  }, [dataId]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}afterlogin/templateCount?tempId=${dataId}`,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        // console.log("Registered", res);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [dataId]);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Subscribed Templates
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/user-dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Subscribed Templates</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="d-flex tw-justify-end tw-items-center mb-2">
          {fSubscribe &&
            fSubscribe[0] &&
            (fSubscribe[0]?.fullAccess || fSubscribe[0]?.add) && (
              <div className="">
                <button
                  type="button"
                  onClick={handleOpenSubscribe}
                  className="btn add-more-btn d-inline"
                >
                  Subscribe
                </button>
              </div>
            )}
          {fView && fView[0] && (fView[0]?.fullAccess || fView[0]?.add) && (
            <div className="ms-3">
              <button
                type="button"
                onClick={handleOpenView}
                className="btn add-more-btn d-inline"
              >
                Custom View
              </button>
            </div>
          )}
          {filter && filter[0] && (filter[0]?.fullAccess || filter[0]?.add) && (
            <div className="col-sm-6 col-md-4 col-lg-4">
              <Select
                style={{ Opacity: "1", zIndex: "9" }}
                options={options}
                value={selectedOptions}
                onChange={handleMultiSelectChange}
                isMulti
                placeholder="Select Filters"
              />
            </div>
          )}
        </div>

        <div className="modalBody tw-w-full">
          <div
            className="card"
            style={{ background: `${data?.TemplateBackgroundTheme}` }}
          >
            <div
              className="card-header"
              style={{
                background: `${data?.pagetitleColorC}`,
                paddingTop: "0px",
                paddingBottom: "8px",
                paddingTop: "8px",
                zIndex: "0",
              }}
            >
              <div className="row">
                <div
                  className="col-md-4 ql-editor py-0"
                  style={{ overflow: "hidden" }}
                >
                  <span
                    className="pageTitle"
                    dangerouslySetInnerHTML={{
                      __html: data?.pageTitlel,
                    }}
                  ></span>
                </div>
                <div
                  className="col-md-4 ql-editor py-0"
                  style={{ overflow: "hidden" }}
                >
                  <span
                    className="pageTitle d-flex tw-justify-center"
                    dangerouslySetInnerHTML={{
                      __html: data?.pageTitlec,
                    }}
                  ></span>
                </div>
                <div
                  className="col-md-4 ql-editor py-0"
                  style={{ overflow: "hidden" }}
                >
                  <span
                    className="pageTitle d-flex tw-justify-end"
                    dangerouslySetInnerHTML={{
                      __html: data?.pageTitler,
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <div className="card-body">
              {data?.templateContainers?.map((container, containerIndex) => (
                <div
                  key={containerIndex}
                  id={"container_" + containerIndex}
                  className="card mb-3"
                  style={{ background: "transparent" }}
                >
                  <div className="card-body d-flex flex-wrap relative containersBodysPeview p-0">
                    {container?.containerHeaders?.map((item3, hti) => (
                      <>
                        {item3?.position === "top" && (
                          <div
                            key={hti}
                            className="mb-2 ql-editor boxHeadingContainer"
                            style={{
                              width: `${item3.width}%`,
                              paddingTop: "0px",
                              paddingBottom: "8px",
                              paddingTop: "8px",
                              backgroundColor: item3.HeadlineBackground,
                            }}
                          >
                            <span
                              className="boxHeading"
                              dangerouslySetInnerHTML={{
                                __html: item3?.quillData,
                              }}
                            ></span>
                          </div>
                        )}
                      </>
                    ))}
                    <span className="d-block tw-w-full"></span>
                    {container?.containerBoxes.length > 0 &&
                      container?.containerBoxes?.map((box, boxIndex) => (
                        <div
                          className="box-body mb-2 mx-1"
                          key={boxIndex}
                          style={{ width: `calc(${box.width}% - 8px)` }}
                        >
                          <div
                            className="box-header position-relative"
                            style={{
                              background: `${box.titleBackgroundColorBG}`,
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{
                                color: `${box.titleBackgroundColor}`,
                              }}
                            >
                              {box.title || ""}
                            </p>
                          </div>
                          <div
                            className="chart-box"
                            style={{
                              height: `${box.height}px`,
                              width: "100%",
                            }}
                          >
                            {box.boxChartType === "Chart1" ? (
                              <div className="chartOrTableType Chart1">
                                <Chart1
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  Color={JSON.parse(box?.Color)}
                                  DataLabels={box?.DataLabels}
                                  LegendDisplay={box?.LegendDisplay}
                                  LegendTextColor={box?.LegendTextColor}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart4" ? (
                              <div className="chartOrTableType Chart4">
                                <Chart4
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  DataLabels={box?.DataLabels}
                                  Color={JSON.parse(box?.Color)}
                                  LegendTextColor={box?.LegendTextColor}
                                  LegendDisplay={box?.LegendDisplay}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart6" ? (
                              <div className="chartOrTableType Chart6">
                                <Chart6
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  DataLabels={box?.DataLabels}
                                  Color={JSON.parse(box?.Color)}
                                  LegendTextColor={box?.LegendTextColor}
                                  LegendDisplay={box?.LegendDisplay}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart8" ? (
                              <div className="chartOrTableType Chart8">
                                <Chart8
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart9" ? (
                              <div className="chartOrTableType Chart9">
                                <Chart9
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart14" ? (
                              <div className="chartOrTableType Chart14">
                                <Chart14
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart15" ? (
                              <div className="chartOrTableType Chart15">
                                <Chart15
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  Stacked={box?.Stacked == 1 ? true : false}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart16" ? (
                              <div className="chartOrTableType Chart16">
                                <Chart16
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart18" ? (
                              <div className="chartOrTableType Chart18">
                                <Chart18
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  Stacked={box?.Stacked == 1 ? true : false}
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart22" ? (
                              <div className="chartOrTableType Chart22">
                                <Chart22
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={box.TargetDisplay}
                                  PeakDisplay={box.PeakDisplay}
                                  LowestDisplay={box.LowestDisplay}
                                  LowestLineDisplay={box.LowestLineDisplay}
                                  TargetLineDisplay={box.TargetLineDisplay}
                                  PeakLineDisplay={box.PeakLineDisplay}
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  Stacked={box.Stacked == 1 ? true : false}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX}
                                  LebalY={box.LebalY}
                                  AverageDisplay={box.AverageDisplay}
                                  AverageLineDisplay={box.AverageLineDisplay}
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={box.MovingDisplay}
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak}
                                  ValueTarget={box.ValueTarget}
                                  ValueLowest={box.ValueLowest}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart23" ? (
                              <div className="chartOrTableType Chart23">
                                <Chart23
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart24" ? (
                              <div className="chartOrTableType Chart24">
                                <Chart24
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  Stacked={box?.Stacked == 1 ? true : false}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart26" ? (
                              <div className="chartOrTableType Chart26">
                                <Chart26
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart28" ? (
                              <div className="chartOrTableType Chart28">
                                <Chart28
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart30" ? (
                              <div className="chartOrTableType Chart30">
                                <Chart30
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart32" ? (
                              <div className="chartOrTableType Chart32">
                                <Chart32
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  Stacked={box?.Stacked == 1 ? true : false}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart33" ? (
                              <div className="chartOrTableType Chart33">
                                <Chart33
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart35" ? (
                              <div className="chartOrTableType Chart35">
                                <Chart35
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart36" ? (
                              <div className="chartOrTableType Chart36">
                                <Chart36
                                  position={box?.position}
                                  BackgroundColor={box?.BackgroundColor}
                                  FontSize={box?.FontSize}
                                  YAxis={box?.YAxis}
                                  XAxis={box?.XAxis}
                                  Color={JSON.parse(box?.Color)}
                                  LegendDisplay={box?.LegendDisplay}
                                  gridX={box?.gridX}
                                  gridY={box?.gridY}
                                  axisX={box?.axisX}
                                  axisY={box?.axisY}
                                  TextColor={box?.textColor}
                                  PeakValueC={box?.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box?.FontSizeTarget}
                                  TargetValue={box?.TargetValue}
                                  LowestValueC={box?.LowestValueC}
                                  TargetValueTitle={box?.TargetValueTitle}
                                  PeakValueTitle={box?.PeakValueTitle}
                                  LowestValueTitle={box?.LowestValueTitle}
                                  Tooltip={box?.Tooltip}
                                  TargetDisplay={
                                    box?.TargetDisplay == 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box?.PeakDisplay == 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box?.LowestDisplay == 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box?.LowestLineDisplay == 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box?.TargetLineDisplay == 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box?.PeakLineDisplay == 1 ? true : false
                                  }
                                  FontSizeLowest={box?.FontSizeLowest}
                                  FontSizePeak={box?.FontSizePeak}
                                  PositionLowestTB={box?.PositionLowestTB}
                                  PositionLowestLR={box?.PositionLowestLR}
                                  PositionTargetTB={box?.PositionTargetTB}
                                  PositionTargetLR={box?.PositionTargetLR}
                                  PositionPeakLR={box?.PositionPeakLR}
                                  PositionPeakTB={box?.PositionPeakTB}
                                  DataLabels={box?.DataLabels}
                                  LebalX={box?.LebalX == 1 ? true : false}
                                  LebalY={box?.LebalY == 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay == 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay == 1 ? true : false
                                  }
                                  PositionAverageLR={box?.PositionAverageLR}
                                  PositionAverageTB={box?.PositionAverageTB}
                                  AverageValueTitle={box?.AverageValueTitle}
                                  AverageValueC={box?.AverageValueC}
                                  FontSizeAverage={box?.FontSizeAverage}
                                  MovingDisplay={box?.MovingDisplay}
                                  MovingValueC={box?.MovingValueC}
                                  LavelYColor={box?.LavelYColor}
                                  LavelXColor={box?.LavelXColor}
                                  StrokeLine={box?.StrokeLine}
                                  LineTpyeSD={box?.LineTpyeSD}
                                  LineWidth={box?.LineWidth}
                                  LegendTextColor={box?.LegendTextColor}
                                  ValuePeak={box?.ValuePeak == 1 ? true : false}
                                  ValueTarget={
                                    box?.ValueTarget == 1 ? true : false
                                  }
                                  ValueLowest={
                                    box?.ValueLowest == 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Group" ? (
                              <div className="chartOrTableType Group">
                                <Group
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                    <span className="d-block tw-w-[100%]"></span>
                    {container?.containerHeaders?.map((item3, hbIndex) => (
                      <>
                        {item3?.position === "bottom" && (
                          <div
                            key={hbIndex}
                            className="ql-editor boxHeadingContainer"
                            style={{
                              width: `${item3.width}%`,
                              paddingTop: "0px",
                              paddingBottom: "8px",
                              paddingTop: "8px",
                              backgroundColor: item3.HeadlineBackground,
                            }}
                          >
                            <span
                              className="boxHeading"
                              dangerouslySetInnerHTML={{
                                __html: item3?.quillData,
                              }}
                            ></span>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={
            open === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button
              className="custom_close"
              onClick={CloseSecondModal2}
            ></button>
            <header className="custom_modal-header">
              <h2>Add Custom View</h2>
            </header>

            <form className="form-box addressForm" id="basic-form">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h6 className="card-title">Custom View Information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 px-3">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          value={view.Name}
                          onChange={handleView}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Description"
                          value={view.Description}
                          onChange={handleView}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Filter </label>
                        <textarea
                          type="text"
                          className="form-control"
                          name="filterType"
                          value={view.filterType}
                          onChange={handleView}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <footer className="custom_modal-footer d-flex tw-justify-end px-2 mb-2">
                    <button
                      className="submit-btn-primary"
                      type="submit"
                      onClick={handleAddView}
                    >
                      Accept
                    </button>
                    <button className="declane-btn-primary ms-3">Clear</button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className={
            open2 === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button
              className="custom_close"
              onClick={CloseSecondModal}
            ></button>
            <header className="custom_modal-header">
              <h2>Add Subscribe</h2>
            </header>

            <form className="form-box addressForm" id="basic-form">
              <div className="col-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <h6 className="card-title">Subscribe Information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Name"
                          value={subscribe.Name}
                          onChange={handleSubscribe}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          name="Description"
                          value={subscribe.Description}
                          onChange={handleSubscribe}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Schedule </label>
                        <select
                          className="form-select"
                          name="Schedule"
                          onChange={handleSubscribe}
                          value={subscribe.Schedule}
                        >
                          <option value="">Select</option>
                          <option value="Daily">Daily</option>
                          <option value="Weekly">Weekly</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="BiAnnually">Bi Annually</option>
                          <option value="Annually">Annually</option>
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Custom View </label>
                        <select
                          className="form-select"
                          name="View"
                          onChange={handleSubscribe}
                          value={subscribe.View}
                        >
                          <option>Select View</option>
                          <option value="default">Default</option>
                          {subView?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.Name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <footer className="custom_modal-footer d-flex tw-justify-end mt-3 ">
                    <button
                      className="submit-btn-primary"
                      type="submit"
                      onClick={handleAddSubscrib}
                    >
                      Accept
                    </button>
                    <button className="declane-btn-primary ms-3">Clear</button>
                  </footer>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscribeTemplate;
