import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import CryptoJS from "crypto-js";

function AddGroup() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [addUser, setAddUser] = useState([]);
  const [addressess, setAddressess] = useState([
    {
      userAccount: "",
    },
  ]);
  const [address, setAddress] = useState({
    userAccount: "",
  });
  const [row, setRow] = useState({
    GroupName: "",
    Description: "",
  });
  const [preloaderOn, setPreloaderOn] = useState(false);

  // User Account
  useEffect(() => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log("USER>>", res.data.data);
        setAddUser(res?.data?.data);
        setPreloaderOn(false);
      })
      .catch((error) => {
        // console.log(error);
        setPreloaderOn(false);
      });
  }, []);

  const handleExpand = (e) => {
    e.preventDefault();
    setAddressess((prev) => [...prev, address]);
  };

  const handleGroup = (e) => {
    const { name, value } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    // if (name === "GroupName") {
    //   if (/^[A-Za-z]+$/.test(value) || value === "") {
    //     setRow({
    //       ...row,
    //       [name]: capitalizeFirstLetter(value),
    //     });
    //   }
    // } else {
    setRow({ ...row, [name]: value });
  };

  const handleAddress = (e, i) => {
    const { name, value } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const isAlphabetic = /^[A-Za-z\s]+$/;

    const updatedAddresses = addressess.map((address, index) => {
      if (index === i) {
        if (name === "SubscriptionTemplate") {
          if (isAlphabetic.test(value) || value === "") {
            const newValue = capitalizeFirstLetter(value);
            return {
              ...address,
              [name]: newValue,
            };
          }
        } else {
          return {
            ...address,
            [name]: value,
          };
        }
      }
      return address;
    });
    setAddressess(updatedAddresses);
  };

  const handlePostGroup = (e) => {
    e.preventDefault();
    const list = addressess.map((item, i) => ({
      ...item,
    }));
    if (row.GroupName.trim() === "") {
      toast.warning("Please fill Group Name fields.");
    } else {
      setPreloaderOn(true);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}subscription/addGroup`,
          {
            list,
            ...row,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setRow({
            GroupName: "",
            Description: "",
          });
          setAddressess([
            {
              userAccount: "",
            },
          ]);
          setPreloaderOn(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setPreloaderOn(false);
        });
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...addressess];
    list.splice(index, 1);
    setAddressess(list);
  };

  const handleClearForm = (e) => {
    e.preventDefault();
    setRow({
      GroupName: "",
      Description: "",
    });
    setAddressess([
      {
        userAccount: "",
      },
    ]);
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Subscription Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add Group</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <form className="form-box" id="basic-form" onSubmit={handlePostGroup}>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title">Group information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 d-block">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Group Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="GroupName"
                        autoComplete="GroupName"
                        value={row.GroupName}
                        onChange={handleGroup}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        General Description
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <textarea
                        name="Description"
                        autoComplete="Description"
                        value={row.Description}
                        onChange={handleGroup}
                        rows={4}
                        type="email"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">
                  <h6 className="card-title">Select Users</h6>
                </div>
                <div className="card-body">
                  <div className="row ">
                    <label className="form-label">
                      User Account
                      <span className="AsteriskSymbol">*</span>
                    </label>
                    {addressess.map((item, i) => (
                      <>
                        <div className="col-sm-6 col-md-4 col-lg-4 d-flex">
                          <select
                            name="userAccount"
                            value={addressess[i].userAccount}
                            onChange={(e) => handleAddress(e, i)}
                            className="form-select select2"
                          >
                            <option selected>Select</option>
                            {addUser?.map((item, ij) => {
                              return (
                                <option key={ij} value={item.id}>
                                  {item.id +
                                    "." +
                                    " " +
                                    item.FirstName +
                                    " " +
                                    item.LastName}
                                </option>
                              );
                            })}
                          </select>
                          <span>
                            <button
                              type="button"
                              title="Remove"
                              onClick={(index) => handleServiceRemove(index)}
                              className="closemoreAddress-sub"
                              style={{ height: "35px" }}
                            >
                              X
                            </button>
                          </span>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4 tw-flex tw-justify-end">
                <button
                  onClick={(e) => handleExpand(e)}
                  className="btn add-more-btn d-inline"
                >
                  + Add More
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={handleClearForm}
                >
                  Clear
                </button>
                <button type="submit" className="btn submit-btn d-inline ms-3">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddGroup;
