import React, { useEffect, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Authentication/signup.css";

function ChooseOTP() {
  const systemLogin = sessionStorage.getItem("systemLogin");
  const [otp, setOTP] = useState({
    MobileInput: "",
    EmailInput: "",
  });
  const navigate = useNavigate();

  const handleOtpUpdate = (e) => {
    setOTP({ ...otp, [e.target.name]: e.target.value });
  };

  const handleCheckOTP = (type) => {
    if (type === "Mobile") {
      $(".Email").removeClass("show");
      $(".Mobile").addClass("show");
    } else {
      $(".Mobile").removeClass("show");
      $(".Email").addClass("show");
    }
  };

  const handelOTP = () => {
    const spinner =
      '<span className="spinner-border spinner-border-sm"></span> Loading.';
    const btns = $("#submitButton");

    btns.html(spinner);
    btns.attr("disabled", true);

    if ($("#Email").is(":checked")) {
      if (otp.EmailInput != "") {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(otp.EmailInput)) {
          toast.error("Please Enter Valid Email Address", { autoClose: 1000 });
          btns.html("Submit");
          btns.attr("disabled", false);
        } else {
          axios
            .put(`${process.env.REACT_APP_BASE_URL}login/sendotp`, {
              slID: systemLogin,
              email: otp.EmailInput,
              type: "email",
            })
            .then((res) => {
              // console.log(res.data);
              navigate(
                "/reset-password",
                sessionStorage.setItem("OTPType", "Email")
              );
              toast.success(res.data.message, { autoClose: 1000 });
            })
            .catch((error) => {
              btns.html("Submit");
              btns.attr("disabled", false);
              // console.log(error);
              if (error.response.data.status === 2) {
                toast.error("Invalied Email Address", { autoClose: 1000 });
              } else {
                toast.error(error.response.data.message, { autoClose: 1000 });
              }
            });
        }
      } else {
        btns.html("Submit");
        btns.attr("disabled", false);
        toast.error("Please Enter Email Address", { autoClose: 1000 });
      }
    } else if ($("#Mobile").is(":checked")) {
      if (otp.MobileInput === "") {
        btns.html("Submit");
        btns.attr("disabled", false);
        toast.error("Please Enter Valid Mobile Number", { autoClose: 1000 });
      } else {
        axios
          .put(`${process.env.REACT_APP_BASE_URL}login/sendotp`, {
            slID: systemLogin,
            mobile: otp.MobileInput,
            type: "mobile",
          })
          .then((res) => {
            // console.log(res.data);
            navigate(
              "/reset-password",
              sessionStorage.setItem("OTPType", "Mobile")
            );
            toast.success(res.data.message, { autoClose: 1000 });
          })
          .catch((error) => {
            btns.html("Submit");
            btns.attr("disabled", false);
            // console.log(error);
            toast.error(error.response.data.message, { autoClose: 1000 });
          });
      }
    } else {
      btns.html("Submit");
      btns.attr("disabled", false);
      toast.error("Please Select Any Option", { autoClose: 1000 });
    }
  };

  useEffect(() => {
    document.title = "sleap: Choose OTP";
  }, []);

  return (
    <>
      <div id="app" style={{ minHeight: "100vh" }}>
        <section
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="container">
            <div className="top mb-2 tw-w-[60%]">
              <div
                className="logo"
                style={{ padding: "0 8px", background: "#fff" }}
              >
                <img src="logo.png" alt="iSleep" className="img-fluid" />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 col-xl-5 ">
                <div className="card card-primary mb-0 px-3">
                  <div className="card-header">
                    <h3 className="text-center  w-100">Request for OTP</h3>
                  </div>
                  <div className="card-body">
                    <p style={{ lineHeight: "18px" }}>
                      <b>Select methode to receive OTP</b>: Secure your account
                      with a one-time code sent to your email or mobile for
                      enhanced authentication.
                    </p>

                    <form
                      method="POST"
                      action="#"
                      className="needs-validation"
                      noValidate=""
                    >
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="Mobile"
                          onChange={() => handleCheckOTP("Mobile")}
                        />
                        <label className="form-check-label" for="Mobile">
                          Mobile
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="Email"
                          name="type"
                          onChange={() => handleCheckOTP("Email")}
                        />
                        <label className="form-check-label" for="Email">
                          Email
                        </label>
                      </div>
                      <div className="form-check Email px-0">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter Register Email ID"
                          id="EmailInput"
                          name="EmailInput"
                          value={otp.EmailInput}
                          onChange={handleOtpUpdate}
                        />
                      </div>
                      <div className="form-check Mobile px-0">
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          placeholder="Enter Register Mobile Number"
                          id="MobileInput"
                          name="MobileInput"
                          value={otp.MobileInput}
                          onChange={handleOtpUpdate}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          id="submitButton"
                          type="button"
                          className="submit-btn-primary mt-3"
                          style={{ maxWidth: "150px" }}
                          onClick={handelOTP}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ChooseOTP;
