import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const PChart2 = () => {
  const [chartAllData, setChartAllData] = useState([44, 55, 13, 43, 22]);
  const [chartAllLabels, setChartAllLabels] = useState([
    "Data A",
    "Data B",
    "Data C",
    "Data D",
    "Data E",
  ]);

  useEffect(() => {
    axios
      .get(
        `https://sleapdata.com/resmedData/kpi?metrics=Age%20distribution/Gender`
      )
      .then((res) => {
        const responseData = res.data.pie[0];
        setChartAllData(responseData.data);
        setChartAllLabels(responseData.labelName);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  }, []);

  const [chartState, setChartState] = useState({
    series: chartAllData,
    options: {
      chart: {
        type: "pie",
        color: ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"],
        height: 280,
      },
      labels: chartAllLabels,
      legend: {
        display: true,
        position: "top",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return val?.toFixed(1) + "%";
        },
        offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: "#000",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    setChartState((prevState) => ({
      ...prevState,
      series: chartAllData,
      options: {
        ...prevState.options,
        labels: chartAllLabels || "Not Defined",
        legend: {
          show: true,
          position: "top",
          labels: {
            colors: "black",
          },
        },
      },
    }));
  }, [chartAllData, chartAllLabels]);

  return (
    <ReactApexChart
      options={chartState.options}
      series={chartState.series}
      type="pie"
      width={"100%"}
      height={280}
      style={{ backgroundColor: "transparent" }}
    />
  );
};

export default PChart2;
