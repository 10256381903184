import React, { useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import { ReactFormBuilder } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import "./app.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import * as variables from "./variables";
import Demobar from "./demobar";

function Home() {
  const [preloaderOn, setPreloaderOn] = useState(false);
  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Clinical Document
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Add Clinical Document
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <Demobar variables={variables} />
          <ReactFormBuilder
            variables={variables}
            locale="en"
            saveAlways={false}
          />
        </div>
      </div>
    </>
  );
}

export default Home;
