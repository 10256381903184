import React from "react";
import MainRoute from "./Routes/MainRoute";
import UserRoute from "./Routes/UserRoute";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Authentication/Login";
import ForgotPsw from "./Authentication/ForgotPsw";
import ChangePsw from "./Authentication/ChangePsw";
import Mfa from "./Authentication/Mfa";
import Signup from "./Authentication/Signup";
import FirstLogin from "./Authentication/FirstLogin";
import ChooseOTP from "./Authentication/ChooseOtp";

function App() {
  let type = sessionStorage.getItem("type");

  if (type === null) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPsw />} />
          <Route path="/change-password" element={<ChangePsw />} />
          <Route path="/mfa" element={<Mfa />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<FirstLogin />} />
          <Route path="/Choose-otp" element={<ChooseOTP />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    );
  }

  if (type === "admin") {
    return <MainRoute />;
  }

  if (type === "user") {
    return <UserRoute />;
  }
}

export default App;
