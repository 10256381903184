import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import CryptoJS from "crypto-js";

function CreateSubscription() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [templates, setTemplates] = useState();
  const [dataUser, setDataUser] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [category, setCategory] = useState("user");
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [addressess, setAddressess] = useState([
    {
      SubscriptionTemplate: "",
      GroupAccount: "",
      type: category,
    },
  ]);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [row, setRow] = useState({
    SubscriptionName: "",
    DefaultEmail: "",
    Description: "",
    EmailMessage: "",
    Daily: "",
    Weekly: "",
    Monthly: "",
    Quarterly: "",
    BiAnnually: "",
    Annually: "",
    schedule: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userRole/template`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log(res.data.data);
        setTemplates(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleExpand = (e) => {
    e.preventDefault();
    const newAddress = {
      SubscriptionTemplate: "",
      GroupAccount: "",
      type: category,
    };
    setAddressess((prev) => [...prev, newAddress]);
  };

  const handleDelete = (i) => {
    const list = [...addressess];
    list.splice(i, 2);
    setAddressess(list);
  };

  const handleChangePost = (e) => {
    const { name, value } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (name === "SubscriptionName") {
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Only Alphabets are allowed");
      }
    } else {
      setRow({ ...row, [name]: value });
    }
  };

  const handleAddress = (e, i, cate) => {
    const { name, value } = e.target;
    if (name == "GroupAccount") {
      addressess[i] = {
        ...addressess[i],
        GroupAccount: value,
        type: cate,
      };
    } else {
      addressess[i] = {
        ...addressess[i],
        [name]: value,
      };
    }
    setAddressess([...addressess]);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "DefaultEmail") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        toast.warning("Please enter a valid email address.", {
          autoClose: 1000,
        });
        setSubmitBtnDisable(true);
      } else {
        setSubmitBtnDisable(false);
      }
    }
  };

  const handleSubscription = (e) => {
    e.preventDefault();
    const newData = addressess?.map((item, i) => ({
      ...item,
    }));
    if (row.SubscriptionName.trim() === "" || row.DefaultEmail.trim() === "") {
      toast.warning(
        "Please fill Subscription Name & Default Email in the form."
      );
    } else {
      setPreloaderOn(true); // Set loading state to true
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}subscription`,
          {
            newData,
            ...row,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false); // Set loading state to false
          // console.log(res.data.data);
          toast.success(res.data.message);
          setAddressess([
            {
              SubscriptionTemplate: "",
              GroupAccount: "",
              type: category,
            },
          ]);
          setRow({
            SubscriptionName: "",
            DefaultEmail: "",
            Description: "",
            EmailMessage: "",
            Daily: "",
            Weekly: "",
            Monthly: "",
            Quarterly: "",
            BiAnnually: "",
            Annually: "",
            schedule: "",
          });
        })
        .catch((error) => {
          setPreloaderOn(false); // Set loading state to false
          // console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleUserAccount = (value, i) => {
    setCategory(value);
    addressess[i] = { ...addressess[i], type: value, GroupAccount: "" };
    setAddressess([...addressess]);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // // console.log("UserAccount>>", res.data.data);
        const selectValue = res?.data?.data;
        setDataUser(selectValue);
      })
      .catch((error) => {
        // console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}subscription/getGroup`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // // console.log("GroupAccount>>", res.data.message);
        setDataGroup(res?.data?.message);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    setAddressess([
      {
        SubscriptionTemplate: "",
        GroupAccount: "",
        type: category,
      },
    ]);
    setRow({
      SubscriptionName: "",
      DefaultEmail: "",
      Description: "",
      EmailMessage: "",
      Daily: "",
      Weekly: "",
      Monthly: "",
      Quarterly: "",
      BiAnnually: "",
      Annually: "",
      schedule: "",
    });
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Subscription Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add Subscription</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-12">
            <form
              className="form-box"
              id="basic-form"
              onSubmit={(e) => handleSubscription(e)}
            >
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title">Subscription Information</h6>
                </div>
                <div className="d-flex py-1 px-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-9">
                        <div className="row g-3">
                          <div className="col-sm-6">
                            <label className="form-label">
                              Name of Subscription
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <input
                              name="SubscriptionName"
                              autoComplete="SubscriptionName"
                              value={row.SubscriptionName}
                              onChange={handleChangePost}
                              type="text"
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label">
                              Default Sender E-mail
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              required
                              name="DefaultEmail"
                              autoComplete="DefaultEmail"
                              value={row.DefaultEmail || ""}
                              onBlur={handleBlur}
                              onChange={handleChangePost}
                            />
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label">
                              General Description
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <textarea
                              name="Description"
                              autoComplete="Description"
                              value={row.Description}
                              onChange={handleChangePost}
                              type="text"
                              rows={4}
                              className="form-control"
                              required
                            />
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label">
                              E-mail Message
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <textarea
                              type="text"
                              name="EmailMessage"
                              value={row.EmailMessage}
                              autoComplete="EmailMessage"
                              onChange={handleChangePost}
                              required
                              rows={4}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="col-12">
                          <label className="form-label">
                            Schedule
                            <span className="AsteriskSymbol">*</span>
                          </label>
                          <div className="form-check form-switch">
                            <input
                              name="schedule"
                              autoComplete="schedule"
                              value="Daily"
                              onChange={handleChangePost}
                              defaultChecked={row.Daily === true}
                              className="form-check-input"
                              type="radio"
                              id="notShared"
                            />
                            <label className="form-check-label">Daily</label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="schedule"
                              name="schedule"
                              value="Weekly"
                              onChange={handleChangePost}
                              defaultChecked={row.Weekly === true}
                            />
                            <label className="form-check-label">Weekly</label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="schedule"
                              value="Monthly"
                              onChange={handleChangePost}
                              defaultChecked={row.Monthly === true}
                            />
                            <label className="form-check-label">Monthly</label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="schedule"
                              name="schedule"
                              value="Quarterly"
                              onChange={handleChangePost}
                              defaultChecked={row.Quarterly === true}
                            />
                            <label className="form-check-label">
                              Quarterly
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="schedule"
                              name="schedule"
                              value="BiAnnually"
                              onChange={handleChangePost}
                              defaultChecked={row.BiAnnually === true}
                            />
                            <label className="form-check-label">
                              Bi Annually
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="schedule"
                              name="schedule"
                              value="Annually"
                              onChange={handleChangePost}
                              defaultChecked={row.Annually === true}
                            />
                            <label className="form-check-label">Annually</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {addressess?.map((item, i) => (
                <div className="card mt-3">
                  <div className="card-header d-flex tw-justify-between">
                    <h6 className="card-title">Subscription Security</h6>
                    {addressess.length - 1 === i && (
                      <span
                        onClick={() => handleDelete(i)}
                        className="subscriptionremove"
                      >
                        X
                      </span>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row g-3">
                      <div className="col-sm-6 col-md-5 col-lg-5">
                        <label className="form-label">
                          Template Name
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <select
                          className="form-select select2 mt-1"
                          name="SubscriptionTemplate"
                          value={addressess[i].SubscriptionTemplate}
                          onChange={(e) => handleAddress(e, i)}
                          required
                        >
                          <option selected>Select</option>
                          {templates?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-5 col-lg-5">
                        <div className="d-flex tw-gap-x-4">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="AccountType"
                              name={`AccountType_${i}`}
                              onChange={() => handleUserAccount("user", i)}
                              checked={addressess[i].type === "user"}
                            />
                            <label className="form-check-label">
                              User Account
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              autoComplete="group"
                              name={`AccountType_${i}`}
                              onChange={() => handleUserAccount("group", i)}
                              checked={addressess[i].type === "group"}
                            />
                            <label className="form-check-label">
                              Group Account
                            </label>
                          </div>
                        </div>
                        <select
                          name="GroupAccount"
                          value={addressess[i].GroupAccount}
                          onChange={(e) => handleAddress(e, i, category)}
                          className="form-select select2"
                        >
                          <option selected>Select</option>
                          {addressess[i].type === "user" && (
                            <>
                              {dataUser?.map((item, ij) => {
                                return (
                                  <option key={ij} value={item.id}>
                                    {item.id +
                                      "." +
                                      " " +
                                      item.FirstName +
                                      " " +
                                      item.LastName}
                                  </option>
                                );
                              })}
                            </>
                          )}
                          {addressess[i].type === "group" && (
                            <>
                              {dataGroup?.map((item, ijk) => {
                                return (
                                  <option key={ijk} value={item.id}>
                                    {item.id + "." + " " + item.GroupName}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-12 mt-4 tw-flex tw-justify-end">
                <button
                  onClick={(e) => handleExpand(e)}
                  type="submit"
                  className="btn add-more-btn d-inline"
                  disabled={submitBtnDisable}
                >
                  + Add More
                </button>
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  // onClick={(e) => handleSubscription(e)}
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  disabled={submitBtnDisable}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSubscription;
