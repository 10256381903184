import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Chart.css";
import axios from "axios";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const Chart15 = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [data, setData] = useState([
    { data: [44, 55, 41, 64, 22, 43, 21] },
    { data: [53, 32, 33, 52, 13, 44, 32] },
  ]);
  const [labels, setLabels] = useState([
    2001, 2002, 2003, 2004, 2005, 2006, 2007,
  ]);

  const backgroundColors = (
    props?.Color?.length ? props.Color : ["#26a0fc", "#26e7a6"]
  ).map((color) => color);

  const userId = sessionStorage.getItem("UserMainID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const username = "Servusys";
        // const password = "Admin@123";
        const username = process.env.REACT_APP_USERNAME;
        const password = process.env.REACT_APP_PASSWORD;
        const basicAuth = "Basic " + btoa(`${username}:${password}`);

        const url = chartFilter
          ? `https://kpiapi.sleapdata.com/api/keymetrics/?physicianID=${userId}&metrics=${props.metrics}&agerange=${chartFilter}&clicdatatype=${chartMeteic}`
          : `https://kpiapi.sleapdata.com/api/keymetrics/?metrics=${props.metrics}&physicianID=${userId}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: basicAuth,
          },
        });

        const responseData = response.data.column[0];
        setData(responseData?.data || []);
        setLabels(responseData?.labels || []);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (props.metrics) {
      fetchData();
    }
  }, [props.metrics, chartFilter, chartMeteic, userId]);

  const [chartData, setChartData] = useState({
    series: data,
    options: {
      chart: { type: "bar", height: "100%" },
      colors: backgroundColors,
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
        position: "center",
        formatter: function (val, opts) {
          const seriesData = data[opts.seriesIndex].data;
          const minVal = Math.min(...seriesData);
          const maxVal = Math.max(...seriesData);

          if (props.DataLabels == "all") {
            return val.toFixed(0);
          } else if (props.DataLabels == "min") {
            return val == minVal ? val.toFixed(0) : "";
          } else if (props.DataLabels == "max") {
            return val == maxVal ? val.toFixed(0) : "";
          } else if (props.DataLabels == "both") {
            return val == minVal || val == maxVal ? val.toFixed(0) : "";
          }
        },
        offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: "#000",
        },
      },
      stroke: { show: true, width: 1, colors: ["#fff"] },
      tooltip: {
        shared: true,
        enabled: props.Tooltip !== false,
        intersect: false,
      },
      legend: {
        show: props.LegendDisplay !== false,
        position: props.position ? props.position : "top",
        horizontalAlign: "center",
        fontSize: "12px",
        labels: { colors: "#26a0fc" },
      },
      xaxis: {
        categories: labels,
        title: {
          text: props.axisX ? props.XAxis : "",
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalX !== false,
          style: { colors: props.LavelXColor || "black" },
        },
      },
      yaxis: {
        title: {
          text: props.axisY ? props.YAxis : "",
          style: {
            fontSize: props.FontSize || 14,
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalY !== false,
          style: { colors: props.LavelYColor || "black" },
        },
      },
      grid: {
        show: true,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: { lines: { show: props.gridX !== false } },
        yaxis: { lines: { show: props.gridY !== false } },
      },
    },
  });

  const latestStateRef = useRef(chartData);

  useEffect(() => {
    latestStateRef.current = chartData;
  }, [chartData]);

  useEffect(() => {
    function handleChart1Click(event, chartContext, { dataPointIndex }) {
      if (dataPointIndex !== undefined) {
        latestStateRef.current.series.forEach((series, index) => {
          const clickedDataValue = series.data[dataPointIndex];
          const clickedCategory =
            latestStateRef.current.options.xaxis.categories[dataPointIndex];
          updateChartFilter(clickedCategory);
          updateChartMetrics(props.metrics);
        });
      }
    }
    setChartData((prevState) => ({
      ...prevState,
      series: data,
      options: {
        ...prevState.options,
        chart: {
          ...prevState.options.chart,
          stacked: props.Stacked == true,
          events: { dataPointSelection: handleChart1Click },
        },
        colors: backgroundColors,
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          position: "center",
          formatter: function (val, opts) {
            const seriesData = data[opts.seriesIndex].data;
            const minVal = Math.min(...seriesData);
            const maxVal = Math.max(...seriesData);

            if (props.DataLabels == "all") {
              return val.toFixed(0);
            } else if (props.DataLabels == "min") {
              return val == minVal ? val.toFixed(0) : "";
            } else if (props.DataLabels == "max") {
              return val == maxVal ? val.toFixed(0) : "";
            } else if (props.DataLabels == "both") {
              return val == minVal || val == maxVal ? val.toFixed(0) : "";
            }
          },
          offsetX: 0,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#000",
          },
        },
        stroke: { show: true, width: 1, colors: ["#fff"] },
        tooltip: {
          shared: true,
          enabled: props.Tooltip !== false,
          intersect: false,
        },
        legend: {
          show: props.LegendDisplay !== false,
          position: props.position ? props.position : "top",
          horizontalAlign: "center",
          fontSize: "12px",
          labels: {
            colors: props?.LegendTextColor || "black",
          },
        },
        xaxis: {
          categories: labels,
          title: {
            text: props.axisX ? props.XAxis : "",
            style: {
              fontSize: props.FontSize || "14px",
              color: props.TextColor || "black",
            },
          },
          labels: {
            show: props.LebalX !== false,
            style: { colors: props.LavelXColor || "black" },
          },
        },
        yaxis: {
          title: {
            text: props.axisY ? props.YAxis : "",
            style: {
              fontSize: props.FontSize || 14,
              color: props.TextColor || "black",
            },
          },
          labels: {
            show: props.LebalY !== false,
            style: { colors: props.LavelYColor || "black" },
          },
        },
        grid: {
          show: true,
          borderColor: "#f0f0f0",
          strokeDashArray: 4,
          position: "back",
          xaxis: { lines: { show: props.gridX !== false } },
          yaxis: { lines: { show: props.gridY !== false } },
        },
        annotations: {
          xaxis: [
            props.TargetDisplay && {
              x: props.TargetValue || 20,
              borderColor: props.TargetLineDisplay
                ? props.TargetValueLineColor || "#0073ff"
                : "",
              label: {
                borderColor: props.TargetValueLineColor || "#0073ff",
                offsetY: +props.PositionTargetTB || 0,
                offsetX: +props.PositionTargetLR || 0,
                style: {
                  color: props.LableTextColorTarget || "#fff",
                  background: props.TargetValueLineColor || "#0073ff",
                  fontSize: props.FontSizeTarget,
                  textAlign: "left",
                },
                text: props.ValueTarget
                  ? (props.TargetValueTitle &&
                      `${props.TargetValueTitle} - ${props.TargetValue}`) ||
                    `Target - ${props.TargetValue}`
                  : props.TargetValueTitle || "Target",
              },
            },
            props.PeakDisplay && {
              x: Math.max(...data.map((series) => Math.max(...series.data))),
              borderColor: props.PeakLineDisplay
                ? props.PeakValueC || "#ff0000"
                : "",
              label: {
                borderColor: props.PeakValueC || "#ff0000",
                offsetY: +props.PositionPeakTB || 0,
                offsetX: +props.PositionPeakLR || 0,
                style: {
                  color: "#fff",
                  fontSize: props.FontSizePeak || "12px",
                  background: props.PeakValueC || "#ff0000",
                },
                text: props.ValuePeak
                  ? (props.PeakValueTitle &&
                      `${props.PeakValueTitle} - ${Math.max(
                        ...data.map((series) => Math.max(...series.data))
                      )}`) ||
                    `Peak - ${Math.max(
                      ...data.map((series) => Math.max(...series.data))
                    )}`
                  : props.PeakValueTitle || "Peak",
              },
            },
            props.LowestDisplay && {
              x: Math.min(...data.map((series) => Math.min(...series.data))),
              borderColor: props.LowestLineDisplay
                ? props.LowestValueC || "#00ff00"
                : "",
              label: {
                borderColor: props.LowestValueC || "#00ff00",
                offsetY: +props.PositionLowestTB || 0,
                offsetX: +props.PositionLowestLR || 0,
                style: {
                  color: "#fff",
                  background: props.LowestValueC || "#00ff00",
                  fontSize: props.FontSizeLowest || "12px",
                },
                text: props.ValueLowest
                  ? (props.LowestValueTitle &&
                      `${props.LowestValueTitle} - ${Math.min(
                        ...data.map((series) => Math.min(...series.data))
                      )}`) ||
                    `Lowest - ${Math.min(
                      ...data.map((series) => Math.min(...series.data))
                    )}`
                  : props.LowestValueTitle || "Lowest",
              },
            },
          ].filter(Boolean),
        },
      },
    }));
  }, [props, data]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};
export default Chart15;
