import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

const SharedContext = createContext();
export const useSharedContext = () => useContext(SharedContext);

const getSessionItem = (key, defaultValue) =>
  sessionStorage.getItem(key) || defaultValue;

export const SharedDataSet = ({ children }) => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const typeOfUser = sessionStorage.getItem("type");

  const [emailValue, setEmailValue] = useState(
    getSessionItem("email", false) === "true"
  );
  const [notificationValue, setNotificationValue] = useState(
    getSessionItem("notification", false) === "true"
  );
  const [dataBtn, setDataBtn] = useState(
    parseInt(getSessionItem("btnValueCount", 0))
  );
  const [type, setType] = useState();
  const [activeUser, setActiveUser] = useState();
  const [uploadedImage, setUploadedImage] = useState(
    getSessionItem("uploadImage", "")
  );
  const [users, setUsers] = useState(0);
  const [providers, setProviders] = useState(0);
  const [newRequests, setNewRequests] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(0);
  const [newUserRequests, setNewUserRequests] = useState(0);
  const [rejectedRequests, setRejectedRequests] = useState(0);
  const [newProviderRequest, setNewProviderRequest] = useState(0);
  const [approvedRequests, setApprovedRequests] = useState(0);
  const [pendingData, setPendingData] = useState(0);
  const [imgs, setImgs] = useState("");
  const [data, setData] = useState(true);
  const [chartFilter, setChartFilter] = useState(null);
  const [chartMetric, setChartMetric] = useState(null);

  useEffect(() => {
    handleChangeNumbers();
    if (typeOfUser === "admin") {
      fetchProfileAdmin();
    } else if (typeOfUser === "user") {
      fetchProfileUser();
    }
  }, []);

  const handleChangeNumbers = async () => {
    try {
      const [providerRes, countRes] = await Promise.all([
        axios.get(`${process.env.REACT_APP_BASE_URL}users/getProvider`, {
          headers: { Authorization: token },
        }),
        axios.get(`${process.env.REACT_APP_BASE_URL}users/getCount`, {
          headers: { Authorization: token },
        }),
      ]);

      setProviders(providerRes.data.data.length);
      setApprovedRequests(countRes.data.approvedUser);
      setPendingRequest(countRes.data.pendingProvider);
      setNewUserRequests(countRes.data.pendingUser);
      setUsers(countRes.data.completedData);
      setNewRequests(countRes.data.newRequestData);
      setRejectedRequests(countRes.data.rejectedData);
      setPendingData(countRes.data.pendingData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const fetchProfileAdmin = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin?stage=byId&id=${
          parseJwt(token).id
        }`,
        {
          headers: { Authorization: token },
        }
      );
      handleProfileResponse(response.data);
    } catch (error) {
      console.error("Failed to fetch admin profile:", error);
    }
  };

  const fetchProfileUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}afterlogin/getuserdetails`,
        {
          headers: { Authorization: token },
        }
      );
      handleProfileResponse(response.data);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };

  const handleProfileResponse = (data) => {
    const { FirstName, LastName } = data.message;
    if (data.url && data.url.url) {
      setUploadedImage(data.url.url);
    }

    if (!data.url?.url) {
      const fname = FirstName || "";
      const lname = LastName || "";
      setImgs(fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase());
    }
    setData({
      ...data.message,
      ImageLocation: data.url?.url,
    });
    setActiveUser({
      ...data.message,
      ImageLocation: data.url?.url,
    });
  };

  const updateProfilePicture = (newPictureUrl) => {
    sessionStorage.setItem("uploadImage", newPictureUrl);
    setUploadedImage(newPictureUrl);
  };

  const updateEmailValue = (newValue) => {
    setEmailValue(newValue);
    sessionStorage.setItem("email", newValue.toString());
  };

  const updateNotificationValue = (newValue) => {
    setNotificationValue(newValue);
    sessionStorage.setItem("notification", newValue.toString());
  };

  const updateBtnVal = (newValue) => {
    setDataBtn(newValue);
    sessionStorage.setItem("btnValueCount", newValue.toString());
  };

  const updateChartFilter = (newFilter) => setChartFilter(newFilter);
  const updateChartMetrics = (newMetric) => setChartMetric(newMetric);

  const memoizedValue = useMemo(
    () => ({
      emailValue,
      updateEmailValue,
      notificationValue,
      updateNotificationValue,
      dataBtn,
      updateBtnVal,
      activeUser,
      setActiveUser,
      setType,
      type,
      pendingData,
      uploadedImage,
      imgs,
      updateProfilePicture,
      users,
      setUsers,
      providers,
      setProviders,
      newRequests,
      setNewRequests,
      pendingRequest,
      setPendingRequest,
      newUserRequests,
      setNewUserRequests,
      rejectedRequests,
      setRejectedRequests,
      newProviderRequest,
      setNewProviderRequest,
      approvedRequests,
      setApprovedRequests,
      handleChangeNumbers,
      data,
      chartFilter,
      updateChartFilter,
      chartMetric,
      updateChartMetrics,
    }),
    [
      emailValue,
      notificationValue,
      dataBtn,
      activeUser,
      type,
      pendingData,
      uploadedImage,
      imgs,
      users,
      providers,
      newRequests,
      pendingRequest,
      newUserRequests,
      rejectedRequests,
      newProviderRequest,
      approvedRequests,
      data,
      chartFilter,
      chartMetric,
    ]
  );

  return (
    <SharedContext.Provider value={memoizedValue}>
      {children}
    </SharedContext.Provider>
  );
};
