import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../CommonPage/Preloader";
import ToggleNavBar from "../CommonPage/ToggleNavBar";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useSharedContext } from "../CommonPage/SharedDataSet";
import AllUserRecords from "../CommonPage/AllUserRecords";
import loadingLogo from "./ZKZg.gif";
import CryptoJS from "crypto-js";

var toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }],
  [{ align: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  ["table"],
  [{ script: "sub" }, { script: "super" }],
  [{ color: [] }, { background: [] }],
];

const UserProfile = () => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [todoData, setTodoData] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [row, setRow] = useState({
    previousPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [todo, setTodo] = useState({
    title: "",
    content: "",
  });
  const [loadingImg, setLoadinImg] = useState(false);

  const handleTodo = (e) => {
    setTodo({ ...todo, [e.target.name]: e.target.value });
  };

  const handleTodoQuill = (content, delta, source, editor) => {
    const newContent = editor.getHTML();
    setTodo({ ...todo, content: newContent });
  };

  const { updateProfilePicture, data, uploadedImage, imgs } =
    useSharedContext();

  const handlePictureChange = (i) => {
    updateProfilePicture(i);
  };

  const UploadImage = (img) => {
    const formData = new FormData();
    formData.append("image", img);
    setLoadinImg(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}admin/uploadImage`, formData, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        handlePictureChange(res?.data?.url);
      })
      .catch(() => {})
      .finally(() => {
        setLoadinImg(false);
      });
  };

  const handlePostTodo = (e) => {
    e.preventDefault();
    setPreloaderOn(false);
    if (todo.title === "" || todo.content === "") {
      toast.warning("Please Fill the title and content Field.");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}admin/addTodo`,
          {
            title: todo.title,
            content: todo.content,
            SystemLogin: data?.SystemLogin,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setTodo({
            title: "",
            content: "",
          });
          setPreloaderOn(false);
          axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/getTodo`, {
              headers: {
                Authorization: token,
              },
            })
            .then((res) => {
              setTodoData(res?.data?.message);
              setPreloaderOn(false);
            });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setPreloaderOn(false);
        });
    }
  };

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    // console.log({ data });
  }, [data]);

  const handlePassword = (e) => {
    setRow({ ...row, [e.target.name]: e.target.value });
  };

  const handleUpdatePass = (e) => {
    e.preventDefault();
    if (row.newPassword === "" || row.confirmPassword === "") {
      toast.warning("Please enter a new password");
    } else {
      setPreloaderOn(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}admin/changePassword`, row, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          toast.success(res.data.message);
          setPreloaderOn(false);
          setRow({
            previousPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setPreloaderOn(false);
        });
    }
  };

  const handleClearPass = (e) => {
    e.preventDefault();
    setRow({ previousPassword: "", newPassword: "", confirmPassword: "" });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/getTodo`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setTodoData(res?.data?.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  return (
    <>
      <>
        {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
        <div className="container-fluid">
          <div className="block-header py-lg-4 py-3">
            <div className="row g-3">
              <div className="col-md-6 col-sm-12">
                <h2 className="m-0 fs-5">
                  <ToggleNavBar />
                  Profile
                </h2>
                <ul className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">sLEAP</Link>
                  </li>
                  <li className="breadcrumb-item active">My Profile</li>
                </ul>
              </div>
              <AllUserRecords />
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-md-12">
              <div className="card mb-3 profile-header">
                <div className="card-body text-center">
                  <div className="profile-image mb-3">
                    {loadingImg === false && uploadedImage ? (
                      <img
                        src={uploadedImage}
                        className="rounded-circle"
                        alt="Profile"
                      />
                    ) : (
                      <div className="noimg" style={{ fontSize: "30px" }}>
                        {imgs || "No Image"}
                      </div>
                    )}
                  </div>
                  <div>
                    <h4 className="">
                      <strong>{data.FirstName}</strong> {data.LastName}
                    </h4>
                    <span>{data.City + ", " + data.Country}</span>
                  </div>
                  <div>
                    <p>Upload Profile Photo</p>
                    <input
                      type="file"
                      disabled={loadingImg}
                      onChange={(e) => UploadImage(e.target.files[0])}
                      placeholder="Upload Image"
                      className="form-control"
                    />
                  </div>
                  <div className="mt-3 d-none">
                    <button className="btn btn-primary">Follow</button>
                    <button className="btn btn-outline-secondary">
                      Message
                    </button>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="card-header">
                  <h6 className="card-title">Info</h6>
                </div>
                <div className="card-body">
                  <small className="text-muted">Address: </small>
                  <p>{data.City}</p>
                  <hr />
                  <small className="text-muted">Email address: </small>
                  <p>{data.E_mail}</p>
                  <hr />
                  <small className="text-muted">Mobile: </small>
                  <p>{data.PhoneNumber}</p>
                  <hr />
                  <small className="text-muted">Birth Date: </small>
                  <p className="">{data.DOB}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="card mb-3">
                <div className="card-body">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="Overview-tab"
                        data-bs-toggle="tab"
                        href="#Overview"
                        role="tab"
                      >
                        Overview
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="Settings-tab"
                        data-bs-toggle="tab"
                        href="#Settings"
                        role="tab"
                      >
                        Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="tab-content p-0" id="myTabContent">
                <div className="tab-pane fade show active" id="Overview">
                  <div className="card mb-3">
                    <div className="card-header custom-headerType2">
                      <h3 className="p-0 m-0">Add Todo</h3>
                    </div>
                    <div className="mb-1 px-3 mt-2">
                      <label>
                        Enter Todo Title
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={todo.title}
                        onChange={handleTodo}
                        className="form-control"
                        placeholder="Add Todo Title"
                      />
                    </div>
                    <div className="card-body">
                      <div className="mb-3">
                        <label>
                          Enter Todo Content
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={todo.content}
                          onChange={handleTodoQuill}
                          modules={{ toolbar: toolbarOptions }}
                        ></ReactQuill>
                      </div>
                      <div className="post-toolbar-b">
                        <button
                          onClick={handlePostTodo}
                          className="btn btn-primary"
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header custom-headerType2">
                      <h4 className="p-0 m-0">Todo List</h4>
                    </div>
                    {todoData.map((todo, to) => {
                      return (
                        <div className="card-body" key={to}>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 mb-2 border">
                              <h5 className="p-0 m-0">{todo.title}</h5>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: todo?.content,
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="tab-pane fade" id="Settings">
                  <form className="tw-w-full">
                    <div className="card mb-3">
                      <div className="card-body">
                        <h6 className="card-title">Basic Information</h6>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={data?.FirstName}
                                placeholder="First Name"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={data?.MiddleName}
                                placeholder="Middle Name"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={data?.LastName}
                                placeholder="Last Name"
                              />
                            </div>
                            <div className="mb-3">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="SexType"
                                  value={data?.SexType}
                                  checked={data?.SexType === "1" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="primary"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="SexType"
                                  value={data?.SexType}
                                  checked={data?.SexType === "2" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="primary"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="SexType"
                                  value={data?.SexType}
                                  checked={data?.SexType === "3" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="primary"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                </div>
                                <input
                                  data-provide="datepicker"
                                  data-date-autoclose="true"
                                  value={data?.FirstName}
                                  className="form-control"
                                  placeholder="Birthdate"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address Line 1"
                                value={data?.AddressLine1}
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address Line 2"
                                value={data?.AddressLine2}
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={data?.City}
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                value={data?.County}
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                value={data?.Country}
                              />
                            </div>
                          </div>
                        </div>
                        <button type="button" className="btn btn-primary">
                          Update
                        </button>
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-secondary">
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <h6 className="card-title">Account Data</h6>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={data?.SystemLogin}
                                disabled
                                placeholder="Username"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="email"
                                className="form-control"
                                value={data?.E_mail}
                                placeholder="Email"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                value={data?.PhoneNumber}
                                placeholder="Phone Number"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <h6 className="card-title">Change Password</h6>
                            <div className="mb-3">
                              <input
                                type="password"
                                name="previousPassword"
                                value={row.previousPassword}
                                onChange={handlePassword}
                                className="form-control"
                                placeholder="Current Password"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="password"
                                name="newPassword"
                                value={row.newPassword}
                                onChange={handlePassword}
                                className="form-control"
                                placeholder="New Password"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="password"
                                name="confirmPassword"
                                value={row.confirmPassword}
                                onChange={handlePassword}
                                className="form-control"
                                placeholder="Confirm New Password"
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleUpdatePass}
                        >
                          Update
                        </button>
                        &nbsp;&nbsp;
                        <button
                          className="btn btn-secondary"
                          onClick={handleClearPass}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="row g-3 text-center">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-center"
                        id="Events"
                      ></div>
                      <h6>Events</h6>
                      <span>12 of this month</span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-center"
                        id="Birthday"
                      ></div>
                      <h6>Birthday</h6>
                      <span>4 of this month</span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-center"
                        id="Conferences"
                      ></div>
                      <h6>Conferences</h6>
                      <span>8 of this month</span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-center"
                        id="Seminars"
                      ></div>
                      <h6>Seminars</h6>
                      <span>2 of this month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default UserProfile;
