import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart23 = (props) => {
  const backgroundColors = (
    props?.Color?.length ? props.Color : ["#00E396"]
  ).map((color) => color);

  const userId = sessionStorage.getItem("UserMainID");

  useEffect(() => {
    if (props.metrics && props.metrics !== undefined) {
      axios
        .get(
          `https://kpiapi.sleapdata.com/api/keymetrics/?metrics=${props.metrics}&physicianID=${userId}`
        )
        .then((res) => {
          const responseData = res.data;
          // console.log("Chart 23", responseData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.metrics]);

  const [series, setSeries] = useState([
    {
      name: "Date",
      data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  });

  const latestStateRef = useRef(series);

  useEffect(() => {
    latestStateRef.current = series;
  }, [series]);

  useEffect(() => {
    function handleChart1Click(event, chartContext, { dataPointIndex }) {
      if (dataPointIndex !== undefined) {
        const clickedDataValue =
          latestStateRef.current.series[0].data[dataPointIndex];
        // console.log("Total Value", clickedDataValue);
        // console.log("dataPointIndex", dataPointIndex);
      }
    }
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        type: "bar",
        height: "100%",
        events: {
          dataPointSelection: handleChart1Click,
        },
      },
      colors: backgroundColors || ["#00E396"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          if (props.DataLabels === "all") {
            return val.toFixed(0);
          } else if (props.DataLabels === "min") {
            if (
              opts.dataPointIndex ===
              series[0].data.indexOf(Math.min(...series[0].data))
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "max") {
            if (
              opts.dataPointIndex ===
              series[0].data.indexOf(Math.max(...series[0].data))
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "both") {
            const minValueIndex = series[0].data.indexOf(
              Math.min(...series[0].data)
            );
            const maxValueIndex = series[0].data.indexOf(
              Math.max(...series[0].data)
            );
            if (
              opts.dataPointIndex === minValueIndex ||
              opts.dataPointIndex === maxValueIndex
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          }
        },
      },
    }));
  }, [props, props.DataLabels, series]);

  useEffect(() => {
    const maxDataValue = Math.max(
      ...series.map((series) => Math.max(...series.data))
    );

    const newAnnotations = [
      props.PeakDisplay === true && {
        y: maxDataValue,
        borderColor: props.PeakLineDisplay ? props.PeakValueC || "#ff0000" : "",
        label: {
          borderColor: props.PeakValueC || "#ff0000",
          offsetY: +props.PositionPeakTB || 0,
          offsetX: +props.PositionPeakLR || 0,
          style: {
            color: "#fff",
            background: props.PeakValueC || "#ff0000",
            fontSize: props.FontSizePeak || "12px",
          },
          text:
            props.ValuePeak === true
              ? (props.PeakValueTitle &&
                  props.PeakValueTitle + " - " + maxDataValue) ||
                "Peak" + " - " + maxDataValue
              : props.PeakValueTitle || "Peak",
        },
      },
    ].filter((annotation) => annotation);

    setOptions((prevState) => ({
      ...prevState,
      annotations: {
        yaxis: newAnnotations,
      },
    }));
  }, [props, series]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart23;
