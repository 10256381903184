import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import { ReactFormGenerator, ReactFormBuilder } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import "./app.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import axios from "axios";
import { Table } from "../../CommonPage/TableContent";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function FormView() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [formData, setFormData] = useState([]);
  const [view, setView] = useState([]);
  const [editData, setEditData] = useState([]);
  const [edName, setEdName] = useState({ name: "" });
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const closeModalBox = () => {
    setOpenModal(false);
  };

  const closeModalBox2 = () => {
    setOpenModal2(false);
    setEditData([]);
    setEdName({ name: "" });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Clinical ID",
        accessor: "id",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    setPreloaderOn(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}clinicalDocument/get?task=all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = res.data?.data || [];
      setPreloaderOn(false);
      const formattedData = data.map((item, index) => ({
        sno: index + 1,
        name: item?.name || "N/A",
        id: item?.id ? (
          <span className="d-flex tw-justify-center tw-items-center">
            {item.id}
          </span>
        ) : (
          ""
        ),
        status: item?.Active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">In-Active</span>
        ),
        action: (
          <div className="action-btn-box d-flex tw-justify-center tw-items-center">
            {/*<Link
              to=""
              className="edit"
              onClick={() => handleEditClick(item.id)}
            >
              <i className="fa fa-pencil"></i>
        </Link>*/}
            <Link className="view" onClick={() => previewBtnClick(item.id)}>
              <i className="fa fa-eye"></i>
            </Link>
            <Link
              to=""
              className="delete"
              onClick={() => handleDeleteClick(item?.id)}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        ),
      }));

      setFormData(formattedData);
    } catch (error) {
      setPreloaderOn(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const previewBtnClick = async (id) => {
    setPreloaderOn(true);
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}clinicalDocument/get?task=single&id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const formatted = JSON.parse(response.data.data.data);
      setView(formatted);
      setOpenModal(true);
      setPreloaderOn(false);
    } catch (error) {
      console.error("Error fetching document:", error);
      setPreloaderOn(false);
    }
  };

  const handleName = (e) => {
    const { name, value } = e.target;
    setEdName((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleEditClick = async (id) => {
    try {
      setOpenModal2(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}clinicalDocument/get?task=single&id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const formatted = JSON.parse(response.data.data.data);
      setEditData(formatted);
      setEdName({ name: response.data.data.name });
      setOpenModal2(true);
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const handleEditSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}clinicalDocument/update`,
        { data: editData, name: edName.name },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      fetchData();
      setOpenModal2(false);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleFormBuilderChange = (data) => {
    setEditData(data);
  };

  const handleDeleteClick = async (id) => {
    setPreloaderOn(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}clinicalDocument/delete?id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success(response.data.message);
      fetchData();
      setPreloaderOn(false);
    } catch (error) {
      console.error("Error deleting document:", error);
      setPreloaderOn(false);
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Clinical Document
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Edit Clinical Document
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={formData} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={openModal ? "custom_modal open openModal" : "custom_modal"}
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Preview Clinical Document</h2>
            </header>
            <div className="row g-2 px-3 tw-w-[100%] clearfix row-deck">
              <div className="card">
                <div className="card-body">
                  <ReactFormGenerator
                    download_path=""
                    back_name="Back"
                    answer_data={{}}
                    action_name="Save"
                    form_method="POST"
                    variables={{}}
                    data={view}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal2 ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox2}></button>
            <header className="custom_modal-header">
              <h2>Edit Clinical Document</h2>
            </header>
            <div className="row g-2 px-3 tw-w-[100%] clearfix row-deck">
              <div className="card">
                <div className="card-body">
                  <div className="tw-w-[100%] mb-3">
                    <label className="form-label">Clinical Document Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="name"
                      value={edName.name}
                      onChange={handleName}
                      required
                      style={{ width: "400px" }}
                    />
                  </div>
                  <ReactFormBuilder
                    data={editData}
                    onChange={handleFormBuilderChange}
                  />
                  ``
                  <button
                    onClick={handleEditSubmit}
                    className="btn btn-primary mt-3"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormView;
