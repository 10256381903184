import Chart from 'react-apexcharts';

const PChart = (props) => {
    const options = {
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: '#000',
        },
        },
        chart: {
            width: 190,
            height: 190,
            type: "pie",   
        },
        labels: ["With Prescription", "Without Prescription"],
        colors: ['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color4)'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };

    return (
        <div id="sparkline-pie">
            <Chart options={options} series={[props.existDataNo || 0, props.existData || 0]} type="pie" width={options.chart.width} height={options.chart.height} />
        </div>
    );
}

export default PChart;
