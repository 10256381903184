import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

function FirstLogin() {
  const systemLogin = sessionStorage.getItem("systemLogin");
  const [securityQuestion, setSecurityQuestion] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [updatePass, setUpdatePass] = useState({
    Password: "",
    ConfirmPassword: "",
    OTP: "",
    Question1: "",
    Question2: "",
    Question3: "",
    Answer1: "",
    Answer2: "",
    Answer3: "",
  });
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=securityQuestion`)
      .then((response) => {
        // console.log(response.data.data);
        setSecurityQuestion(response.data.data);
      })
      .catch((error) => {
        toast(error);
      });
  }, []);

  const handlePassword = (e) => {
    if (e.target.name == "Question1") {
      if (
        e.target.value === updatePass.Question2 ||
        e.target.value === updatePass.Question3
      ) {
        toast.warning(
          "This question already selected. Please choose different question"
        );
      } else {
        setUpdatePass({ ...updatePass, [e.target.name]: e.target.value });
      }
    } else if (e.target.name == "Question2") {
      if (
        e.target.value === updatePass.Question1 ||
        e.target.value === updatePass.Question3
      ) {
        toast.warning(
          "This question already selected. Please choose different question"
        );
      } else {
        setUpdatePass({ ...updatePass, [e.target.name]: e.target.value });
      }
    } else if (e.target.name == "Question3") {
      if (
        e.target.value === updatePass.Question1 ||
        e.target.value === updatePass.Question2
      ) {
        toast.warning(
          "This question already selected. Please choose different question"
        );
      } else {
        setUpdatePass({ ...updatePass, [e.target.name]: e.target.value });
      }
    } else {
      setUpdatePass({ ...updatePass, [e.target.name]: e.target.value });
    }
  };

  const handleUpdatePass = () => {
    const spinner =
      '<span className="spinner-border spinner-border-sm"></span> Loading.';
    const btns = $("#submitButton");

    btns.html(spinner);
    btns.attr("disabled", true);

    if (updatePass.Password !== updatePass.ConfirmPassword) {
      toast.error("Passwords do not match");
      btns.html("Submit");
      btns.attr("disabled", false);
      return;
    } else if (
      updatePass.Password === "" ||
      updatePass.ConfirmPassword === "" ||
      updatePass.OTP === "" ||
      updatePass.Question1 === "" ||
      updatePass.Answer1 === "" ||
      updatePass.Answer2 === "" ||
      updatePass.Answer3 === "" ||
      updatePass.Question2 === "" ||
      updatePass.Question3 === ""
    ) {
      toast.error("Please fill required field");
      btns.html("Submit");
      btns.attr("disabled", false);
    } else {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}login/updatePassword`, {
          slID: systemLogin,
          ...updatePass,
        })
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          navigate("/");
        })
        .catch((error) => {
          // console.log(error);
          btns.html("Submit");
          btns.attr("disabled", false);
          toast.error(error.response.data.message, { autoClose: 1000 });
        });
    }
  };

  useEffect(() => {
    document.title = "sleap: First Login";
  }, []);

  return (
    <>
      <div id="app" style={{ minHeight: "100vh" }}>
        <section
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xl-12 mx-auto">
                <div className="card card-primary mb-0">
                  <div className="card-header" style={{ padding: "5px" }}>
                    <h3>Reset Your password here</h3>
                  </div>
                  <div className="card-body px-2">
                    <p style={{ lineHeight: "18px" }}>
                      Content will be add here.
                    </p>
                    <p>
                      <b>System Login ID : </b>
                      <span className="text-info">{systemLogin}</span>
                    </p>
                    <p>
                      Successfully OTP has been sent on your registered E-mail
                    </p>
                    <form
                      method="POST"
                      action="#"
                      className="needs-validation"
                      noValidate=""
                    >
                      <div className="row g-3">
                        <div className="col-md-4">
                          <div className="form-group mt-3">
                            <strong>New Password</strong>{" "}
                            <span className="text-danger">*</span>
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon21"
                              >
                                <i className="fa fa-lock"></i>
                              </span>
                              <input
                                type={showPassword ? "text" : "password"}
                                name="Password"
                                value={updatePass.Password}
                                onChange={handlePassword}
                                className="form-control key"
                                placeholder="Password"
                                aria-label="key"
                                aria-describedby="basic-addon21"
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={togglePasswordVisibility}
                                style={{ height: "36px" }}
                              >
                                {showPassword ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mt-3">
                            <strong>Confirm Password</strong>{" "}
                            <span className="text-danger">*</span>
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon21"
                              >
                                <i className="fa fa-lock"></i>
                              </span>
                              <input
                                type={showPassword ? "text" : "password"}
                                name="ConfirmPassword"
                                value={updatePass.ConfirmPassword}
                                onChange={handlePassword}
                                className="form-control key"
                                placeholder="Password"
                                aria-label="key"
                                aria-describedby="basic-addon21"
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary"
                                onClick={togglePasswordVisibility}
                                style={{ height: "36px" }}
                              >
                                {showPassword ? (
                                  <i className="fa fa-eye-slash"></i>
                                ) : (
                                  <i className="fa fa-eye"></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group mt-3">
                            <strong>OTP Code</strong>{" "}
                            <span className="text-danger">*</span>
                            <div className="input-group">
                              <span
                                className="input-group-text"
                                id="basic-addon21"
                              >
                                <i className="fa fa-key"></i>
                              </span>
                              <input
                                type="number"
                                name="OTP"
                                value={updatePass.OTP}
                                onChange={handlePassword}
                                className="form-control key"
                                placeholder="000000"
                                aria-label="key"
                                aria-describedby="basic-addon21"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label htmlFor="frist_name">
                            Security Question 1{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-select mb-1"
                            name="Question1"
                            value={updatePass.Question1}
                            onChange={handlePassword}
                            required
                          >
                            <option value="">Select</option>
                            {securityQuestion?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {" "}
                                  {item.Type}
                                </option>
                              );
                            })}
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Security Question 1 answer"
                            name="Answer1"
                            value={updatePass.Answer1}
                            onChange={handlePassword}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="frist_name">
                            Security Question 2{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-select mb-1"
                            name="Question2"
                            value={updatePass.Question2}
                            onChange={handlePassword}
                            required
                          >
                            <option value="">Select</option>
                            {securityQuestion?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {" "}
                                  {item.Type}
                                </option>
                              );
                            })}
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Security Question 2 answer"
                            name="Answer2"
                            value={updatePass.Answer2}
                            onChange={handlePassword}
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="frist_name">
                            Security Question 3{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-select mb-1"
                            name="Question3"
                            value={updatePass.Question3}
                            onChange={handlePassword}
                            required
                          >
                            <option value="">Select</option>
                            {securityQuestion?.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>
                                  {" "}
                                  {item.Type}
                                </option>
                              );
                            })}
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Security Question 3 answer"
                            name="Answer3"
                            value={updatePass.Answer3}
                            onChange={handlePassword}
                          />
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <div className="d-flex tw-justify-center">
                          <button
                            id="submitButton"
                            onClick={handleUpdatePass}
                            className="submit-btn-primary"
                            style={{ maxWidth: "160px" }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default FirstLogin;
