import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

const ProtectedRoute = ({ children }) => {
  // const user = JSON.parse(sessionStorage.getItem("token"));
  const { dataId } = useParams();
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);

  if (dataId) {
    sessionStorage.setItem("dataMainID", dataId);
  }

  if (!token) {
    window.location.href = "/";
    return;
  }

  return children;
};

export default ProtectedRoute;
