import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function SecurityQuestionType() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  // State variables for managing data and form inputs
  const [data, setData] = useState([]);
  const [row, setRow] = useState({ Type: "" });
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedType, setEditedType] = useState("");
  const [preloaderOn, setPreloaderOn] = useState(false);

  // Function to handle edit button click
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setEditedType(item.Type);
    setOpenModal(true);
  };

  // Function to close modal
  const closeModalBox = () => {
    setOpenModal(false);
  };

  // Columns definition for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Security Question Type",
        accessor: "description",
      },
      {
        Header: "#",
        accessor: "C",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  // Function to fetch Security Question Type data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}types?type=securityQuestion&category=all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Format fetched data for display
      const formattedData = response.data.data.map((item, index) => ({
        sno: index + 1,
        C: (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              value={item.id}
              onChange={(e) =>
                handleChangeCheck(
                  e.target.value,
                  e.target.checked ? true : false
                )
              }
              defaultChecked={item.Active}
            />
          </div>
        ),
        description: item.Type,
        status: item.Active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">In-Active</span>
        ),
        action: (
          <div className="action-btn-box">
            <Link to="" className="edit" onClick={() => handleEditClick(item)}>
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              to=""
              className="delete"
              onClick={() => handleDeleteClick(item.id)}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        ),
      }));
      // Update state with formatted data
      setData(formattedData);
    } catch (error) {
      // console.log(error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle form input change
  const handleChange = (e) => {
    setRow({ [e.target.name]: e.target.value, Active: true });
  };

  // Function to handle form submission
  const handleClick = (e) => {
    e.preventDefault();
    if (row.Type === "") {
      toast.warning("Please fill Secutity Question");
    } else {
      setPreloaderOn(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}types?type=securityQuestion`,
          row,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.message, { autoClose: 1000 });
          fetchData();
          setRow({ Type: "" });
        })
        .catch((error) => {
          setPreloaderOn(false);
        });
    }
  };

  // Function to handle delete action
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}types/archive?type=securityQuestion`,
        { id },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message);
      });
  };

  // Function to handle update action
  const handleUpdateProvider = () => {
    if (!editedType) {
      toast.warning("Field cannot be Empty");
    } else {
      setPreloaderOn(true);
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}types?type=securityQuestion`,
          {
            ...selectedItem,
            Type: editedType,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.message, { autoClose: 1000 });
          fetchData();
          setOpenModal(false);
        })
        .catch((error) => {
          setPreloaderOn(false);
          toast.error(error.response.data.message);
        });
    }
  };

  // Function to handle checkbox change
  const handleChangeCheck = (a, b) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}types?type=securityQuestion`,
        {
          id: a,
          Active: b,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        fetchData();
        if (b === true) {
          toast.success("Activation successful! ", { autoClose: 1000 });
        } else {
          toast.success("Deactivation successful!", { autoClose: 1000 });
        }
      })
      .catch((error) => {
        toast.error(error.data.message, { autoClose: 1000 });
      });
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Category List
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Security Question Type
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <form className="form-box" id="basic-form" method="post" novalidate>
            <div className="col-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h6 className="card-title">Security Question Type</h6>
                </div>

                <div className="card-body">
                  <div className="row tw-px-[13px]">
                    <div className="col-sm-6 col-md-4">
                      <label className="form-label">
                        Add Security Question Type
                      </label>
                      <input
                        type="text"
                        name="Type"
                        autoComplete="Type"
                        value={row.Type}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <button
                        onClick={handleClick}
                        type="submit"
                        className="btn submit-btn tw-mt-[22px]"
                      >
                        Add Security Question Type
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="row g-2 clearfix row-deck mt-3">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          openModal === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[50%]">
          <button className="custom_close" onClick={closeModalBox}></button>
          <header className="custom_modal-header">
            <h2>Edit Security Question Type</h2>
          </header>
          <div className="tw-w-[100%]">
            <div className="col-12">
              <label className="form-label">Edit Security Question Type</label>
              <input
                type="text"
                value={editedType}
                onChange={(e) => setEditedType(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </div>
          <footer className="custom_modal-footer">
            <button
              className="action"
              type="submit"
              onClick={handleUpdateProvider}
            >
              Accept
            </button>
            <button className="action" onClick={closeModalBox}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

export default SecurityQuestionType;
