import React, { useState, useMemo, useEffect, useRef } from "react";
import { Table } from "../../../CommonPage/TableContent";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import axios from "axios";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";
import { Tooltip } from "react-tooltip";

const Group = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [activeTab2, setActiveTab2] = useState("daily");
  const [records, setRecords] = useState([]);
  const [barData, setBarData] = useState([]);
  const [barLabel, setBarLabel] = useState([]);
  const userId = sessionStorage.getItem("UserMainID");

  const [dailyData, setDailyData] = useState({
    Usage_Hours: [],
  });
  const [weeklyData, setWeeklyData] = useState({
    Usage_Hours: [],
  });
  const [monthlyData, setMonthlyData] = useState({
    Usage_Hours: [],
  });
  const [yearlyData, setYearlyData] = useState({
    Usage_Hours: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const username = "Servusys";
        // const password = "Admin@123";
        const username = process.env.REACT_APP_USERNAME;
        const password = process.env.REACT_APP_PASSWORD;
        const basicAuth = "Basic " + btoa(`${username}:${password}`);

        const params = {
          physicianID: userId,
          metrics: props.metrics,
          clicdatatype: chartMeteic,
          time: activeTab2,
          contact_id: chartFilter,
        };

        const response = await axios.get(
          `https://kpiapi.sleapdata.com/api/keymetrics/`,
          {
            params,
            headers: {
              Authorization: basicAuth,
            },
          }
        );

        if (response.data) {
          setDailyData(response?.data?.Daily);
          setWeeklyData(response?.data?.Weekly);
          setMonthlyData(response?.data?.Monthly);
          setYearlyData(response?.data?.Yearly);
          const formattedData = response?.data?.table_data?.map(
            (item, key) => ({
              Sn: key + 1,
              Pid: item.Contact__c || "N/A",
              totalusagehours: item?.Average_Usage_Hours__c.toFixed(2) || 0,
              compilanice: item?.CompliancePercentage__c.toFixed(2) || 0,
              dailysparkline: item?.Usage_Hours__c || [],
              Session_Date__c: item?.Session_Date__c || [],
            })
          );
          setRecords(formattedData);
          setBarData(response?.data?.ComplianceDistribution?.data || []);
          setBarLabel(response?.data?.ComplianceDistribution?.labelName || []);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (props.metrics) {
      fetchData();
    }
  }, [props.metrics, chartFilter, chartMeteic, userId, activeTab2]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "Sn",
      },
      {
        Header: "Patient ID",
        accessor: "Pid",
      },
      {
        Header: "Average Usage Hours",
        accessor: "totalusagehours",
      },
      {
        Header: "Compliance %",
        accessor: "compilanice",
      },
      {
        Header: "Daily Spark Line",
        accessor: "dailysparkline",
        Cell: ({ value, row }) => {
          const sessionDates = row.original.Session_Date__c || [];
          const formattedDates = sessionDates.map((date) => date.split("T")[0]);

          return (
            <div style={{ position: "relative" }}>
              <Sparklines data={value} height={15} width={150}>
                <SparklinesLine color="green" />
              </Sparklines>
              {value.map((val, index) => (
                <div
                  key={index}
                  style={{
                    position: "absolute",
                    left: `${(index / value.length) * 100}%`,
                    top: "0px",
                    width: "10px",
                    height: "15px",
                    transform: "translateX(-50%)",
                    cursor: "pointer",
                  }}
                  data-tooltip-id={`sparkline-tooltip-${row.id}-${index}`}
                  data-tooltip-content={`Session Date: ${formattedDates[index]} - Usage Hours: ${val}`}
                />
              ))}
              {value?.map((_, index) => (
                <Tooltip
                  key={index}
                  id={`sparkline-tooltip-${row.id}-${index}`}
                  place="top"
                  effect="solid"
                />
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  const getCurrentData = () => {
    switch (activeTab2) {
      case "daily":
        return dailyData.Usage_Hours || [];
      case "week":
        return weeklyData.Usage_Hours || [];
      case "month":
        return monthlyData.Usage_Hours || [];
      case "year":
        return yearlyData.Usage_Hours || [];
      default:
        return dailyData.Usage_Hours || [];
    }
  };

  const currentUsageHours = getCurrentData();

  const handleWeekTwo = (e) => {
    setActiveTab2(e);
  };

  const [series, setSeries] = useState([
    {
      name: "Usage Hours",
      data: [10, 20, 30, 40, 50, 60],
      color: "#008FFB",
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "Usage Hours",
        data: currentUsageHours,
        color: "#008FFB",
      },
    ]);
  }, [currentUsageHours]);

  const handleChart1Click = (event, chartContext, config) => {
    const { dataPointIndex } = config;
    const contact3String = sessionStorage.getItem("contactd");
    const fruitArray = contact3String.split(",");
    console.log("<><><>" + fruitArray);
    updateChartFilter(fruitArray[dataPointIndex]);
    updateChartMetrics(props?.metrics);
  };

  const getCurrentCategories = () => {
    switch (activeTab2) {
      case "daily":
        return dailyData.Session_Dates || [];
      case "week":
        return weeklyData.Session_Dates || [];
      case "month":
        return monthlyData.Session_Dates || [];
      case "year":
        return yearlyData.Session_Dates || [];
      default:
        return dailyData.Session_Dates || [];
    }
  };

  const currentUsage = getCurrentData();

  useEffect(() => {
    const currentCategories = getCurrentCategories();
    sessionStorage.setItem("contactd", currentCategories);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        categories: currentCategories,
        labels: {
          show: true,
          style: {
            colors: "black",
          },
        },
      },
    }));
  }, [
    currentUsage,
    activeTab2,
    dailyData,
    weeklyData,
    monthlyData,
    yearlyData,
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "line",
      height: 280,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        markerClick: handleChart1Click,
      },
    },
    grid: {
      show: true,
      borderColor: "#f0f0f0",
      strokeDashArray: 4,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: [("Jan", "Feb", "Mar", "Apr", "May", "Jun")],
      labels: {
        show: true,
        style: {
          colors: "black",
          fontSize: "10px",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: "black",
        },
      },
    },
    stroke: {
      curve: "smooth",
      dashArray: 0,
      width: 2,
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [0, 1],
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
  });

  const latestStateRef = useRef({ series: [], options: {} });

  useEffect(() => {
    latestStateRef.current = { series, options };
  }, [series, options]);

  const handleBarChartClick = (event, chartContext, { dataPointIndex }) => {
    if (dataPointIndex !== undefined) {
      const currentOptions = latestBarStateRef.current.options;
      const clickedCategory = currentOptions.xaxis.categories[dataPointIndex];
      updateChartFilter(clickedCategory);
      updateChartMetrics(props?.metrics);
    }
  };

  const [BarOption, setBarOption] = useState({
    series: [
      {
        name: "Data",
        data: [],
      },
    ],
    chart: {
      type: "bar",
      height: 245,
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleBarChartClick,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: ["#073b4c", "#27187e", "#a31c40"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      formatter: function (val) {
        return val.toFixed(0);
      },
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 0,
    },
    grid: {
      show: true,
      borderColor: "#f0f0f0",
      strokeDashArray: 4,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
  });

  useEffect(() => {
    if (barData.length > 0 && barLabel.length > 0) {
      setBarOption((prev) => ({
        ...prev,
        series: [
          {
            name: "Data",
            data: barData,
          },
        ],
        xaxis: {
          categories: barLabel,
        },
      }));
    }
  }, [barData, barLabel]);

  const latestBarStateRef = useRef({ series: [], options: {} });

  useEffect(() => {
    latestBarStateRef.current = {
      series: BarOption.series,
      options: BarOption,
    };
  }, [BarOption]);

  return (
    <>
      <div className="row g-2 clearfix row-deck">
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="card" style={{ width: "100%" }}>
            <div className="card-header border-0">
              <ul className="header-dropdown list-unstyled mt-3">
                <li>
                  <Link
                    className={`tab_btn ${
                      activeTab2 === "daily" ? "active" : ""
                    }`}
                    to="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Daily"
                    onClick={() => handleWeekTwo("daily")}
                  >
                    D
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab_btn ${
                      activeTab2 === "week" ? "active" : ""
                    }`}
                    to="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Weekly"
                    onClick={() => handleWeekTwo("week")}
                  >
                    W
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab_btn ${
                      activeTab2 === "month" ? "active" : ""
                    }`}
                    to="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Monthly"
                    onClick={() => handleWeekTwo("month")}
                  >
                    M
                  </Link>
                </li>
                <li>
                  <Link
                    className={`tab_btn ${
                      activeTab2 === "year" ? "active" : ""
                    }`}
                    to="#"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Yearly"
                    onClick={() => handleWeekTwo("year")}
                  >
                    Y
                  </Link>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="line"
                  height={280}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12">
          <div className="card">
            <div className="card-body">
              <div id="Salary_Statistics">
                {BarOption &&
                BarOption.series &&
                BarOption.series.length > 0 ? (
                  <Chart
                    options={BarOption}
                    series={BarOption.series}
                    type="bar"
                    height={310}
                  />
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-2 renderTable"
          style={{ width: "100%", overflowY: "scroll" }}
        >
          <Table columns={columns} data={records || []} />
        </div>
      </div>
    </>
  );
};

export default Group;

// import React, { useState, useMemo, useEffect, useRef } from "react";
// import { Table } from "../../../CommonPage/TableContent";
// import { Link } from "react-router-dom";
// import ReactApexChart from "react-apexcharts";
// import Chart from "react-apexcharts";
// import axios from "axios";
// import { Sparklines, SparklinesLine } from "react-sparklines";
// import { useSharedContext } from "../../../CommonPage/SharedDataSet";
// import { Tooltip } from "react-tooltip";

// const Group = (props) => {
//   const { chartFilter, updateChartFilter } = useSharedContext();
//   const { chartMeteic, updateChartMetrics } = useSharedContext();
//   const [activeTab2, setActiveTab2] = useState("daily");
//   const [records, setRecords] = useState([]);
//   const [barData, setBarData] = useState([]);
//   const [barLabel, setBarLabel] = useState([]);
//   const [isLineChartSource, setIsLineChartSource] = useState(true); // Track if data comes from line chart or bar chart
//   const userId = sessionStorage.getItem("UserMainID");

//   const [dailyData, setDailyData] = useState({
//     Usage_Hours: [],
//   });
//   const [weeklyData, setWeeklyData] = useState({
//     Usage_Hours: [],
//   });
//   const [monthlyData, setMonthlyData] = useState({
//     Usage_Hours: [],
//   });
//   const [yearlyData, setYearlyData] = useState({
//     Usage_Hours: [],
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const username = "Servusys";
//         const password = "Admin@123";
//         const basicAuth = "Basic " + btoa(`${username}:${password}`);

//         const params = {
//           physicianID: userId,
//           metrics: props.metrics,
//           clicdatatype: chartMeteic,
//           time: activeTab2,
//           contact_id: chartFilter,
//         };

//         const response = await axios.get(
//           `https://kpiapi.sleapdata.com/api/keymetrics/`,
//           {
//             params,
//             headers: {
//               Authorization: basicAuth,
//             },
//           }
//         );

//         if (response.data) {
//           setDailyData(response?.data?.Daily);
//           setWeeklyData(response?.data?.Weekly);
//           setMonthlyData(response?.data?.Monthly);
//           setYearlyData(response?.data?.Yearly);

//           // Format table data for line chart source
//           const formattedData = response?.data?.table_data?.map(
//             (item, key) => ({
//               Sn: key + 1,
//               Pid: item.Contact__c || "N/A",
//               totalusagehours: item?.Average_Usage_Hours__c.toFixed(2) || 0,
//               compilanice: item?.CompliancePercentage__c.toFixed(2) || 0,
//               dailysparkline: item?.Usage_Hours__c || [],
//               Session_Date__c: item?.Session_Date__c || [],
//             })
//           );

//           setRecords(formattedData);
//           setBarData(response?.data?.ComplianceDistribution?.data || []);
//           setBarLabel(response?.data?.ComplianceDistribution?.labelName || []);
//         }
//       } catch (error) {
//         console.error("Error fetching data: ", error);
//       }
//     };

//     if (props.metrics) {
//       fetchData();
//     }
//   }, [props.metrics, chartFilter, chartMeteic, userId, activeTab2]);

//   // Columns for the table, change based on isLineChartSource
//   const lineChartColumns = useMemo(
//     () => [
//       {
//         Header: "S.No.",
//         accessor: "Sn",
//       },
//       {
//         Header: "Patient ID",
//         accessor: "Pid",
//       },
//       {
//         Header: "Average Usage Hours",
//         accessor: "totalusagehours",
//       },
//       {
//         Header: "Compliance %",
//         accessor: "compilanice",
//       },
//       {
//         Header: "Daily Spark Line",
//         accessor: "dailysparkline",
//         Cell: ({ value, row }) => {
//           const sessionDates = row.original.Session_Date__c || [];
//           const formattedDates = sessionDates.map((date) => date.split("T")[0]);

//           return (
//             <div style={{ position: "relative" }}>
//               <Sparklines data={value} height={15} width={150}>
//                 <SparklinesLine color="green" />
//               </Sparklines>
//               {value.map((val, index) => (
//                 <div
//                   key={index}
//                   style={{
//                     position: "absolute",
//                     left: `${(index / value.length) * 100}%`,
//                     top: "0px",
//                     width: "10px",
//                     height: "15px",
//                     transform: "translateX(-50%)",
//                     cursor: "pointer",
//                   }}
//                   data-tooltip-id={`sparkline-tooltip-${row.id}-${index}`}
//                   data-tooltip-content={`Session Date: ${formattedDates[index]} - Usage Hours: ${val}`}
//                 />
//               ))}
//               {value?.map((_, index) => (
//                 <Tooltip
//                   key={index}
//                   id={`sparkline-tooltip-${row.id}-${index}`}
//                   place="top"
//                   effect="solid"
//                 />
//               ))}
//             </div>
//           );
//         },
//       },
//     ],
//     []
//   );

//   // Different columns for bar chart source
//   const barChartColumns = useMemo(
//     () => [
//       {
//         Header: "S.No.",
//         accessor: "Sn",
//       },
//       {
//         Header: "Compliance Distribution Label",
//         accessor: "Pid", // You can map this to another bar chart-related data point if needed
//       },
//       {
//         Header: "Compliance %",
//         accessor: "compilanice", // Example column, adjust according to bar chart data
//       },
//     ],
//     []
//   );

//   const getCurrentData = () => {
//     switch (activeTab2) {
//       case "daily":
//         return dailyData.Usage_Hours || [];
//       case "week":
//         return weeklyData.Usage_Hours || [];
//       case "month":
//         return monthlyData.Usage_Hours || [];
//       case "year":
//         return yearlyData.Usage_Hours || [];
//       default:
//         return dailyData.Usage_Hours || [];
//     }
//   };

//   const currentUsageHours = getCurrentData();

//   const handleWeekTwo = (e) => {
//     setActiveTab2(e);
//   };

//   const [series, setSeries] = useState([
//     {
//       name: "Usage Hours",
//       data: [10, 20, 30, 40, 50, 60],
//       color: "#008FFB",
//     },
//   ]);

//   useEffect(() => {
//     setSeries([
//       {
//         name: "Usage Hours",
//         data: currentUsageHours,
//         color: "#008FFB",
//       },
//     ]);
//   }, [currentUsageHours]);

//   const handleChart1Click = (event, chartContext, config) => {
//     const { dataPointIndex } = config;
//     const contact3String = sessionStorage.getItem("contactd");
//     const fruitArray = contact3String.split(",");
//     updateChartFilter(fruitArray[dataPointIndex]);
//     updateChartMetrics(props?.metrics);
//     setIsLineChartSource(true); // Set to true when interacting with the line chart
//   };

//   const getCurrentCategories = () => {
//     switch (activeTab2) {
//       case "daily":
//         return dailyData.Session_Dates || [];
//       case "week":
//         return weeklyData.Session_Dates || [];
//       case "month":
//         return monthlyData.Session_Dates || [];
//       case "year":
//         return yearlyData.Session_Dates || [];
//       default:
//         return dailyData.Session_Dates || [];
//     }
//   };

//   const currentUsage = getCurrentData();

//   useEffect(() => {
//     const currentCategories = getCurrentCategories();
//     sessionStorage.setItem("contactd", currentCategories);

//     setOptions((prevOptions) => ({
//       ...prevOptions,
//       xaxis: {
//         categories: currentCategories,
//         labels: {
//           show: true,
//           style: {
//             colors: "black",
//           },
//         },
//       },
//     }));
//   }, [
//     currentUsage,
//     activeTab2,
//     dailyData,
//     weeklyData,
//     monthlyData,
//     yearlyData,
//   ]);

//   const [options, setOptions] = useState({
//     chart: {
//       type: "line",
//       height: 280,
//       zoom: {
//         enabled: false,
//       },
//       toolbar: {
//         show: false,
//       },
//       events: {
//         markerClick: handleChart1Click,
//       },
//     },
//     grid: {
//       show: true,
//       borderColor: "#f0f0f0",
//       strokeDashArray: 4,
//       xaxis: {
//         lines: {
//           show: true,
//         },
//       },
//       yaxis: {
//         lines: {
//           show: true,
//         },
//       },
//     },
//     xaxis: {
//       categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
//       labels: {
//         show: true,
//         style: {
//           colors: "black",
//         },
//       },
//     },
//     yaxis: {
//       title: {
//         text: "Usage Hours",
//         style: {
//           color: "black",
//         },
//       },
//     },
//   });

//   const handleBarClick = (event, chartContext, config) => {
//     const { dataPointIndex } = config;
//     updateChartFilter(barLabel[dataPointIndex]);
//     updateChartMetrics(props?.metrics);
//     setIsLineChartSource(false); // Set to false when interacting with the bar chart
//   };

//   const series3 = [
//     {
//       name: "Compliance %",
//       data: barData,
//     },
//   ];

//   const options3 = {
//     chart: {
//       type: "bar",
//       height: 280,
//       zoom: {
//         enabled: false,
//       },
//       toolbar: {
//         show: false,
//       },
//       events: {
//         dataPointSelection: handleBarClick,
//       },
//     },
//     grid: {
//       show: true,
//       borderColor: "#f0f0f0",
//       strokeDashArray: 4,
//       xaxis: {
//         lines: {
//           show: true,
//         },
//       },
//       yaxis: {
//         lines: {
//           show: true,
//         },
//       },
//     },
//     xaxis: {
//       categories: barLabel,
//       labels: {
//         show: true,
//         style: {
//           colors: "black",
//         },
//       },
//     },
//     yaxis: {
//       title: {
//         text: "Compliance %",
//         style: {
//           color: "black",
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <div className="chart-container" style={{ height: "350px" }}>
//         <ReactApexChart
//           options={options}
//           series={series}
//           type="line"
//           height={280}
//         />
//       </div>
//       <div className="chart-container" style={{ height: "350px" }}>
//         <Chart options={options3} series={series3} type="bar" height={280} />
//       </div>
//       <div>
//         <Table columns={isLineChartSource ? lineChartColumns : barChartColumns} data={records} />
//       </div>
//     </div>
//   );
// };

// export default Group;
