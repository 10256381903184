import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const colors = ["#80c7fd", "#008FFB", "#80f1cb", "#00E396"];

const SalesForceLine = () => {
  const [chartAllData, setChartAllData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://sleapdata.com/resmedData/kpi?metrics=Age%20distribution/Gender`
      )
      .then((res) => {
        const responseData = res.data;
        const data = responseData.group.map((item) => item || 0);
        setChartAllData(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const initialChartData = {
    series: [
      {
        name: "Undisclosed",
        group: "Sex",
        data: chartAllData.disclosed || [
          18000, 38000, 25000, 10000, 12000, 28000,
        ],
      },
      {
        name: "Male",
        group: "Sex",
        data: chartAllData.male || [20000, 40000, 25000, 10000, 12000, 28000],
      },
      {
        name: "Female",
        group: "Sex",
        data: chartAllData.female || [15000, 32000, 20000, 8000, 13000, 27000],
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          return val / 1000 + "K";
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: [
          "Online ",
          "Sales ",
          "Print ",
          "Catalogs",
          "Meetings",
          "Public ",
        ],
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors: colors,
      yaxis: {
        labels: {
          formatter: (val) => {
            return val / 1000 + "K";
          },
        },
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
    },
  };

  const [chartData, setChartData] = useState(initialChartData);

  useEffect(() => {
    if (chartAllData && Object.keys(chartAllData).length > 0) {
      setChartData((prevState) => ({
        ...prevState,
        series: chartAllData,
      }));
    }
  }, [chartAllData]);

  useEffect(() => {
    const newOptions = {
      ...chartData.options,
      colors: colors,
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          return val / 1000 + "K";
        },
        offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: "#000",
        },
      },
      legend: {
        show: true,
        position: "top",
        labels: {
          colors: "black",
        },
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [`Total Contacts`, `Age 18-39`, `Age 40-59`, `Age 60+`],
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };

    setChartData((prevState) => ({
      ...prevState,
      options: newOptions,
    }));
  }, [chartData.series, chartData.series[0].data]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={"100%"}
      width={"100%"}
    />
  );
};

export default SalesForceLine;
