import React, { useState } from "react";
import Preloader from "../CommonPage/Preloader";
import ToggleNavBar from "../CommonPage/ToggleNavBar";
import AllUserRecords from "../CommonPage/AllUserRecords";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AllCronJobs = () => {
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);

  const handleOne = () => {
    setOpen1(true);
  };

  const close1 = () => {
    setOpen1(null);
  };

  const handleTwo = () => {
    setOpen2(true);
  };

  const close2 = () => {
    setOpen2(null);
  };

  const handleThree = () => {
    setOpen3(true);
  };

  const close3 = () => {
    setOpen3(null);
  };

  const handleFour = () => {
    setOpen4(true);
  };

  const close4 = () => {
    setOpen4(null);
  };

  const handleFive = () => {
    setOpen5(true);
  };

  const close5 = () => {
    setOpen5(null);
  };

  const handleSix = () => {
    setOpen6(true);
  };

  const close6 = () => {
    setOpen6(null);
  };

  const handleJob1 = () => {
    toast.success("Cron Job 1 updated successfully!");
  };

  const handleJob2 = () => {
    toast.success("Cron Job 2 updated successfully!");
  };

  const handleJob3 = () => {
    toast.success("Cron Job 3 updated successfully!");
  };

  const handleJob4 = () => {
    toast.success("Cron Job 4 updated successfully!");
  };

  const handleJob5 = () => {
    toast.success("Cron Job 5 updated successfully!");
  };

  const handleJob6 = () => {
    toast.success("Cron Job 6 updated successfully!");
  };

  return (
    <div>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                All Cron Jobs
              </h2>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 1
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob1}>Update</Link>
                <Link onClick={handleOne}>Show</Link>
              </div>
            </div>
          </div>

          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 2
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob2}>Update</Link>
                <Link onClick={handleTwo}>Show</Link>
              </div>
            </div>
          </div>

          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 3
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob3}>Update</Link>
                <Link onClick={handleThree}>Show</Link>
              </div>
            </div>
          </div>

          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 4
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob4}>Update</Link>
                <Link onClick={handleFour}>Show</Link>
              </div>
            </div>
          </div>

          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 5
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob5}>Update</Link>
                <Link onClick={handleFive}>Show</Link>
              </div>
            </div>
          </div>

          <div className="job-container">
            <div className="px-2 tw-border-solid tw-border tw-border-gray-300">
              <p className="tw-mb-1 tw-font-bold tw-text-[17px]">
                Cron Job Title 6
              </p>
              <p>Created Date: MM-DD-YYYY 12:00:00</p>
              <p>Last Updated: MM-DD-YYYY 12:00:00</p>
              <p className="">Text to be add: Same as here</p>
              <p className="tw-mb-2">
                Status: <span className="badge bg-success">Active</span>
              </p>
              <div className="d-flex tw-justify-between tw-border-t tw-border-gray-400 pt-2">
                <Link onClick={handleJob6}>Update</Link>
                <Link onClick={handleSix}>Show</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          open1 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close1}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 1</h2>
          </header>
        </div>
      </div>

      <div
        className={
          open2 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close2}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 2</h2>
          </header>
        </div>
      </div>

      <div
        className={
          open3 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close3}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 3</h2>
          </header>
        </div>
      </div>

      <div
        className={
          open4 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close4}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 4</h2>
          </header>
        </div>
      </div>

      <div
        className={
          open5 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close5}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 5</h2>
          </header>
        </div>
      </div>

      <div
        className={
          open6 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div class="custom_content-wrapper tw-w-[90%]">
          <button class="custom_close" onClick={close6}></button>
          <header class="custom_modal-header">
            <h2>Cron Job 6</h2>
          </header>
        </div>
      </div>
    </div>
  );
};

export default AllCronJobs;
