import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function ForgotPsw() {
  const navigate = useNavigate();
  const [values, setValues] = useState({ email: "", slID: "" });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((values.email === "", values.slID === "")) {
      toast.error("Please enter required field.", { autoClose: 1000 });
    } else {
      axios
        .put(`${process.env.REACT_APP_BASE_URL}login/sendotp`, {
          ...values,
        })
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          navigate("/change-password");
          setValues({ email: "" });
          sessionStorage.setItem("slID", values.slID);
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 1000 });
        });
    }
  };

  useEffect(() => {
    document.title = "sleap: Forgot Password";
  }, []);

  return (
    <>
      <div id="layout" className="theme-cyan">
        <div id="wrapper">
          <div className="d-flex h100vh align-items-center auth-main w-100">
            <div className="auth-box">
              <div className="top mb-2">
                <div
                  className="logo"
                  style={{ padding: "0 8px", background: "#fff" }}
                >
                  <img src="logo.png" alt="iSleep" className="img-fluid" />
                </div>
              </div>
              <div className="card shadow p-lg-4">
                <div className="card-header">
                  <p className="fs-5 mb-0">Forgot password</p>
                </div>
                <div className="card-body">
                  <p>
                    Please enter your email address below to receive
                    instructions for resetting password.
                  </p>
                  <form action="#">
                    <div className="form-group mb-1">
                      <strong>E-mail</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          className="form-control email"
                          placeholder="example@example.com"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <strong>System Login ID</strong>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          type="text"
                          name="slID"
                          value={values.slID}
                          onChange={handleChange}
                          className="form-control email"
                          placeholder="example@example"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-primary w-100 px-3 py-2"
                    >
                      RESET PASSWORD
                    </button>
                    <div className="text-center mt-3">
                      <span className="helper-text">
                        Know your password? <Link to={"/"}>Login</Link>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPsw;
