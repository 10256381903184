import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { toast } from "react-toastify";
import { Table } from "../../CommonPage/TableContent";
import { useSharedContext } from "../../CommonPage/SharedDataSet";
import CryptoJS from "crypto-js";

function PendingRequest() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [records, setRecords] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [sexData, setSexData] = useState();
  const [titleData, setTitleData] = useState();
  const [communicationData, setCommunicationData] = useState();
  const [specialityData, setSpecialityData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [providerData, setProviderData] = useState();
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [modalData, setModalData] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Sex_Type: "",
    SexTypeId: "",
    DOB: "",
    NPINumber: "",
    MedicalLicenseNumber: "",
    Title_Type: "",
    TitleTypeId: "",
    Provider_Type: "",
    ProviderTypeId: "",
    Speciality_Type: "",
    SpecialityTypeId: "",
    Communication_Type: "",
    CommunicationTypeId: "",
    PhoneNumber: "",
    FaxNumber: "",
    PreferredContact: "",
    E_Mail: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    PracticeName: "",
    Demographic: false,
    userRole: false,
  });
  const [validationState, setValidationState] = useState({
    NPINumber: null,
    MedicalLicenseNumber: null,
  });
  const { handleChangeNumbers } = useSharedContext();

  const fetchTypeData = (type, setter) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=${type}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setter(res?.data?.data);
      })
      .catch((error) => {
        console.error(`Error fetching ${type} data:`, error);
      });
  };

  useEffect(() => {
    fetchTypeData("provider", setProviderData);
    fetchTypeData("sex", setSexData);
    fetchTypeData("title", setTitleData);
    // fetchTypeData("referralSource", setReferralSourceData);
    fetchTypeData("communication", setCommunicationData);
    fetchTypeData("speciality", setSpecialityData);
  }, [token]);

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=pending`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          request: new Date(item?.createdAt).toLocaleDateString("en-US"),
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.Title_Type?.type,
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleClick(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));
        setRecords(formattedData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Request Date",
        accessor: "request",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Title",
        accessor: "system",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const handleApproved = (id) => {
    var d = true;
    var u = true;
    if (document.getElementById("Demographic").checked) {
      d = true;
    } else {
      d = false;
    }
    if (document.getElementById("userRole").checked) {
      u = true;
    } else {
      u = false;
    }
    setPreloaderOn(true);
    if (d === true) {
      if (modalData.NPINumber == "") {
        toast.warning("Please fill NPI Number", { autoClose: 2000 });
      } else if (modalData.MedicalLicenseNumber == "") {
        toast.warning("Please fill Medical License Number", {
          autoClose: 2000,
        });
      } else if (modalData.PhoneNumber == modalData.PreferredContact) {
        toast.warning("Phone Number and Alternate Number could not be same!", {
          autoClose: 2000,
        });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}users?task=toApproved&id=${id}`,
            {
              Demographic: d,
              userRole: u,
              ...modalData,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((res) => {
            handleChangeNumbers();
            toast.success(res.data.message, { autoClose: 2000 });
            setOpen1(false);
            setValidationState({});
            fetchData();
            setPreloaderOn(false);
          })
          .catch((error) => {
            setPreloaderOn(false);
            toast.error(error.response.data.message, { autoClose: 2000 });
          });
      }
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}users?task=toApproved&id=${id}`,
          {
            Demographic: d,
            userRole: u,
            ...modalData,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message);
          setOpen1(false);
          fetchData();
        })
        .catch((error) => {
          // console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleReject = (id) => {
    setProviderData(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users?task=toReject&id=${id}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        handleChangeNumbers();
        toast.success(res.data.message, { autoClose: 2000 });
        setOpen1(false);
        fetchData();
        setProviderData(true);
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.response.data.message, { autoClose: 2000 });
      });
  };

  const handleClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users?stage=newRequest&UserId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Update state here
      setModalData(response.data.data);
      setOpen1(true);
    } catch (error) {
      // Handle errors
      // console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (
      name === "FirstName" ||
      name === "MiddleName" ||
      name === "LastName" ||
      name === "PracticeName"
    ) {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setModalData({
          ...modalData,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Only Alphabets are allowed");
      }
    } else if (
      name === "City" ||
      name === "County" ||
      name === "Country" ||
      name === "State"
    ) {
      if (/^[A-Za-z\s]+$/u.test(value) || value === "") {
        setModalData({ ...modalData, [name]: value });
      } else {
        toast.warning("Only Alphabets are allowed");
      }
    } else if (name === "E_Mail") {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === "") {
        setModalData({ ...modalData, [name]: value });
      }
    } else if (name === "Demographic" || name === "userRole") {
      setModalData({
        ...modalData,
        [name]: checked,
      });
    } else if (
      name === "FaxNumber" ||
      name === "PreferredContact" ||
      name === "PhoneNumber" ||
      name === "ZipCode" ||
      name === "NPINumber"
    ) {
      const red = /^[0-9]+$/;
      if (value === "" || red.test(value)) {
        setModalData({ ...modalData, [name]: value });
      } else {
        toast.warning("Invalid Input: Enter Numeric value only!");
      }
    } else if (name === "MedicalLicenseNumber") {
      const noSpacesValue = value.replace(/\s/g, "");
      setModalData({ ...modalData, [name]: noSpacesValue });
    } else {
      setModalData({ ...modalData, [name]: value });
    }
  };

  const handleKeyUp = (id) => {
    if (id.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checknumber?NPINumber=${id}`
        )
        .then((response) => {
          if (response.data.message === "Proceed") {
            setValidationState((prevState) => ({
              ...prevState,
              NPINumber: true,
            }));
          } else {
            setValidationState((prevState) => ({
              ...prevState,
              NPINumber: false,
            }));
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setValidationState((prevState) => ({
            ...prevState,
            NPINumber: false,
          }));
          // toast.error(error.response.data.message);
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        NPINumber: "Invalid",
      }));
    }
  };

  const handleMLNumber = (id) => {
    if (id.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checknumber?MedicalLicenseNumber=${id}`
        )
        .then((response) => {
          if (response.data.message === "Proceed") {
            setValidationState((prevState) => ({
              ...prevState,
              MedicalLicenseNumber: true,
            }));
          } else {
            setValidationState((prevState) => ({
              ...prevState,
              MedicalLicenseNumber: false,
            }));
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setValidationState((prevState) => ({
            ...prevState,
            MedicalLicenseNumber: false,
          }));
          // toast.error(error.response.data.message);
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        MedicalLicenseNumber: "Invalid",
      }));
    }
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Access Request
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Pending Request</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={records} />
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        <div
          className={
            open1 === true ? "custom_modal open openModal" : "custom_modal"
          }
          open={open1}
          onClose={() => setOpen1(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button
              className="custom_close"
              onClick={() => setOpen1(false)}
            ></button>
            <header className="custom_modal-header">
              <h2>Pending Request</h2>
            </header>

            {/* <form> */}

            <form
              className="form-box w-100"
              id="basic-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleApproved(modalData.id);
              }}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Form</h6>{" "}
                  <div className="tw-flex tw-items-center">
                    <div className="tw-w-[150px]">
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          name="Demographic"
                          autoComplete="Demographic"
                          checked={modalData?.Demographic === true}
                          onChange={(e) => handleChange(e)}
                          className="form-check-input"
                          id="Demographic"
                        />
                        <label className="form-check-label" htmlFor="example-1">
                          Provider Account
                        </label>
                      </div>
                    </div>
                    <div className="tw-w-[150px]">
                      <div className="form-check form-switch">
                        <>
                          <input
                            type="checkbox"
                            name="userRole"
                            autoComplete="userRole"
                            checked={true}
                            onChange={(e) => handleChange(e)}
                            className="form-check-input"
                            id="userRole"
                          />
                          <label htmlFor="example-1">User Account</label>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                {open1 && (
                  <div className="card-body">
                    <div className="md:tw-grid tw-grid-cols-8 tw-px-5 tw-gap-x-6 tw-gap-y-2 tw-mt-2">
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="FirstName"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          First Name<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            id="FirstName"
                            name="FirstName"
                            autoComplete="FirstName"
                            value={modalData?.FirstName}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="FirstName"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Middle Name
                        </label>
                        <div className="">
                          <input
                            id="MiddleName"
                            name="MiddleName"
                            autoComplete="MiddleName"
                            value={modalData?.MiddleName}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="FirstName"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Last Name<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            id="LastName"
                            name="LastName"
                            autoComplete="LastName"
                            value={modalData?.LastName}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="FirstName"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Sex
                        </label>
                        <div className="">
                          <select
                            className="form-select"
                            name="SexTypeId"
                            onChange={(e) => handleChange(e)}
                          >
                            <option>Select</option>
                            {sexData?.map((item, i) => {
                              return (
                                <option
                                  key={i}
                                  value={item?.id}
                                  selected={
                                    item.id == modalData?.SexTypeId
                                      ? true
                                      : false
                                  }
                                >
                                  {item?.Type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="FirstName"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Date of Birth<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon11">
                            <i className="fa fa-calendar"></i>
                          </span>
                          <DatePicker
                            selected={
                              modalData?.DOB ? new Date(modalData?.DOB) : null
                            }
                            onChange={(date) => {
                              if (date) {
                                const formattedDate = date.toISOString();
                                setStartDate(date);
                                setModalData({
                                  ...modalData,
                                  DOB: formattedDate,
                                });
                              } else {
                                setStartDate(null);
                                setModalData({
                                  ...modalData,
                                  DOB: null,
                                });
                              }
                            }}
                            yearDropdownItemNumber={2000}
                            dateFormat="MM-dd-yyyy"
                            placeholderText="MM-DD-YYYY"
                            maxDate={new Date()}
                            yearDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            className="form-control"
                            aria-label="date"
                            aria-describedby="basic-addon11"
                            required
                          />
                        </div>
                      </div>

                      {modalData?.Demographic && (
                        <>
                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="city"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              NPI Number
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <div className="">
                              <input
                                required
                                name="NPINumber"
                                type="text"
                                value={modalData?.NPINumber}
                                onChange={(e) => handleChange(e)}
                                className={`form-control ${
                                  validationState.NPINumber === true
                                    ? "is-valid"
                                    : validationState.NPINumber === false
                                    ? "is-invalid"
                                    : validationState.NPINumber === "Invalid"
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onBlur={() => handleKeyUp(modalData.NPINumber)}
                                onInput={(e) => {
                                  if (e.target.value.length > 15) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      15
                                    );
                                  }
                                }}
                              />
                              <div className="valid-feedback">Looks good!</div>
                              {validationState.NPINumber === false ? (
                                <div className="invalid-feedback">
                                  NPI Number Already Taken.
                                </div>
                              ) : (
                                <div className="invalid-feedback">
                                  NPI Number Invalid.
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="city"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Medical License Number
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <div className="">
                              <input
                                name="MedicalLicenseNumber"
                                value={modalData?.MedicalLicenseNumber}
                                onChange={(e) => handleChange(e)}
                                required
                                className={`form-control ${
                                  validationState.MedicalLicenseNumber === true
                                    ? "is-valid"
                                    : validationState.MedicalLicenseNumber ===
                                      false
                                    ? "is-invalid"
                                    : validationState.MedicalLicenseNumber ===
                                      "Invalid"
                                    ? "is-invalid"
                                    : ""
                                }`}
                                onBlur={() =>
                                  handleMLNumber(modalData.MedicalLicenseNumber)
                                }
                                onInput={(e) => {
                                  if (e.target.value.length > 15) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      15
                                    );
                                  }
                                }}
                              />
                              <div className="valid-feedback">Looks good!</div>
                              {validationState.MedicalLicenseNumber ===
                              false ? (
                                <div className="invalid-feedback">
                                  Medical License Number Already Taken.
                                </div>
                              ) : (
                                <div className="invalid-feedback">
                                  Medical License Number Invalid.
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="city"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Title
                            </label>
                            <div className="">
                              <select
                                className="form-select"
                                id="TitleType"
                                name="TitleTypeId"
                                onChange={(e) => handleChange(e)}
                              >
                                <option selected>Select</option>
                                {titleData?.map((item, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={item.id}
                                      selected={
                                        item.id == modalData?.TitleTypeId
                                          ? true
                                          : false
                                      }
                                    >
                                      {item.Type}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="tw-col-span-2">
                            <label
                              htmlFor="country"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Provider Type
                            </label>
                            <div className="">
                              <select
                                id="ProviderType"
                                name="ProviderTypeId"
                                onChange={(e) => handleChange(e)}
                                className="form-select"
                              >
                                <option selected>Select</option>
                                {providerData.data &&
                                  providerData?.map((item, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={item.id}
                                        selected={
                                          item.id == modalData?.ProviderTypeId
                                            ? true
                                            : false
                                        }
                                      >
                                        {item.Type}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>

                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="city"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Speciality
                            </label>
                            <div className="">
                              <select
                                id="SpecialityType"
                                name="SpecialityTypeId"
                                onChange={(e) => handleChange(e)}
                                className="form-select"
                              >
                                <option selected>Select</option>
                                {specialityData?.map((item, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={item.id}
                                      selected={
                                        item.id == modalData?.SpecialityTypeId
                                          ? true
                                          : false
                                      }
                                    >
                                      {item.Type}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="country"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Preferred Communication
                            </label>
                            <div className="">
                              <select
                                className="form-select"
                                id="CommunicationTypeId"
                                name="CommunicationTypeId"
                                onChange={(e) => handleChange(e)}
                              >
                                <option selected>Select</option>
                                {communicationData?.map((item, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={item.id}
                                      selected={
                                        item.id ==
                                        modalData?.CommunicationTypeId
                                          ? true
                                          : false
                                      }
                                    >
                                      {item.Type}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="sm:tw-col-span-2">
                            <label
                              htmlFor="city"
                              className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                            >
                              Practice Name
                              <span className="AsteriskSymbol">*</span>
                            </label>
                            <div className="">
                              <input
                                name="PracticeName"
                                autoComplete="PracticeName"
                                value={modalData?.PracticeName}
                                onChange={(e) => handleChange(e)}
                                required
                                className="form-control"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Address Line 1
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            name="AddressLine1"
                            autoComplete="AddressLine1"
                            value={modalData?.AddressLine1}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Address Line 2
                        </label>
                        <div className="">
                          <input
                            name="AddressLine2"
                            autoComplete="AddressLine2"
                            value={modalData?.AddressLine2}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          City<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            name="City"
                            autoComplete="City"
                            value={modalData?.City}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Zip Code<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            name="ZipCode"
                            autoComplete="ZipCode"
                            type="text"
                            value={modalData?.ZipCode}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control"
                            onInput={(e) => {
                              if (e.target.value.length > 8) {
                                e.target.value = e.target.value.slice(0, 8);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          County<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            name="County"
                            autoComplete="County"
                            required
                            value={modalData?.County}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Country<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            required
                            name="Country"
                            autoComplete="Country"
                            value={modalData?.Country}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Phone Number<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon16">
                            <i className="fa fa-phone"></i>
                          </span>
                          <input
                            type="text"
                            required
                            name="PhoneNumber"
                            autoComplete="PhoneNumber"
                            value={modalData?.PhoneNumber}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Alternate Number
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon16">
                            <i className="fa fa-phone"></i>
                          </span>
                          <input
                            type="text"
                            name="PreferredContact"
                            autoComplete="PreferredContact"
                            value={modalData?.PreferredContact}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Fax Number<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="">
                          <input
                            name="FaxNumber"
                            autoComplete="FaxNumber"
                            required
                            type="text"
                            value={modalData?.FaxNumber}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="city"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          E-mail<span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon20">
                            <i className="fa fa-envelope-o"></i>
                          </span>
                          <input
                            required
                            type="email"
                            name="E_Mail"
                            autoComplete="E_Mail"
                            value={modalData?.E_Mail}
                            onChange={(e) => handleChange(e)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <footer className="custom_modal-footer">
                <button className="btn submit-btn d-inline ms-3" type="submit">
                  Approve
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleReject(modalData.id);
                  }}
                  className="btn submit-btn d-inline ms-3"
                  // onClick={closeModalBox}
                >
                  Reject
                </button>
              </footer>
              {/* </form> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PendingRequest;
