import axios from "axios";
import React, { useEffect, useState, useMemo, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const colors = ["#80c7fd", "#008FFB", "#80f1cb", "#00E396"];

const Chart36 = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [chartAllData, setChartAllData] = useState([]);
  const [labels, setLabels] = useState([]);

  const backgroundColors = useMemo(
    () => (props?.Color?.length ? props.Color : colors),
    [props.Color]
  );

  const userId = sessionStorage.getItem("UserMainID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const username = "Servusys";
        // const password = "Admin@123";
        const username = process.env.REACT_APP_USERNAME;
        const password = process.env.REACT_APP_PASSWORD;
        const basicAuth = "Basic " + btoa(`${username}:${password}`);

        const url = chartFilter
          ? `https://kpiapi.sleapdata.com/api/keymetrics/?physicianID=${userId}&metrics=${props.metrics}&agerange=${chartFilter}`
          : `https://kpiapi.sleapdata.com/api/keymetrics/?metrics=${props.metrics}&physicianID=${userId}`;

        const response = await axios.get(url, {
          headers: {
            Authorization: basicAuth,
          },
        });

        const responseData = response.data;
        const data = responseData.group?.map((item) => item || 0) || [];
        const dataLabels =
          responseData.group?.map((item) => item.name || 0) || [];

        setLabels(dataLabels);
        setChartAllData(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (props.metrics) {
      fetchData();
    }
  }, [props.metrics, chartFilter, chartMeteic, userId]);

  const initialChartData = {
    series: [
      {
        name: "Undisclosed",
        group: "Sex",
        data: chartAllData.disclosed || [18000, 38000, 25000],
      },
      {
        name: "Male",
        group: "Sex",
        data: chartAllData.male || [20000, 40000, 25000],
      },
      {
        name: "Female",
        group: "Sex",
        data: chartAllData.female || [15000, 32000, 20000],
      },
    ],
    options: {
      chart: {
        type: "bar",
        stacked: true,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection: function (e, chart, opts) {
            // console.log("Data Point Selected:", e, chart, opts);
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          return val / 1000 + "K";
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
        },
      },
      xaxis: {
        categories: [
          "Online ",
          "Sales ",
          "Print ",
          "Catalogs",
          "Meetings",
          "Public ",
        ],
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors: colors,
      yaxis: {
        labels: {
          formatter: (val) => {
            return val / 1000 + "K";
          },
        },
        labels: {
          show: true,
          style: {
            colors: "red",
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        formatter: function (val, opts) {
          const total = opts.w.globals.series[opts.seriesIndex].reduce(
            (a, b) => a + b,
            0
          );
          return val + ": " + total;
        },
      },
    },
  };

  const [chartData, setChartData] = useState(initialChartData);

  const maxDataValue = useMemo(
    () =>
      Math.max(
        ...chartData?.series?.map((series) => Math?.max(...series?.data))
      ),
    [chartData?.series]
  );

  const minDataValue = useMemo(
    () =>
      Math.min(
        ...chartData?.series?.map((series) => Math?.min(...series?.data))
      ),
    [chartData?.series]
  );

  useEffect(() => {
    if (chartAllData && Object.keys(chartAllData).length > 0) {
      setChartData((prevState) => ({
        ...prevState,
        series: chartAllData,
      }));
    }
  }, [chartAllData]);

  const latestStateRef = useRef(chartData);

  useEffect(() => {
    latestStateRef.current = chartData;
  }, [chartData]);

  function handleChart1Click(event, chartContext, { dataPointIndex }) {
    const currentOptions = latestStateRef.current;
    if (dataPointIndex !== undefined) {
      currentOptions.series.forEach((series, index) => {
        const clickedDataValue = series.data[dataPointIndex];
        const clickedLabels = series.name;
        updateChartFilter(clickedLabels);
        updateChartMetrics(props.metrics);
      });
    }
  }

  useEffect(() => {
    const newOptions = {
      ...chartData.options,
      chart: {
        type: "bar",
        height: "100%",
        events: {
          dataPointSelection: handleChart1Click,
        },
      },
      colors: backgroundColors,
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0, 1, 2],
        formatter: function (val, opts) {
          if (props.DataLabels === "all") {
            return val.toFixed(0);
          } else if (props.DataLabels === "min") {
            if (
              opts.dataPointIndex ===
              chartData.series[0].data.indexOf(
                Math.min(...chartData.series[0].data)
              )
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "max") {
            if (
              opts.dataPointIndex ===
              chartData.series[0].data.indexOf(
                Math.max(...chartData.series[0].data)
              )
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "both") {
            const minValueIndex = chartData.series[0].data.indexOf(
              Math.min(...chartData.series[0].data)
            );
            const maxValueIndex = chartData.series[0].data.indexOf(
              Math.max(...chartData.series[0].data)
            );
            if (
              opts.dataPointIndex === minValueIndex ||
              opts.dataPointIndex === maxValueIndex
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          }
        },
        offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: "#000",
        },
      },
      legend: {
        show: props.LegendDisplay === false ? false : true,
        position: props.position ? props.position : "top",
        labels: {
          colors: props.LegendTextColor || "black",
        },
        formatter: function (val, opts) {
          const total = opts.w.globals.series[opts.seriesIndex].reduce(
            (a, b) => a + b,
            0
          );
          return val + ": " + total;
        },
      },
      tooltip: {
        enabled: props.Tooltip === false ? false : true,
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["Age 18-39", "Age 40-59", "Age 60+"],
        title: {
          text: props.axisX === true ? props.XAxis : "",
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalX === false ? false : true,
          style: {
            colors: props.LavelXColor || "red",
          },
        },
      },
      yaxis: {
        title: {
          text: props.axisY === true ? props.YAxis : "",
          style: {
            fontSize: props.FontSize || 14,
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalY === false ? false : true,
          style: {
            colors: props.LavelYColor || "red",
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: props.gridX === false ? false : true,
          },
        },
        yaxis: {
          lines: {
            show: props.gridY === false ? false : true,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "15%",
        },
      },
    };
    setChartData((prevState) => ({
      ...prevState,
      options: newOptions,
    }));
  }, [
    props,
    backgroundColors,
    chartData.series,
    props.DataLabels,
    chartData.series[0].data,
  ]);

  useEffect(() => {
    const totalValue = chartData.series.reduce((acc, series) => {
      return acc + series.data.reduce((sum, value) => sum + value, 0);
    }, 0);

    const newAnnotations = [
      props.TargetDisplay === true && {
        y: props.TargetValue || 20,
        borderColor: props.TargetLineDisplay
          ? props.TargetValueLineColor || "#0073ff"
          : "",
        label: {
          borderColor: props.TargetValueLineColor || "#0073ff",
          offsetY: +props.PositionTargetTB || 0,
          offsetX: +props.PositionTargetLR || 0,
          style: {
            color: props.LabelTextColorTarget || "#fff",
            background: props.TargetValueLineColor || "#0073ff",
            fontSize: props.FontSizeTarget || "14px",
          },
          text:
            props.ValueTarget === true
              ? (props.TargetValueTitle &&
                  props.TargetValueTitle + " - " + props.TargetValue) ||
                "Target" + " - " + props.TargetValue
              : props.TargetValueTitle || "Target",
        },
      },
      props.PeakDisplay === true && {
        y: maxDataValue,
        borderColor: props.PeakLineDisplay ? props.PeakValueC || "#ff0000" : "",
        label: {
          borderColor: props.PeakValueC || "#ff0000",
          offsetY: +props.PositionPeakTB || 0,
          offsetX: +props.PositionPeakLR || 0,
          style: {
            color: "#fff",
            background: props.PeakValueC || "#ff0000",
            fontSize: props.FontSizePeak || "12px",
          },
          text:
            props.ValuePeak === true
              ? (props.PeakValueTitle &&
                  props.PeakValueTitle + " - " + maxDataValue) ||
                "Peak" + " - " + maxDataValue
              : props.PeakValueTitle || "Peak",
        },
      },
      props.LowestDisplay === true && {
        y: 0,
        borderColor: props.LowestLineDisplay
          ? props.LowestValueC || "#00ff00"
          : "",
        label: {
          borderColor: props.LowestValueC || "#00ff00",
          offsetY: +props.PositionLowestTB || 0,
          offsetX: +props.PositionLowestLR || 0,
          style: {
            color: "#fff",
            background: props.LowestValueC || "#00ff00",
            fontSize: props.FontSizeLowest || "12px",
          },
          text:
            props.ValueLowest === true
              ? (props.LowestValueTitle &&
                  props.LowestValueTitle + " - " + minDataValue) ||
                "Lowest" + " - " + minDataValue
              : props.LowestValueTitle || "Lowest",
        },
      },
    ].filter((annotation) => annotation);

    setChartData((prevData) => ({
      ...prevData,
      options: {
        ...prevData.options,
        annotations: {
          yaxis: newAnnotations,
        },
      },
    }));
  }, [
    props.TargetDisplay,
    props.TargetValue,
    props.TargetLineDisplay,
    props.TargetValueLineColor,
    props.PositionTargetTB,
    props.PositionTargetLR,
    props.LabelTextColorTarget,
    props.FontSizeTarget,
    props.ValueTarget,
    props.TargetValueTitle,
    props.PeakDisplay,
    props.PeakValueC,
    props.PeakLineDisplay,
    props.PositionPeakTB,
    props.PositionPeakLR,
    props.FontSizePeak,
    props.ValuePeak,
    props.PeakValueTitle,
    props.LowestDisplay,
    props.LowestValueC,
    props.LowestLineDisplay,
    props.PositionLowestTB,
    props.PositionLowestLR,
    props.FontSizeLowest,
    props.ValueLowest,
    props.LowestValueTitle,
    maxDataValue,
    minDataValue,
    chartData.series,
  ]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="bar"
      height={"100%"}
      width={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart36;
