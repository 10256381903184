import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import _ from "lodash";
import CryptoJS from "crypto-js";

function ListUser() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [sexData, setSexData] = useState();
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [Demographic, setDemographic] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Sex_Type: "",
    SexTypeId: "",
    DOB: "",
    PhoneNumber: "",
    E_Mail: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    SystemLogin: "",
    userRoles: [],
    LinkedAccount: "",
  });
  const [roleD, setRoleD] = useState();
  const [role, setRole] = useState([]);
  const [validationState, setValidationState] = useState({
    SystemLogin: null,
  });

  // User Role Name
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userrole`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setRoleD(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const addmoreRole = (e) => {
    e.preventDefault();
    setRole([...role, { service: "" }]);
  };

  const closeModalBox = () => {
    setDemographic({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Sex_Type: "",
      SexTypeId: "",
      DOB: "",
      PhoneNumber: "",
      E_Mail: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      County: "",
      ZipCode: "",
      Country: "",
      SystemLogin: "",
      roleData: [],
      LinkedAccount: "",
    });
    setRole([]);
    setOpenModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "System Login",
        accessor: "system",
      },
      {
        Header: "Linked Account",
        accessor: "ml",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          name: item?.FirstName + " " + item?.LastName + " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.SystemLogin,
          ml: item?.ProviderId,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
          last: convertTimestampToDateFormat(item?.createdAt),
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="delete"
                onClick={() => handleEditClick(item.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));
        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Sex Type
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=sex`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setSexData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEditClick = async (id) => {
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users?stage=byId&id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("DEMODATA", response.data);
      const userAccountData = response?.data?.message;
      setDemographic(userAccountData);
      // setRoleData(userAccountData.userRoles);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    const updatedRole = roleD.find((element) => element.id == value);
    // console.log("Updated role:", updatedRole);
    setDemographic((prevDemographic) => {
      const updatedUserRoles = prevDemographic.userRoles.map((item, i) => {
        if (i === index) {
          return updatedRole || item;
        }
        return item;
      });
      return {
        ...prevDemographic,
        userRoles: updatedUserRoles,
      };
    });
  };

  const handleServiceRole = (e, index) => {
    const { name, value } = e.target;
    const list = [...role];
    list[index][name] = value;
    setRole(list);
  };

  const handleFinalUpdate = (e) => {
    e.preventDefault();
    setPreloaderOn(true);
    // Check if any role is empty
    const emptyRole = role.some((item) => item.service === "");

    if (
      Demographic?.FirstName === "" ||
      Demographic?.LastName === "" ||
      Demographic?.DOB === "" ||
      Demographic?.SystemLogin === "" ||
      Demographic?.ZipCode === "" ||
      Demographic?.AddressLine1 === "" ||
      Demographic?.PhoneNumber === "" ||
      Demographic?.City === "" ||
      Demographic?.State === "" ||
      Demographic?.County === "" ||
      Demographic?.Country === "" ||
      Demographic?.E_Mail === "" ||
      emptyRole
    ) {
      toast.warning("Please select user roles.", { autoClose: 2000 });
    } else {
      const newUserData = role.map((item, i) => {
        return { ...item };
      });
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}users?task=updateUser`,
          {
            ...Demographic,
            newUserData,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message, { autoClose: 2000 });
          setOpenModal(false);
          setRole([]);
          fetchData();
          setValidationState({});
          setPreloaderOn(false);
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 2000 });
          setPreloaderOn(false);
        });
    }
  };

  const handleDelete = (i) => {
    const newArr = role.filter((item, index) => index !== i);
    setRole(newArr);
  };

  const debouncedSave = useCallback(
    _.debounce((nextValue, type) => handleSearch(nextValue, type), 500),
    []
  );

  const handleSearch = (value, type) => {
    if (value.length >= 5) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checkNumber?${type}=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setValidationState((prevState) => ({
            ...prevState,
            [type]: true,
          }));
        })
        .catch((error) => {
          console.log("ER", error);
          setValidationState((prevState) => ({
            ...prevState,
            [type]: false,
          }));
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        [type]: "Invalid",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const checkboxValue = type === "checkbox" ? checked : value;

    if (name === "Active") {
      setDemographic({
        ...Demographic,
        [name]: checkboxValue,
      });
    } else {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      if (
        name === "FirstName" ||
        name === "MiddleName" ||
        name === "LastName"
      ) {
        if (/^[A-Za-z]+$/.test(value) || value === "") {
          setDemographic({
            ...Demographic,
            [name]: capitalizeFirstLetter(value),
          });
        } else {
          toast.warning("Only Alphabets are allowed", { autoClose: 2000 });
        }
      } else if (
        name === "City" ||
        name === "County" ||
        name === "Country" ||
        name === "State"
      ) {
        if (/^[A-Za-z\s]+$/u.test(value) || value === "") {
          setDemographic({ ...Demographic, [name]: value });
        } else {
          toast.warning("Only Alphabets are allowed", { autoClose: 2000 });
        }
      } else if (name === "E_mail") {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === "") {
          setDemographic({ ...Demographic, [name]: value });
        }
      } else if (name === "PhoneNumber" || name === "ZipCode") {
        if (isNaN(value)) {
          toast.warning(`${name} must be a number`, { autoClose: 2000 });
        } else {
          setDemographic({ ...Demographic, [name]: value });
        }
      } else if (name === "DOB") {
        setStartDate((date) => {
          const formattedDate = date.toISOString();
          setStartDate(date);
          setDemographic({ ...Demographic, DOB: formattedDate });
        });
        setDemographic({ ...Demographic, [name]: value });
      } else if (name === "SystemLogin") {
        debouncedSave(value, "SystemLogin");
        setDemographic({ ...Demographic, [name]: value });
      } else {
        setDemographic({ ...Demographic, [name]: value });
      }
    }
  };

  return (
    <>
      {preloaderOn == true ? (
        <Preloader text="Please wait while form is updating..." />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Account
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit User Account</li>
              </ul>
            </div>
            <AllUserRecords user={data} />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit User Account</h2>
            </header>
            <form
              className="form-box"
              id="basic-form"
              onSubmit={handleFinalUpdate}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Demographic Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        checked={Demographic.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FirstName"
                        autoComplete="FirstName"
                        value={Demographic?.FirstName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        autoComplete="MiddleName"
                        value={Demographic?.MiddleName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="LastName"
                        autoComplete="LastName"
                        value={Demographic?.LastName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexTypeId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option>Select</option>
                        {sexData?.map((item, iT) => {
                          return (
                            <option
                              key={iT}
                              value={item.id}
                              selected={
                                item.id == Demographic.SexTypeId ? true : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          defaultValue={Demographic.DOB}
                          selected={
                            Demographic?.DOB ? new Date(Demographic?.DOB) : null
                          }
                          onChange={(date) => {
                            const formattedDate = date.toISOString();
                            setStartDate(date);
                            setDemographic({
                              ...Demographic,
                              DOB: formattedDate,
                            });
                          }}
                          dateFormat="MM-dd-yyyy"
                          yearDropdownItemNumber={2000}
                          placeholderText="MM-DD-YYYY"
                          maxDate={new Date()}
                          yearDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control date"
                          aria-label="date"
                          aria-describedby="basic-addon11"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Phone Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon16">
                          <i className="fa fa-phone"></i>
                        </span>
                        <input
                          type="text"
                          name="PhoneNumber"
                          autoComplete="PhoneNumber"
                          value={Demographic?.PhoneNumber}
                          onChange={(e) => handleChange(e)}
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          maxLength={10}
                          minLength={8}
                          className="form-control phone-number"
                          placeholder="Phone Number"
                          aria-label="phone-number"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        E-mail<span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          className="form-control email"
                          placeholder="Ex: example@example.com"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                          type="email"
                          name="E_Mail"
                          autoComplete="E_Mail"
                          value={Demographic?.E_Mail}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">Contact information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Address Line 1<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="AddressLine1"
                        autoComplete="AddressLine1"
                        value={Demographic?.AddressLine1}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Address Line 2</label>
                      <input
                        className="form-control"
                        type="text"
                        name="AddressLine2"
                        autoComplete="AddressLine2"
                        value={Demographic?.AddressLine2}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        City<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="City"
                        autoComplete="City"
                        value={Demographic?.City}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        State<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="State"
                        autoComplete="State"
                        value={Demographic?.State}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        County<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="County"
                        autoComplete="County"
                        value={Demographic?.County}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Zip Code<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="ZipCode"
                        autoComplete="ZipCode"
                        value={Demographic?.ZipCode}
                        onChange={(e) => handleChange(e)}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        maxLength={8}
                        minLength={6}
                        onInput={(e) => {
                          if (e.target.value.length > 8) {
                            e.target.value = e.target.value.slice(0, 8);
                          }
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Country<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="Country"
                        autoComplete="Country"
                        value={Demographic?.Country}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">User Security</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">System Login</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationState.SystemLogin === true
                            ? "is-valid"
                            : validationState.SystemLogin === false
                            ? "is-invalid"
                            : validationState.SystemLogin === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="SystemLogin"
                        autoComplete="SystemLogin"
                        value={Demographic.SystemLogin}
                        onChange={(e) => handleChange(e)}
                        maxLength={20}
                        onInput={(e) => {
                          if (e.target.value.length > 16) {
                            e.target.value = e.target.value.slice(0, 20);
                          }
                        }}
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.SystemLogin === false ? (
                        <div className="invalid-feedback">
                          System Login Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          System Login Invalid.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Linked Account</label>
                      <input
                        className="form-control"
                        type="number"
                        name="LinkedAccount"
                        autoComplete="LinkedAccount"
                        value={Demographic?.ProviderId}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3"></div>
                    <div className="col-sm-6 col-md-4 col-lg-3"></div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Security Question 1</label>
                      <input
                        type="text"
                        value={Demographic?.QuestionTypes?.[0]?.Type || "NA"}
                        disabled
                        className="form-control"
                        name="QuestionTypes1"
                        autoComplete="QuestionTypes1"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Security Question 2</label>
                      <input
                        type="text"
                        value={Demographic?.QuestionTypes?.[1]?.Type || "NA"}
                        disabled
                        className="form-control"
                        name="QuestionTypes2"
                        autoComplete="QuestionTypes2"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Security Question 3</label>
                      <input
                        type="text"
                        value={Demographic?.QuestionTypes?.[2]?.Type || "NA"}
                        disabled
                        className="form-control"
                        name="QuestionTypes3"
                        autoComplete="QuestionTypes3"
                      />
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3"></div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 1</label>
                      <input
                        type="text"
                        value={
                          Demographic?.QuestionTypes?.[0]?.userQuestion
                            ?.Answer || "NA"
                        }
                        disabled
                        className="form-control"
                        name="Answer1"
                        autoComplete="Answer1"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 2</label>
                      <input
                        type="text"
                        value={
                          Demographic?.QuestionTypes?.[1]?.userQuestion
                            ?.Answer || "NA"
                        }
                        disabled
                        className="form-control"
                        name="Answer2"
                        autoComplete="Answer2"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 3</label>
                      <input
                        type="text"
                        value={
                          Demographic?.QuestionTypes?.[2]?.userQuestion
                            ?.Answer || "NA"
                        }
                        disabled
                        className="form-control"
                        name="Answer3"
                        autoComplete="Answer3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">User Role</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    {Demographic?.userRoles?.length > 0 &&
                      Demographic?.userRoles?.map((items, ixvdd) => {
                        return (
                          <div
                            className="col-sm-6 col-md-4 col-lg-3"
                            key={ixvdd}
                          >
                            <label className="form-label">Role</label>
                            <select
                              className="form-select select2"
                              onChange={(e) => handleServiceChange(e, ixvdd)}
                              required
                              name="service"
                              defaultValue={
                                Demographic?.userRoles[ixvdd]?.id || "Select"
                              }
                            >
                              <option disabled>Select</option>
                              {roleD?.map((item, idrfg) => {
                                return (
                                  <option
                                    key={item.UserRoleName + idrfg}
                                    value={item.id}
                                  >
                                    {item.UserRoleName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        );
                      })}
                  </div>

                  <div className="tw-border-b tw-border-gray-400 mt-3"></div>

                  <div className="row g-3 mt-1">
                    {role.map((singleService, index) => (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 d-flex"
                        key={index}
                      >
                        <label className="form-label mt-1">Role:</label>
                        &nbsp;{" "}
                        <select
                          className="form-select"
                          value={singleService.service}
                          onChange={(e) => handleServiceRole(e, index)}
                          required
                          name="service"
                        >
                          <option>Select</option>
                          {roleD?.map((item, idrfg) => {
                            return (
                              <option key={idrfg} value={item.id}>
                                {item.UserRoleName}
                              </option>
                            );
                          })}
                        </select>
                        <span>
                          <button
                            type="button"
                            onClick={() => handleDelete(index)}
                            className="closemoreAddress-cross tw-mt-[1px]"
                          >
                            X
                          </button>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <footer className="custom_modal-footer">
                <button
                  className="btn add-more-btn d-inline "
                  onClick={addmoreRole}
                >
                  Add More Role
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button className="btn submit-btn d-inline ms-3" type="submit">
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListUser;
