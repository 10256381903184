import React from "react";
import { Link } from "react-router-dom";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "react-datepicker/dist/react-datepicker.css";
import Chart1 from "../TemplateConfiguration/Chart/Chart1";
import Chart4 from "../TemplateConfiguration/Chart/Chart4";
import Chart6 from "../TemplateConfiguration/Chart/Chart6";
import Chart8 from "../TemplateConfiguration/Chart/Chart8";
import Chart9 from "../TemplateConfiguration/Chart/Chart9";
import Chart14 from "../TemplateConfiguration/Chart/Chart14";
import Chart15 from "../TemplateConfiguration/Chart/Chart15";
import Chart16 from "../TemplateConfiguration/Chart/Chart16";
import Chart18 from "../TemplateConfiguration/Chart/Chart18";
import Chart22 from "../TemplateConfiguration/Chart/Chart22";
import Chart23 from "../TemplateConfiguration/Chart/Chart23";
import Chart24 from "../TemplateConfiguration/Chart/Chart24";
import Chart26 from "../TemplateConfiguration/Chart/Chart26";
import Chart28 from "../TemplateConfiguration/Chart/Chart28";
import Chart30 from "../TemplateConfiguration/Chart/Chart30";
import Chart32 from "../TemplateConfiguration/Chart/Chart32";
import Chart33 from "../TemplateConfiguration/Chart/Chart33";
import Chart35 from "../TemplateConfiguration/Chart/Chart35";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import Group from "../TemplateConfiguration/Chart/Group";

function KeyMetrics() {
  return (
    <>
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Administrative Dashboard
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Administrative Dashboard
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart1 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart4 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart6 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart8 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart9 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart14 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart15 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart16 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart18 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart22 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart23 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart24 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart26 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart28 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart30 />
            </div>
          </div>

          <div className="col-md-4 chart-box-container">
            <div>
              <Chart32 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart33 />
            </div>
          </div>
          <div className="col-md-4 chart-box-container">
            <div>
              <Chart35 />
            </div>
          </div>
          <div className="col-md-12 chart-box-container">
            <div>
              <Group />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KeyMetrics;
