import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSharedContext } from "../../CommonPage/SharedDataSet";
import axios from "axios";
import CryptoJS from "crypto-js";

const UserSideBar = React.memo(() => {
  const { activeUser, uploadedImage } = useSharedContext();
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [datas, setDatas] = useState([]);
  const [sub, setSub] = useState([]);
  const [clinical, setClinical] = useState([]);
  const [image, setImgs] = useState("");

  useEffect(() => {
    if (activeUser) {
      const fname = activeUser?.FirstName || "";
      const lname = activeUser?.LastName || "";

      const firstInitials =
        fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
      setImgs(firstInitials);
    }
  }, [activeUser]);

  useEffect(() => {}, [image]);

  const storedValue = sessionStorage.getItem("localTextColor");
  const storedTheme = sessionStorage.getItem("theme");
  const [textColor, setTextColor] = useState(storedValue);
  const [themeChecked, setThemeChecked] = useState(false);
  const [tabPanel, setTabPanel] = useState("project_menu");
  const [highContrastChecked, setHighContrastChecked] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(storedTheme);
  const [offlineBtn, setOfflineBtn] = useState(
    sessionStorage.getItem("offline") === "true" || false
  );
  const [menuItem, setMenuItem] = useState(sessionStorage.getItem("menuItems"));
  const [subMenu, setSubMenu] = useState(sessionStorage.getItem("subMenus"));
  const [menuType, setMenuType] = useState(sessionStorage.getItem("menuType"));

  useEffect(() => {
    if (sessionStorage.getItem("activeItem") === null) {
      setTabPanel("project_menu");
    } else {
      setTabPanel(sessionStorage.getItem("activeItem"));
    }
    if (menuItem) {
      if (menuType === "singleMenuBtn") {
        $("." + menuItem).addClass("active");
        // navigate('/' + menuItem);
      } else if (menuType === "hasMenuBtn") {
        // navigate('/' + menuItem);
        $("." + menuItem).addClass("active mm-show");
        $("." + subMenu + "-has").addClass("mm-active active");
        $("." + subMenu + "-sub").addClass("mm-show");
      }
    } else {
      sessionStorage.setItem("menuItems", "dashboard");
      sessionStorage.setItem("menuType", "singleMenuBtn");
    }
    document.documentElement.setAttribute("data-theme", currentTheme);
    if (!menuItem) {
      setMenuItem("dashboard");
      setSubMenu("");
    }
    if (storedValue) {
      setTextColor(storedValue);
      $("#layout").addClass("theme-" + storedValue);
    } else {
      $("#layout").addClass("theme-cyan");
    }
    if (currentTheme == "dark") {
      setHighContrastChecked(false);
      setThemeChecked(true);
    } else if (currentTheme == "high-contrast") {
      setHighContrastChecked(true);
      setThemeChecked(false);
    } else {
      setHighContrastChecked(false);
      setThemeChecked(false);
    }
  }, []);

  const singleMenu = (a, b, c) => {
    if (a != "" && b != "" && c != "") {
      sessionStorage.setItem("menuItems", c);
      sessionStorage.setItem("subMenus", a);
      sessionStorage.setItem("menuType", b);
    } else if (a != "" && b != "" && c == "") {
      if ($("." + a + "-has").hasClass("mm-active")) {
        $("." + a + "-has").removeClass("mm-active");
        $("." + a + "-sub").removeClass("mm-show");
      } else {
        if ($("li").hasClass("mm-active") && $("ul").hasClass("mm-show")) {
          $("li").removeClass("mm-active");
          $("ul").removeClass("mm-show");
        }
        $("." + a + "-has").addClass("mm-active");
        $("." + a + "-sub").addClass("mm-show");
      }
    }
  };

  const handleLogout = async () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("email");
    window.location.href = "/";
  };

  useEffect(() => {
    const handleConnectivityChange = () => {
      setOfflineBtn(!navigator.onLine);
    };
    window.addEventListener("online", handleConnectivityChange);
    window.addEventListener("offline", handleConnectivityChange);
    return () => {
      window.removeEventListener("online", handleConnectivityChange);
      window.removeEventListener("offline", handleConnectivityChange);
    };
  }, []);

  const sideBarTab = (e, a) => {
    sessionStorage.setItem("activeItem", a);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const data1 = res.data.filteredArray;
        setDatas(data1);
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
  }, []);

  useEffect(() => {
    //setUploadImage(sessionStorage.getItem("uploadImage"));
  }, [uploadedImage]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const data1 = res.data.subscribedTemplates;
        setSub(data1);
        const clinic = res.data.clinicalDocument;
        setClinical(clinic);
      })
      .catch((err) => {
        console.error("Error fetching subscribed templates:", err);
      });
  }, []);

  return (
    <div id="left-sidebar" className="sidebar">
      <div className="user-account p-2 mb-1">
        <div className=" d-flex  border-bottom align-items-center">
          <div
            className="profile-image m-3 border-2"
            style={{ height: "50px", width: "50px" }}
          >
            {uploadedImage ? (
              <img
                src={uploadedImage}
                className="rounded-circle tw-text-xs"
                alt="Profile"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <div className="noimg">{image || "No Image"}</div>
            )}
          </div>
          <br />
          <div className="dropdown flex-grow-1">
            <span>Welcome &nbsp; </span>
            <br />
            <Link
              className="dropdown-toggle user-name"
              data-bs-toggle="dropdown"
            >
              <strong>{activeUser?.FirstName} </strong>
            </Link>
            <ul className="dropdown-menu p-2 shadow-sm ">
              <li>
                <Link to="/profile">
                  <i className="fa fa-user me-2"></i>My Profile
                </Link>
              </li>
              <li>
                <Link to="/setting">
                  <i className="fa fa-cog me-2"></i>Theme Setting
                </Link>
              </li>
              <li className="divider"></li>
              <li className="tw-cursor-pointer">
                <Link onClick={handleLogout}>
                  <i className="fa fa-power-off me-2"></i>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul className="nav nav-tabs text-center mb-2" role="tablist">
        <li className="nav-item flex-fill">
          <Link
            onClick={(e) => {
              sideBarTab(e, "project_menu");
            }}
            className={
              tabPanel === "project_menu"
                ? "px-1 nav-link active"
                : "px-1 nav-link"
            }
            data-bs-toggle="tab"
            to="#project_menu"
            role="tab"
            title="Text add here"
          >
            <i className="fa fa-list-alt" aria-hidden="true"></i>
          </Link>
        </li>
        <li className="nav-item flex-fill">
          <Link
            onClick={(e) => {
              sideBarTab(e, "sub_menu");
            }}
            className={
              tabPanel === "sub_menu" ? "px-1 nav-link active" : "px-1 nav-link"
            }
            data-bs-toggle="tab"
            to="#sub_menu"
            role="tab"
            title="Subcribed"
          >
            <i className="fa fa-shield" aria-hidden="true"></i>
          </Link>
        </li>
      </ul>

      <div className="tab-content px-0">
        <div
          className={
            tabPanel === "project_menu"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="project_menu"
          role="tabpanel"
        >
          <nav className="sidebar-nav">
            <ul className="metismenu list-unstyled" id="menu">
              <li className="user-dashboard">
                <Link
                  className="menu-item-single"
                  to="/user-dashboard"
                  onClick={() => {
                    singleMenu(
                      "user-dashboard",
                      "singleMenuBtn",
                      "user-dashboard"
                    );
                  }}
                >
                  <i className="fa fa-tachometer"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              {datas.map((category, ind) => (
                <li className={`id${ind}-has`} key={ind}>
                  <Link
                    to="#"
                    className="has-arrow"
                    onClick={() => {
                      singleMenu(`id${ind}`, "hasMenuBtn", "");
                    }}
                  >
                    <i className="fa fa-bar-chart" aria-hidden="true"></i>
                    <span>{category?.category}</span>
                  </Link>
                  <ul className={`list-unstyled mm-collapse id${ind}-sub`}>
                    {category?.datas?.map((item, i) => (
                      <li className={`view_template${i}`}>
                        <Link
                          className="menu-item"
                          onClick={() => {
                            singleMenu(
                              `id${ind}`,
                              "hasMenuBtn",
                              `view_template${i}`
                            );
                          }}
                          to={`/user-dashboard/${item?.id}`}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li className="subscribe-template-has">
                <Link
                  to="#"
                  className="has-arrow"
                  onClick={() => {
                    singleMenu(`subscribe-template`, "hasMenuBtn", "");
                  }}
                >
                  <i className="fa fa-bar-chart" aria-hidden="true"></i>
                  <span>Subscribed Templates</span>
                </Link>
                <ul className="list-unstyled mm-collapse  subscribe-template-sub">
                  {sub?.map((item, i) => (
                    <li className={`subscribe_template${i}`} key={i}>
                      <Link
                        className="menu-item"
                        onClick={() => {
                          singleMenu(
                            `id${i}`,
                            "hasMenuBtn",
                            `subscribe_template${i}`
                          );
                        }}
                        to={`/subscribe-template/${item?.id}`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="clinical-document-has">
                <Link
                  to="#"
                  className="has-arrow"
                  onClick={() => {
                    singleMenu(`clinical-document`, "hasMenuBtn", "");
                  }}
                >
                  <i className="fa fa-bar-chart" aria-hidden="true"></i>
                  <span>Clinical Document</span>
                </Link>
                <ul className="list-unstyled mm-collapse  clinical-document-sub">
                  {clinical?.map((item, ikl) => (
                    <li className={`clinical_document${ikl}`} key={ikl}>
                      <Link
                        className="menu-item"
                        onClick={() => {
                          singleMenu(
                            `id${ikl}`,
                            "hasMenuBtn",
                            `clinical_document${ikl}`
                          );
                        }}
                        to={`/clinical/${item?.id}`}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>

        <div
          className={
            tabPanel === "sub_menu"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="sub_menu"
          role="tabpanel"
        >
          <nav className="sidebar-nav">
            <ul className="metismenu list-unstyled" id="menu">
              <li className="Subscribe-has">
                <Link
                  className="has-arrow"
                  onClick={() => {
                    singleMenu("Subscribe", "hasMenuBtn", "");
                  }}
                >
                  <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                  <span>Subscribe</span>
                </Link>
                <ul className="list-unstyled mm-collapse Subscribe-sub">
                  <li className="add_subscribe">
                    <Link
                      className="menu-item"
                      onClick={() => {
                        singleMenu("Subscribe", "hasMenuBtn", "add_subscribe");
                      }}
                      to={"/add_subscribe"}
                    >
                      Edit Subscribe
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="CustomView-has">
                <Link
                  className="has-arrow"
                  onClick={() => {
                    singleMenu("CustomView", "hasMenuBtn", "");
                  }}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>
                  <span>Custom View</span>
                </Link>
                <ul className="list-unstyled mm-collapse CustomView-sub">
                  <li className="add_view">
                    <Link
                      className="menu-item"
                      onClick={() => {
                        singleMenu("CustomView", "hasMenuBtn", "add_view");
                      }}
                      to={"/add_view"}
                    >
                      {" "}
                      Edit Custom View
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
});

export default UserSideBar;
