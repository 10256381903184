import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ResmedLine = (props) => {
  // console.log("PROPS FIRST", props.series);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Data",
        data: [],
      },
    ],
    options: {
      chart: {
        height: "100%",
        width: "100%",
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 5,
        curve: "smooth",
      },
      xaxis: {
        tickAmount: 10,
        title: {
          text: "",
          style: {
            fontSize: "14px",
            color: "green",
          },
        },
        labels: {
          show: true,
        },
        categories: [] || "2024-05-03",
      },
      yaxis: {
        tickAmount: 10,
        title: {
          style: {
            fontSize: "14px",
            color: "black",
          },
        },
        labels: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
      grid: {
        show: false,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (Array.isArray(props?.series)) {
      const newData = props.series.map((item) => item.count);
      const newCategories = props.series.map((item) => item.month);

      setChartData((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: "Count",
            data: newData,
          },
        ],
        options: {
          ...prevOptions.options,
          xaxis: {
            ...prevOptions.options.xaxis,
            categories: newCategories,
          },
        },
      }));
    }
  }, [props.series]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={"100%"}
      width={"100%"}
    />
  );
};

export default ResmedLine;
