import React, { useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function AddSecurityClass() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [value, setValue] = React.useState(0);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const handleChangeTwo = (event, newValue) => {
    setValue(newValue);
  };

  const [security, setSecurity] = useState({
    name: "",
    description: "",
    frontend: false,
    superAdmin: false,
  });

  const [checkboxData, setCheckBoxData] = useState([
    {
      category: "Provider",
      subcategory: [
        {
          fields: "demographic",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "contactInformation",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "newApprovedProvider",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
    {
      category: "userAccount",
      subcategory: [
        {
          fields: "demographicInformation",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "address",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "userSecurity",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "userRoles",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "newApprovedUser",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
    {
      category: "userRole",
      subcategory: [
        {
          fields: "generalInformation",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "templateSecurity",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
    {
      category: "templateConfig",
      subcategory: [
        {
          fields: "template",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
    {
      category: "subscriptionConfig",
      subcategory: [
        {
          fields: "subscriptionInformation",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "subscriptionSecurity",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "groupInformation",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
  ]);

  const [checkboxFrontendData, setCheckBoxFrontendData] = useState([
    {
      category: "User Profile",
      subcategory: [
        {
          fields: "profileImage",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "updatePassword",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "updateAddress",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "updateEmail",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "updatePhone",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "updateSecurityQuestions",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
    {
      category: "User Filter",
      subcategory: [
        {
          fields: "subscribe",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "customView",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
        {
          fields: "filter",
          read: false,
          add: false,
          edit: false,
          delete: false,
          fullAccess: false,
          disable: false,
        },
      ],
    },
  ]);

  function sendDataToAPI(e) {
    e.preventDefault();
    if (!security.name) {
      toast.warning("Please fill Security Class Name", { autoClose: 1000 });
      return;
    }
    if (!security.description) {
      toast.warning("Please fill General Description", { autoClose: 1000 });
      return;
    }
    const requestData = {
      securityPoints: security.superAdmin ? checkboxData : checkboxFrontendData,
      name: security.name,
      description: security.description,
      frontend: security.frontend,
      superAdmin: security.superAdmin,
    };
    setPreloaderOn(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}securityclass/add`, requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
      .then((data) => {
        // console.log("Response: ", data);
        setPreloaderOn(false);
        toast.success(data.data.message);
        setSecurity({});
        setCheckBoxFrontendData([{}]);
        setCheckBoxData([{}]);
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message);
      });
  }

  function updateCheckboxData(
    categoryIndex,
    subcategoryIndex,
    fieldName,
    value
  ) {
    if (security.superAdmin) {
      const updateCheckBox = [...checkboxData];

      if (
        fieldName != "disable" &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "disable"
        ] == true
      ) {
        toast.error("Please uncheck disable first!");
        return;
      }

      if (fieldName == "fullAccess") {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "read"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "add"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "edit"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "delete"
        ] = true;
      } else if (fieldName == "disable") {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "read"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "add"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "edit"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "delete"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][fieldName] =
          value;
        setCheckBoxData(updateCheckBox);
        return;
      } else if (
        fieldName != "fullAccess" &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] == true
      ) {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = value;
      }

      updateCheckBox[categoryIndex].subcategory[subcategoryIndex][fieldName] =
        value;

      if (
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["read"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["add"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["edit"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["delete"] ==
          true
      ) {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = true;
      }

      setCheckBoxData(updateCheckBox);
    } else if (security.frontend) {
      const updateCheckBox = [...checkboxFrontendData];
      if (
        fieldName != "disable" &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "disable"
        ] == true
      ) {
        toast.error("Please uncheck disable first!");
        return;
      }

      if (fieldName == "fullAccess") {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "read"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "add"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "edit"
        ] = true;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "delete"
        ] = true;
      } else if (fieldName == "disable") {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "read"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "add"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "edit"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "delete"
        ] = false;
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = false;
      } else if (
        fieldName != "fullAccess" &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] == true
      ) {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = value;
      }
      updateCheckBox[categoryIndex].subcategory[subcategoryIndex][fieldName] =
        value;

      if (
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["read"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["add"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["edit"] ==
          true &&
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex]["delete"] ==
          true
      ) {
        updateCheckBox[categoryIndex].subcategory[subcategoryIndex][
          "fullAccess"
        ] = true;
      }
      setCheckBoxFrontendData(updateCheckBox);
    }

    // console.log("real", checkboxData);
  }

  function handleCheckboxClick(
    categoryIndex,
    subcategoryIndex,
    fieldName,
    checked
  ) {
    updateCheckboxData(categoryIndex, subcategoryIndex, fieldName, checked);
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "name" || name === "description") {
      setSecurity((prevState) => ({ ...prevState, [name]: value }));
    } else if (name === "superAdmin" && checked) {
      setSecurity((prevState) => ({
        ...prevState,
        superAdmin: true,
        frontend: false,
      }));
    } else if (name === "frontend" && checked) {
      setSecurity((prevState) => ({
        ...prevState,
        frontend: true,
        superAdmin: false,
      }));
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Security Class
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Create Security Class
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <form className="form-box" id="basic-form">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title">General information</h6>
                </div>

                <div className="card-body">
                  <div className="row g-3 d-block tw-px-[12px]">
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        Security Class Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        autoComplete="name"
                        value={security.name}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5 mb-3">
                      <label className="form-label">
                        General Description
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <textarea
                        name="description"
                        autoComplete="description"
                        value={security.description}
                        onChange={handleChange}
                        rows={4}
                        type="email"
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header">
                  <h6 className="card-title">Security Points</h6>
                </div>

                <div className="card-body">
                  <div className="tw-px-[12px]">
                    <div className="d-flex tw-gap-x-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="superAdmin"
                          checked={security.superAdmin}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="primary">
                          Super Admin
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="frontend"
                          checked={security.frontend}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="primary">
                          User/Physician (Frontend)
                        </label>
                      </div>
                    </div>

                    {security.superAdmin && (
                      <>
                        <div className="d-flex">
                          <div
                            className="box-one tw-bg-blue-300 p-1 text-center tw-font-bold mt-2"
                            style={{ width: "212px" }}
                          >
                            Function
                          </div>
                        </div>

                        <Box
                          sx={{
                            flexGrow: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                            height: 260,
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChangeTwo}
                            aria-label="Vertical tabs example"
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                            }}
                            style={{ width: "300px !important" }}
                            id="TabsMains"
                          >
                            <Tab label="Provider Admin" {...a11yProps(0)} />
                            <Tab label="User Account" {...a11yProps(1)} />
                            <Tab label="User Role" {...a11yProps(2)} />
                            <Tab label="Template Config" {...a11yProps(3)} />
                            <Tab
                              label="Subscription Config"
                              {...a11yProps(4)}
                            />
                          </Tabs>
                          <TabPanel
                            value={value}
                            index={0}
                            id="TabPanal-one"
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Demographic Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxData[0].subcategory[0].read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxData[0].subcategory[0].add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="edit"
                                      checked={
                                        checkboxData[0].subcategory[0].edit ||
                                        ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxData[0].subcategory[0].delete ||
                                        ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxData[0].subcategory[0]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxData[0].subcategory[0]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Contact Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxData[0].subcategory[1].read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxData[0].subcategory[1].add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxData[0].subcategory[1].edit ||
                                        ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxData[0].subcategory[1].delete ||
                                        ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxData[0].subcategory[1]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxData[0].subcategory[1]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    New Approved Provider
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxData[0].subcategory[2].read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxData[0].subcategory[2].add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxData[0].subcategory[2].edit ||
                                        ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxData[0].subcategory[2].delete ||
                                        ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxData[0].subcategory[2]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxData[0].subcategory[2]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={1}
                            style={{ width: "80%" }}
                          >
                            <table
                              className="tw-w-[100%]"
                              style={{ overflow: "scroll" }}
                            >
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Demographic Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[0].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Address</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[1].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">User Security</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[2].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">User Roles</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[3].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    New Approved User
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[1].subcategory[4].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={2}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    General Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[0].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Template Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[2].subcategory[1].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={3}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Template</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[3].subcategory[0].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={4}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[0].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[1].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Group Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2].read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2].add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2].edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2].delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2]
                                          .fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        checkboxData[4].subcategory[2].disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel value={value} index={5}>
                            Item Six
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            Item Seven
                          </TabPanel>
                          <TabPanel value={value} index={7}>
                            Item Eight
                          </TabPanel>
                          <TabPanel value={value} index={8}>
                            Item Nine
                          </TabPanel>
                          <TabPanel value={value} index={9}>
                            Item Ten
                          </TabPanel>
                        </Box>
                      </>
                    )}

                    {security.frontend && (
                      <>
                        <div className="d-flex">
                          <div
                            className="box-one tw-bg-blue-300 p-1 text-center tw-font-bold mt-2"
                            style={{ width: "212px" }}
                          >
                            Function
                          </div>
                        </div>

                        <Box
                          sx={{
                            flexGrow: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                            height: 280,
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChangeTwo}
                            aria-label="Vertical tabs example"
                            sx={{
                              borderRight: 1,
                              borderColor: "divider",
                            }}
                            style={{ width: "300px !important" }}
                            id="TabsMains"
                          >
                            <Tab label="User Profile" {...a11yProps(0)} />
                            <Tab label="User Filter" {...a11yProps(1)} />
                          </Tabs>

                          <TabPanel
                            value={value}
                            index={0}
                            id="TabPanal-one"
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Profile Image</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[0]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Password</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[1]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Address</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[2]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Email</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[3]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Phone</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[4]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Security Question
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[0].subcategory[5]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={1}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Subscribe</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="edit"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[1].subcategory[0]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Custom View</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[1].subcategory[1]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Filter</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .add || ""
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .edit || ""
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .delete || ""
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .fullAccess || ""
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        checkboxFrontendData[1].subcategory[2]
                                          .disable || ""
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4 tw-flex tw-justify-end">
                <button
                  type="reset"
                  className="btn submit-btn d-inline ms-3"
                  // onClick={handleClearForm}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  onClick={(e) => sendDataToAPI(e)}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSecurityClass;
