import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

let Filter = [
  {
    id: 1,
    type: "Age 55+",
  },
  {
    id: 2,
    type: "Age more then 60+",
  },
  {
    id: 3,
    type: "Date Range Last 12th Month",
  },
];

function AddView() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const tokenUser = bytes.toString(CryptoJS.enc.Utf8);
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [view, setView] = useState({
    Name: "",
    Description: "",
    filterType: "",
  });
  const [secure, setSecure] = useState([]);

  const handleView = (e) => {
    setView({ ...view, [e.target.name]: e.target.value });
  };

  const handleAddView = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}afterLogin/addSubscribe?check=view`,
        { ...view },
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        fetchData();
        setView({
          Name: "",
          Description: "",
          filterType: "",
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const CloseSecondModal2 = (e) => {
    e.preventDefault();
    setOpen(false);
    setEditedType("");
  };

  const handleEdit = (e) => {
    setEditedType({ ...editedType, [e.target.name]: e.target.value });
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}afterlogin/getSubscribe?task=view`,
  //       {
  //         headers: {
  //           Authorization: tokenUser,
  //         },
  //       }
  //     );
  //     const formattedData = response?.data?.date?.map((item, index) => ({
  //       sno: index + 1,
  //       name: item.Name || "",
  //       description: item.Description || "",
  //       Schedule: item.filterType,
  //       created: convertTimestampToDateFormat(item?.createdAt),
  //       status: item.Active ? (
  //         <span className="badge bg-success">Active</span>
  //       ) : (
  //         <span className="badge bg-danger">In-Active</span>
  //       ),
  //       action: (
  //         <div className="action-btn-box">
  //         {secure &&
  //           secure[7] &&
  //           (secure[7]?.fullAccess || secure[7]?.edit) && (
  //           <Link to="" className="edit" onClick={() => handleEditClick(item)}>
  //             <i className="fa fa-pencil"></i>
  //           </Link>
  //           )}
  //           {secure &&
  //             secure[7] &&
  //             (secure[7]?.fullAccess || secure[7]?.delete) && (
  //           <Link
  //             className="delete"
  //             onClick={(e) => handleDeleteClick(e, item.id)}
  //           >
  //             <i className="fa fa-trash"></i>
  //           </Link>
  //             )}
  //         </div>
  //       ),
  //     }));
  //     setData(formattedData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchData = async () => {
    try {
      if (!secure) return;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}afterlogin/getSubscribe?task=view`,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      );
      const formattedData = response?.data?.date?.map((item, index) => ({
        sno: index + 1,
        name: item.Name || "",
        description: item.Description || "",
        Schedule: item.filterType,
        created: convertTimestampToDateFormat(item?.createdAt),
        status: item.Active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">In-Active</span>
        ),
        action: (
          <div className="action-btn-box">
            {secure &&
              secure[0] &&
              (secure[0].fullAccess || secure[0].edit) && (
                <Link className="edit" onClick={() => handleEditClick(item)}>
                  <i className="fa fa-pencil"></i>
                </Link>
              )}
            {secure &&
              secure[0] &&
              (secure[0].fullAccess || secure[0].delete) && (
                <Link
                  className="delete"
                  onClick={(e) => handleDeleteClick(e, item.id)}
                >
                  <i className="fa fa-trash"></i>
                </Link>
              )}
          </div>
        ),
      }));
      setData(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = (e, item) => {
    e.preventDefault();
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}afterLogin/deleteSubscribe?check=view&id=${item}`,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        fetchData();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, [secure]);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Custom View Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "FilterType",
        accessor: "Schedule",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      // },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const handleEditClick = (item) => {
    setEditedType(item);
    setOpen(true);
  };

  const handleEditSubscrib = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/editSubscribe?check=view`,
        {
          Description: editedType.Description,
          Name: editedType.Name,
          filterType: editedType.filterType,
          id: editedType.id,
        },
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        fetchData();
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin`, {
        headers: {
          Authorization: tokenUser,
        },
      })
      .then((res) => {
        const security = res?.data?.userRole;
        const finalSecure = security[security.length - 1];
        const mainData = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "customView";
          }
        );
        setSecure(mainData);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Custom View
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/user-dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Custom View</li>
              </ul>
            </div>
          </div>
        </div>

        {/* <div className="row g-2 clearfix row-deck">
              <form className="form-box addressForm" id="basic-form">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <h6 className="card-title">Custom View Information</h6>
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Name"
                            value={view.Name}
                            onChange={handleView}
                          />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            name="Description"
                            value={view.Description}
                            onChange={handleView}
                          />
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">Filter </label>
                          <select
                            className="form-select"
                            name="filterType"
                            onChange={handleView}
                            value={view.filterType}
                          >
                            <option>Select Filter</option>
                            {Filter?.map((item, i) => {
                              return (
                                <option key={i} value={item.type}>
                                  {item.type}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <footer className="custom_modal-footer d-flex tw-justify-end">
                      <button
                        className="submit-btn-primary"
                        type="submit"
                        onClick={handleAddView}
                      >
                        Accept
                      </button>
                      <button className="declane-btn-primary ms-3">
                        Clear
                      </button>
                    </footer>
                  </div>
                </div>
              </form>
            </div> */}

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            open === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button
              className="custom_close"
              onClick={CloseSecondModal2}
            ></button>
            <header className="custom_modal-header">
              <h2>Edit Custom View</h2>
            </header>

            <form className="form-box addressForm" id="basic-form">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="card-body">
                  <div className="row g-3">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Name"
                        value={editedType.Name || ""}
                        onChange={handleEdit}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="Description"
                        value={editedType.Description || ""}
                        onChange={handleEdit}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Filter </label>
                      <select
                        className="form-select"
                        name="filterType"
                        onChange={handleEdit}
                      >
                        <option>Select Filter</option>
                        {Filter?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.type}
                              selected={item.type === editedType.filterType}
                            >
                              {item.type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <footer className="custom_modal-footer tw-gap-x-4">
                <button
                  className="submit-btn-primary"
                  type="submit"
                  onClick={handleEditSubscrib}
                >
                  Accept
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal2}
                >
                  Cancel
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddView;
