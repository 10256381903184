import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CryptoJS from "crypto-js";

const Chart3 = (props) => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    // Extract dates from props and update state
    const datesFromProps =
      props?.series?.enrolledRequestUsersWeekly?.dates || [];
    setDates(datesFromProps);
  }, [props.series]);
  // console.log("first", dates);
  const handleColumnClick = async (_, __, config) => {
    // console.log(config);
    const newCheck = config?.config?.series[0]?.data?.length > 0;
    const pendingCheck = config?.config?.series[1]?.data?.length > 0;
    const approvedCheck = config?.config?.series[2]?.data?.length > 0;

    // Get the date corresponding to the clicked data point
    const dateSelected = config?.globals?.labels[config.dataPointIndex];
    // console.log("date", dateSelected);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}dashboard/filter?date=${dateSelected}&new=${newCheck}&pending=${pendingCheck}&approved=${approvedCheck}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const formattedData = res.data.data.map((item, index) => ({
        sno: index + 1,
        request: new Date(item?.createdAt).toLocaleDateString("en-US"),
        name: `${item?.FirstName} ${item?.MiddleName} ${item?.LastName}`,
        phone: item?.PhoneNumber,
        email: item?.E_Mail,
        system: item?.Title_Type?.type,
      }));

      // Pass the formatted data to a function received as props
      props.data(formattedData);
      props.open(false);
    } catch (error) {}
  };

  useEffect(() => {
    const formattedDates =
      props?.series?.enrolledRequestUsersWeekly?.dates?.map((date) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      );
    const formattedDates2 =
      props?.series?.enrolledPendingUsersWeekly?.dates?.map((date) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      );
    const formattedDates3 =
      props?.series?.enrolledPendingUsersWeekly?.dates?.map((date) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
      );
    setOptions({
      series: [
        {
          name: "New",
          data: props?.series?.enrolledRequestUsersWeekly?.data,
        },
        {
          name: "Pending",
          data: props?.series?.enrolledPendingUsersWeekly?.data || [
            25, 83, 92, 26, 23, 25,
          ],
        },
        {
          name: "Approved",
          data: props?.series?.enrolledCompletedUsersWeekly?.data || [
            12, 52, 25, 35, 19, 52,
          ],
        },
      ],
      xaxis: {
        categories: formattedDates ||
          formattedDates2 ||
          formattedDates3 || [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
          ],
        labels: {
          formatter: function (val) {
            return val + "";
          },
        },
      },
    });
  }, [props.series]);

  const [options, setOptions] = useState({
    series: [
      {
        name: "New",
        data: props?.series?.enrolledProvidersWeekly?.data,
      },
      {
        name: "Pending",
        data: [25, 83, 92, 26, 23, 25],
      },
      {
        name: "Approved",
        data: [12, 52, 25, 35, 19, 52],
      },
    ],
    chart: {
      type: "bar",
      events: {
        click: handleColumnClick,
      },
      height: 245,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: [
      "var(--chart-color4)",
      "var(--chart-color1)",
      "var(--chart-color2)",
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props?.series?.enrolledProvidersWeekly?.dates || [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ],
      labels: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 0,
    },
  });

  return (
    <div id="Salary_Statistics">
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={245}
      />
    </div>
  );
};

export default Chart3;
