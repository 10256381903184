import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CryptoJS from "crypto-js";

const PieChart = () => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [reqs, setReqs] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getCount`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setReqs(res?.data || {});
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const seriesData = [
    reqs.newRequestData || 0,
    reqs.pendingData || 0,
    reqs.completedData || 0,
  ];

  // const handleColumnClick = (event, chartContext, config) => {
  //   if (config && config.dataPointIndex !== undefined) {
  //     const clickedSliceLabel = options.labels[config.dataPointIndex];
  //     console.log("Clicked slice label:", clickedSliceLabel);
  //       switch (clickedSliceLabel) {
  //         case "New":
  //           console.log("Clicked on New slice.");
  //           break;
  //         case "Pending":
  //           console.log("Clicked on Pending slice.");
  //           break;
  //         case "Completed":
  //           console.log("Clicked on Completed slice.");
  //           break;
  //         default:
  //           console.log("Unknown slice clicked.");
  //       }
  //   } else {
  //     console.log("Click event occurred outside of any data point.");
  //   }
  // };

  const handleColumnClick = (event, chartContext, config) => {
    if (
      config &&
      config.dataPointIndex !== undefined &&
      seriesData[config.dataPointIndex] !== 0
    ) {
      switch (config.dataPointIndex) {
        case 0:
          // console.log("Clicked on New slice.");
          break;
        case 1:
          // console.log("Clicked on Pending slice.");
          break;
        case 2:
          // console.log("Clicked on Completed slice.");
          break;
        default:
        // console.log("Unknown slice clicked.");
      }
    } else {
      // console.log(
      //   "Click event occurred outside of any data point or no data available for this slice."
      // );
    }
  };

  const options = {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: 0,
      background: {
        enabled: true,
        foreColor: "#000",
      },
    },
    chart: {
      width: 190,
      height: 190,
      type: "pie",
      events: {
        click: handleColumnClick,
      },
    },
    labels: ["New", "Pending", "Completed"],
    colors: [
      "var(--chart-color1)",
      "var(--chart-color2)",
      "var(--chart-color4)",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="sparkline-pie">
      <Chart
        options={options}
        series={seriesData}
        type="pie"
        width={options.chart.width}
        height={options.chart.height}
      />
    </div>
  );
};

export default PieChart;
