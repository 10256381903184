import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useSharedContext } from "./SharedDataSet";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";

function TopBar(props) {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const mobileMenuBar = () => {
    $("body").toggleClass("offcanvas-active");
  };
  const { emailValue } = useSharedContext();
  const { notificationValue } = useSharedContext();
  const [emailBtn, setEmailBtn] = useState(emailValue);
  const [notificationBtn, setNotificationBtn] = useState(notificationValue);
  const { newUserRequests, pendingRequest, newRequests, pendingData } =
    useSharedContext();

  useEffect(() => {
    setEmailBtn(emailValue);
    setNotificationBtn(notificationValue);
  }, [emailValue, notificationValue]);

  const handleLogout = async () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("email");
    localStorage.removeItem("subMenus");
    localStorage.removeItem("menuType");
    localStorage.removeItem("menuItems");
    localStorage.removeItem("localTextColor");
    localStorage.removeItem("types");
    localStorage.removeItem("activeItem");
    sessionStorage.removeItem("type");
    window.location.href = "/";
  };
  const showDot =
    newUserRequests > 0 ||
    pendingRequest > 0 ||
    newRequests > 0 ||
    pendingData > 0;

  return (
    <nav className="navbar navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-btn">
          <button
            type="button"
            className="btn-toggle-offcanvas"
            onClick={mobileMenuBar}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>

        <div className="navbar-brand pt-1">
          <a href="/dashboard" className="d-flex">
            <img src="logo.png" alt="" className="tw-h-[36px]" />
          </a>
        </div>

        <div className="d-flex flex-grow-1 align-items-center">
          <div className="flex-grow-1">
            <ul className="nav navbar-nav flex-row justify-content-end align-items-center">
              {emailBtn == true ? (
                <li>
                  <a href="#" className="icon-menu">
                    <i className="fa fa-envelope"></i>
                    <span className="notification-dot"></span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {notificationBtn == true ? (
                <li className="dropdown">
                  <a
                    className="dropdown-toggle icon-menu"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bell"></i>

                    <span className={showDot ? "notification-dot" : ""}></span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end p-0 shadow notification">
                    <ul className="list-unstyled feeds_widget">
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_user_request">
                            <h6 className="mb-1">
                              <span className="tw-text-green-500">
                                {newRequests || "0"} New Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_pending_request">
                            <h6 className="mb-1 text-warning">
                              {pendingData || "0"} Pending Request
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_provider_request">
                            <h6 className="mb-1">
                              <span className="tw-text-yellow-300">
                                {pendingRequest || "0"} Pending Provider Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <Link to="/new_users_request">
                            <h6 className="mb-1">
                              <span className="tw-text-yellow-300">
                                {newUserRequests || "0"} Pending User Request
                              </span>
                            </h6>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              )}

              {/* more link  */}
              <li className="dropdown ">
                <a
                  className="dropdown-toggle icon-menu d-none"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-sliders"></i>
                </a>
                <ul className="dropdown-menu dropdown-menu-end p-2 shadow">
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-pencil-square-o"></i>
                      <span>Basic</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-sliders fa-rotate-90"></i>
                      <span>Preferences</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-lock"></i> <span>Privacy</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-bell"></i>
                      <span>Notifications</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-credit-card"></i>
                      <span>Payments</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-print"></i> <span>Invoices</span>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item rounded-pill" href="#">
                      <i className="me-2 fa fa-refresh"></i>
                      <span>Renewals</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  onClick={handleLogout}
                  className="icon-menu tw-cursor-pointer logout-main"
                  title="Logout"
                >
                  <i className="fa fa-sign-out"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopBar;
