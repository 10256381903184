import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function EditSecurityClass() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [value, setValue] = React.useState(0);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const handleChangeTwo = (event, newValue) => {
    setValue(newValue);
  };
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const [data, setData] = useState([]);
  const [Demographic, setDemographic] = useState({
    description: "",
    name: "",
    Active: "",
    frontend: "",
    superAdmin: "",
    SecurityPoints: [],
    id: "",
  });
  const [openModal, setOpenModal] = useState(false);

  function handleCheckboxClick(
    categoryIndex,
    subcategoryIndex,
    fieldName,
    checked
  ) {
    updateCheckboxData(categoryIndex, subcategoryIndex, fieldName, checked);
  }

  function updateCheckboxData(
    categoryIndex,
    subcategoryIndex,
    fieldName,
    value
  ) {
    const updateCheckBox = { ...Demographic };
    if (
      fieldName != "disable" &&
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["disable"] == true
    ) {
      toast.error("Please uncheck disable first!");
      return;
    }

    if (fieldName == "fullAccess") {
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["read"] = true;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["add"] = true;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["edit"] = true;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["delete"] = true;
    } else if (fieldName == "disable") {
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["read"] = false;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["add"] = false;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["edit"] = false;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["delete"] = false;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["fullAccess"] = false;
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ][fieldName] = value;
      setDemographic(updateCheckBox);
      return;
    } else if (
      fieldName != "fullAccess" &&
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["fullAccess"] == true
    ) {
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["fullAccess"] = value;
    }
    updateCheckBox.SecurityPoints[categoryIndex].subCategory[subcategoryIndex][
      fieldName
    ] = value;

    if (
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["read"] == true &&
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["add"] == true &&
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["edit"] == true &&
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["delete"] == true
    ) {
      updateCheckBox.SecurityPoints[categoryIndex].subCategory[
        subcategoryIndex
      ]["fullAccess"] = true;
    }
    setDemographic(updateCheckBox);
  }

  const closeModalBox = (e) => {
    e.preventDefault();
    setOpenModal(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Security Class Name",
        accessor: "name",
      },
      {
        Header: "Security Class Type",
        accessor: "type",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}securityClass/get?stage=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        // console.log("admi", res.data.message);
        const formattedData = res.data.message.map((item, index) => ({
          sno: index + 1,
          name: item?.name,
          description: item?.description,
          type:
            item?.superAdmin == true ? (
              <span className="">Super Admin</span>
            ) : (
              <span className="">Frontend </span>
            ),
          created: convertTimestampToDateFormat(item?.createdAt),
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link className="edit" onClick={() => handleSubmit(item?.id)}>
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));
        setData(formattedData);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (id) => {
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}securityClass/get?id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const userAccountData = response?.data?.message;
      setDemographic(userAccountData);
      setOpenModal(true);
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const checkboxValue = type === "checkbox" ? checked : value;

    if (name === "Active") {
      setDemographic({
        ...Demographic,
        [name]: checkboxValue,
      });
    } else {
      setDemographic({ ...Demographic, [name]: value });
    }
  };

  const handleFinalUpdate = (e) => {
    e.preventDefault();
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}securityclass/update`,
        {
          securityPoints: Demographic.SecurityPoints,
          name: Demographic.name,
          description: Demographic.description,
          frontend: Demographic.frontend,
          superAdmin: Demographic.superAdmin,
          id: Demographic.id,
          Active: Demographic.Active,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 2000 });
        setOpenModal(false);
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message, { autoClose: 2000 });
      });
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Security Class
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Security Class</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit Security Class</h2>
            </header>

            <form className="form-box tw-w-[100%]" id="basic-form">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">General Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        autoComplete="Active"
                        checked={Demographic.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row g-3 d-block tw-px-[12px]">
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        Security Class Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        id="UserRoleName"
                        name="name"
                        value={Demographic.name}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5 mb-3">
                      <label className="form-label">General Description</label>
                      <textarea
                        name="description"
                        value={Demographic.description}
                        onChange={(e) => handleChange(e)}
                        rows={4}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-header">
                  <h6 className="card-title">Security Points</h6>
                </div>

                <div className="card-body">
                  <div className="tw-px-[12px]">
                    <div className="d-flex tw-gap-x-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="superAdmin"
                          checked={Demographic.superAdmin}
                          disabled={Demographic.frontend}
                        />
                        <label className="form-check-label" htmlFor="primary">
                          Super Admin
                        </label>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="frontend"
                          checked={Demographic.frontend}
                          disabled={Demographic.superAdmin}
                        />
                        <label className="form-check-label" htmlFor="primary">
                          Frontend (User/Physician)
                        </label>
                      </div>
                    </div>

                    {Demographic.superAdmin && (
                      <>
                        <div className="d-flex">
                          <div
                            className="box-one tw-bg-blue-300 p-1 text-center tw-font-bold mt-2"
                            style={{ width: "220px" }}
                          >
                            Function
                          </div>
                        </div>

                        <Box
                          sx={{
                            flexGrow: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                            height: 260,
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChangeTwo}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: "divider" }}
                            style={{ width: "300px !important" }}
                            id="TabsMains"
                          >
                            <Tab label="Provider Admin" {...a11yProps(0)} />
                            <Tab label="User Account" {...a11yProps(1)} />
                            <Tab label="User Role" {...a11yProps(2)} />
                            <Tab label="Template Config" {...a11yProps(3)} />
                            <Tab
                              label="Subscription Config"
                              {...a11yProps(4)}
                            />
                            {/*<Tab label="Analytics" {...a11yProps(5)} />
                            <Tab label="Record Viewer" {...a11yProps(6)} />
                            <Tab label="Category List" {...a11yProps(7)} />
                            <Tab
                              label="User Access Request"
                              {...a11yProps(8)}
                            />
                            <Tab label="Archive" {...a11yProps(9)} />*/}
                          </Tabs>
                          <TabPanel
                            value={value}
                            index={0}
                            id="TabPanal-one"
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Demographic Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="fullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Contact Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    New Approved Provider
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={1}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Demographic Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Address</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">User Security</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">User Roles</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[3]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          3,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    New Approved User
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[4]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          4,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={2}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    General Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Template Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={3}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Template</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={4}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Group Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel value={value} index={5}>
                            Item Six
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            Item Seven
                          </TabPanel>
                          <TabPanel value={value} index={7}>
                            Item Eight
                          </TabPanel>
                          <TabPanel value={value} index={8}>
                            Item Nine
                          </TabPanel>
                          <TabPanel value={value} index={9}>
                            Item Ten
                          </TabPanel>
                        </Box>
                      </>
                    )}
                    {Demographic.frontend && (
                      <>
                        <div className="d-flex">
                          <div
                            className="box-one tw-bg-blue-300 p-1 text-center tw-font-bold mt-2"
                            style={{ width: "220px" }}
                          >
                            Function
                          </div>
                        </div>

                        <Box
                          sx={{
                            flexGrow: 1,
                            bgcolor: "background.paper",
                            display: "flex",
                            height: 280,
                          }}
                        >
                          <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChangeTwo}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: "divider" }}
                            style={{ width: "300px !important" }}
                            id="TabsMains"
                          >
                            <Tab label="User Profile" {...a11yProps(0)} />
                            <Tab label="User Filter" {...a11yProps(1)} />

                            {/*<Tab label="Analytics" {...a11yProps(5)} />
                            <Tab label="Record Viewer" {...a11yProps(6)} />
                            <Tab label="Category List" {...a11yProps(7)} />
                            <Tab
                              label="User Access Request"
                              {...a11yProps(8)}
                            />
                            <Tab label="Archive" {...a11yProps(9)} />*/}
                          </Tabs>
                          <TabPanel
                            value={value}
                            index={0}
                            id="TabPanal-one"
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Profile Image</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="fullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Update Password</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Update Address</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Update Email</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[3]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          3,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Update Phone</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[4]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          4,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Update Security Question
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="read"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.read
                                      }
                                      id="read"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="add"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.add
                                      }
                                      id="Add"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Edit"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.edit
                                      }
                                      id="Edit"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Delete"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.delete
                                      }
                                      id="Delete"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="FullAccess"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.fullAccess
                                      }
                                      id="FullAccess"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Disable"
                                      checked={
                                        Demographic?.SecurityPoints[0]
                                          ?.subCategory[5]?.disable
                                      }
                                      id="Disable"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          0,
                                          5,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={1}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Subscribe</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Custom View</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">Filter</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[1]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          1,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={2}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    General Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Template Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[2]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          2,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={3}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">Template</td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[3]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          3,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={4}
                            style={{ width: "80%" }}
                          >
                            <table className="tw-w-[100%]">
                              <thead>
                                <tr>
                                  <th className="tw-w-[%]">Section</th>
                                  <th className="tw-w-[%] text-center">Read</th>
                                  <th className="tw-w-[%] text-center">Add</th>
                                  <th className="tw-w-[%] text-center">Edit</th>
                                  <th className="tw-w-[%] text-center">
                                    Delete
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Full Access
                                  </th>
                                  <th className="tw-w-[%] text-center">
                                    Disable
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[0]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          0,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Subscription Security
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[1]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          1,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="tw-w-[%]">
                                    Group Information
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.read
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "read",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.add
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "add",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.edit
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "edit",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.delete
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "delete",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.fullAccess
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "fullAccess",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="tw-w-[%] text-center">
                                    <input
                                      type="checkbox"
                                      name="Frontend"
                                      checked={
                                        Demographic?.SecurityPoints[4]
                                          ?.subCategory[2]?.disable
                                      }
                                      id="SuperAdmin"
                                      className="shadow-sm  sm:text-sm sm:leading-6 mr-[6px]"
                                      onChange={(event) =>
                                        handleCheckboxClick(
                                          4,
                                          2,
                                          "disable",
                                          event.target.checked,
                                          event
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </TabPanel>
                          <TabPanel value={value} index={5}>
                            Item Six
                          </TabPanel>
                          <TabPanel value={value} index={6}>
                            Item Seven
                          </TabPanel>
                          <TabPanel value={value} index={7}>
                            Item Eight
                          </TabPanel>
                          <TabPanel value={value} index={8}>
                            Item Nine
                          </TabPanel>
                          <TabPanel value={value} index={9}>
                            Item Ten
                          </TabPanel>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  type="submit"
                  onClick={handleFinalUpdate}
                >
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSecurityClass;
