import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CryptoJS from "crypto-js";

const PieChart2 = () => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [datesData, setDatesData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getProvider`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setData(res?.data?.data); // Store the actual data
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setUser(res?.data?.data); // Store the actual data
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const handleColumnClick = async (_, __, config) => {
    // console.log(config);
    // console.log(config.config.labels[1]);
    // const newCheck = config?.config?.series[0]?.data?.length > 0;
    // const pendingCheck = config?.config?.series[1]?.data?.length > 0;
    // const approvedCheck = config?.config?.series[2]?.data?.length > 0;
    // Get the date corresponding to the clicked data point
    // Use a callback to ensure we get the most recent state
    // setDatesData((prevDatesData) => {
    //   console.log(prevDatesData);
    //   const dateSelected = prevDatesData[config.dataPointIndex];
    //   const fetchData = async () => {
    //     axios
    //       .get(
    //         `${process.env.REACT_APP_BASE_URL}dashboard/filter?date=${dateSelected}&new=${newCheck}&pending=${pendingCheck}&approved=${approvedCheck}`
    //       )
    //       .then(function (res) {
    //         console.log("MANI<><>", res.data.data);
    //         const formattedData = res.data.data[0].map((item, index) => ({
    //           sno: index + 1,
    //           request: new Date(item?.createdAt).toLocaleDateString("en-US"),
    //           name:
    //             item?.FirstName +
    //             " " +
    //             item?.MiddleName +
    //             " " +
    //             item?.LastName +
    //             " ",
    //           phone: item?.PhoneNumber,
    //           email: item?.E_Mail,
    //           system: item?.Title_Type?.type,
    //         }));
    //         props.data(formattedData);
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });
    //   };
    //   //fetchData();
    //   return prevDatesData;
    // });
  };

  const options = {
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: 0,
      background: {
        enabled: true,
        foreColor: "#000",
      },
    },
    chart: {
      width: 190,
      height: 190,
      type: "pie",
      events: {
        click: handleColumnClick,
      },
    },
    labels: ["Provider", "User"],
    colors: [
      "var(--chart-color1)",
      "var(--chart-color2)",
      "var(--chart-color4)",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="sparkline-pie">
      <Chart
        options={options}
        series={[data.length || 0, user.length || 0]}
        type="pie"
        width={options.chart.width}
        height={options.chart.height}
      />
    </div>
  );
};

export default PieChart2;
