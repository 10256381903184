import React, { useEffect, useState } from "react";
import ToggleNavBar from "../CommonPage/ToggleNavBar";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useSharedContext } from "../CommonPage/SharedDataSet";
import CryptoJS from "crypto-js";

const Profile = () => {
  const { activeUser } = useSharedContext();
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [imageMain, setImgs] = useState("");
  const { updateProfilePicture, uploadedImage } = useSharedContext();
  const [loadingImg, setLoadinImg] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open, setOpen] = useState(false);
  const [Demographic, setDemographic] = useState({
    QuestionTypes: [{}, {}, {}],
  });
  const [securityQuestion, setSecurityQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [updatePass, setUpdatePass] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const [Profilers, setProfilers] = useState([]);
  const [Password, setPassword] = useState([]);
  const [Address, setAddress] = useState([]);
  const [Email, setEmail] = useState([]);
  const [Phone, setPhone] = useState([]);
  const [SecurityQ, setSecurityQ] = useState([]);

  const handlePictureChange = (i) => {
    updateProfilePicture(i);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const security = res?.data?.userRole;
        const finalSecure = security[security.length - 1];
        const mainProfile = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "profileImage";
          }
        );
        const mainPassword = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "updatePassword";
          }
        );
        const mainAddress = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "updateAddress";
          }
        );
        const mainEmail = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "updateEmail";
          }
        );
        const mainPhone = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "updatePhone";
          }
        );
        const mainSecurityQ = finalSecure?.SecurityClass?.SecurityPoints.filter(
          (points) => {
            return points.subcategory == "updateSecurityQuestions";
          }
        );
        setProfilers(mainProfile);
        setPassword(mainPassword);
        setAddress(mainAddress);
        setEmail(mainEmail);
        setPhone(mainPhone);
        setSecurityQ(mainSecurityQ);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}afterlogin/getuserdetails`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setDemographic(res?.data?.message || {});
        if (res.data.url && res.data.url.url) {
          handlePictureChange(res.data.url.url);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=securityQuestion`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setSecurityQuestion(response.data.data);
      })
      .catch((error) => {
        toast(error);
      });
  }, []);

  const UploadImage = (img, ids) => {
    const formData = new FormData();
    formData.append("image", img);
    formData.append("sysId", ids);
    setLoadinImg(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update?task=updateProfileImage`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        handlePictureChange(res?.data?.url);
      })
      .catch(() => {
        console.log("error");
      })
      .finally(() => {
        setLoadinImg(false);
      });
  };

  const CloseSecondModal = (e) => {
    e.preventDefault();
    setOpen2(false);
  };

  const CloseSecondModal4 = (e) => {
    e.preventDefault();
    setOpen4(false);
  };

  const CloseSecondModal5 = (e) => {
    e.preventDefault();
    setOpen5(false);
  };

  const CloseSecondModal2 = (e) => {
    e.preventDefault();
    setOpen(false);
  };

  const CloseSecondModal3 = (e) => {
    e.preventDefault();
    setOpen3(false);
  };

  const handleOpenAddress = () => {
    setOpen2(true);
  };

  const handleOpenQuestion = () => {
    setOpen5(true);
  };

  const handleOpenPassword = () => {
    setOpen4(true);
  };

  const handleOpenEmail = () => {
    setOpen3(true);
  };

  const handleOpenPhone = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      name === "City" ||
      name === "County" ||
      name === "Country" ||
      name === "State"
    ) {
      if (/^[A-Za-z\s]+$/u.test(value) || value === "") {
        setDemographic({ ...Demographic, [name]: value });
      }
    } else {
      setDemographic({ ...Demographic, [name]: value });
    }
  };

  const handleUpdateDataTwo = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update`,
        {
          AddressLine1: Demographic.AddressLine1,
          AddressLine2: Demographic.AddressLine2,
          City: Demographic.City,
          State: Demographic.State,
          County: Demographic.County,
          ZipCode: Demographic.ZipCode,
          Country: Demographic.Country,
          id: Demographic.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen2(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleUpdatePhone = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update?task=updatePhone`,
        {
          PhoneNumber: Demographic.PhoneNumber,
          id: Demographic.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleUpdateEmail = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update?task=updateEmail`,
        {
          E_Mail: Demographic.E_Mail,
          id: Demographic.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen3(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update?task=updatePassword`,
        {
          Password: updatePass.Password,
          ConfirmPassword: updatePass.ConfirmPassword,
          id: Demographic.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen4(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleUpdateQuestion = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}afterLogin/update?task=updateQuestions`,
        {
          ...Demographic,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen5(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleEmail = (e) => {
    const { name, value } = e.target;
    setDemographic({ ...Demographic, [name]: value });
  };

  const handlePhone = (e) => {
    const { name, value } = e.target;
    setDemographic({ ...Demographic, [name]: value });
  };

  const handleBlurEmail = (e) => {
    const { name, value } = e.target;
    if (name === "E_Mail") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        toast.warning("Please enter a valid email.", { autoClose: 1000 });
        setDemographic({ ...Demographic, [name]: value });
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
        setDemographic({ ...Demographic, [name]: value });
      }
    }
  };

  const handlePassword = (e) => {
    setUpdatePass({ ...updatePass, [e.target.name]: e.target.value });
  };

  const handleQuestion = async (e) => {
    const { name, value } = e.target;
    console.log(value);
    if (name.startsWith("Question")) {
      const questionIndex = parseInt(name[name.length - 1]) - 1;
      const updatedQuestionTypes = [...Demographic.QuestionTypes];
      // console.log(updatedQuestionTypes);

      if (name == "Question1") {
        if (
          value === updatedQuestionTypes[1]?.id ||
          value === updatedQuestionTypes[2]?.id
        ) {
          toast.warning(
            "This question already selected. Please choose different question"
          );
          return "";
        }
      } else if (name == "Question2") {
        if (
          value === updatedQuestionTypes[0]?.id ||
          value === updatedQuestionTypes[2]?.id
        ) {
          toast.warning(
            "This question already selected. Please choose different question"
          );
          return "";
        }
      } else if (name == "Question3") {
        if (
          value === updatedQuestionTypes[1]?.id ||
          value === updatedQuestionTypes[0]?.id
        ) {
          toast.warning(
            "This question already selected. Please choose different question"
          );
          return "";
        }
      }

      updatedQuestionTypes[questionIndex] = {
        ...updatedQuestionTypes[questionIndex],
        id: value,
      };

      setDemographic((prevState) => ({
        ...prevState,
        QuestionTypes: updatedQuestionTypes,
      }));
    } else if (name.startsWith("Answer")) {
      const answerIndex = parseInt(name[name.length - 1]) - 1;
      const updatedQuestionTypes = [...Demographic.QuestionTypes];
      updatedQuestionTypes[answerIndex] = {
        ...updatedQuestionTypes[answerIndex],
        userQuestion: { Answer: value },
      };

      setDemographic((prevState) => ({
        ...prevState,
        QuestionTypes: updatedQuestionTypes,
      }));
    } else {
      setDemographic({ ...Demographic, [name]: value });
    }
  };

  useEffect(() => {
    if (activeUser) {
      const fname = activeUser?.FirstName || "";
      const lname = activeUser?.LastName || "";

      const firstInitials =
        fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
      console.log("DATA IMG", firstInitials);
      setImgs(firstInitials);
    }
  }, [activeUser]);

  useEffect(() => {
    // console.log("Updated imgs state:", imageMain);
  }, [imageMain]);

  return (
    <>
      <>
        <div className="container-fluid">
          <div className="block-header py-lg-4 py-3">
            <div className="row g-3">
              <div className="col-md-6 col-sm-12">
                <h2 className="m-0 fs-5">
                  <ToggleNavBar />
                  My Profile
                </h2>
                <ul className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/user-dashboard">sLEAP</Link>
                  </li>
                  <li className="breadcrumb-item active">My Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="d-flex">
              <div class="tw-w-[22%] mb-3">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-column align-items-center text-center">
                      <div className="tw-border-[4px] tw-border-[var(--primary-colorRGB2)] mt-1 mb-1 tw-rounded-full p-2">
                        {loadingImg == false ? (
                          <img
                            src={uploadedImage}
                            className="rounded-circle"
                            alt=""
                            width="97"
                          />
                        ) : (
                          <div className="noimg">{imageMain}</div>
                        )}
                      </div>
                      <div class="mt-3">
                        <h4>
                          {Demographic.FirstName + " " + Demographic.LastName}
                        </h4>
                        <p class="mb-1 tw-text-md">
                          {Demographic.State + ", " + Demographic.Country}
                        </p>
                      </div>
                      {Profilers && Profilers[0] && Profilers[0]?.edit && (
                        <div>
                          <input
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files && e.target.files[0];
                              if (file) {
                                UploadImage(file, Demographic?.id);
                              }
                            }}
                            placeholder="Upload Image"
                            className="form-control"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class="card mt-2">
                  <h6 class="d-flex align-items-center ml-2 mt-1">About Me</h6>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <Link class="mb-0" onClick={handleOpenAddress}>
                        Update Address
                      </Link>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <Link class="mb-0" onClick={handleOpenPhone}>
                        Update Phone Number
                      </Link>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <Link class="mb-0" onClick={handleOpenEmail}>
                        Update E-mail
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="card mt-2">
                  <h6 class="d-flex align-items-center ml-2 mt-1">
                    Privacy Setting
                  </h6>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <Link class="mb-0" onClick={handleOpenPassword}>
                        Change Password
                      </Link>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                      <Link class="mb-0" onClick={handleOpenQuestion}>
                        Security Question
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row gutters-sm">
                  <div class="col-sm-6 mb-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h5 class="d-flex align-items-center tw-mb-[11px]">
                          Demographic Information
                        </h5>
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Name</h6>
                          </div>
                          <div class="col-sm-6 text-secondary">
                            {Demographic.FirstName + " " + Demographic.LastName}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Date of Birth</h6>
                          </div>
                          <div class="col-sm-6 text-secondary">
                            {Demographic.DOB}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">NPI Number</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic?.Provider?.NPINumber || "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">ML Number</h6>
                          </div>
                          <div class="col-sm-5 text-secondary">
                            {Demographic?.Provider?.MedicalLicenseNumber ||
                              "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Title</h6>
                          </div>
                          <div class="col-sm-6 text-secondary">
                            {Demographic?.Provider?.Title_Type?.type || "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Provider Type</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic?.Provider?.Provider_Type?.type || "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Specility</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic?.Provider?.Speciality_Type?.type ||
                              "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-6">
                            <h6 class="mb-0">Referral Source</h6>
                          </div>
                          <div class="col-sm-6 text-secondary">
                            {Demographic?.Provider?.Referral_Source_Type
                              ?.type || "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-7">
                            <h6 class="mb-0">Preferred Communication</h6>
                          </div>
                          <div class="col-sm-4 text-secondary">
                            {Demographic?.Provider?.Communication_Type?.type ||
                              "NA"}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-3">
                    <div class="card tw-h-[100px]">
                      <div class="card-body">
                        <h5 class="d-flex align-items-center mb-3">
                          Assigned Security
                        </h5>
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">User Role</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic?.userRoles?.map((item, i) => {
                              return (
                                <div key={i} class="">
                                  {i + 1 + ". " + item?.UserRoleName}
                                </div>
                              );
                            }) || ""}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">Linked ID</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic.ProviderId || "NA"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-5">
                            <h6 class="mb-0">System Login</h6>
                          </div>
                          <div class="col-sm-7 text-secondary">
                            {Demographic.SystemLogin}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <div
        className={
          open2 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={CloseSecondModal}></button>
          <header className="custom_modal-header">
            <h2>Update Address</h2>
          </header>

          <form
            className="form-box addressForm"
            id="basic-form"
            onSubmit={handleUpdateDataTwo}
          >
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Address Line 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="AddressLine1"
                      value={Demographic?.AddressLine1}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Address Line 2</label>
                    <input
                      className="form-control"
                      type="text"
                      name="AddressLine2"
                      value={Demographic?.AddressLine2}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      name="City"
                      value={Demographic?.City}
                      onChange={(e) => handleChange(e)}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      name="State"
                      onChange={(e) => handleChange(e)}
                      value={Demographic.State}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">County</label>
                    <input
                      type="text"
                      name="County"
                      value={Demographic?.County}
                      onChange={(e) => handleChange(e)}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Zip Code</label>
                    <input
                      type="number"
                      name="ZipCode"
                      value={Demographic?.ZipCode}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      onChange={(e) => handleChange(e)}
                      style={{
                        WebkitAppearance: "none",
                        MozAppearance: "textfield",
                      }}
                      max={99999999}
                      min={10000}
                      onInput={(e) => {
                        if (e.target.value.length > 8) {
                          e.target.value = e.target.value.slice(0, 8);
                        }
                      }}
                      className="form-control"
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Country</label>
                    <input
                      type="text"
                      name="Country"
                      value={Demographic?.Country}
                      onChange={(e) => handleChange(e)}
                      disabled={
                        Address && Address[0] && Address[0]?.edit == false
                      }
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            {Address && Address[0] && Address[0]?.edit ? (
              <footer className="custom_modal-footer tw-gap-x-4">
                <button className="submit-btn-primary" type="submit">
                  Update Address
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal}
                >
                  Cancel
                </button>
              </footer>
            ) : (
              <p className="d-flex tw-justify-end mt-">
                You can't update the Address.
              </p>
            )}
          </form>
        </div>
      </div>

      <div
        className={
          open === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={CloseSecondModal2}></button>
          <header className="custom_modal-header">
            <h2>Update Phone Number</h2>
          </header>

          <form className="form-box addressForm" id="basic-form">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="PhoneNumber"
                      value={Demographic?.PhoneNumber}
                      disabled={Phone && Phone[0] && Phone[0]?.edit == false}
                      onChange={(e) => handlePhone(e)}
                      onInput={(e) => {
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {Phone && Phone[0] && Phone[0]?.edit ? (
              <footer className="custom_modal-footer tw-gap-x-4">
                <button
                  className="submit-btn-primary"
                  type="submit"
                  onClick={handleUpdatePhone}
                >
                  Update Phone Number
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal2}
                >
                  Cancel
                </button>
              </footer>
            ) : (
              <p className="d-flex tw-justify-end mt-">
                You can't update the Phone Number.
              </p>
            )}
          </form>
        </div>
      </div>

      <div
        className={
          open3 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={CloseSecondModal3}></button>
          <header className="custom_modal-header">
            <h2>Update E-mail</h2>
          </header>

          <form className="form-box addressForm" id="basic-form">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">E-mail</label>
                    <input
                      type="text"
                      className="form-control"
                      name="E_Mail"
                      disabled={Email && Email[0] && Email[0]?.edit == false}
                      value={Demographic?.E_Mail}
                      onBlur={(e) => handleBlurEmail(e)}
                      onChange={(e) => handleEmail(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {Email && Email[0] && Email[0]?.edit ? (
              <footer className="custom_modal-footer tw-gap-x-4">
                <button
                  className="submit-btn-primary"
                  type="submit"
                  onClick={handleUpdateEmail}
                  disabled={!isValidEmail}
                >
                  Update E-mail
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal3}
                >
                  Cancel
                </button>
              </footer>
            ) : (
              <p className="d-flex tw-justify-end mt-">
                You can't update the E-mail.
              </p>
            )}
          </form>
        </div>
      </div>

      <div
        className={
          open4 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={CloseSecondModal4}></button>
          <header className="custom_modal-header">
            <h2>Update Password</h2>
          </header>

          <form className="form-box addressForm" id="basic-form">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="card-body">
                <div className="row g-3">
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="text"
                      className="form-control"
                      name="Password"
                      value={updatePass.Password}
                      disabled={
                        Password && Password[0] && Password[0]?.edit == false
                      }
                      onChange={handlePassword}
                    />
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3">
                    <label className="form-label">Conform Password</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ConfirmPassword"
                      disabled={
                        Password && Password[0] && Password[0]?.edit == false
                      }
                      value={updatePass.ConfirmPassword}
                      onChange={handlePassword}
                    />
                  </div>
                </div>
              </div>
            </div>
            {Password && Password[0] && Password[0]?.edit ? (
              <footer className="custom_modal-footer tw-gap-x-4">
                <button
                  className="submit-btn-primary"
                  type="submit"
                  onClick={handleUpdatePassword}
                >
                  Update Password
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal4}
                >
                  Cancel
                </button>
              </footer>
            ) : (
              <p className="d-flex tw-justify-end mt-">
                You can't update the password.
              </p>
            )}
          </form>
        </div>
      </div>

      <div
        className={
          open5 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={CloseSecondModal5}></button>
          <header className="custom_modal-header">
            <h2>Update Security Question</h2>
          </header>

          <form className="form-box addressForm" id="basic-form">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="card-body">
                  <div className="row g-3">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Security Question 1</label>
                      <select
                        type="text"
                        // value={Demographic?.QuestionTypes?.[0]?.Type || "NA"}
                        className="form-select mb-1"
                        name="Question1"
                        onChange={handleQuestion}
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                      >
                        <option value="">Select</option>
                        {securityQuestion?.map((item, index) => {
                          return (
                            <option
                              key={index}
                              value={item.id}
                              selected={
                                item.id == Demographic?.QuestionTypes[0]?.id
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Security Question 2</label>
                      <select
                        // value={Demographic?.QuestionTypes?.[0]?.Type || "NA"}
                        type="text"
                        className="form-select mb-1"
                        name="Question2"
                        onChange={handleQuestion}
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                      >
                        <option value="">Select</option>
                        {securityQuestion?.map((item, index) => {
                          return (
                            <option
                              key={index}
                              value={item.id}
                              selected={
                                item.id == Demographic?.QuestionTypes[1]?.id
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label"> Security Question 3</label>
                      <select
                        type="text"
                        // value={Demographic?.QuestionTypes?.[2]?.Type || "NA"}
                        className="form-select mb-1"
                        name="Question3"
                        onChange={handleQuestion}
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                      >
                        <option value="">Select</option>
                        {securityQuestion?.map((item, index) => {
                          return (
                            <option
                              key={index}
                              value={item.id}
                              selected={
                                item.id == Demographic?.QuestionTypes[2]?.id
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3"></div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 1</label>
                      <input
                        type="text"
                        value={
                          Demographic.QuestionTypes[0]?.userQuestion?.Answer ||
                          "NA"
                        }
                        className="form-control"
                        name="Answer1"
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                        onChange={handleQuestion}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 2</label>
                      <input
                        type="text"
                        value={
                          Demographic.QuestionTypes[1]?.userQuestion?.Answer ||
                          "NA"
                        }
                        className="form-control"
                        name="Answer2"
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                        onChange={handleQuestion}
                      />
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Answer 3</label>
                      <input
                        type="text"
                        value={
                          Demographic.QuestionTypes[2]?.userQuestion?.Answer ||
                          "NA"
                        }
                        className="form-control"
                        name="Answer3"
                        disabled={
                          SecurityQ &&
                          SecurityQ[0] &&
                          SecurityQ[0]?.edit == false
                        }
                        onChange={handleQuestion}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {SecurityQ && SecurityQ[0] && SecurityQ[0]?.edit ? (
              <footer className="custom_modal-footer tw-gap-x-4">
                <button
                  className="submit-btn-primary"
                  type="submit"
                  onClick={handleUpdateQuestion}
                >
                  Update Question
                </button>
                <button
                  className="declane-btn-primary"
                  onClick={CloseSecondModal5}
                >
                  Cancel
                </button>
              </footer>
            ) : (
              <p className="d-flex tw-justify-end mt-">
                You can't update the Security Questions.
              </p>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
