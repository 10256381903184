import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import CryptoJS from "crypto-js";

function AddUserRole() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [row, setRow] = useState({
    UserRoleName: "",
    Description: "",
    defaultTemp: "",
    SecurityClassId: "",
    ClinicalDocumentId: "",
  });
  const [templates, setTemplates] = useState([]);
  const [security, setSecurity] = useState([]);
  const [clinicalDocument, setClinicalDocument] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userRole/template`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setTemplates(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}securityClass/get?stage=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const defaultUserole = res.data.message.filter((role) => {
          return role.frontend == true;
        });
        setSecurity(defaultUserole);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}clinicalDocument/get?task=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setClinicalDocument(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = (e) => {
    e.preventDefault();
    setServiceList([...serviceList, { service: "" }]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    if (name === "UserRoleName") {
      const isValidInput = /^[A-Za-z\s]*$/.test(value); // Check if the input contains only alphabets and spaces

      if (isValidInput) {
        const capitalizedValue = value.replace(/\b\w/g, (char) =>
          char.toUpperCase()
        );
        setRow({ ...row, [name]: capitalizedValue });
      } else {
        toast.warning("Only Alphabets and spaces are allowed");
      }
    } else {
      setRow({ ...row, [name]: value });
    }
  };

  useEffect(() => {
    // // console.log({ row });
  }, [row]);

  const handleAddData = (e) => {
    e.preventDefault();
    const newData = serviceList.map((item, i) => ({
      ...item,
    }));
    if (row.SecurityClassId === "" || row.SecurityClassId === "0") {
      toast.warning("Please Select Security Points");
    } else if (
      row.ClinicalDocumentId === "" ||
      row.ClinicalDocumentId === "0"
    ) {
      toast.warning("Please Select Clinical Document");
    } else if (row.defaultTemp.trim() === "" || row.defaultTemp === "0") {
      toast.warning("Please Select Default Template");
    } else {
      setPreloaderOn(true);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}userrole`,
          {
            newData,
            ...row,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.message);
          setRow({
            UserRoleName: "",
            Description: "",
            defaultTemp: "",
            SecurityClassId: "",
          });
          setServiceList([{ service: "" }]);
        })
        .catch((error) => {
          setPreloaderOn(false);
          // console.log(error);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleClearForm = () => {
    setRow({
      UserRoleName: "",
      Description: "",
      defaultTemp: "",
    });
    setServiceList([{ service: "" }]);
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Role
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add User Role</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <form className="form-box" id="basic-form" onSubmit={handleAddData}>
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title">General information</h6>
                </div>
                <div className="card-body mb-2">
                  <div className="row g-3 d-block">
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        User Role Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="UserRoleName"
                        id="UserRoleName"
                        autoComplete="UserRoleName"
                        value={row.UserRoleName}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        General Description
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <textarea
                        name="Description"
                        autoComplete="Description"
                        value={row.Description}
                        onChange={handleChange}
                        rows={4}
                        type="email"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        Security Points
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <select
                        name="SecurityClassId"
                        value={row.SecurityClassId}
                        id="SecurityClassId"
                        onChange={handleChange}
                        required
                        className="form-select mt-1"
                      >
                        <option value="0">Select</option>
                        {security?.length &&
                          security?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <label className="form-label">
                        Clinical Document
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <select
                        name="ClinicalDocumentId"
                        value={row.ClinicalDocumentId}
                        id="clinicalDocument"
                        onChange={handleChange}
                        className="form-select mt-1"
                        required
                      >
                        <option value="0">Select</option>
                        {clinicalDocument?.length &&
                          clinicalDocument?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header">
                  <h6 className="card-title">Template Security</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <label className="form-label">
                        Default Template
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <select
                        name="defaultTemp"
                        value={row.defaultTemp}
                        id="defaultTemp"
                        onChange={handleChange}
                        className="form-select mt-1"
                        required
                      >
                        <option value="0" selected>
                          Select
                        </option>
                        {templates?.length &&
                          templates?.map((item, i) => {
                            return (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4">
                      <label className="form-label">Sub Template</label>
                      {serviceList.map((singleService, index) => (
                        <div className="tw-relative">
                          <select
                            value={singleService.service}
                            onChange={(e) => handleServiceChange(e, index)}
                            name="service"
                            className="form-select mt-1"
                          >
                            <option selected>Select</option>
                            {templates?.length &&
                              templates?.map((item, i) => {
                                return (
                                  <option key={i} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          {serviceList.length !== 1 && (
                            <span
                              className="deleteUserRoleBoxaddUserRole cursor-pointer"
                              onClick={() => handleServiceRemove(index)}
                            >
                              X
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4 tw-flex tw-justify-end">
                <button
                  onClick={handleServiceAdd}
                  className="btn add-more-btn d-inline"
                >
                  + Add More
                </button>
                <button
                  type="reset"
                  className="btn submit-btn d-inline ms-3"
                  onClick={handleClearForm}
                >
                  Clear
                </button>
                <button type="submit" className="btn submit-btn d-inline ms-3">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddUserRole;
