import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "datatables.net-dt/css/dataTables.dataTables.css";
import axios from "axios";
import { toast } from "react-toastify";
import "./UserRole.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function EditUserRole() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [records, setRecords] = useState([]);
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [userRole, setUserRole] = useState({
    UserRoleName: "",
    Description: "",
    SecurityClassId: "",
    ClinicalDocumentId: "",
    active: "",
    Template: [],
  });
  const [Template, setTemplate] = useState();
  const [defaultTemplate, setDefaultTemplate] = useState();
  const [subTemplate, setSubTemplate] = useState();
  const [addressExpand, setAddressExpand] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [security, setSecurity] = useState([]);
  const [clinicalDocument, setClinicalDocument] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}clinicalDocument/get?task=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setClinicalDocument(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}securityClass/get?stage=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const defaultUserole = res.data.message.filter((role) => {
          return role.frontend == true;
        });
        setSecurity(defaultUserole);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const closeModalBox = (e) => {
    e.preventDefault();
    setUserRole({});
    setOpenModal(false);
    setServiceList([""]);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userRole/template`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log("QQWERRT", res.data.data);
        setTemplates(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSubmit = async (id) => {
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}userrole?id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const mainData = response?.data?.data;
      setOpenModal(true);
      setUserRole(mainData[0]);
      setTemplate(mainData[0]?.Templates);

      const filteredData = mainData[0]?.Templates?.filter((temp) => {
        return temp?.userTemplate?.defaultTemplate === true;
      });

      const filteredDataSub = mainData[0]?.Templates?.filter((temp) => {
        return temp?.userTemplate?.defaultTemplate !== true;
      });

      setDefaultTemplate(filteredData?.length > 0 ? filteredData[0] : null);
      setSubTemplate(filteredDataSub?.length > 0 ? filteredDataSub : []);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "User Role ID",
        accessor: "id",
      },
      {
        Header: "User Role Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Default Template",
        accessor: "defaultTemp",
        Cell: ({ row }) => row?.original?.defaultTemp?.name,
      },
      {
        Header: "Sub Template",
        accessor: "subTemplate",
        Cell: ({ row }) =>
          row?.original?.subTemplate?.map((sub) => sub?.name).join(", "),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userrole?stage=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const updatedRecords = res?.data?.data?.map((item) => {
          const defaultTemp = item?.Templates?.filter(
            (filter) => filter.userTemplate.defaultTemplate === true
          );

          const subTemplate = item?.Templates?.filter(
            (filter) => filter.userTemplate.defaultTemplate !== true
          );

          return {
            name: item.UserRoleName,
            description: item.Description,
            defaultTemp: defaultTemp[0],
            subTemplate: subTemplate,
            id: item.id,
            active: item.active,
          };
        });
        const formattedData = updatedRecords?.map((item, index) => ({
          sno: index + 1,
          name: item?.name,
          description: item?.description,
          defaultTemp: item?.defaultTemp,
          subTemplate: item?.subTemplate,
          id: (
            <span className="d-flex tw-justify-center tw-items-center">
              {item.id}
            </span>
          ),
          status: item?.active ? (
            <span className="badge bg-success ">Active</span>
          ) : (
            <span className="badge bg-danger ">In-Active</span>
          ),
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleSubmit(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));
        setRecords(formattedData);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const checkboxValue = type === "checkbox" ? checked : value;

    if (name === "active") {
      setUserRole({
        ...userRole,
        [name]: checkboxValue,
      });
    } else if (name === "securityPoints") {
      // Handle the select element separately
      setUserRole({
        ...userRole,
        SecurityClassId: value,
      });
    } else if (name === "UserRoleName") {
      const cleanedValue = value.replace(/[^a-zA-Z\s]/g, ""); // Remove non-alphabetic characters
      const capitalizedValue = cleanedValue.replace(/\b\w/g, (char) =>
        char.toUpperCase()
      );

      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setUserRole({ ...userRole, [name]: capitalizedValue });
      } else {
        toast.warning("Only alphabets and spaces are allowed");
        return;
      }
    } else {
      setUserRole({ ...userRole, [name]: value });
    }
  };

  const handleUpdateRole = (e) => {
    e.preventDefault();
    const newData = serviceList
      .map((item, i) => ({
        ...item,
      }))
      .filter((item) => item.service);

    const { name, value } = e.target;
    if (name === "UserRoleName") {
      const isValidInput = /^[A-Za-z\s]*$/.test(value); // Check if the input contains only alphabets and spaces

      if (isValidInput) {
        const capitalizedValue = value.replace(/\b\w/g, (char) =>
          char.toUpperCase()
        );
        setUserRole({ ...userRole, [name]: capitalizedValue });
      } else {
        toast.warning("Only Alphabets and spaces are allowed");
      }
    } else {
      setUserRole({ ...userRole, [name]: value });
    }

    if (userRole.SecurityClassId === "" || userRole.SecurityClassId === "0") {
      toast.warning("Please Select Security Points");
    } else if (
      userRole.ClinicalDocumentId === "" ||
      userRole.ClinicalDocumentId === "0"
    ) {
      toast.warning("Please Select Clinical Document");
    } else if (userRole.defaultTemp === "" || userRole.defaultTemp === "0") {
      toast.warning("Please Select Default Template");
    } else {
      setPreloaderOn(true);
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}userRole`,
          {
            ...userRole,
            newData: newData.length > 0 ? newData : [],
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.message, { autoClose: 1000 });
          setOpenModal(false);
          setAddressExpand(false);
          setServiceList([{ service: "" }]);
          fetchData();
        })
        .catch((error) => {
          setPreloaderOn(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleRemove = (id, userid, i) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}userRole?userRoleId=${userid}&TemplateId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 1000 });
        handleSubmit(userRole?.id);
        handleServiceRemove(i);
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);

    if (list.length === 0) {
      setAddressExpand(false);
    }
  };

  const handleExpandAddress = () => {
    setAddressExpand(true);
  };

  const handleExpandAddressClose = () => {
    setAddressExpand(false);
    setServiceList([{ service: "" }]);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Role
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit User Role</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body overflow-auto">
                <Table columns={columns} data={records} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit User Role</h2>
            </header>
            <form
              className="form-box tw-w-[100%]"
              id="basic-form"
              onSubmit={handleUpdateRole}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">General Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="active"
                        autoComplete="active"
                        checked={userRole.active}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    <div className="md:tw-grid tw-grid-cols-4 tw-gap-x-6 tw-gap-y-2 tw-mt-2">
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="Subscription"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          User Role Name
                          <span className="tw-text-red-500">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            name="UserRoleName"
                            autoComplete="UserRoleName"
                            value={userRole?.UserRoleName}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-control rounded-3"
                          />
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="Description"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            General Description
                            <span className="AsteriskSymbol">*</span>
                          </label>
                          <div className="">
                            <textarea
                              rows={4}
                              type="text"
                              name="Description"
                              value={userRole?.Description}
                              onChange={(e) => handleChange(e)}
                              required
                              className="form-control rounded-3"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                            Security Points
                            <span className="AsteriskSymbol">*</span>
                          </label>
                          <select
                            name="securityPoints"
                            value={userRole?.SecurityClassId}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-select rounded-3"
                          >
                            <option value="0" selected>
                              Select
                            </option>
                            {security?.length &&
                              security?.map((item, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={item.id}
                                    selected={
                                      item.id === userRole?.SecurityClassId
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900">
                            Clinical Document
                            <span className="AsteriskSymbol">*</span>
                          </label>
                          <select
                            name="ClinicalDocumentId"
                            value={userRole?.ClinicalDocumentId}
                            onChange={(e) => handleChange(e)}
                            required
                            className="form-select rounded-3"
                          >
                            <option value="0" selected>
                              Select
                            </option>
                            {clinicalDocument?.length &&
                              clinicalDocument?.map((item, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={item.id}
                                    selected={
                                      item.id === userRole?.ClinicalDocumentId
                                    }
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card tw-mt-2">
                <div className="card-header">
                  <h6 className="card-title">Template Security</h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="mt-1">
                      <span className="text-md font-medium leading-6">
                        Default Template
                        <span className="AsteriskSymbol">*</span>
                      </span>
                      <div className="tw-relative tw-pr-[30px]">
                        <div className="tw-w-[31%] mt-2">
                          <select
                            required
                            name="defaultTemp"
                            value={userRole?.defaultTemp}
                            id="defaultTemp"
                            className="form-select"
                            onChange={(e) =>
                              setUserRole({
                                ...userRole,
                                defaultTemp: e.target.value,
                              })
                            }
                          >
                            <option value="0">Select</option>
                            {templates?.length &&
                              templates?.length &&
                              templates?.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  selected={item.id === defaultTemplate?.id}
                                >
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="mt-1">
                      <span className="text-md font-medium leading-6">
                        Sub Template
                      </span>
                      <div className="tw-flex tw-flex-wrap">
                        {subTemplate &&
                          subTemplate.map((item, i) => (
                            <div key={i} className="tw-w-[33%]">
                              <div className="tw-relative tw-pr-[30px] tw-flex mt-2">
                                <input
                                  type="text"
                                  name="UserRoleName"
                                  autoComplete="name"
                                  value={item?.name}
                                  readOnly
                                  className="form-control"
                                  // className="block w-[100%] rounded-sm border-0 py-0.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                                />
                                <span
                                  className="groupDelet tw-cursor-pointer"
                                  onClick={() =>
                                    handleRemove(item.id, userRole.id, i)
                                  }
                                >
                                  X
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="tw-flex tw-justify-end mt-3">
                      {!addressExpand && (
                        <button
                          type="button"
                          onClick={() => handleExpandAddress()}
                          className="btn btn-outline-success"
                        >
                          + Add More Sub Template
                        </button>
                      )}
                      {addressExpand && (
                        <button
                          type="button"
                          onClick={() => handleExpandAddressClose()}
                          className="btn btn-outline-danger"
                        >
                          Close Section
                        </button>
                      )}
                    </div>

                    {addressExpand && (
                      <>
                        <p className="text-md font-medium leading-6">
                          Add Sub Template
                        </p>
                        <div className="tw-flex tw-flex-wrap">
                          {serviceList.map((singleService, index) => (
                            <div key={index} className="tw-w-[30%] mt-1">
                              <div className="tw-w-[100%]">
                                <div className="tw-relative tw-pr-[30px] d-flex">
                                  <select
                                    value={singleService.service}
                                    onChange={(e) =>
                                      handleServiceChange(e, index)
                                    }
                                    required
                                    name="service"
                                    type="text"
                                    id="service"
                                    className="form-select"
                                  >
                                    <option>Select</option>
                                    {templates?.map((item, i) => {
                                      return (
                                        <option key={i} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {serviceList.length !== 1 && (
                                    <span
                                      className="groupDelet tw-cursor-pointer"
                                      onClick={() => handleServiceRemove(index)}
                                    >
                                      X
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="first-division">
                            <br />
                            <button
                              type="button"
                              onClick={handleServiceAdd}
                              className="btn btn-outline-success"
                            >
                              <span>+ Add More</span>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button className="btn submit-btn d-inline ms-3" type="submit">
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUserRole;
