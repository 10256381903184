import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import CryptoJS from "crypto-js";

const Chart12 = (props) => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  // console.log("PROPS FIRST", props);

  const handleColumnClick = async (_, __, config) => {
    // console.log(config);
    // Get the date corresponding to the clicked data point
    // console.log("Config", config);
    const dateSelected =
      config?.w?.globals?.categoryLabels[config.dataPointIndex];
    // console.log("date", dateSelected);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/getTemplateCountDetailed?task=single&date=${dateSelected}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const formattedData = res.data.data.map((item, index) => ({
        sno: index + 1,
        name: item.name,
        count: item.count,
        description: item?.description,
        id: item.tempId,
      }));

      // Pass the formatted data to a function received as props
      props.data(formattedData);
      props.open(true);
    } catch (error) {
      console.log(error);
    }
  };

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Data",
        data: [],
      },
    ],
    options: {
      chart: {
        height: "100%",
        width: "100%",
        type: "line",
        events: {
          markerClick: handleColumnClick,
        },
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 5,
        curve: "smooth",
      },
      xaxis: {
        tickAmount: 10,
        title: {
          text: "",
          style: {
            fontSize: "14px",
            color: "green",
          },
        },
        labels: {
          show: true,
        },
        categories: [] || "2024-05-03",
      },
      yaxis: {
        tickAmount: 10,
        title: {
          style: {
            fontSize: "14px",
            color: "black",
          },
        },
        labels: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
      grid: {
        show: false,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (Array.isArray(props?.series)) {
      const newData = props.series.map((item) => item.count);
      const newCategories = props.series.map((item) => item.date);

      setChartData((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            name: "Count",
            data: newData,
          },
        ],
        options: {
          ...prevOptions.options,
          xaxis: {
            ...prevOptions.options.xaxis,
            categories: newCategories,
          },
        },
      }));
    }
  }, [props.series]);

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={"100%"}
      width={"100%"}
    />
  );
};

export default Chart12;
