import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import axios from "axios";
import CryptoJS from "crypto-js";

const TemplateCount = () => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [records, setRecords] = useState([]);
  const [temp, setTemp] = useState([]);
  const [user, setUser] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [OpenModal2, setOpenModal2] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const closeModalBox = () => {
    setOpenModal(null);
  };
  const closeModalBox2 = () => {
    setOpenModal2(null);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Template Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Template ID",
        accessor: "id",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Template Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Views Count",
        accessor: "count",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  useEffect(() => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}admin/getTemplateCount`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setPreloaderOn(false);
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          name: item?.templateName,
          description: item?.Description,
          id: item?.templateId,
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="view"
                onClick={() => handleEditClick(item?.templateId)}
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ),
        }));
        setRecords(formattedData);
      })
      .catch(function (error) {
        setPreloaderOn(false);
      });
  }, []);

  const handleEditClick = async (id) => {
    setPreloaderOn(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/getTemplateCount?template=true&templateId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setPreloaderOn(false);
      setTemp(response?.data?.aggregatedData?.userData);
      setOpenModal(true);
    } catch (error) {
      setPreloaderOn(false);
      console.error("Error fetching data:", error);
    }
  };

  const initialsOfName = (i) => {
    let arr = i.split(" ");
    if (arr.length > 0) {
      let firstNameInitial = arr[0].charAt(0);
      let secondNameInitial = "";
      if (arr.length > 1) {
        secondNameInitial = arr[1].charAt(0);
      }
      return firstNameInitial + secondNameInitial;
    }
    return "";
  };

  const handleUser = async (id) => {
    setPreloaderOn(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin/getTemplateCount?user=true&userId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setPreloaderOn(false);
      const formattedData = response.data.data.map((item, index) => ({
        sno: index + 1,
        name: item?.tempName,
        description: item?.Description,
        count: item?.count,
        action: (
          <div className="action-btn-box">
            {/* <Link to="" className="view">
              <i className="fa fa-eye"></i>
            </Link> */}
          </div>
        ),
      }));
      setUser(formattedData);
      setOpenModal2(true);
    } catch (error) {
      setPreloaderOn(false);
      // console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div id="wrapper">
        <div className="container-fluid">
          <div className="block-header py-lg-4 py-3">
            <div className="row g-3">
              <div className="col-md-6 col-sm-12">
                <h2 className="m-0 fs-5">
                  <ToggleNavBar />
                  Template Configuration
                </h2>
                <ul className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">sLEAP</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Template View Record
                  </li>
                </ul>
              </div>
              <AllUserRecords />
            </div>
          </div>

          <div className="mt-2" style={{ width: "100%" }}>
            <Table columns={columns} data={records} />
          </div>

          <div
            className={
              openModal === true
                ? "custom_modal open openModal"
                : "custom_modal"
            }
          >
            <div className="custom_content-wrapper tw-w-[90%]">
              <button className="custom_close" onClick={closeModalBox}></button>
              <header className="custom_modal-header">
                <h2>Total Count Details</h2>
              </header>
              <form action="" className=" form-box w-100" id="basic-form">
                <div className="card">
                  <div className="card-header px-1">
                    <h6 className="card-title">All User List</h6>
                  </div>
                  <div className="row g-0 archive-box">
                    {temp?.length > 0 &&
                      temp?.map((item, dt) => {
                        return (
                          <div
                            key={dt}
                            className="col-md-4 col-lg-3 col-xl-3 p-1 p-sm-2 p-md-3"
                          >
                            <div className="col-12 border p-0">
                              <div className="text-center">
                                <div className="my-2">
                                  {item?.url?.url ? (
                                    <img
                                      src={item?.url?.url}
                                      className="tw-rounded-[50%] tw-w-[100px] tw-h-[100px] tw-mx-auto tw-bg-slate-600"
                                    />
                                  ) : (
                                    <div className="noimgs">
                                      {initialsOfName(item.name)}
                                    </div>
                                  )}
                                </div>
                                <Link onClick={() => handleUser(item?.id)}>
                                  {item?.name || "NA"}
                                </Link>
                                <p className="mb-2 p-1 d-flex tw-justify-center tw-items-center">
                                  Visited : {item?.count || "NA"} Times
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            className={
              OpenModal2 === true
                ? "custom_modal open openModal"
                : "custom_modal"
            }
          >
            <div className="custom_content-wrapper tw-w-[90%]">
              <button
                className="custom_close"
                onClick={closeModalBox2}
              ></button>
              <header className="custom_modal-header">
                <h2>User Assigned Templates</h2>
              </header>

              <div className="mt-2" style={{ width: "100%" }}>
                <Table columns={columns2} data={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateCount;
