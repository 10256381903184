import React from "react";
import { useSharedContext } from "./SharedDataSet";

const AllUserRecords = React.memo(() => {
  const { newRequests, users, providers } = useSharedContext();

  return (
    <div className="col-md-6 col-sm-12 text-md-end">
      <div className="d-inline-flex text-start">
        <div className="me-2">
          <h6 className="mb-0">
            <i className="fa fa-user"></i> {users}
          </h6>
          <small>Users</small>
        </div>
        <span id="bh_visitors"></span>
      </div>
      <div className="d-inline-flex text-start ms-lg-3 me-lg-3 ms-1 me-1">
        <div className="me-2">
          <h6 className="mb-0">
            <i className="fa fa-globe"></i> {providers}
          </h6>
          <small>Providers</small>
        </div>
        <span id="bh_visits"></span>
      </div>
      <div className="d-inline-flex text-start">
        <div className="me-2">
          <h6 className="mb-0">
            <i className="fa fa-comments"></i> {newRequests}
          </h6>
          <small>New Request</small>
        </div>
        <span id="bh_chats"></span>
      </div>
    </div>
  );
});

export default AllUserRecords;
