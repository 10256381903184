import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "datatables.net-dt/css/dataTables.dataTables.css";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function AllUserRolesRecord() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [preloaderOn, setPreloaderOn] = useState(false);
  // Function to convert timestamp to date format
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // State to hold user role data
  const [data, setData] = useState([]);

  // Function to handle delete action
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}userRole/archive`,
        {
          id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData(); // Fetch data again after successful deletion
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.success(error.response.data.message, { autoClose: 1000 });
      });
  };

  // Columns definition for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "User Role ID",
        accessor: "id",
      },
      {
        Header: "User Role Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Default Template",
        accessor: "defaultTemp",
        Cell: ({ row }) => row?.original?.defaultTemp?.name, // Render default template name
      },
      {
        Header: "Sub Template",
        accessor: "subTemplate",
        Cell: ({ row }) =>
          row?.original?.subTemplate?.map((sub) => sub.name).join(", "), // Render sub templates names
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  // Function to fetch data from API
  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userrole?stage=all`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const updatedRecords = res?.data?.data?.map((item) => {
          const defaultTemp = item?.Templates?.filter(
            (filter) => filter.userTemplate.defaultTemplate === true
          );

          const subTemplate = item?.Templates?.filter(
            (filter) => filter.userTemplate.defaultTemplate !== true
          );

          return {
            name: item.UserRoleName,
            description: item.Description,
            defaultTemp: defaultTemp[0],
            subTemplate: subTemplate,
            id: item.id,
            created: convertTimestampToDateFormat(item?.createdAt),
            active: item.active,
          };
        });
        const formattedData = updatedRecords?.map((item, index) => ({
          sno: index + 1,
          name: item?.name,
          description: item?.description,
          defaultTemp: item?.defaultTemp,
          subTemplate: item?.subTemplate,
          id: item?.id,
          created: item?.created,
          status: item?.active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="delete"
                onClick={() => handleDeleteClick(item?.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        // // console.log(error);
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  All User Role Records
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUserRolesRecord;
