import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../CommonPage/Preloader";
import ToggleNavBar from "../CommonPage/ToggleNavBar";
import { useSharedContext } from "../CommonPage/SharedDataSet";
import axios from "axios";
import { Table } from "../CommonPage/TableContent";
import AllUserRecords from "../CommonPage/AllUserRecords";
import PChart from "./DashboardChart/PData";
import ChartResmed from "./DashboardChart/ChartResmed";
import TempCount from "./DashboardChart/TempCount";
import TempTop10 from "./DashboardChart/TempTop10";
import ResmedLine from "./DashboardChart/ResmedLine";
import KpiData from "./DashboardChart/KpiData";
import PChart2 from "./DashboardChart/PChart2";
import SalesForceLine from "./DashboardChart/SalesForceLine";
import CpapData from "./DashboardChart/CpapData";
import PieChart from "./DashboardChart/PieChart";
import PieChart2 from "./DashboardChart/PieChart2";
import Chart2 from "./DashboardChart/Chart2";
import Chart3 from "./DashboardChart/Chart3";
import CryptoJS from "crypto-js";

function Home() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const { newRequests, pendingData, approvedRequests, users } =
    useSharedContext();
  const [chartData, setChartData] = useState([]);
  const [chart3Data, setChart3Data] = useState([]);
  const [reqs, setReqs] = useState([]);
  const [data, setData] = useState([]);
  const [user, setUser] = useState([]);
  const [records, setRecords] = useState([]);
  const [records2, setRecords2] = useState([]);
  const [existDataNo, setExistDataNo] = useState(0);
  const [existData, setExistData] = useState(0);
  const [resmed, setResmed] = useState([]);
  const [activeTab, setActiveTab] = useState("week");
  const [dataTemp, setDataTemp] = useState([]);
  const [top10, setTop10] = useState([]);
  const [tempTableData, setTempTableData] = useState([]);
  const [tableOpen, setTableOpen] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/getTemplateCountDetailed?task=byDate`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (res) {
        setDataTemp(res?.data?.data);
      })
      .catch(function (error) {
        // console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getProvider`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setData(res?.data?.data?.length);
      })
      .catch(function (error) {});

    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setUser(res?.data?.data?.length);
      })
      .catch(function (error) {
        // console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}resmedData/count`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setResmed(res?.data?.message);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}admin/getTemplateCountDetailed?task=top10`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then(function (res) {
        setTop10(res?.data?.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getCount`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setReqs(res?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const fetchData = async () => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=newRequest`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setPreloaderOn(false);
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          request: new Date(item?.createdAt).toLocaleDateString("en-US"),
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.Title_Type?.type,
        }));
        setRecords(formattedData);
      })
      .catch(function (error) {
        // console.log(error);
        setPreloaderOn(false);
      });
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}resmedData/all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const formattedData = response.data.data;
      let countNA = 0;
      let count = 0;

      const dataSet = formattedData.map((item, ij) => {
        if (item.p_m_sid == "") {
          countNA++;
        } else {
          count++;
        }
        return {
          sno: ij + 1,
          name: item?.fname + " " + item?.lname || "NA",
          location: item?.location || "NA",
          locationid: item?.location_id || "NA",
          clinical: item?.clinicalUser || "NA",
          setup: item?.setupDate || "NA",
          mid: item?.p_m_sid || "NA",
          device: item?.d_sid || "NA",
          devicetype: item?.d_type || "NA",
          deviceTypeDesc: item?.d_desc || "NA",
          thresholds: item?.t_leak || "NA",
          myAirStatus: item?.myair_status || "NA",
        };
      });
      setExistData(count);
      setExistDataNo(countNA);

      setRecords2(dataSet);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchData2();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Request Date",
        accessor: "request",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "Title",
        accessor: "system",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      // },
    ],
    []
  );
  const templateColumns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Username",
        accessor: "name",
      },
      {
        Header: "User ID",
        accessor: "id",
      },
      {
        Header: "Count",
        accessor: "count",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      // },
    ],
    []
  );
  const columns2 = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Location ID",
        accessor: "locationid",
      },
      {
        Header: "Clinical User",
        accessor: "clinical",
      },
      {
        Header: "Setup Date",
        accessor: "setup",
      },
      {
        Header: "Prescription M ID",
        accessor: "mid",
      },
      {
        Header: "Device S.No.",
        accessor: "device",
      },
      {
        Header: "Device Type",
        accessor: "devicetype",
      },
      {
        Header: "Device Type Desc",
        accessor: "deviceTypeDesc",
      },
      {
        Header: "Thresholds Leak",
        accessor: "thresholds",
      },
      {
        Header: "My AirStatus",
        accessor: "myAirStatus",
      },
    ],
    []
  );

  const handleWeek = (e) => {
    setActiveTab(e);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}dashboard?type=${e}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setChartData(res?.data);
      });
  };
  const handleChart3Data = (e) => {
    setActiveTab(e);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}dashboard?type=${e}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setChart3Data(res?.data);
      });
  };
  useEffect(() => {
    handleWeek("week");
    handleChart3Data("week");
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Dashboard
              </h2>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="tab-pan-body">
          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" data-bs-toggle="pill" href="#Pie">
                Admin
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" data-bs-toggle="pill" href="#Horizontal">
                Resmed
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="pill" href="#SalesForce">
                SalesForce
              </a>
            </li> */}
          </ul>

          <div className="tab-content">
            <div id="Pie" className="containers tab-pane active">
              <div className="row g-2 clearfix row-deck">
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="card top_counter">
                    <div className="list-group list-group-custom list-group-flush">
                      <div className="list-group-item d-flex align-items-center py-3">
                        <div className="icon text-center me-3">
                          <i className="fa fa-user"></i>{" "}
                        </div>
                        <div className="content">
                          <div>New Request</div>
                          <h5 className="mb-0">{newRequests}</h5>
                        </div>
                      </div>
                      <div className="list-group-item d-flex align-items-center py-3">
                        <div className="icon text-center me-3">
                          <i className="fa fa-users"></i>{" "}
                        </div>
                        <div className="content">
                          <div>Pending Request</div>
                          <h5 className="mb-0">{pendingData}</h5>
                        </div>
                      </div>
                      <div className="list-group-item d-flex align-items-center py-3">
                        <div className="icon text-center me-3">
                          <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        </div>
                        <div className="content">
                          <div>Approved Request</div>
                          <h5 className="mb-0">{approvedRequests}</h5>
                        </div>
                      </div>
                      <div className="list-group-item d-flex align-items-center py-3">
                        <div className="icon text-center me-3">
                          <i class="fa fa-th-large" aria-hidden="true"></i>
                        </div>
                        <div className="content">
                          <div>Completed Request</div>
                          <h5 className="mb-0">{users}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="card text-center">
                    <div className="card-body">
                      <h6>User Request Analysis</h6>
                      {/*<span className="text-muted">8% High then last month</span> */}
                      <div className="mt-5 d-flex justify-content-center sparkline-pie">
                        <PieChart />
                      </div>
                      <div className="stats-report mt-4">
                        <div className="stat-item d-inline-block px-2 mt-4">
                          <h5 className="mb-0 fw-normal fs-6">New</h5>
                          <strong>{reqs?.newRequestData}</strong>
                        </div>
                        <div className="stat-item d-inline-block px-2 mt-4">
                          <h5 className="mb-0 fw-normal fs-6">Pending</h5>
                          <strong>{reqs?.pendingData}</strong>
                        </div>
                        <div className="stat-item d-inline-block px-2 mt-4">
                          <h5 className="mb-0 fw-normal fs-6">Completed</h5>
                          <strong>{reqs?.completedData}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header border-0">
                      <h6 className="card-title">User Request Statistics</h6>
                      <ul className="header-dropdown list-unstyled">
                        <li>
                          <Link
                            className={`tab_btn ${
                              activeTab === "week" ? "active" : ""
                            }`}
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Weekly"
                            onClick={() => handleWeek("week")}
                          >
                            W
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`tab_btn ${
                              activeTab === "month" ? "active" : ""
                            }`}
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Monthly"
                            onClick={() => handleWeek("month")}
                          >
                            M
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`tab_btn ${
                              activeTab === "year" ? "active" : ""
                            }`}
                            to="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Yearly"
                            onClick={() => handleWeek("year")}
                          >
                            Y
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div>
                        <Chart2
                          series={chartData}
                          data={setRecords}
                          open={setTableOpen}
                          type={activeTab}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="card-title">Daily User Requests</h6>
                    </div>
                    <div className="card-body">
                      <div>
                        <Chart3
                          series={chart3Data}
                          data={setRecords}
                          open={setTableOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">Total User vs Provider</h6>
                    </div>
                    <div className="card-body text-center">
                      <div className="d-flex tw-justify-center">
                        <PieChart2 />
                      </div>
                    </div>
                    <div className="d-flex tw-justify-center ">
                      <div className="stat-item d-inline-block px-2 mb-3">
                        <h5 className="mb-0 fw-normal fs-6">
                          Provider : <strong>{data}</strong>{" "}
                        </h5>
                      </div>
                      <div className="stat-item d-inline-block px-2 mb-5">
                        <h5 className="mb-0 fw-normal fs-6">
                          User : <strong>{user}</strong>{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="card-title">Top 10 Template by Views</h6>
                    </div>
                    <div className="card-body">
                      <div>
                        <TempTop10
                          series={top10}
                          data={setTempTableData}
                          open={setTableOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">Total Template Count</h6>
                    </div>
                    <div className="card-body text-center">
                      <div
                        className="d-flex tw-justify-center tw-w-full tempchartdash"
                        style={{ height: "100%" }}
                      >
                        <TempCount
                          series={dataTemp}
                          data={setTempTableData}
                          open={setTableOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2" style={{ width: "100%" }}>
                  <Table
                    columns={tableOpen ? templateColumns : columns}
                    data={tableOpen ? tempTableData : records}
                  />
                </div>
              </div>
            </div>

            <div id="Horizontal" className="containers tab-pane ">
              <div className="row g-2 clearfix row-deck">
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">Patient Data</h6>
                    </div>
                    <div className="card-body">
                      <div className="d-flex tw-justify-center ">
                        <PChart
                          existDataNo={existDataNo}
                          existData={existData}
                        />
                      </div>
                    </div>
                    <div className="d-flex tw-justify-center ">
                      <div className="stat-item d-inline-block px-2 mb-3">
                        <h5 className="mb-0 fw-normal fs-6">
                          With Prescription : <strong>{existDataNo}</strong>
                        </h5>
                      </div>
                      <div className="stat-item d-inline-block px-2 mb-3">
                        <h5 className="mb-0 fw-normal fs-6">
                          Without Prescription : <strong>{existData}</strong>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="card-title">Patient Data Month Wise</h6>
                    </div>
                    <div className="card-body">
                      <div>
                        <ChartResmed series={resmed} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-3" style={{ width: "100%" }}>
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">To be discussed</h6>
                    </div>
                    <div className="card-body text-center">
                      <div
                        className="d-flex tw-justify-center tw-w-full tempchartdash"
                        style={{ height: "100%" }}
                      >
                        <ResmedLine series={resmed} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-3" style={{ width: "100%" }}>
                  <Table columns={columns2} data={records2} />
                </div>
              </div>
            </div>

            <div id="SalesForce" className="containers tab-pane ">
              <div className="row g-2 clearfix row-deck">
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">Age distribution</h6>
                    </div>
                    <div className="card-body">
                      <div className="d-flex tw-justify-center ">
                        <PChart2 />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="card-title tw-text-center">Mask Type</h6>
                    </div>
                    <div className="card-body">
                      <div>
                        <KpiData />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="card-title tw-text-center">
                        CPAP Compliance Rate
                      </h6>
                    </div>
                    <div className="card-body">
                      <div>
                        <CpapData />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-3" style={{ width: "100%" }}>
                  <div className="card">
                    <div className="card-header d-flex tw-justify-center">
                      <h6 className="card-title">Age distribution/Gender</h6>
                    </div>
                    <div className="card-body text-center">
                      <div
                        className="d-flex tw-justify-center tw-w-full tempchartdash"
                        style={{ height: "100%", height: "300px" }}
                      >
                        <SalesForceLine />
                      </div>
                    </div>
                  </div>
                </div>

                {/*<CrossFilter />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
