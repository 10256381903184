import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import "bootstrap/dist/css/bootstrap.min.css";
import CryptoJS from "crypto-js";

function AdminRecord() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users/archive`,
        { id },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
        setPreloaderOn(false);
      })
      .catch((error) => {
        toast.error(error?.data?.message);
        setPreloaderOn(false);
      });
  };

  const columns = React.useMemo(
    () => [
      { Header: "S.No.", accessor: "sno" },
      { Header: "Name", accessor: "name" },
      { Header: "Phone No", accessor: "phone" },
      { Header: "E-mail", accessor: "email" },
      { Header: "System Login", accessor: "system" },
      { Header: "Status", accessor: "status" },
      { Header: "Created On", accessor: "created" },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}admin`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        const formattedData = res.data.message.map((item, index) => ({
          sno: index + 1,
          name: `${item.FirstName} ${item.MiddleName} ${item.LastName}`,
          phone: item?.PhoneNumber,
          email: item?.E_mail,
          system: item?.SystemLogin,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
          last: convertTimestampToDateFormat(item?.createdAt),
          action: (
            <div className="action-btn-box d-flex justify-content-center align-items-center">
              <Link
                to=""
                className="delete"
                onClick={() => handleDeleteClick(item?.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        }));
        setData(formattedData);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">All Admin Records</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>
        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "auto" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminRecord;
