import React, { useEffect, useState } from "react";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { Link } from "react-router-dom";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import _ from "lodash";
import CryptoJS from "crypto-js";

function EditAdmin() {
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [sexData, setSexData] = useState();
  const [Demographic, setDemographic] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SexType: "",
    SexTypeId: "",
    DOB: "",
    PhoneNumber: "",
    E_mail: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    SystemLogin: "",
    LinkedAccount: "",
    Active: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const closeModalBox = () => {
    setDemographic({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      SexType: "",
      SexTypeId: "",
      DOB: "",
      PhoneNumber: "",
      E_mail: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      County: "",
      ZipCode: "",
      Country: "",
      SystemLogin: "",
      LinkedAccount: "",
      Active: "",
    });
    setOpenModal(false);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "System Login",
        accessor: "system",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}admin`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        // console.log("admi", res.data.message);
        const formattedData = res.data.message.map((item, index) => ({
          sno: index + 1,
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_mail,
          system: item?.SystemLogin,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
          last: convertTimestampToDateFormat(item?.createdAt),
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleEditClick(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Sex Type
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=sex`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setSexData(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleEditClick = async (id) => {
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin?stage=byId&id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const userAccountData = response?.data?.message;
      setDemographic(userAccountData);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const checkboxValue = type === "checkbox" ? checked : value;

    if (name === "Active") {
      setDemographic({
        ...Demographic,
        [name]: checkboxValue,
      });
    } else {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      if (
        name === "FirstName" ||
        name === "MiddleName" ||
        name === "LastName"
      ) {
        if (/^[A-Za-z]+$/.test(value) || value === "") {
          setDemographic({
            ...Demographic,
            [name]: capitalizeFirstLetter(value),
          });
        } else {
          toast.warning("Only Alphabets are allowed!");
        }
      } else if (
        name === "City" ||
        name === "County" ||
        name === "Country" ||
        name === "State"
      ) {
        if (/^[A-Za-z\s]+$/u.test(value) || value === "") {
          setDemographic({ ...Demographic, [name]: value });
        } else {
          toast.warning("Only Alphabets are allowed!");
        }
      } else if (name === "E_mail") {
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === "") {
          setDemographic({ ...Demographic, [name]: value });
          $(".error-msg").removeClass("Show");
          setIsValidEmail(true);
        } else {
          setDemographic({ ...Demographic, [name]: value });
          $(".error-msg").addClass("Show");
          setIsValidEmail(false);
        }
      } else if (name === "ZipCode" || name === "PhoneNumber") {
        if (/^[0-9]+$/.test(value) || value === "") {
          setDemographic({ ...Demographic, [name]: value });
        } else {
          toast.warning("Accept Only Numbers for " + name, { autoClose: 1000 });
        }
      } else if (name === "DOB") {
        setStartDate((date) => {
          const formattedDate = date.toISOString();
          setStartDate(date);
          setDemographic({ ...Demographic, DOB: formattedDate });
        });
        setDemographic({ ...Demographic, [name]: value });
      } else if (name === "SystemLogin") {
        setDemographic({ ...Demographic, [name]: value });
      } else {
        setDemographic({ ...Demographic, [name]: value });
      }
    }
  };

  const handleFinalUpdate = (e) => {
    e.preventDefault();
    if (
      Demographic?.FirstName === "" ||
      Demographic?.LastName === "" ||
      Demographic?.DOB === "" ||
      Demographic?.SystemLogin === "" ||
      Demographic?.ZipCode === "" ||
      Demographic?.State === "" ||
      Demographic?.E_mail === ""
    ) {
      toast.warning("Please fill the Details");
    } else if (Demographic.ZipCode.toString().length < 6) {
      toast.warning("ZipCode must be between 6 to 8 digits", {
        autoClose: 1000,
      });
    } else if (Demographic.PhoneNumber.toString().length < 10) {
      toast.warning("PhoneNumber must be of 10 digits", { autoClose: 1000 });
    } else {
      setPreloaderOn(true);
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}admin`,
          {
            ...Demographic,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.Message, { autoClose: 2000 });
          setOpenModal(false);
          fetchData();
        })
        .catch((error) => {
          setPreloaderOn(false);
          toast.error(error.response.data.message, { autoClose: 2000 });
        });
    }
  };

  const handleLinkAccountKeyPress = async (e) => {
    const linkAccountValue = e.target.value;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin?stage=systemLogin&SystemLogin=${linkAccountValue}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const searchData = response.data.data;
      if (searchData) {
        toast.error(
          "Admin System Login is already in use with another user-admin!",
          { autoClose: 2000 }
        );
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Admin Login
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Admin</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit Admin Account</h2>
            </header>
            <form
              className="form-box"
              id="basic-form"
              onSubmit={handleFinalUpdate}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Demographic Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        autoComplete="Active"
                        checked={Demographic.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FirstName"
                        autoComplete="FirstName"
                        value={Demographic?.FirstName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        autoComplete="MiddleName"
                        value={Demographic?.MiddleName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="LastName"
                        autoComplete="LastName"
                        value={Demographic?.LastName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexType"
                        onChange={(e) => handleChange(e)}
                      >
                        <option>Select</option>
                        {sexData?.map((item, iT) => {
                          return (
                            <option
                              key={iT}
                              value={item.id}
                              selected={
                                item.id == Demographic.SexType ? true : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          value={Demographic.DOB}
                          yearDropdownItemNumber={2000}
                          selected={
                            Demographic?.DOB ? new Date(Demographic?.DOB) : null
                          }
                          onChange={(date) => {
                            const formattedDate = date.toISOString();
                            setStartDate(date);
                            setDemographic({
                              ...Demographic,
                              DOB: formattedDate,
                            });
                          }}
                          required
                          dateFormat="MM-dd-yyyy"
                          placeholderText="MM-DD-YYYY"
                          maxDate={new Date()}
                          yearDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control date"
                          aria-label="date"
                          aria-describedby="basic-addon11"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Phone Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon16">
                          <i className="fa fa-phone"></i>
                        </span>
                        <input
                          type="text"
                          name="PhoneNumber"
                          autoComplete="PhoneNumber"
                          value={Demographic?.PhoneNumber}
                          onChange={(e) => handleChange(e)}
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          max={9999999999}
                          min={10000000}
                          className="form-control phone-number"
                          placeholder="Phone Number"
                          aria-label="phone-number"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        E-mail<span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          className="form-control"
                          placeholder="Ex: example@example.com"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                          type="email"
                          name="E_mail"
                          autoComplete="E_mail"
                          value={Demographic?.E_mail || ""}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                      <span className="error-msg error-msg-email text-danger">
                        Please Enter a valid email.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">Contact Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Address Line 1<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="AddressLine1"
                        autoComplete="AddressLine1"
                        value={Demographic?.AddressLine1}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Address Line 2</label>
                      <input
                        className="form-control"
                        type="text"
                        name="AddressLine2"
                        autoComplete="AddressLine2"
                        value={Demographic?.AddressLine2}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        City<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="City"
                        autoComplete="City"
                        value={Demographic?.City}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        State<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="State"
                        autoComplete="State"
                        value={Demographic?.State}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        County<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="County"
                        autoComplete="County"
                        value={Demographic?.County}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Zip Code<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="ZipCode"
                        autoComplete="ZipCode"
                        value={Demographic?.ZipCode}
                        onChange={(e) => handleChange(e)}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        max={99999999}
                        min={10000}
                        onInput={(e) => {
                          if (e.target.value.length > 8) {
                            e.target.value = e.target.value.slice(0, 8);
                          }
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Country<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="Country"
                        autoComplete="Country"
                        value={Demographic?.Country}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">Admin Security</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Admin System Login
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="SystemLogin"
                        autoComplete="SystemLogin"
                        value={Demographic?.SystemLogin}
                        onChange={(e) => handleChange(e)}
                        onKeyUp={handleLinkAccountKeyPress}
                        required
                        minLength={8}
                        maxLength={20}
                        onInput={(e) => {
                          if (e.target.value.length > 20) {
                            e.target.value = e.target.value.slice(0, 20);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  type="submit"
                  disabled={!isValidEmail}
                >
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditAdmin;
