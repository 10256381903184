import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const Chart1 = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [chartAllData, setChartAllData] = useState([44, 55, 13, 43, 22]);
  const [chartAllLabels, setChartAllLabels] = useState([
    "Data A",
    "Data B",
    "Data C",
    "Data D",
    "Data E",
  ]);
  const backgroundColors = (
    props?.Color?.length
      ? props.Color
      : ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"]
  ).map((color) => color);
  const userId = sessionStorage.getItem("UserMainID");
  // const username = "Servusys";
  // const password = "Admin@123";
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;

  const token = btoa(`${username}:${password}`);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `https://kpiapi.sleapdata.com/api/keymetrics/?physicianID=${userId}&metrics=${props.metrics}`;
        if (chartFilter != null) {
          url += `&agerange=${chartFilter}&clicdatatype=${chartMeteic}`;
        }

        const res = await axios.get(url, {
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        const responseData = res.data.pie[0];
        setChartAllData(responseData.data);
        setChartAllLabels(responseData.labelName);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (props.metrics) {
      fetchData();
    }
  }, [props.metrics, chartFilter, chartMeteic, userId]);

  const [chartState, setChartState] = useState({
    series: chartAllData,
    options: {
      chart: {
        type: "pie",
        color: backgroundColors,
      },
      labels: chartAllLabels,
      legend: {
        display: props.LegendDisplay === false ? false : true,
        position: props.position ? props.position : "top",
      },
      dataLabels: {
        enabled: true,
        formatter: (val, opts) => {
          const tot = chartAllData.reduce((acc, curr) => acc + curr, 0);
          const min = Math.min(...chartAllData).toFixed(1);
          const max = Math.max(...chartAllData).toFixed(1);
          const mind = ((min * 100) / tot).toFixed(1);
          const maxd = ((max * 100) / tot).toFixed(1);

          if (props.DataLabels === "all") {
            if (val.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return val?.toFixed(1) + "%";
            }
          } else if (props.DataLabels === "min") {
            if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          } else if (props.DataLabels === "max") {
            if (val?.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          } else if (props.DataLabels === "both") {
            if (val?.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          }
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    },
  });

  const latestStateRef = useRef(chartState);

  useEffect(() => {
    latestStateRef.current = chartState;
  }, [chartState]);

  useEffect(() => {
    function handleChart1Click(event, chartContext, { dataPointIndex }) {
      if (dataPointIndex !== undefined) {
        const clickedDataValue = latestStateRef.current.series[dataPointIndex];
        const clickedDataLabels =
          latestStateRef.current.options.labels[dataPointIndex];
        updateChartFilter(clickedDataLabels);
        updateChartMetrics(props.metrics);
      }
    }

    setChartState((prevState) => ({
      ...prevState,
      series: chartAllData,
      options: {
        ...prevState.options,
        chart: {
          type: "pie",
          events: {
            dataPointSelection: handleChart1Click,
          },
        },
        labels: chartAllLabels || "Not Defined",
        legend: {
          show: props.LegendDisplay === false ? false : true,
          position: props.position ? props.position : "top",
          labels: {
            colors: props.LegendTextColor || "black",
          },
        },
        colors: backgroundColors,
        selection: {
          enabled: true,
        },
      },
    }));
  }, [chartAllData, chartAllLabels, props]);

  useEffect(() => {
    setChartState((prevState) => {
      const newSeries = [...prevState.series];
      const newDataLabels = {
        enabled: true,
        formatter: (val, opts) => {
          const tot = newSeries.reduce((acc, curr) => acc + curr, 0);
          const min = Math.min(...newSeries).toFixed(1);
          const max = Math.max(...newSeries).toFixed(1);
          const mind = ((min * 100) / tot).toFixed(1);
          const maxd = ((max * 100) / tot).toFixed(1);

          if (props.DataLabels === "all") {
            if (val.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return val?.toFixed(1) + "%";
            }
          } else if (props.DataLabels === "min") {
            if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          } else if (props.DataLabels === "max") {
            if (val?.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          } else if (props.DataLabels === "both") {
            if (val?.toFixed(1) == maxd) {
              return "Max: " + val?.toFixed(1) + "%";
            } else if (val?.toFixed(1) == mind) {
              return "Min: " + val?.toFixed(1) + "%";
            } else {
              return "";
            }
          }
        },
        offsetX: 0,
        offsetY: 0,
        background: {
          enabled: true,
          foreColor: "#000",
        },
      };

      const newOptions = {
        ...prevState.options,
        dataLabels: newDataLabels,
      };

      return {
        series: newSeries,
        options: newOptions,
      };
    });
  }, [props.DataLabels]);

  return (
    <ReactApexChart
      options={chartState.options}
      series={chartState.series}
      type="pie"
      width={"100%"}
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart1;
