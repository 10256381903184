import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function AllUserRecord() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [preloaderOn, setPreloaderOn] = useState(false);
  // Function to convert timestamp to date format
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // State to hold user data
  const [data, setData] = useState([]);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle delete action
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users/archive`,
        {
          id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error?.data?.message);
      });
  };

  // Define columns for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "System Login",
        accessor: "system",
      },
      {
        Header: "Linked Account",
        accessor: "ml",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  // Function to fetch user data from API
  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.SystemLogin,
          ml: item?.ProviderId,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
          last: convertTimestampToDateFormat(item?.createdAt),
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="delete"
                onClick={() => handleDeleteClick(item?.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        {/* Header section with navigation */}
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">All User Records</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        {/* Main content area with data table */}
        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUserRecord;
