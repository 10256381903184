import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import { Table } from "../../CommonPage/TableContent";
import axios from "axios";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

function EditGroup() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [Demographic, setDemographic] = useState({
    Description: "",
    GroupName: "",
    Active: "",
    roleData: "",
  });
  const [roleData, setRoleData] = useState([]);
  const [role, setRole] = useState([{ userAccount: "" }]);
  const [openModal, setOpenModal] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const closeModalBox = (e) => {
    e.preventDefault();
    setDemographic({});
    setOpenModal(false);
  };

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}subscription/getGroup`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res?.data?.message.map((item, index) => ({
          sno: index + 1,
          id: item?.id,
          name: item?.GroupName,
          Description: item?.Description,
          createdAt: new Date(item?.createdAt).toLocaleDateString(),
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleSubmit(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));
        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Group Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const handleExpandAddress = () => {
    setRole([...role, { userAccount: "" }]);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=completed`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log("USER>>", res.data.data);
        setUserData(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleServiceChange = (e, index) => {
    const { value } = e.target;

    setDemographic((prevDemographic) => {
      return {
        ...prevDemographic,
        Users: prevDemographic.Users.map((item, i) => {
          if (i === index) {
            return data.find((d) => d.id == value);
          }
          return item;
        }),
      };
    });
  };

  const handleServiceRemove = (id, grpID) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}subscription/removeUser?userId=${id}&groupId=${grpID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 1000 });
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}subscription/getGroupmember?groupId=${grpID}`,
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then((res) => {
            // console.log(res.data.data);
            const userAccountData = res?.data?.data;
            setDemographic(userAccountData);
            setRoleData(userAccountData.Users);
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleSubmit = async (id) => {
    try {
      setOpenModal(false);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}subscription/getGroupmember?groupId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("DEMODATA", response.data);
      const userAccountData = response?.data?.data;
      setDemographic(userAccountData);
      setRoleData(userAccountData.Users);
      setOpenModal(true);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}subscription/getGroup`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // console.log("DATA>>", res.data.message);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    // alert(e.target.value);
    const { name, checked, value, type } = e.target;

    const inputValue = type === "checkbox" ? checked : value;

    if (name === "Active") {
      setDemographic({
        ...Demographic,
        [name]: inputValue,
      });
    } else {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };

      // if (name === "GroupName") {
      //   if (/^[A-Za-z]+$/.test(value) || value === "") {
      //     setDemographic({
      //       ...Demographic,
      //       [name]: capitalizeFirstLetter(value),
      //     });
      //   }
      // } else {
      setDemographic({ ...Demographic, [name]: value });
    }
  };

  const handleFinalUpdate = (e) => {
    e.preventDefault();
    setPreloaderOn(true);
    if (Demographic?.GroupName === "") {
      toast.warning("Please fill the Details");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}subscription/editGroup`,
          {
            ...Demographic,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setPreloaderOn(false);
          toast.success(res.data.message);
          setOpenModal(false);
          fetchData();
        })
        .catch((error) => {
          setPreloaderOn(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleDelete = (i) => {
    if (i !== 0) {
      const newArr = role.filter((item, index) => index !== i);
      setRole(newArr);
    }
  };

  const handleAddress = (e, index) => {
    const { name, value } = e.target;

    const isAlphabetic = /^[A-Za-z\s]+$/;

    const updatedAddresses = role.map((address, i) => {
      if (i === index) {
        if (isAlphabetic.test(value) || value === "") {
          return {
            ...address,
            [name]: value,
          };
        } else {
          return {
            ...address,
            [name]: value,
          };
        }
      }
      return address;
    });

    setRole(updatedAddresses);
  };

  const handleNewUser = () => {
    const list = role.map((item, i) => ({
      ...item,
    }));
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}subscription/addUser`,
        {
          list,
          groupId: Demographic.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        const updatedData = [...roleData, res?.data?.user];
        setRoleData(updatedData);
        handleSubmit(Demographic?.id);
        setRole([{ userAccount: "" }]);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Subscription Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Group</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit Group</h2>
            </header>
            <form className="form-box tw-w-[100%]" id="basic-form">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Group Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        autoComplete="Active"
                        value={true}
                        checked={Demographic.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="Active">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    <div className="md:tw-grid tw-grid-cols-4 tw-gap-x-6 tw-gap-y-2 tw-mt-2">
                      <div className="sm:tw-col-span-2">
                        <label
                          htmlFor="Subscription"
                          className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                        >
                          Group Name
                          <span className="tw-text-red-500">*</span>
                        </label>
                        <div className="">
                          <input
                            type="text"
                            name="GroupName"
                            autoComplete="GroupName"
                            value={Demographic?.GroupName}
                            onChange={(e) => handleChange(e)}
                            className="form-control rounded-3"
                          />
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="Description"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            General Description
                          </label>
                          <div className="">
                            <textarea
                              rows={4}
                              type="text"
                              name="Description"
                              autoComplete="Description"
                              value={Demographic?.Description}
                              onChange={(e) => handleChange(e)}
                              className="form-control rounded-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-body">
                  <div className="row g-3">
                    <div className="tw-w-[100%]">
                      {roleData?.map((items, i) => {
                        return (
                          <>
                            {items && (
                              <div className="flex-wrap d-flex" key={i}>
                                <div className="col-sm-6 mb-3 mt-2 col-md-4 col-lg-4 position-relative groupSelectBox">
                                  <select
                                    onChange={(e) => handleServiceChange(e, i)}
                                    name="service"
                                    className="form-select"
                                  >
                                    <option value="0">Select</option>
                                    {userData?.map((item, jk) => {
                                      return (
                                        <option
                                          key={jk}
                                          value={item?.id}
                                          selected={
                                            item?.id == roleData[i]?.id
                                              ? true
                                              : false
                                          }
                                        >
                                          {item?.id +
                                            ". " +
                                            " " +
                                            item.FirstName +
                                            " " +
                                            item.LastName}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span
                                    className="groupDelet tw-cursor-pointer"
                                    onClick={() =>
                                      handleServiceRemove(
                                        roleData[i]?.id,
                                        Demographic?.id
                                      )
                                    }
                                  >
                                    X
                                  </span>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-2">
                <div className="card-body">
                  <div className="row g-3">
                    <div className="row g-3 d-flex">
                      {role.map((singleService, index) => (
                        <div
                          className="col-sm-6 col-md-4 col-lg-4 col-xl-3 position-relative groupSelectBox flex-wrap"
                          key={index}
                        >
                          <label className="form-label d-block">
                            Select User Account :
                          </label>
                          &nbsp;
                          <select
                            className="form-select add-user"
                            value={singleService.userAccount}
                            onChange={(e) => handleAddress(e, index)}
                            required
                            name="userAccount"
                          >
                            <option>Select User Account</option>
                            {userData?.map((item, idrfg) => {
                              return (
                                <option key={idrfg} value={item.id}>
                                  {item.id +
                                    " " +
                                    item.FirstName +
                                    " " +
                                    item.LastName}
                                </option>
                              );
                            })}
                          </select>
                          <span
                            type="button"
                            title="Remove"
                            onClick={() => handleDelete(index)}
                            className="groupDelet tw-cursor-pointer"
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </div>

                    <div className="tw-flex tw-justify-end tw-gap-6 tw-mt-5 tw-w-[100%]">
                      <button
                        type="button"
                        onClick={handleNewUser}
                        className="btn btn-outline-success"
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <footer className="custom_modal-footer">
                <button
                  type="button"
                  onClick={() => handleExpandAddress()}
                  className="btn btn-outline-success"
                >
                  + Add More User
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  type="submit"
                  onClick={handleFinalUpdate}
                >
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditGroup;
