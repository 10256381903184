import React, { useEffect, useState } from "react";
import $ from "jquery";
import { useSharedContext } from "../../CommonPage/SharedDataSet";
import TopImg from "../../sleap.png";

function UserTopBar() {
  const mobileMenuBar = () => {
    $("body").toggleClass("offcanvas-active");
  };
  const { emailValue } = useSharedContext();
  const { notificationValue } = useSharedContext();
  const [emailBtn, setEmailBtn] = useState(emailValue);
  const [notificationBtn, setNotificationBtn] = useState(notificationValue);
  useEffect(() => {
    setEmailBtn(emailValue);
    setNotificationBtn(notificationValue);
  }, [emailValue, notificationValue]);
  const handleLogout = async () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("email");
    window.location.href = "/";
  };

  return (
    <nav className="navbar navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-btn">
          <button
            type="button"
            className="btn-toggle-offcanvas"
            onClick={mobileMenuBar}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>

        <div className="navbar-brand pt-1">
          <a href="/user-dashboard" className="d-flex">
            <img src={TopImg} alt="" className="tw-h-[36px]" />
          </a>
        </div>

        <div className="d-flex flex-grow-1 align-items-center">
          <div className="flex-grow-1">
            <ul className="nav navbar-nav flex-row justify-content-end align-items-center">
              {emailBtn == true ? (
                <li>
                  <a href="#" className="icon-menu">
                    <i className="fa fa-envelope"></i>
                    <span className="notification-dot"></span>
                  </a>
                </li>
              ) : (
                ""
              )}
              {notificationBtn == true ? (
                <li className="dropdown">
                  <a
                    className="dropdown-toggle icon-menu"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-bell"></i>
                    <span className="notification-dot"></span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end p-0 shadow notification">
                    <ul className="list-unstyled feeds_widget">
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-thumbs-o-up"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <h6 className="mb-1">
                            7 New Feedback{" "}
                            <small className="float-end text-muted small">
                              Today
                            </small>
                          </h6>
                          <span className="text-muted">
                            It will give a smart finishing to your site
                          </span>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <h6 className="mb-1">
                            New User{" "}
                            <small className="float-end text-muted small">
                              10:45
                            </small>
                          </h6>
                          <span className="text-muted">
                            I feel great! Thanks team
                          </span>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-question-circle"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <h6 className="mb-1 text-warning">
                            Server Warning{" "}
                            <small className="float-end text-muted small">
                              10:50
                            </small>
                          </h6>
                          <span className="text-muted">
                            Your connection is not private
                          </span>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-check"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <h6 className="mb-1 text-danger">
                            Issue Fixed{" "}
                            <small className="float-end text-muted small">
                              11:05
                            </small>
                          </h6>
                          <span className="text-muted">
                            WE have fix all Design bug with Responsive
                          </span>
                        </div>
                      </li>
                      <li className="d-flex">
                        <div className="feeds-left">
                          <i className="fa fa-shopping-basket"></i>
                        </div>
                        <div className="feeds-body flex-grow-1">
                          <h6 className="mb-1">
                            7 New Orders{" "}
                            <small className="float-end text-muted small">
                              11:35
                            </small>
                          </h6>
                          <span className="text-muted">
                            You received a new oder from Tina.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              )}

              {/* more link  */}
              <li>
                <a onClick={handleLogout} className="icon-menu">
                  <i className="fa fa-sign-out"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default UserTopBar;
