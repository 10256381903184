import axios from "axios";
import React, { useState, useEffect } from "react";
import { ReactFormGenerator, ElementStore } from "react-form-builder2";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";

const Demobar = (props) => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [row, setRow] = useState({ name: "" });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [shortPreviewVisible, setShortPreviewVisible] = useState(false);
  const [roPreviewVisible, setRoPreviewVisible] = useState(false);

  useEffect(() => {
    const onChange = (state) => {
      setData([...state.data]);
    };
    ElementStore.subscribe(onChange);
  }, []);

  const showPreview = () => {
    setPreviewVisible(true);
  };

  const closePreview = (e) => {
    e.preventDefault();
    setPreviewVisible(false);
    setShortPreviewVisible(false);
    setRoPreviewVisible(false);
  };

  const onSubmit = () => {
    if (!row.name.trim()) {
      toast.warning("Clinical Document Name is required");
      return;
    }

    const processedData = data.map((item) => {
      const placeholder = item.placeholder || "default_placeholder";
      return {
        ...item,
        custom_name: item.custom_name || placeholder,
      };
    });

    const apiUrl = `${process.env.REACT_APP_BASE_URL}clinicaldocument/add`;

    axios
      .post(
        apiUrl,
        { data: processedData, name: row.name },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setRow({ name: "" });
        setData([]);
      })
      .catch((error) => {
        // console.log("first error:", error);
        toast.error("Error submitting form data:", error.response.data.message);
      });
  };

  const handleName = (e) => {
    setRow({ ...row, [e.target.name]: e.target.value });
  };

  const handleCustomNameChange = (index, e) => {
    const newData = [...data];
    newData[index].custom_name = e.target.value;
    setData(newData);
  };

  const onFormChange = (newData) => {
    setData(newData);
  };

  const modalClass = previewVisible ? "modal show d-block" : "modal";
  const shortModalClass = shortPreviewVisible
    ? "modal short-modal show d-block"
    : "modal short-modal";
  const roModalClass = roPreviewVisible
    ? "modal ro-modal show d-block"
    : "modal ro-modal";

  return (
    <div className="clearfix">
      <div className="d-flex tw-w-[100%] tw-justify-between">
        <div className="">
          <div className="tw-w-[100%]">
            <label className="form-label">
              Clinical Document Name<span className="AsteriskSymbol">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              name="name"
              autoComplete="name"
              value={row.name}
              onChange={handleName}
              required
              style={{ width: "400px" }}
            />
          </div>
        </div>
        <div className="mt-3">
          <button
            className="btn submit-btn d-inline ms-3"
            onClick={showPreview}
          >
            Preview Form
          </button>
          <button className="btn submit-btn d-inline ms-3" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>

      {data.map((item, index) => (
        <div key={index} className="form-group">
          <label>Custom Name for {item.text}</label>
          <input
            type="text"
            autoComplete="custom_name"
            className="form-control"
            value={item.custom_name || ""}
            onChange={(e) => handleCustomNameChange(index, e)}
          />
        </div>
      ))}

      {previewVisible && (
        <div className={modalClass}>
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_name="Back"
                answer_data={{}}
                action_name="Save"
                form_method="POST"
                onSubmit={onSubmit}
                variables={props.variables}
                data={data}
                onFormChange={onFormChange}
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={closePreview}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {roPreviewVisible && (
        <div className={roModalClass}>
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_name="Back"
                answer_data={{}}
                action_name="Save"
                form_method="POST"
                read_only={true}
                variables={props.variables}
                hide_actions={true}
                data={data}
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={closePreview}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {shortPreviewVisible && (
        <div className={shortModalClass}>
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action=""
                answer_data={{}}
                form_method="POST"
                data={data}
                display_short={true}
                variables={props.variables}
                hide_actions={false}
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={closePreview}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Demobar;
