import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import _ from "lodash";
import CryptoJS from "crypto-js";

function ListProvider() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [providerData, setProviderData] = useState();
  const [sexData, setSexData] = useState();
  const [titleData, setTitleData] = useState();
  const [referralSourceData, setReferralSourceData] = useState();
  const [communicationData, setCommunicationData] = useState();
  const [specialityData, setSpecialityData] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [Demographic, setDemographic] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Sex_Type: "",
    SexTypeId: "",
    DOB: "",
    NPINumber: "",
    MedicalLicenseNumber: "",
    ProviderAddresses: [],
    Title_Type: "",
    TitleTypeId: "",
    Provider_Type: "",
    ProviderTypeId: "",
    Speciality_Type: "",
    SpecialityTypeId: "",
    ReferralSource_Type: "",
    ReferralSourceTypeId: "",
    Communication_Type: "",
    CommunicationTypeId: "",
    resmedPhysicianID: "",
  });
  const [ProviderAddresses, setProviderAddresses] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [filteredAddress, setFilteredAddress] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(0);
  const [validationState, setValidationState] = useState({
    NPINumber: null,
    MedicalLicenseNumber: null,
    resmedPhysicianID: null,
  });

  useEffect(() => {
    if (open2) {
      setExpandedAccordion(0);
    }
  }, [open2]);

  const handleSecondModal = () => {
    setOpen2(true);
  };

  const CloseSecondModal = (e) => {
    e.preventDefault();
    setOpen2(false);
  };

  const handleEditClick = async (id) => {
    try {
      setOpenModal(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users/getProvider?UserId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const providerData = response.data.data;
      setDemographic(providerData);
      setProviderAddresses(providerData.ProviderAddresses);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTypeData = (type, setter) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=${type}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setter(res?.data?.data);
      })
      .catch((error) => {
        console.error(`Error fetching ${type} data:`, error);
      });
  };

  useEffect(() => {
    fetchTypeData("provider", setProviderData);
    fetchTypeData("sex", setSexData);
    fetchTypeData("title", setTitleData);
    fetchTypeData("referralSource", setReferralSourceData);
    fetchTypeData("communication", setCommunicationData);
    fetchTypeData("speciality", setSpecialityData);
  }, [token]);

  const closeModalBox = () => {
    setDemographic({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Sex_Type: "",
      SexTypeId: "",
      DOB: "",
      NPINumber: "",
      MedicalLicenseNumber: "",
      ProviderAddresses: [],
      Title_Type: "",
      TitleTypeId: "",
      Provider_Type: "",
      ProviderTypeId: "",
      Speciality_Type: "",
      SpecialityTypeId: "",
      ReferralSource_Type: "",
      ReferralSourceTypeId: "",
      Communication_Type: "",
      CommunicationTypeId: "",
      Active: "",
    });
    setProviderAddresses([]);
    setFilteredAddress([]);
    setOpenModal(false);
  };

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getProvider`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          pid: item?.id,
          name: item?.FirstName + " " + item?.LastName + " ",
          phone: item?.ProviderAddresses[0]?.PhoneNumber,
          email: item?.ProviderAddresses[0]?.Email,
          providerType: item?.Provider_Type?.type,
          speciality: item?.Speciality_Type?.type,
          npi: item?.NPINumber,
          ml: item?.MedicalLicenseNumber,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box d-flex tw-justify-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleEditClick(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Provider ID",
        accessor: "pid",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Provider Type",
        accessor: "providerType",
      },
      {
        Header: "NPI No",
        accessor: "npi",
      },
      {
        Header: "ML No",
        accessor: "ml",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const handleChangeInside = (e, index) => {
    const { name, value } = e.target;
    const regex = /^[a-zA-Z\s]*$/;
    let newProviderAddresses = [...ProviderAddresses];

    if (name === "Primary") {
      newProviderAddresses[index] = {
        ...newProviderAddresses[index],
        Primary: true,
      };
      for (let i = 0; i < newProviderAddresses.length; i++) {
        if (i !== index) {
          newProviderAddresses[i] = {
            ...newProviderAddresses[i],
            Primary: false,
          };
        }
      }
    } else if (name === "Shared") {
      newProviderAddresses[index] = {
        ...newProviderAddresses[index],
        [name]: e.target.checked,
      };
    } else if (
      name === "PracticeName" ||
      name === "City" ||
      name === "State" ||
      name === "County" ||
      name === "Country"
    ) {
      if (regex.test(value)) {
        newProviderAddresses[index] = {
          ...newProviderAddresses[index],
          [name]: value,
        };
      } else {
        toast.warning(`${name} should contain only alphabets and spaces.`);
        return; // Prevent further execution
      }
    } else if (
      name === "ZipCode" ||
      name === "PhoneNumber" ||
      name === "PreferredContact" ||
      name === "FaxNumber"
    ) {
      if (isNaN(value)) {
        toast.warning(`${name} must be a number`, { autoClose: 1000 });
        return;
      } else {
        if (value.length > 10) {
          toast.warning(`${name} cannot exceed 10 characters`, {
            autoClose: 1000,
          });
          return;
        }
        newProviderAddresses[index] = {
          ...newProviderAddresses[index],
          [name]: value,
        };
      }
    } else {
      newProviderAddresses[index] = {
        ...newProviderAddresses[index],
        [name]: value,
      };
    }

    const phoneNumber = newProviderAddresses[index].PhoneNumber;
    const preferredContact = newProviderAddresses[index].PreferredContact;
    if (
      phoneNumber === preferredContact &&
      phoneNumber !== "" &&
      preferredContact !== ""
    ) {
      toast.error("Alternate Contact cannot be same as Phone Number.");
    }

    // Update the state after all logic checks
    setProviderAddresses(newProviderAddresses);
    setDemographic({
      ...Demographic,
      ProviderAddresses: newProviderAddresses,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "Email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
      }
    }
  };

  const handleUpdateData = (e) => {
    e.preventDefault();
    // setOpenModal(false);
    if (
      Demographic.FirstName.trim() === "" ||
      Demographic.LastName.trim() === "" ||
      Demographic.DOB.trim() === "" ||
      Demographic.NPINumber === "" ||
      Demographic.MedicalLicenseNumber === ""
    ) {
      var msg = "Please fill ";
      if (Demographic.FirstName.trim() === "") {
        msg += " First Name";
      }
      if (Demographic.LastName.trim() === "") {
        msg += ", Last Name";
      }
      if (Demographic.DOB.trim() === "") {
        msg += ", DOB";
      }
      if (Demographic.NPINumber === "") {
        msg += ", NPI Number";
      }
      if (Demographic.MedicalLicenseNumber === "") {
        msg += ", Medical License Number";
      }
      msg += " field";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: msg,
      });
    } else {
      setPreloaderOn(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to update the provider information.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // Proceed with the axios put request
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}users/updateProvider`,
              {
                ...Demographic,
                type: "demographic",
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              fetchData();
              setOpenModal(false);
              setValidationState({});
              setPreloaderOn(false);
            })
            .catch((error) => {
              setPreloaderOn(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: "Cancelled",
            text: "Provider information update has been cancelled.",
            icon: "error",
          });
        }
      });
    }
  };

  const handleUpdateDataTwo = (e) => {
    e.preventDefault();
    setPreloaderOn(true);
    const hasEmptyFields = ProviderAddresses.some(
      (item) =>
        item.PracticeName === "" ||
        item.AddressLine1 === "" ||
        item.ZipCode === "" ||
        item.PhoneNumber === "" ||
        item.FaxNumber === "" ||
        item.Email === ""
    );

    if (hasEmptyFields) {
      toast.warning("Please fill in all required fields.");
      return;
    }

    const isNumeric = (value) => {
      return !isNaN(value) && !isNaN(parseFloat(value));
    };

    const validateProvider = (provider) => {
      if (!provider.FirstName || !provider.LastName || !provider.NPINumber) {
        toast.error("Please provide all necessary provider details.");
        return false;
      }

      for (let i = 0; i < provider.ProviderAddresses.length; i++) {
        const address = provider.ProviderAddresses[i];
        if (
          !address.ZipCode ||
          !address.City ||
          !address.County ||
          !address.Country ||
          !address.PhoneNumber ||
          !address.FaxNumber ||
          !address.PreferredContact
        ) {
          toast.error(
            `Please fill in all required fields in Contact Information ${
              i + 1
            }.`
          );
          return false;
        }

        // ZipCode validation
        if (
          !isNumeric(address.ZipCode) ||
          address.ZipCode.toString().length < 6 ||
          address.ZipCode.toString().length > 8
        ) {
          toast.error(
            `ZipCode in Contact Information ${
              i + 1
            } should between 6 to 8 digit.`
          );
          return false;
        }

        // Phone Number validation
        if (
          !isNumeric(address.PhoneNumber) ||
          address.PhoneNumber.toString().length !== 10
        ) {
          toast.error(
            `PhoneNumber in Contact Information ${
              i + 1
            } should be a 10-digit number.`
          );
          return false;
        }

        // Preferred Contact (Alternate Number) validation
        if (
          !isNumeric(address.PreferredContact) ||
          address.PreferredContact.toString().length !== 10
        ) {
          toast.error(
            `Alternate Number in Contact Information ${
              i + 1
            } should be a 10-digit number.`
          );
          return false;
        }

        // Fax Number validation
        if (!isNumeric(address.FaxNumber)) {
          toast.error(
            `FaxNumber in Contact Information ${i + 1} should be numeric.`
          );
          return false;
        }
      }

      return true;
    };

    if (!validateProvider(Demographic)) {
      return;
    }

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users/updateProvider`,
        {
          ...Demographic,
          type: "address",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setOpen2(false);
        fetchData();
        setValidationState({});
        setPreloaderOn(false);
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error.response.data.message);
      });
  };

  const handleExpandAddress = () => {
    setProviderAddresses((prevAddresses) => [
      ...prevAddresses,
      {
        PracticeName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        ZipCode: "",
        County: "",
        Country: "",
        PhoneNumber: "",
        FaxNumber: "",
        E_mail: "",
        PreferredContact: "",
        Primary: false,
        Shared: false,
        State: "",
      },
    ]);
  };

  const handleDelete = (i) => {
    const newArr = ProviderAddresses.filter((item, index) => index !== i);
    setProviderAddresses(newArr);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (type === "checkbox") {
      setDemographic({
        ...Demographic,
        [name]: checked,
      });
    } else if (
      name === "FirstName" ||
      name === "MiddleName" ||
      name === "LastName"
    ) {
      if (/^[A-Za-z]*$/.test(value) || value === "") {
        setDemographic({
          ...Demographic,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Only Alphabets are allowed!", { autoClose: 1000 });
      }
    } else if (name === "NPINumber") {
      if (/^[0-9]*$/.test(value) || value === "") {
        setDemographic({
          ...Demographic,
          [name]: value,
        });
        debouncedSave(value, "NPINumber");
      } else {
        toast.warning("Only Numeric values are allowed!", { autoClose: 1000 });
      }
    } else if (name === "MedicalLicenseNumber") {
      if (/^[^\s]+$/.test(value) || value === "") {
        setDemographic({ ...Demographic, [name]: value });
        debouncedSave(value, "MedicalLicenseNumber");
      } else {
        toast.warning("No Spaces Allowed", { autoClose: 1000 });
      }
    } else if (name === "Email") {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === "") {
        setDemographic({ ...Demographic, [name]: value });
      }
    } else {
      setDemographic({ ...Demographic, [name]: value });
    }
  };

  const debouncedSave = useCallback(
    _.debounce((nextValue, type) => handleSearch(nextValue, type), 500),
    []
  );

  const handleSearch = (value, type) => {
    if (value.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checkNumber?${type}=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setIsValidEmail(true);
          setValidationState((prevState) => ({
            ...prevState,
            [type]: true,
          }));
        })
        .catch((error) => {
          setIsValidEmail(false);
          setValidationState((prevState) => ({
            ...prevState,
            [type]: false,
          }));
        });
    } else {
      setIsValidEmail(false);
      setValidationState({ ...validationState, [type]: "Invalid" });
    }
  };

  const handlePhysician = async (value) => {
    if (value.length >= 5) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}resmedData/resmedPhysician?id=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.data.valid) {
          setValidationState((prevState) => ({
            ...prevState,
            resmedPhysicianID: true,
          }));
        } else {
          setValidationState((prevState) => ({
            ...prevState,
            resmedPhysicianID: false,
          }));
        }
      } catch (error) {
        console.error("Error fetching physician data:", error);
        setValidationState((prevState) => ({
          ...prevState,
          resmedPhysicianID: false,
        }));
      }
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        resmedPhysicianID: "Invalid",
      }));
    }
  };

  return (
    <>
      {preloaderOn === true ? (
        <Preloader text="Please wait while form is updating..." />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Provider Administration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Provider</li>
              </ul>
            </div>
            <AllUserRecords provider={data} />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit Provider</h2>
            </header>
            <form
              className="form-box"
              id="basic-form"
              onSubmit={handleUpdateData}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Demographic Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        autoComplete="Active"
                        value={Demographic?.Active}
                        checked={Demographic?.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={Demographic?.FirstName}
                        name="FirstName"
                        autoComplete="FirstName"
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        autoComplete="MiddleName"
                        value={Demographic?.MiddleName}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="LastName"
                        autoComplete="LastName"
                        value={Demographic?.LastName}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexTypeId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option>Select</option>
                        {sexData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item?.id}
                              selected={
                                item.id == Demographic?.SexTypeId ? true : false
                              }
                            >
                              {item?.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          selected={
                            Demographic?.DOB ? new Date(Demographic?.DOB) : null
                          }
                          onChange={(date) => {
                            const formattedDate = date.toISOString() || "";
                            setStartDate(date);
                            setDemographic({
                              ...Demographic,
                              DOB: formattedDate,
                            });
                          }}
                          yearDropdownItemNumber={2000}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="MM-DD-YYYY"
                          maxDate={new Date()}
                          yearDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control date"
                          aria-label="date"
                          aria-describedby="basic-addon11"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        NPI Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          validationState.NPINumber === true
                            ? "is-valid"
                            : validationState.NPINumber === false
                            ? "is-invalid"
                            : validationState.NPINumber === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        value={Demographic?.NPINumber}
                        type="text"
                        name="NPINumber"
                        id="npi"
                        onChange={handleChange}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        maxLength={15}
                        minLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > 15) {
                            e.target.value = e.target.value.slice(0, 15);
                          }
                        }}
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.NPINumber === false ? (
                        <div className="invalid-feedback">
                          NPI Number Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          NPI Number Invalid.
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Medical License Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationState.MedicalLicenseNumber === true
                            ? "is-valid"
                            : validationState.MedicalLicenseNumber === false
                            ? "is-invalid"
                            : validationState.MedicalLicenseNumber === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="MedicalLicenseNumber"
                        value={Demographic?.MedicalLicenseNumber}
                        onChange={handleChange}
                        maxLength={15}
                        minLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > 15) {
                            e.target.value = e.target.value.slice(0, 15);
                          }
                        }}
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.MedicalLicenseNumber === false ? (
                        <div className="invalid-feedback">
                          Medical License Number Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          Medical License Number Invalid.
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Resmed Physician ID</label>
                      <input
                        className={`form-control ${
                          validationState.resmedPhysicianID === true
                            ? "is-valid"
                            : validationState.resmedPhysicianID === false
                            ? "is-invalid"
                            : validationState.resmedPhysicianID === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        value={Demographic?.resmedPhysicianID}
                        type="text"
                        name="resmedPhysicianID"
                        onChange={handleChange}
                        onBlur={(e) => handlePhysician(e.target.value)}
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.resmedPhysicianID === false && (
                        <div className="invalid-feedback">
                          Resmed Physician ID Already Taken.
                        </div>
                      )}
                      {validationState.resmedPhysicianID === "Invalid" && (
                        <div className="invalid-feedback">
                          Resmed Physician ID Invalid.
                        </div>
                      )}
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Title</label>
                      <select
                        className="form-select"
                        id="TitleType"
                        name="TitleTypeId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected>Select</option>
                        {titleData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                              selected={
                                item.id == Demographic?.TitleTypeId
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Provider Type</label>
                      <select
                        id="ProviderType"
                        name="ProviderTypeId"
                        onChange={(e) => handleChange(e)}
                        className="form-select"
                      >
                        <option selected>Select</option>
                        {providerData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                              selected={
                                item.id == Demographic?.ProviderTypeId
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Speciality</label>
                      <select
                        id="SpecialityType"
                        name="SpecialityTypeId"
                        onChange={(e) => handleChange(e)}
                        className="form-select"
                      >
                        <option selected>Select</option>
                        {specialityData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                              selected={
                                item.id == Demographic?.SpecialityTypeId
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Choose Referral Source
                      </label>
                      <select
                        className="form-select"
                        id="ReferralSourceType"
                        name="ReferralSourceTypeId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected>Select</option>
                        {referralSourceData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                              selected={
                                item.id == Demographic?.ReferralSourceTypeId
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Choose Preferred Communication
                      </label>
                      <select
                        className="form-select"
                        id="CommunicationType"
                        name="CommunicationTypeId"
                        onChange={(e) => handleChange(e)}
                      >
                        <option selected>Select</option>
                        {communicationData?.map((item, i) => {
                          return (
                            <option
                              key={i}
                              value={item.id}
                              selected={
                                item.id == Demographic?.CommunicationTypeId
                                  ? true
                                  : false
                              }
                            >
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {Demographic.ProviderAddresses.length > 0 &&
                Demographic.ProviderAddresses.map((item, index) => (
                  <>
                    {item.Primary === true ? (
                      <div className="card mt-3" key={index}>
                        <div className="card-header d-flex justify-content-between align-item-center">
                          <h6 className="card-title">Contact Information</h6>
                          <Link
                            to=""
                            className="text-blue-400 underline"
                            onClick={handleSecondModal}
                          >
                            Edit & View all Contact Info
                          </Link>
                        </div>
                        <div className="card-body">
                          <div className="row g-3 ">
                            <div className="col-12 d-flex">
                              <div className="form-check form-switch">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="primary"
                                  id="primary"
                                  autoComplete="primary"
                                  checked={item?.Primary}
                                  disabled
                                  onChange={(e) => handleChangeInside(e, index)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="primary"
                                >
                                  Primary
                                </label>
                              </div>
                              <div className="form-check form-switch mx-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  disabled
                                  name="Shared"
                                  autoComplete="Shared"
                                  checked={item.Shared === true}
                                  onChange={(e) => handleChangeInside(e, index)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Shared"
                                >
                                  Shared
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">
                                Practice Name
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                onChange={(e) => handleChangeInside(e, index)}
                                defaultValue={item?.PracticeName}
                                name="PracticeName"
                                autoComplete="PracticeName"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">
                                Address Line 1
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="AddressLine1"
                                autoComplete="AddressLine1"
                                defaultValue={item?.AddressLine1}
                                onChange={(e) => handleChangeInside(e, index)}
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">
                                Address Line 2
                              </label>
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                name="AddressLine2"
                                autoComplete="AddressLine2"
                                defaultValue={item?.AddressLine2}
                                onChange={(e) => handleChangeInside(e, index)}
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">City</label>
                              <input
                                disabled
                                type="text"
                                name="City"
                                autoComplete="City"
                                value={item?.City}
                                onChange={(e) => handleChangeInside(e, index)}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">State</label>
                              <input
                                disabled
                                type="text"
                                name="State"
                                autoComplete="State"
                                onChange={(e) => handleChangeInside(e, index)}
                                value={item.State}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">County</label>
                              <input
                                disabled
                                type="text"
                                name="County"
                                autoComplete="County"
                                defaultValue={item?.County}
                                onChange={(e) => handleChangeInside(e, index)}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">Zip Code</label>
                              <input
                                disabled
                                type="text"
                                name="ZipCode"
                                autoComplete="ZipCode"
                                defaultValue={item?.ZipCode}
                                onChange={(e) => handleChangeInside(e, index)}
                                style={{
                                  WebkitAppearance: "none",
                                  MozAppearance: "textfield",
                                }}
                                max={99999999}
                                min={10000}
                                onInput={(e) => {
                                  if (e.target.value.length > 8) {
                                    e.target.value = e.target.value.slice(0, 8);
                                  }
                                }}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">Country</label>
                              <input
                                disabled
                                type="text"
                                name="Country"
                                autoComplete="Country"
                                defaultValue={item?.Country}
                                onChange={(e) => handleChangeInside(e, index)}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">Phone Number</label>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon16"
                                >
                                  <i className="fa fa-phone"></i>
                                </span>
                                <input
                                  disabled
                                  type="text"
                                  name="PhoneNumber"
                                  autoComplete="PhoneNumber"
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  defaultValue={item?.PhoneNumber}
                                  onChange={(e) => handleChangeInside(e, index)}
                                  onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      );
                                    }
                                  }}
                                  // onChange={(e) => handleAddress(e, i)}
                                  max={9999999999}
                                  min={10000000}
                                  className="form-control phone-number"
                                  placeholder="Ex: +00 (000) 000-00-00"
                                  aria-label="phone-number"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">
                                Alternate Number
                              </label>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon16"
                                >
                                  <i className="fa fa-phone"></i>
                                </span>
                                <input
                                  disabled
                                  type="text"
                                  name="PreferredContact"
                                  autoComplete="PreferredContact"
                                  defaultValue={item?.PreferredContact}
                                  onChange={(e) => handleChangeInside(e, index)}
                                  style={{
                                    WebkitAppearance: "none",
                                    MozAppearance: "textfield",
                                  }}
                                  max={9999999999}
                                  min={10000000}
                                  onInput={(e) => {
                                    if (e.target.value.length > 10) {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      );
                                    }
                                  }}
                                  className="form-control phone-number"
                                  placeholder="Ex: +00 (000) 000-00-00"
                                  aria-label="phone-number"
                                  aria-describedby="basic-addon16"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">Fax Number</label>
                              <input
                                type="text"
                                name="FaxNumber"
                                autoComplete="FaxNumber"
                                disabled
                                onChange={(e) => handleChangeInside(e, index)}
                                defaultValue={
                                  ProviderAddresses[index]?.FaxNumber || ""
                                }
                                style={{
                                  WebkitAppearance: "none",
                                  MozAppearance: "textfield",
                                }}
                                maxLength={10} // Ensure the input length doesn't exceed 10
                                className="form-control"
                                required
                              />
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <label className="form-label">E-mail</label>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon20"
                                >
                                  <i className="fa fa-envelope-o"></i>
                                </span>
                                <input
                                  disabled
                                  className="form-control email"
                                  placeholder="Ex: example@example.com"
                                  aria-label="email"
                                  aria-describedby="basic-addon20"
                                  type="email"
                                  name="Email"
                                  autoComplete="Email"
                                  defaultValue={item?.Email}
                                  onChange={(e) => handleChangeInside(e, index)}
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}

              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  type="submit"
                  disabled={!isValidEmail}
                >
                  Update Demographic Info.
                </button>
              </footer>
            </form>
          </div>
        </div>

        <div
          className={
            open2 === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button
              className="custom_close"
              onClick={CloseSecondModal}
            ></button>
            <header className="custom_modal-header">
              <h2>Edit Provider Address</h2>
            </header>

            <form
              className="form-box addressForm"
              id="basic-form"
              onSubmit={handleUpdateDataTwo}
            >
              <div className="accordion" id="accordionPanelsStayOpenExample">
                {ProviderAddresses.length > 0 &&
                  ProviderAddresses.map((item, index) => {
                    return (
                      <>
                        {item.Primary === true ? (
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={"panelsStayOpen-heading" + index}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={
                                  "#panelsStayOpen-collapse" + index
                                }
                                aria-expanded="false"
                                aria-controls={
                                  "panelsStayOpen-collapse" + index
                                }
                              >
                                Contact Information (Primary)
                              </button>
                            </h2>
                            <div
                              id={"panelsStayOpen-collapse" + index}
                              className="accordion-collapse collapse show"
                              aria-labelledby={"panelsStayOpen-heading" + index}
                            >
                              <div className="accordion-body">
                                <div className="card-body">
                                  <div className="row g-3 ">
                                    <div className="col-12 d-flex">
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="Primary"
                                          id="primary"
                                          autoComplete="primary"
                                          defaultChecked={item?.Primary}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="primary"
                                        >
                                          Primary
                                        </label>
                                      </div>
                                      <div className="form-check form-switch mx-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="Shared"
                                          autoComplete="Shared"
                                          defaultChecked={item.Shared === true}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="Shared"
                                        >
                                          Shared
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label
                                        className="form-label"
                                        htmlFor="PracticeName"
                                      >
                                        Practice Name
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item?.PracticeName}
                                        name="PracticeName"
                                        autoComplete="PracticeName"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Address Line 1
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="AddressLine1"
                                        autoComplete="AddressLine1"
                                        value={item?.AddressLine1}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Address Line 2
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="AddressLine2"
                                        autoComplete="AddressLine2"
                                        value={item?.AddressLine2}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        City
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="City"
                                        autoComplete="City"
                                        value={item?.City}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        State
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="State"
                                        autoComplete="State"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item.State}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        County
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="County"
                                        autoComplete="County"
                                        value={item?.County}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Zip Code
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="ZipCode"
                                        autoComplete="ZipCode"
                                        value={item?.ZipCode}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        style={{
                                          WebkitAppearance: "none",
                                          MozAppearance: "textfield",
                                        }}
                                        max={99999999}
                                        min={10000}
                                        onInput={(e) => {
                                          if (e.target.value.length > 8) {
                                            e.target.value =
                                              e.target.value.slice(0, 8);
                                          }
                                        }}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Country
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="Country"
                                        autoComplete="Country"
                                        value={item?.Country}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Phone Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon16"
                                        >
                                          <i className="fa fa-phone"></i>
                                        </span>
                                        <input
                                          type="text"
                                          name="PhoneNumber"
                                          autoComplete="PhoneNumber"
                                          required
                                          style={{
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                          value={item?.PhoneNumber}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          onInput={(e) => {
                                            if (e.target.value.length > 10) {
                                              e.target.value =
                                                e.target.value.slice(0, 10);
                                            }
                                          }}
                                          // onChange={(e) => handleAddress(e, i)}
                                          max={9999999999}
                                          min={1000000000}
                                          className="form-control phone-number"
                                          placeholder="Ex: +00 (000) 000-00-00"
                                          aria-label="phone-number"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Alternate Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon16"
                                        >
                                          <i className="fa fa-phone"></i>
                                        </span>
                                        <input
                                          type="text"
                                          required
                                          name="PreferredContact"
                                          autoComplete="PreferredContact"
                                          value={item?.PreferredContact}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          style={{
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                          max={9999999999}
                                          min={1000000000}
                                          onInput={(e) => {
                                            if (e.target.value.length > 10) {
                                              e.target.value =
                                                e.target.value.slice(0, 10);
                                            }
                                          }}
                                          className="form-control phone-number"
                                          placeholder="Ex: +00 (000) 000-00-00"
                                          aria-label="phone-number"
                                          aria-describedby="basic-addon16"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Fax Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="FaxNumber"
                                        autoComplete="FaxNumber"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item?.FaxNumber}
                                        style={{
                                          WebkitAppearance: "none",
                                          MozAppearance: "textfield",
                                        }}
                                        max={9999999999}
                                        min={1000000000}
                                        onInput={(e) => {
                                          if (e.target.value.length > 10) {
                                            e.target.value =
                                              e.target.value.slice(0, 10);
                                          }
                                        }}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        E-mail
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon20"
                                        >
                                          <i className="fa fa-envelope-o"></i>
                                        </span>
                                        <input
                                          className="form-control email"
                                          placeholder="Ex: example@example.com"
                                          aria-label="email"
                                          aria-describedby="basic-addon20"
                                          type="email"
                                          name="Email"
                                          autoComplete="Email"
                                          value={item?.Email}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          onBlur={handleBlur}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                {ProviderAddresses.length > 0 &&
                  ProviderAddresses.map((item, index) => {
                    return (
                      <>
                        {item.Primary === false ? (
                          <div className="accordion-item">
                            <h2
                              className="accordion-header position-relative"
                              id={"panelsStayOpen-heading" + index}
                            >
                              <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={
                                  "panelsStayOpen-collapse" + index
                                }
                                aria-expanded="false"
                                aria-controls={
                                  "panelsStayOpen-collapse" + index
                                }
                              >
                                Contact Information {index + 1}
                              </button>
                              <span className="d-flex tw-justify-end">
                                <button
                                  type="button"
                                  className="closemoreAddress-edit"
                                  onClick={() => handleDelete(index)}
                                >
                                  X
                                </button>
                              </span>
                            </h2>
                            <div
                              id={"panelsStayOpen-collapse" + index}
                              className="accordion-collapse collapse show"
                              aria-labelledby={"panelsStayOpen-heading" + index}
                            >
                              <div className="accordion-body">
                                <div className="card-body">
                                  <div className="row g-3 ">
                                    <div className="col-12 d-flex">
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="Primary"
                                          id="primary"
                                          defaultChecked={item?.Primary}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="primary"
                                        >
                                          Primary
                                        </label>
                                      </div>
                                      <div className="form-check form-switch mx-3">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="Shared"
                                          defaultChecked={item.Shared === true}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="Shared"
                                        >
                                          Shared
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Practice Name
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item?.PracticeName}
                                        name="PracticeName"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Address Line 1
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="AddressLine1"
                                        value={item?.AddressLine1}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Address Line 2
                                      </label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="AddressLine2"
                                        value={item?.AddressLine2}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        City
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="City"
                                        value={item?.City}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        State
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="State"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item.State}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        County
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="County"
                                        value={item?.County}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Zip Code
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="number"
                                        name="ZipCode"
                                        value={item?.ZipCode}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        style={{
                                          WebkitAppearance: "none",
                                          MozAppearance: "textfield",
                                        }}
                                        max={99999999}
                                        min={10000}
                                        onInput={(e) => {
                                          if (e.target.value.length > 8) {
                                            e.target.value =
                                              e.target.value.slice(0, 8);
                                          }
                                        }}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Country
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        name="Country"
                                        value={item?.Country}
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Phone Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon16"
                                        >
                                          <i className="fa fa-phone"></i>
                                        </span>
                                        <input
                                          type="number"
                                          name="PhoneNumber"
                                          required
                                          style={{
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                          value={item?.PhoneNumber}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          onInput={(e) => {
                                            if (e.target.value.length > 10) {
                                              e.target.value =
                                                e.target.value.slice(0, 10);
                                            }
                                          }}
                                          // onChange={(e) => handleAddress(e, i)}
                                          max={9999999999}
                                          min={1000000000}
                                          className="form-control phone-number"
                                          placeholder="Ex: +00 (000) 000-00-00"
                                          aria-label="phone-number"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Alternate Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon16"
                                        >
                                          <i className="fa fa-phone"></i>
                                        </span>
                                        <input
                                          type="number"
                                          required
                                          name="PreferredContact"
                                          value={item?.PreferredContact}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          style={{
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                          max={9999999999}
                                          min={1000000000}
                                          onInput={(e) => {
                                            if (e.target.value.length > 10) {
                                              e.target.value =
                                                e.target.value.slice(0, 10);
                                            }
                                          }}
                                          className="form-control phone-number"
                                          placeholder="Ex: +00 (000) 000-00-00"
                                          aria-label="phone-number"
                                          aria-describedby="basic-addon16"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        Fax Number
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <input
                                        type="number"
                                        name="FaxNumber"
                                        onChange={(e) =>
                                          handleChangeInside(e, index)
                                        }
                                        value={item?.FaxNumber}
                                        style={{
                                          WebkitAppearance: "none",
                                          MozAppearance: "textfield",
                                        }}
                                        max={9999999999}
                                        min={1000000000}
                                        onInput={(e) => {
                                          if (e.target.value.length > 10) {
                                            e.target.value =
                                              e.target.value.slice(0, 10);
                                          }
                                        }}
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-4 col-lg-3">
                                      <label className="form-label">
                                        E-mail
                                        <span className="AsteriskSymbol">
                                          *
                                        </span>
                                      </label>
                                      <div className="input-group">
                                        <span
                                          className="input-group-text"
                                          id="basic-addon20"
                                        >
                                          <i className="fa fa-envelope-o"></i>
                                        </span>
                                        <input
                                          className="form-control email"
                                          placeholder="Ex: example@example.com"
                                          aria-label="email"
                                          aria-describedby="basic-addon20"
                                          type="email"
                                          name="Email"
                                          value={item?.Email}
                                          onChange={(e) =>
                                            handleChangeInside(e, index)
                                          }
                                          onBlur={handleBlur}
                                          required
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </div>
              <footer className="custom_modal-footer">
                <button
                  type="button"
                  className="btn add-more-btn d-inline"
                  onClick={() => handleExpandAddress()}
                >
                  Add More Addresss
                </button>

                <button className="btn submit-btn d-inline ms-3" type="submit">
                  Update Address
                </button>
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={CloseSecondModal}
                >
                  Cancel
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListProvider;
