import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import "./Chart.css";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const Chart12 = (props) => {
  const backgroundColors = props?.Color?.length ? props.Color : ["#008FFB"];
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [chartAllData, setChartAllData] = useState([
    10, 41, 35, 51, 10, 41, 35, 10, 29, 51,
  ]);
  const [chartAllLabels, setChartAllLabels] = useState([]);
  const [state, setState] = useState({
    series: [
      {
        name: "Data",
        data: [10, 41, 35, 51, 10, 41, 35, 10, 29, 51],
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      forecastDataPoints: {
        count: 7,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        tickAmount: 10,
        categories: chartAllLabels,
        title: {
          text: props.XAxis,
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "green",
          },
        },
        labels: {
          show: props.LebalX !== false,
        },
      },
      yaxis: {
        tickAmount: 10,
        title: {
          text: props.YAxis,
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalY !== false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: backgroundColors,
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      legend: {
        show: props.LegendDisplay !== false,
        position: props.position || "top",
      },
      tooltip: {
        enabled: props.Tooltip !== false,
      },
      grid: {
        show: true,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: props.gridX !== false,
          },
        },
        yaxis: {
          lines: {
            show: props.gridY !== false,
          },
        },
      },
    },
  });
  const userId = sessionStorage.getItem("UserMainID");

  const latestStateRef = useRef(state);

  useEffect(() => {
    latestStateRef.current = state;
  }, [state]);

  useEffect(() => {
    const fetchData = (url) => {
      // const username = "Servusys";
      // const password = "Admin@123";
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const basicAuth = "Basic " + btoa(`${username}:${password}`);

      axios
        .get(url, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((res) => {
          const responseData = res.data.line[0];
          setChartAllData(responseData?.data || []);
          setChartAllLabels(responseData?.labelName || []);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };

    if (props.metrics && props.metrics !== undefined) {
      if (chartFilter != null) {
        const url = `https://kpiapi.sleapdata.com/api/keymetrics/?physicianID=${userId}&metrics=${props.metrics}&agerange=${chartFilter}&clicdatatype=${chartMeteic}`;
        fetchData(url);
      } else {
        const url = `https://kpiapi.sleapdata.com/api/keymetrics/?metrics=${props.metrics}&physicianID=${userId}`;
        fetchData(url);
      }
    }
  }, [props.metrics, chartFilter, chartMeteic]);

  useEffect(() => {
    const maxDataValue = Math.max(...chartAllData);
    const minDataValue = Math.min(...chartAllData);

    function handleChart1Click(event, chartContext, { dataPointIndex }) {
      if (dataPointIndex !== undefined) {
        const clickedDataValue =
          latestStateRef.current.series[0].data[dataPointIndex];
        const clickedLabels = chartAllLabels[dataPointIndex];
        updateChartFilter(clickedLabels);
        updateChartMetrics(props.metrics);
      }
    }

    setState((prevState) => ({
      ...prevState,
      series: [
        {
          name: "Data",
          data: chartAllData,
        },
      ],
      options: {
        ...prevState.options,
        chart: {
          ...prevState.options.chart,
          events: {
            markerClick: handleChart1Click,
          },
        },
        stroke: {
          curve: props.StrokeLine || "straight",
          dashArray: props.LineTpyeSD || 0,
          width: props.LineWidth || 2,
          colors: backgroundColors,
        },
        xaxis: {
          ...prevState.options.xaxis,
          categories: chartAllLabels,
          title: {
            text: props.axisX === true ? props.XAxis : "",
            style: {
              fontSize: props.FontSize || "14px",
              color: props.TextColor || "green",
            },
          },
          labels: {
            show: props.LebalX === false ? false : true,
            style: {
              colors: props.LavelXColor || "red",
            },
          },
          show: props.axisX !== false,
        },
        yaxis: {
          tickAmount: 10,
          title: {
            text: props.axisY === true ? props.YAxis : "",
            style: {
              fontSize: props.FontSize || "14px",
              color: props.TextColor || "black",
            },
          },
          labels: {
            show: props.LebalY === false ? false : true,
            style: {
              colors: props.LavelYColor || "red",
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: backgroundColors,
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        legend: {
          show: props.LegendDisplay === false ? false : true,
          position: props.position ? props.position : "top",
          labels: {
            colors: props.LegendTextColor || "black",
          },
        },
        tooltip: {
          enabled: props.Tooltip === false ? false : true,
        },
        grid: {
          show: true,
          borderColor: "#f0f0f0",
          strokeDashArray: 4,
          position: "back",
          xaxis: {
            lines: {
              show: props.gridX === false ? false : true,
            },
          },
          yaxis: {
            lines: {
              show: props.gridY === false ? false : true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          formatter: function (val, opts) {
            if (props.DataLabels === "all") {
              return val.toFixed(0);
            } else if (props.DataLabels === "min") {
              if (opts.seriesIndex === 0) {
                if (val === Math.min(...state.series[0].data)) {
                  return val.toFixed(0);
                } else {
                  return "";
                }
              }
            } else if (props.DataLabels === "max") {
              if (opts.seriesIndex === 0) {
                if (val === Math.max(...state.series[0].data)) {
                  return val.toFixed(0);
                } else {
                  return "";
                }
              }
            } else if (props.DataLabels === "both") {
              if (opts.seriesIndex === 0) {
                if (
                  val === Math.max(...state.series[0].data) ||
                  val === Math.min(...state.series[0].data)
                ) {
                  return val.toFixed(0);
                } else {
                  return "";
                }
              }
            }
          },
        },
        annotations: {
          yaxis: [
            props.TargetDisplay && {
              y: props.TargetValue || 20,
              borderColor: props.TargetLineDisplay
                ? props.TargetValueLineColor || "#0073ff"
                : "",
              label: {
                borderColor: props.TargetValueLineColor || "#0073ff",
                offsetY: +props.PositionTargetTB || 0,
                offsetX: +props.PositionTargetLR || 0,
                style: {
                  color: props.LableTextColorTarget || "#fff",
                  background: props.TargetValueLineColor || "#0073ff",
                  fontSize: props.FontSizeTarget,
                  textAlign: "left",
                },
                text:
                  props.ValueTarget === true
                    ? (props.TargetValueTitle &&
                        props.TargetValueTitle + " - " + props.TargetValue) ||
                      "Target" + " - " + props.TargetValue
                    : props.TargetValueTitle || "Target",
              },
            },
            props.PeakDisplay && {
              y: maxDataValue,
              borderColor: props.PeakLineDisplay
                ? props.PeakValueC || "#ff0000"
                : "",
              label: {
                borderColor: props.PeakValueC || "#ff0000",
                offsetY: +props.PositionPeakTB || 0,
                offsetX: +props.PositionPeakLR || 0,
                style: {
                  color: "#fff",
                  background: props.PeakValueC || "#ff0000",
                  fontSize: props.FontSizePeak || "12px",
                },
                text:
                  props.ValuePeak === true
                    ? (props.PeakValueTitle &&
                        props.PeakValueTitle + " - " + maxDataValue) ||
                      "Peak" + " - " + maxDataValue
                    : props.PeakValueTitle || "Peak",
              },
            },
            props.LowestDisplay && {
              y: minDataValue,
              borderColor: props.LowestLineDisplay
                ? props.LowestValueC || "#00ff00"
                : "",
              label: {
                borderColor: props.LowestValueC || "#00ff00",
                offsetY: +props.PositionLowestTB || 0,
                offsetX: +props.PositionLowestLR || 0,
                style: {
                  color: "#fff",
                  background: props.LowestValueC || "#00ff00",
                  fontSize: props.FontSizeLowest || "12px",
                },
                text:
                  props.ValueLowest === true
                    ? (props.LowestValueTitle &&
                        props.LowestValueTitle + " - " + minDataValue) ||
                      "Lowest" + " - " + minDataValue
                    : props.LowestValueTitle || "Lowest",
              },
            },
          ],
        },
      },
    }));
  }, [chartAllData, chartAllLabels]);

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="line"
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart12;
