import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function AllProviderRecord() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  // State hooks to manage data and modal states
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newApprovedData, setNewApprovedData] = useState();
  const [address, setAddress] = useState();
  const [preloaderOn, setPreloaderOn] = useState(false);

  // Function to convert timestamp to a human-readable date format
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Function to close the modal
  const closeModalBox = () => {
    setOpenModal(null);
  };

  // Fetch data from the API
  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getProvider`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        // console.log(res?.data?.data);

        // Format the fetched data
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          name: `${item.FirstName} ${item.MiddleName} ${item.LastName}`,
          phone: item?.ProviderAddresses[0]?.PhoneNumber,
          providerType: item?.Provider_Type?.type,
          speciality: item?.Speciality_Type?.type,
          npi: item?.NPINumber,
          ml: item?.MedicalLicenseNumber,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          created: convertTimestampToDateFormat(item?.createdAt),
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="view"
                onClick={() => handleEditClick(item?.id)}
              >
                <i className="fa fa-eye"></i>
              </Link>
              <Link
                to=""
                className="delete"
                onClick={() => handleDeleteClick(item?.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Handle edit button click to fetch and display specific provider data
  const handleEditClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users/getProvider?UserId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setNewApprovedData(response?.data?.data);
      var addressSec = newApprovedData?.ProviderAddresses.map((item) => {
        return {
          PracticeName: item?.PracticeName,
          AddressLine1: item?.AddressLine1,
          AddressLine2: item?.AddressLine2,
          City: item?.City,
          ZipCode: item?.ZipCode,
          County: item?.County,
          Country: item?.Country,
          PhoneNumber: item?.PhoneNumber,
          FaxNumber: item?.FaxNumber,
          E_mail: item?.E_mail,
          PreferredContact: item?.PreferredContact,
          Primary: item?.Primary,
          Shared: item?.Shared,
          State: item?.State,
        };
      });
      setAddress(addressSec);
      setOpenModal(true);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  // Handle delete button click to archive provider record
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}users/providerArchive`,
        {
          id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error?.data?.message);
      });
  };

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      { Header: "S.No.", accessor: "sno" },
      { Header: "Name", accessor: "name" },
      { Header: "Phone No", accessor: "phone" },
      { Header: "Provider Type", accessor: "providerType" },
      { Header: "Speciality", accessor: "speciality" },
      { Header: "NPI No", accessor: "npi" },
      { Header: "ML No", accessor: "ml" },
      { Header: "Status", accessor: "status" },
      { Header: "Created On", accessor: "created" },
      { Header: "Action", accessor: "action" },
    ],
    []
  );

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">All Provider Records</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        {newApprovedData && (
          <div
            className={
              openModal === true
                ? "custom_modal open openModal"
                : "custom_modal"
            }
          >
            <div class="custom_content-wrapper tw-w-[90%]">
              <button class="custom_close" onClick={closeModalBox}></button>
              <header class="custom_modal-header">
                <h2>All Provider Record</h2>
              </header>
              <form className="form-box" id="basic-form">
                <div className="card">
                  <div className="card-header">
                    <h6 className="card-title">Demographic Information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 ">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          First Name : {newApprovedData?.FirstName || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Middle Name : {newApprovedData?.MiddleName || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Last Name : {newApprovedData?.LastName || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Gender : {newApprovedData?.Sex_Type?.type || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Date of Birth : {newApprovedData?.DOB || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          NPI Number : {newApprovedData?.NPINumber || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          ML Number :
                          {newApprovedData?.MedicalLicenseNumber || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Title : {newApprovedData?.Title_Type?.type || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Provider Type :
                          {newApprovedData?.Provider_Type?.type || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Speciality :
                          {newApprovedData?.Speciality_Type?.type || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Referral Source :
                          {newApprovedData?.Referral_Source_Type?.type || "NA"}
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Preferred Communication :
                          {newApprovedData?.Communication_Type?.type || "NA"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {newApprovedData?.ProviderAddresses?.map((item, i) => (
                  <div className="card mt-1" key={i}>
                    <div className="card-header">
                      <h6 className="card-title">
                        Primary Contact Information
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Practice Name : {item?.PracticeName || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Address Line 1 : {item?.AddressLine1 || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Address Line 2 : {item?.AddressLine2 || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            City : {item?.City || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            County : {item?.County || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            ZipCode : {item?.ZipCode || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Country : {item?.Country || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Phone Number : {item?.PhoneNumber || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Fax Number : {item?.FaxNumber || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            E-mail : {item?.Email || "NA"}
                          </label>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3">
                          <label className="form-label">
                            Alternate Number : {item?.PreferredContact || "NA"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AllProviderRecord;
