import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CryptoJS from "crypto-js";

const Chart2 = (props) => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [datesData, setDatesData] = useState([]);
  const typeRef = useRef("");

  useEffect(() => {
    typeRef.current = props.type;
  }, [props.type]);

  useEffect(() => {
    let dates = [];
    if (props.type === "week") {
      dates = props?.series?.enrolledRequestUsersWeekly?.dates || [];
    } else if (props.type === "month") {
      dates = props?.series?.enrolledUserRequestMonthlyData?.months || [];
    } else if (props.type === "year") {
      dates = props?.series?.enrolledRequesUsersYearly?.year || [];
    }
    setDatesData(dates);
  }, [props.series, props.type]);

  const fetchData = async (
    dateSelected,
    newCheck,
    approvedCheck,
    pendingCheck
  ) => {
    let url = `${process.env.REACT_APP_BASE_URL}dashboard/filter?new=${newCheck}&pending=${pendingCheck}&approved=${approvedCheck}&type=${typeRef.current}&`;
    if (typeRef.current === "week") {
      url += `date=${dateSelected}`;
    } else if (typeRef.current === "month") {
      dateSelected = dateSelected?.split(" ")[0];
      url += `month=${dateSelected}`;
    } else if (typeRef.current === "year") {
      url += `year=${dateSelected}`;
    }
    axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          request: new Date(item?.createdAt).toLocaleDateString("en-US"),
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.Title_Type?.type,
        }));
        props.data(formattedData);
        props.open(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const handleColumnClick = async (_, __, config) => {
    const newCheck = config?.config?.series[0]?.data?.length > 0;
    const pendingCheck = config?.config?.series[1]?.data?.length > 0;
    const approvedCheck = config?.config?.series[2]?.data?.length > 0;

    setDatesData((prevDatesData) => {
      const dateSelected = prevDatesData[config.dataPointIndex];
      fetchData(dateSelected, newCheck, pendingCheck, approvedCheck);
      return prevDatesData;
    });
  };

  const [options, setOptions] = useState({
    series: [
      {
        name: "New",
        data: props?.series?.enrolledProvidersWeekly?.data,
      },
      {
        name: "Pending",
        data: [25, 83, 92, 26, 23, 25],
      },
      {
        name: "Approved",
        data: [12, 52, 25, 35, 19, 52],
      },
    ],
    chart: {
      type: "bar",
      events: {
        click: handleColumnClick,
      },
      height: 280,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    colors: [
      "var(--chart-color4)",
      "var(--chart-color1)",
      "var(--chart-color2)",
    ],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: props?.series?.enrolledProvidersWeekly?.dates || [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ],
      labels: {
        formatter: function (val) {
          return val + "";
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      offsetX: 0,
    },
  });

  useEffect(() => {
    setOptions({
      series: [
        {
          name: "New",
          data:
            props?.series?.enrolledRequestUsersWeekly?.data ||
            props?.series?.enrolledUserRequestMonthlyData?.data ||
            props?.series?.enrolledCompletedUsersYearly?.data,
        },
        {
          name: "Pending",
          data: props?.series?.enrolledPendingUsersWeekly?.data ||
            props?.series?.enrolledUserPendingMonthlyData?.data ||
            props?.series?.enrolledPendingUserYearly?.data || [
              25, 83, 92, 26, 23, 25,
            ],
        },
        {
          name: "Approved",
          data: props?.series?.enrolledCompletedUsersWeekly?.data ||
            props?.series?.enrolledCompletedUserMonthly?.data ||
            props?.series?.enrolledCompletedUsersYearly?.data || [
              12, 52, 25, 35, 19, 52,
            ],
        },
      ],
      xaxis: {
        categories: props?.series?.enrolledCompletedUsersWeekly?.days ||
          props?.series?.enrolledPendingUsersWeekly?.days ||
          props?.series?.enrolledRequestUsersWeekly?.days ||
          props?.series?.enrolledCompletedUserMonthly?.dates ||
          props?.series?.enrolledUserPendingMonthlyData?.dates ||
          props?.series?.enrolledUserRequestMonthlyData?.dates ||
          props?.series?.enrolledCompletedUsersYearly?.year ||
          props?.series?.enrolledPendingUserYearly?.year ||
          props?.series?.enrolledRequesUsersYearly?.year || [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
          ],
        labels: {
          formatter: function (val) {
            return val + "";
          },
        },
      },
    });
  }, [props.series]);

  return (
    <div id="Salary_Statistics">
      <Chart
        options={options}
        series={options.series}
        type="bar"
        height={280}
      />
    </div>
  );
};

export default Chart2;
