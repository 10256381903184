import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { useSharedContext } from "../CommonPage/SharedDataSet";
import { toast } from "react-toastify";
import axios from "axios";
import ToggleNavBar from "../CommonPage/ToggleNavBar";
import CryptoJS from "crypto-js";

const AdminSetting = () => {
  // const token = JSON.parse(sessionStorage.getItem("token"));
  const secretKey = "Secured Key"; // Replace with your own secret key
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  // console.log("PROFILE", token);

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const decodedToken = parseJwt(token);
  const { activeUser, setActiveUser } = useSharedContext();
  const fetchProfile = async () => {
    try {
      // setData(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users?stage=byId&id=${decodedToken.id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("!!!!!!", response.data);
      if (response.data && response.data.message) {
        const { FirstName, LastName, ImageLocation } = response.data.message;

        if (ImageLocation === undefined) {
          const fname = FirstName || "";
          const lname = LastName || "";
          const firstInitials =
            fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
          setImgs(firstInitials);
          // console.log(firstInitials);
        }
        setActiveUser(response.data.message);
        // setData(response.data.message);
      }
    } catch (error) {
      // console.log(error);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  const { emailValue, updateEmailValue } = useSharedContext();
  const { notificationValue, updateNotificationValue } = useSharedContext();
  const [imgs, setImgs] = useState();
  useEffect(() => {
    // if(activeUser)let { FirstName, LastName, img } = activeUser;
    if (activeUser && activeUser?.ImageLocation === undefined) {
      const fname = activeUser?.FirstName || "";
      const lname = activeUser?.LastName || "";
      const firstInitials =
        fname.charAt(0).toUpperCase() + lname.charAt(0).toUpperCase();
      setImgs(firstInitials);
      // console.log(firstInitials);
    }
  }, [activeUser]);
  const storedValue = localStorage.getItem("localTextColor");
  const storedTheme = localStorage.getItem("theme");
  const [textColor, setTextColor] = useState(storedValue);
  const [themeChecked, setThemeChecked] = useState(false);
  const [tabPanel, setTabPanel] = useState();
  const [highContrastChecked, setHighContrastChecked] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(storedTheme);
  const [emailBtn, setEmailBtn] = useState(
    localStorage.getItem("email") === "true" || false
  );
  const [notificationBtn, setNotificationBtn] = useState(
    localStorage.getItem("notification") === "true" || false
  );
  const [offlineBtn, setOfflineBtn] = useState(
    localStorage.getItem("offline") === "true" || false
  );
  const [locationState, setLocationState] = useState(
    localStorage.getItem("location") === "true" || false
  );

  const [menuItem, setMenuItem] = useState(localStorage.getItem("menuItems"));
  const [subMenu, setSubMenu] = useState(localStorage.getItem("subMenus"));
  const [menuType, setMenuType] = useState(localStorage.getItem("menuType"));
  useEffect(() => {
    if (localStorage.getItem("activeItem") === null) {
      setTabPanel("hr_menu");
    } else {
      setTabPanel(localStorage.getItem("activeItem"));
    }
    if (menuItem) {
      if (menuType === "singleMenuBtn") {
        $("." + menuItem).addClass("active");
        // navigate('/' + menuItem);
      } else if (menuType === "hasMenuBtn") {
        // navigate('/' + menuItem);
        $("." + menuItem).addClass("active mm-show");
        $("." + subMenu + "-has").addClass("mm-active active");
        $("." + subMenu + "-sub").addClass("mm-show");
      }
    } else {
      localStorage.setItem("menuItems", "dashboard");
      localStorage.setItem("menuType", "singleMenuBtn");
    }
    document.documentElement.setAttribute("data-theme", currentTheme);
    if (!menuItem) {
      setMenuItem("dashboard");
      setSubMenu("");
    }
    if (storedValue) {
      setTextColor(storedValue);
      $("#layout").addClass("theme-" + storedValue);
    } else {
      $("#layout").addClass("theme-cyan");
    }
    if (currentTheme == "dark") {
      setHighContrastChecked(false);
      setThemeChecked(true);
    } else if (currentTheme == "high-contrast") {
      setHighContrastChecked(true);
      setThemeChecked(false);
    } else {
      setHighContrastChecked(false);
      setThemeChecked(false);
    }
  }, []);
  const colorHandle = (colorCode) => {
    setTextColor(colorCode);
    localStorage.setItem("localTextColor", colorCode);
    var t = $("#layout"),
      e = $(this),
      i = $(".choose-skin li.active").data("theme");
    $("#layout").removeClass("theme-" + i);
    $("#layout").addClass("theme-" + colorCode);
    $(".choose-skin li").removeClass("active");
  };
  const miniSideBar = () => {
    $("body").toggleClass("sidebar-mini");
  };
  const darkModeTheame = () => {
    if (themeChecked == false) {
      setThemeChecked(true);
      setHighContrastChecked(false);
      localStorage.setItem("theme", "dark");
      setCurrentTheme("dark");
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      setThemeChecked(false);
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");
      document.documentElement.setAttribute("data-theme", "light");
    }
  };
  const highContrast = () => {
    if (highContrastChecked == false) {
      document.documentElement.setAttribute("data-theme", "high-contrast");
      setHighContrastChecked(true);
      setThemeChecked(false);
      localStorage.setItem("theme", "high-contrast");
      setCurrentTheme("high-contrast");
    } else {
      setHighContrastChecked(false);
      localStorage.setItem("theme", "light");
      setCurrentTheme("light");
      document.documentElement.setAttribute("data-theme", "light");
    }
  };
  const rtlMode = () => {
    $("body").toggleClass("rtl_mode");
  };
  const emailNotifyHandle = () => {
    if (emailBtn === false) {
      setEmailBtn(true);
      updateEmailValue(true);
      localStorage.setItem("email", true);
    } else {
      updateEmailValue(false);
      localStorage.setItem("email", false);
      setEmailBtn(false);
    }
  };
  const alertNotification = () => {
    if (notificationBtn === false) {
      localStorage.setItem("notification", true);
      setNotificationBtn(true);
      updateNotificationValue(true);
    } else {
      localStorage.setItem("notification", false);
      setNotificationBtn(false);
      updateNotificationValue(false);
    }
  };
  const offlineHandle = () => {
    if (offlineBtn === false) {
      toast.warning(
        "Software is now in offline mode. Network requests are disabled.",
        { autoClose: 2000 }
      );
      localStorage.setItem("offline", true);
      setOfflineBtn(true);
    } else {
      toast.success(
        "Software is now back online. Network requests are enabled.",
        { autoClose: 2000 }
      );
      localStorage.setItem("offline", false);
      setOfflineBtn(false);
    }
  };
  const locationPermission = () => {
    if (locationState === false) {
      localStorage.setItem("location", true);
      setLocationState(true);
    } else {
      localStorage.setItem("location", false);
      setLocationState(false);
    }
  };
  useEffect(() => {
    const handleConnectivityChange = () => {
      setOfflineBtn(!navigator.onLine);
    };
    window.addEventListener("online", handleConnectivityChange);
    window.addEventListener("offline", handleConnectivityChange);
    return () => {
      window.removeEventListener("online", handleConnectivityChange);
      window.removeEventListener("offline", handleConnectivityChange);
    };
  }, []);
  const uploadImage = localStorage.getItem("uploadImage");
  useEffect(() => {
    // console.log("TARUN", uploadImage);
  }, [uploadImage]);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Theme Setting
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Theme Setting</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="setting_menutab-pane fade show active"
          id="setting_menu"
          role="tabpanel"
        >
          <div className="px-3 row tw-justify-between settingBoxCard">
            <div className="col-lg-4 col-md-6 p-2">
              <div class="card">
                <div class="card-header custom-header py-2">Choose Skin</div>
                <ul className="choose-skin list-unstyled  p-2">
                  <li
                    data-theme="purple"
                    className={
                      textColor === "purple" ? "active  mb-2" : " mb-2"
                    }
                  >
                    <div
                      className="purple"
                      onClick={() => colorHandle("purple")}
                    ></div>
                    <span>Purple</span>
                  </li>
                  <li
                    data-theme="blue"
                    className={textColor === "blue" ? "active  mb-2" : " mb-2"}
                  >
                    <div
                      className="blue"
                      onClick={() => colorHandle("blue")}
                    ></div>
                    <span>Blue</span>
                  </li>
                  <li
                    data-theme="cyan"
                    className={textColor === "cyan" ? "active  mb-2" : " mb-2"}
                  >
                    <div
                      className="cyan"
                      onClick={() => colorHandle("cyan")}
                    ></div>
                    <span>Cyan</span>
                  </li>
                  <li
                    data-theme="green"
                    className={textColor === "green" ? "active  mb-2" : " mb-2"}
                  >
                    <div
                      className="green"
                      onClick={() => colorHandle("green")}
                    ></div>
                    <span>Green</span>
                  </li>
                  <li
                    data-theme="orange"
                    className={
                      textColor === "orange" ? "active  mb-2" : " mb-2"
                    }
                  >
                    <div
                      className="orange"
                      onClick={() => colorHandle("orange")}
                    ></div>
                    <span>Orange</span>
                  </li>
                  <li
                    data-theme="blush"
                    className={textColor === "blush" ? "active  mb-2" : " mb-2"}
                  >
                    <div
                      className="blush"
                      onClick={() => colorHandle("blush")}
                    ></div>
                    <span>Blush</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-2">
              <div class="card ">
                <div class="card-header  custom-header py-2">Theme Option</div>
                <ul className="list-unstyled mm-collapse mm-show  p-2">
                  <li className="d-flex align-items-center mb-1">
                    <div className="form-check form-switch theme-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-switch"
                        checked={themeChecked}
                        onChange={darkModeTheame}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-switch"
                      >
                        Enable Dark Mode!
                      </label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center mb-1">
                    <div className="form-check form-switch theme-high-contrast">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-high-contrast"
                        checked={highContrastChecked}
                        onChange={highContrast}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-high-contrast"
                      >
                        Enable High Contrast
                      </label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center mb-1">
                    <div className="form-check form-switch theme-rtl">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-rtl"
                        onChange={rtlMode}
                      />
                      <label className="form-check-label" htmlFor="theme-rtl">
                        Enable RTL Mode!
                      </label>
                    </div>
                  </li>
                  <li className="d-flex align-items-center mb-1">
                    <div className="form-check form-switch minisidebar-active">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mini-active"
                        onChange={miniSideBar}
                      />
                      <label className="form-check-label" htmlFor="mini-active">
                        Mini Sidebar
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-2">
              <div class="card ">
                <div class="card-header custom-header py-2">
                  General Settings
                </div>
                <ul className="setting-list list-unstyled  p-2">
                  <li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault1"
                        checked={emailBtn}
                        onChange={emailNotifyHandle}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault1"
                      >
                        Email Redirect
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault2"
                        checked={notificationBtn}
                        onChange={alertNotification}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault2"
                      >
                        Notifications
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault4"
                        checked={offlineBtn}
                        onChange={offlineHandle}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault4"
                      >
                        Offline
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault5"
                        checked={locationState}
                        onChange={locationPermission}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault5"
                      >
                        Location Permission
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSetting;
