import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import "../Analytics/kpi.css";
import CryptoJS from "crypto-js";

function KeyMetrics() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Category",
        accessor: "Category",
      },
      {
        Header: "Sub Category",
        accessor: "SubCategory",
      },
      {
        Header: "KPI Measurements",
        accessor: "KPIMeasurements",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
    ],
    []
  );

  const fetchData = async () => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getkeymetrics`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setPreloaderOn(false);
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          Category: item?.Category,
          SubCategory: item?.Sub_category,
          KPIMeasurements: item?.KPI_Measurements,
          Description: item?.Description,
        }));
        setData(formattedData);
      })
      .catch(function (error) {
        setPreloaderOn(false);
        // console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Administrative Dashboard
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Key Metrics</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div
                className="card-body kpitable"
                style={{ overflowX: "scroll" }}
              >
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KeyMetrics;
