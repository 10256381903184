import axios from "axios";
import React, { useEffect, useState } from "react";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import { Link, useParams } from "react-router-dom";
import { ReactFormGenerator } from "react-form-builder2";
import { toast } from "react-toastify";
import Preloader from "../../CommonPage/Preloader";
import CryptoJS from "crypto-js";

const ClinicalDoc = () => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const tokenUser = bytes.toString(CryptoJS.enc.Utf8);
  const [formData, setFormData] = useState([]);
  const [formID, setFormID] = useState([]);
  const { dataId } = useParams();
  const [preloaderOn, setPreloaderOn] = useState(false);

  useEffect(() => {
    setPreloaderOn(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}afterlogin/getsingleclinicaldocument?id=${dataId}`,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((res) => {
        const formatted = JSON.parse(res.data.data.data);
        setFormData(formatted);
        setFormID(res.data.data);
        setPreloaderOn(false);
      })
      .catch((err) => {
        console.error(err);
        setPreloaderOn(false);
      });
  }, [dataId, tokenUser]);

  const handleSubmit = (data) => {
    const formattedData = data.map((item) => {
      const originalItem = formData.find((original) => original.id === item.id);
      return {
        ...originalItem,
        value: item.value,
      };
    });

    const payload = {
      data: formattedData,
      id: formID.id,
    };

    setPreloaderOn(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}afterlogin/addclinicaldata`,
        payload,
        {
          headers: {
            Authorization: tokenUser,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setPreloaderOn(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setPreloaderOn(false);
      });
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Clinical Document
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="#">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Clinical Document</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="card">
            <div className="card-body">
              <ReactFormGenerator
                back_name="Back"
                answer_data={{}}
                action_name="Submit"
                onSubmit={handleSubmit}
                form_method="POST"
                data={formData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicalDoc;
