import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "select2/dist/css/select2.min.css";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import _ from "lodash";
import CryptoJS from "crypto-js";

function AddAdmin() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [selectedDate, setSelectedDate] = useState(null);
  const [sexData, setSexData] = useState();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;

    return formattedDate;
  };

  const [row, setRow] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SexType: "",
    DOB: { selectedDate },
    PhoneNumber: "",
    E_mail: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    SystemLogin: "",
    CreatePassword: "",
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=sex`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setSexData(res?.data?.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleChangePost = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (name === "FirstName" || name === "MiddleName" || name === "LastName") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Accept Only Alphabets for " + name, { autoClose: 1000 });
      }
    } else if (
      name === "City" ||
      name === "County" ||
      name === "Country" ||
      name === "State"
    ) {
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
      } else {
        toast.warning("Accept Only Alphabets and Spaces for " + name, {
          autoClose: 1000,
        });
      }
    } else if (name === "ZipCode" || name === "PhoneNumber") {
      if (/^[0-9]+$/.test(value) || value === "") {
        setRow({ ...row, [name]: value });
      } else {
        toast.warning("Accept Only Numbers for " + name, { autoClose: 1000 });
      }
    } else if (name === "SystemLogin") {
      setRow({ ...row, [name]: value });
    } else if (name === "PhoneNumber" || name === "ZipCode") {
      if (isNaN(value)) {
        // Show toast error
        toast.warning(`${name} must be a number`, { autoClose: 1000 });
      } else {
        // Update the state if the value is a number
        setRow({ ...row, [name]: value });
      }
    } else {
      setRow({ ...row, [name]: value });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "E_mail") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        toast.warning("Please fill a valid email.", {
          autoClose: 1000,
        });
        setIsValidEmail(false);
        $("." + name).addClass("invalidInputData");
      } else {
        setIsValidEmail(true);
        $("." + name).removeClass("invalidInputData");
        setRow({
          ...row,
          [name]: value,
        });
      }
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (
      row.FirstName === "" ||
      row.LastName === "" ||
      row.DOB === "" ||
      row.SystemLogin === "" ||
      row.ZipCode === "" ||
      row.State === "" ||
      row.CreatePassword === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all the required details.",
      });
    } else if (row.ZipCode.toString().length < 6) {
      toast.warning("ZipCode must be between 6 to 8 digits", {
        autoClose: 1000,
      });
    } else if (row.PhoneNumber.toString().length < 10) {
      toast.warning("PhoneNumber must be of 10 digits", { autoClose: 1000 });
    } else {
      setPreloaderOn(true);

      Swal.fire({
        text: "Are you sure you want to add a New Admin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, update it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}admin/register`,
              {
                ...row,
                DOB: formatDate(selectedDate),
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              setPreloaderOn(false);
              Swal.fire({
                title: "Success!",
                text: res.data.message,
                icon: "success",
              });
              setRow({
                FirstName: "",
                MiddleName: "",
                LastName: "",
                SexType: "",
                DOB: "",
                PhoneNumber: "",
                E_mail: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                County: "",
                ZipCode: "",
                Country: "",
                SystemLogin: "",
                CreatePassword: "",
              });
            })
            .catch((error) => {
              setPreloaderOn(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: "Cancelled",
            text: "Admin information update has been cancelled.",
            icon: "error",
          });
        }
      });
    }
  };

  const handleLinkAccountKeyPress = async (e) => {
    const linkAccountValue = e.target.value;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}admin?stage=systemLogin&SystemLogin=${linkAccountValue}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const searchData = response.data.data;
      if (searchData) {
        toast.error(
          "Admin System Login is already in use with another user-admin!",
          { autoClose: 2000 }
        );
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    const form = document.getElementById("yourFormId");

    if (form) {
      Array.from(form.elements).forEach((element) => {
        if (element.tagName === "INPUT") {
          element.value = "";
        } else if (element.tagName === "SELECT") {
          element.value = "";
        }
      });
    }
  };

  const handleBlurPass = (e) => {
    const { name, value } = e.target;

    if (name === "CreatePassword") {
      if (
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,16}$/.test(
          value
        ) ||
        value === ""
      ) {
        setIsValidEmail(true);
      } else {
        toast.warning(
          "Password must be between 8 to 16 characters with at least one uppercase letter, one numeric digit, and one special character.",
          { autoClose: 1000 }
        );
        setIsValidEmail(false);
      }
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Admin Login
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add Admin</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-12">
            <form className="form-box" id="yourFormId" onSubmit={handleUpdate}>
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <h6 className="card-title">Demographic Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FirstName"
                        autoComplete="FirstName"
                        value={row.FirstName}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        autoComplete="MiddleName"
                        value={row.MiddleName}
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="LastName"
                        autoComplete="LastName"
                        value={row.LastName}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexType"
                        value={row.SexType}
                        onChange={handleChangePost}
                      >
                        <option>Select</option>
                        {sexData?.map((item, iT) => {
                          return (
                            <option key={iT} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          selected={selectedDate}
                          yearDropdownItemNumber={2000}
                          onChange={handleChange}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="MM-DD-YYYY"
                          maxDate={new Date()}
                          value={row.DOB}
                          yearDropdown
                          showYearDropdown
                          required
                          scrollableYearDropdown
                          className="form-control date"
                          aria-label="date"
                          aria-describedby="basic-addon11"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Phone Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon16">
                          <i className="fa fa-phone"></i>
                        </span>
                        <input
                          type="text"
                          name="PhoneNumber"
                          autoComplete="PhoneNumber"
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          onChange={handleChangePost}
                          max={9999999999}
                          min={10000000}
                          value={row.PhoneNumber}
                          className="form-control phone-number"
                          placeholder="Phone Number"
                          aria-label="phone-number"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        E-mail<span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon20">
                          <i className="fa fa-envelope-o"></i>
                        </span>
                        <input
                          className="form-control email E_mail"
                          placeholder="Ex: example@example.com"
                          aria-label="email"
                          aria-describedby="basic-addon20"
                          type="email"
                          name="E_mail"
                          autoComplete="E_mail"
                          value={row.E_mail}
                          onBlur={handleBlur}
                          onChange={handleChangePost}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">
                  <h6 className="card-title">Contact Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Address Line 1<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="AddressLine1"
                        autoComplete="AddressLine1"
                        value={row.AddressLine1}
                        onChange={handleChangePost}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Address Line 2</label>
                      <input
                        className="form-control"
                        type="text"
                        name="AddressLine2"
                        autoComplete="AddressLine2"
                        value={row.AddressLine2}
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        City<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="City"
                        autoComplete="City"
                        value={row.City}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        State<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="State"
                        autoComplete="State"
                        onChange={handleChangePost}
                        value={row.State}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        County<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="County"
                        autoComplete="County"
                        value={row.County}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Zip Code<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="ZipCode"
                        autoComplete="ZipCode"
                        value={row.ZipCode}
                        onChange={handleChangePost}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        max={99999999}
                        min={10000}
                        onInput={(e) => {
                          if (e.target.value.length > 8) {
                            e.target.value = e.target.value.slice(0, 8);
                          }
                        }}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Country<span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="Country"
                        autoComplete="Country"
                        value={row.Country}
                        onChange={handleChangePost}
                        className="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header ">
                  <h6 className="card-title">Admin Security</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Admin System Login
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="SystemLogin"
                        autoComplete="SystemLogin"
                        value={row.SystemLogin}
                        onChange={handleChangePost}
                        onKeyUp={handleLinkAccountKeyPress}
                        minLength={8}
                        maxLength={20}
                        onInput={(e) => {
                          if (e.target.value.length > 20) {
                            e.target.value = e.target.value.slice(0, 20);
                          }
                        }}
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Create Password
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        name="CreatePassword"
                        autoComplete="Create-Password"
                        value={row.CreatePassword}
                        onChange={handleChangePost}
                        onBlur={handleBlurPass}
                        placeholder="Example@123"
                        className="form-control"
                        minLength={8}
                        maxLength={16}
                        onInput={(e) => {
                          if (e.target.value.length > 16) {
                            e.target.value = e.target.value.slice(0, 16);
                          }
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4 text-end">
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  disabled={!isValidEmail}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAdmin;
