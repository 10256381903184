import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const ChartResmed = (props) => {
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 280,
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: [
        "var(--chart-color4)",
        "var(--chart-color1)",
        "var(--chart-color2)",
        "var(--chart-color3)",
        "var(--chart-color5)",
      ],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontSize: "12px",
      labels: "black",
    },
    xaxis: {
      tickAmount: 10,
    },
    yaxis: {
      tickAmount: 10,
    },
    tooltip: {
      theme: "dark",
    },
  });

  useEffect(() => {
    // Update series and options when props.series changes
    if (props.series) {
      setSeries([
        {
          data: props.series.map((item) => item.count),
        },
      ]);

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: props.series.map((item) => item.month),
        },
      }));
    }
  }, [props.series]);

  return (
    <ReactApexChart options={options} series={series} type="bar" height={280} />
  );
};

export default ChartResmed;
