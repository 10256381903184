import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function EditSubScription() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [data, setData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [Demographic, setDemographic] = useState({
    Schedule: "",
    eMailMessage: "",
    email: "",
    generalDescription: "",
    name: "",
    // Daily: "",
    // Weekly: "",
    // Monthly: "",
    // Quarterly: "",
    // BiAnnually: "",
    // Annually: "",
    Active: "",
    Subscription: [],
  });
  const [category, setCategory] = useState("");
  const [addressess, setAddressess] = useState([]);
  const [Subscription, setSubscription] = useState();
  const [dataUser, setDataUser] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [addressExpand, setAddressExpand] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const closeModalBox = (e) => {
    e.preventDefault();
    setDemographic({});
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    const checkboxValue = type === "checkbox" ? checked : value;

    if (name === "Active") {
      setDemographic({
        ...Demographic,
        [name]: checkboxValue,
      });
    } else {
      const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      if (name === "name") {
        if (/^[A-Za-z]+$/.test(value) || value === "") {
          setDemographic({
            ...Demographic,
            [name]: capitalizeFirstLetter(value),
          });
        } else {
          toast.warning("Only Alphabets are allowed");
        }
      } else if (name === "Schedule") {
        setDemographic({
          ...Demographic,
          Schedule: value,
        });
      } else {
        setDemographic({ ...Demographic, [name]: value });
      }
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === "DefaultEmail") {
      // Check if the value matches the email format
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        toast.warning("Please enter a valid email address.");
      }
    }
  };

  const handleRemove = (id, subId, type) => {
    // alert(type);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}subscription?subscriptionId=${subId}&groupId=${id}&type=${type}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, { autoClose: 1000 });
        axios
          .get(`${process.env.REACT_APP_BASE_URL}subscription?id=${subId}`, {
            headers: {
              Authorization: token,
            },
          })
          .then((res) => {
            // console.log(res.data.data);
            const userAccountData = res?.data?.data;
            setDemographic(userAccountData);
            setSubscription(userAccountData?.SubscriptionSecurity);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const usersRes = await axios.get(
          `${process.env.REACT_APP_BASE_URL}users?stage=completed`,
          {
            headers: { Authorization: token },
          }
        );
        setDataUser(usersRes.data.data);

        const groupRes = await axios.get(
          `${process.env.REACT_APP_BASE_URL}subscription/getGroup`,
          {
            headers: { Authorization: token },
          }
        );
        setDataGroup(groupRes.data.message);

        const templatesRes = await axios.get(
          `${process.env.REACT_APP_BASE_URL}userRole/template`,
          {
            headers: { Authorization: token },
          }
        );
        setTemplates(templatesRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchInitialData();
  }, [token]);

  const handleExpandAddress = () => {
    const defaultAddressess = [
      {
        SubscriptionTemplate: "",
        type: "",
        GroupAccount: "",
      },
    ];

    setAddressess(defaultAddressess);
    setAddressExpand(true);
    // setAddressExpand(true);
  };

  const handleExpandAddressClose = () => {
    setAddressess([
      {
        SubscriptionTemplate: "",
        GroupAccount: "",
        type: "",
      },
    ]);
    setAddressExpand(false);
  };

  const handleAddress = (e, i, cate) => {
    const { name, value } = e.target;
    if (name == "GroupAccount") {
      addressess[i] = {
        ...addressess[i],
        GroupAccount: value,
        type: cate,
      };
    } else {
      addressess[i] = {
        ...addressess[i],
        [name]: value,
      };
    }
    setAddressess([...addressess]);
  };

  const handleUserAccount = (value, i) => {
    setCategory(value);
    addressess[i] = { ...addressess[i], type: value, GroupAccount: "" };
    setAddressess([...addressess]);
  };

  const handleExpand = () => {
    const newAddress = {
      SubscriptionTemplate: "",
      GroupAccount: "",
      type: category,
    };
    setAddressess((prev) => [...prev, newAddress]);
  };

  const handleDelete = (i) => {
    const list = [...addressess];
    list.splice(i, 1);
    setAddressess(list);
  };

  const handleFinalUpdate = (e) => {
    e.preventDefault();
    setPreloaderOn(true);
    // const newData =
    //   addressess.length > 0
    //     ? addressess.map((item, i) => ({
    //         ...item,
    //       }))
    //     : [];
    const newData = addressess.map((item, i) => ({
      ...item,
    }));
    if (
      Demographic?.name == "" ||
      Demographic?.email == "" ||
      Demographic?.generalDescription == "" ||
      Demographic?.eMailMessage == "" ||
      Demographic?.Schedule == ""
    ) {
      toast.warning("Please fill the Details");
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}subscription`,
          {
            ...Demographic,
            Schedule: Demographic.Schedule,
            newData: newData.length > 0 ? newData : [],
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setOpenModal(false);
          setPreloaderOn(false);
          setAddressess([]);
          setAddressExpand(false);
          setOpenModal(false);
          fetchData();
        })
        .catch((error) => {
          console.log(error);
          setPreloaderOn(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  const handleSubmit = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}subscription?id=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("DEMODATA", response.data);
      const userAccountData = response?.data?.data;
      setDemographic(userAccountData);
      setSubscription(userAccountData?.SubscriptionSecurity);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Sub ID",
        accessor: "id",
      },
      {
        Header: "Subscription Name",
        accessor: "name",
      },
      {
        Header: "Default E-mail",
        accessor: "email",
      },
      {
        Header: "General Description",
        accessor: "generalDescription",
      },
      {
        Header: "Email Message",
        accessor: "eMailMessage",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}subscription`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          id: item?.id,
          name: item?.name,
          email: item?.email,
          generalDescription: item?.generalDescription,
          eMailMessage: item?.eMailMessage,
          status: item?.active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="edit"
                onClick={() => handleSubmit(item?.id)}
              >
                <i className="fa fa-pencil"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Subscription Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Subscription</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            openModal === true ? "custom_modal open openModal" : "custom_modal"
          }
        >
          <div className="custom_content-wrapper tw-w-[90%]">
            <button className="custom_close" onClick={closeModalBox}></button>
            <header className="custom_modal-header">
              <h2>Edit Subscription</h2>
            </header>
            <form
              className="form-box tw-w-[100%]"
              id="basic-form"
              onSubmit={handleFinalUpdate}
            >
              <div className="card">
                <div className="card-header d-flex justify-content-between align-item-center">
                  <h6 className="card-title">Subscription Information</h6>
                  <div className="d-flex tw-gap-x-4">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="Active"
                        autoComplete="Active"
                        checked={Demographic.Active}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="form-check-label" htmlFor="primary">
                        Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="g-3 d-flex">
                    <div className="tw-w-[80%]">
                      <div className="md:tw-grid tw-grid-cols-4 tw-gap-x-6 tw-gap-y-2 tw-mt-2">
                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="Subscription"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Name of Subscription{" "}
                            <span className="tw-text-red-500">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              name="name"
                              autoComplete="name"
                              value={Demographic?.name}
                              onChange={(e) => handleChange(e)}
                              className="form-control rounded-3"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="DefaultEmail"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Default Sender E-mail
                            <span className="tw-text-red-500">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              name="email"
                              autoComplete="email"
                              value={Demographic?.email}
                              onBlur={handleBlur}
                              onChange={(e) => handleChange(e)}
                              className="form-control rounded-3"
                              required
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="Description"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            General Description
                            <span className="tw-text-red-500">*</span>
                          </label>
                          <div className="">
                            <textarea
                              rows={4}
                              type="text"
                              name="generalDescription"
                              autoComplete="generalDescription"
                              value={Demographic?.generalDescription}
                              onChange={(e) => handleChange(e)}
                              required
                              className="form-control rounded-3"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="EnailMessage"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            E-mail Message
                            <span className="tw-text-red-500">*</span>
                          </label>
                          <div className="">
                            <textarea
                              rows={4}
                              required
                              type="text"
                              name="eMailMessage"
                              autoComplete="eMailMessage"
                              value={Demographic?.eMailMessage}
                              onChange={(e) => handleChange(e)}
                              className="form-control rounded-3"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tw-w-[20%] tw-ml-3">
                      <div className="tw-mt-2">
                        <p className="tw-text-medium tw-font-medium tw-leading-6 tw-text-gray-900">
                          Schedule<span className="tw-text-red-500">*</span>
                        </p>
                        <div className="c">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Daily"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "Daily"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Daily
                            </label>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Weekly"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "Weekly"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Weekly
                            </label>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Monthly"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "Monthly"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Monthly
                            </label>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Quarterly"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "Quarterly"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Quarterly
                            </label>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="BiAnnually"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "BiAnnually"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Bi Annually
                            </label>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="radio"
                              value="Annually"
                              autoComplete="Schedule"
                              onChange={(e) => handleChange(e)}
                              checked={Demographic.Schedule === "Annually"}
                              name="Schedule"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="primary"
                            >
                              Annually
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Subscription?.length > 0 &&
                Subscription?.map((item, sub) => {
                  return (
                    <>
                      <div key={sub} className="card mt-2">
                        <div className="card-header d-flex justify-content-between align-item-center">
                          <h6 className="card-title">Subscription Security</h6>
                          <span
                            className="closemoreAddress-three"
                            onClick={() =>
                              handleRemove(
                                item?.type === "user" ? item?.id : item?.id,
                                Demographic.id,
                                item?.type
                              )
                            }
                          >
                            Remove
                          </span>
                        </div>
                        <div className="md:tw-grid tw-grid-cols-4 tw-mb-3 px-3  tw-gap-x-6 tw-gap-y-2 mt-2">
                          <div className="sm:tw-col-span-2">
                            <label htmlFor="city">
                              Template Name
                              <span className="tw-text-red-500">*</span>
                            </label>
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                autoComplete="name"
                                defaultValue={item?.tempDetails?.name}
                                disabled
                                className="form-control rounded-3"
                              />
                            </div>
                          </div>

                          <div className="sm:tw-col-span-2">
                            <label htmlFor="city" className="form-check-label">
                              <span>
                                {" "}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <input
                                  type="radio"
                                  defaultChecked={
                                    // item?.Users?.length > 0 ? true : false
                                    item?.type === "user" ? true : false
                                  }
                                  className="form-check-input"
                                  disabled
                                />
                                User Account
                              </span>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              <span>
                                <input
                                  type="radio"
                                  // name={`AccountType_${i}`}
                                  // onChange={() => handleUserAccount("group", i)}
                                  defaultChecked={
                                    item?.type === "group" ? true : false
                                  }
                                  className="form-check-input tw-mr-1"
                                  disabled
                                />
                                Group Account
                              </span>
                            </label>
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                autoComplete="name"
                                defaultValue={
                                  item?.type === "user"
                                    ? `${
                                        item?.groupDetails?.id +
                                        ". " +
                                        item?.groupDetails?.FirstName +
                                        " " +
                                        item?.groupDetails?.LastName
                                      }`
                                    : `${
                                        item?.groupDetails?.id +
                                        ". " +
                                        item?.groupDetails?.GroupName
                                      }`
                                }
                                disabled
                                className="form-control rounded-3"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              <div className="tw-flex tw-justify-end tw-gap-x-2 px-2 mt-2 mb-2">
                {!addressExpand && (
                  <button
                    type="button"
                    onClick={handleExpandAddress}
                    className="btn btn-outline-success"
                  >
                    + Add More Subscription Security
                  </button>
                )}
                {addressExpand && (
                  <button
                    type="button"
                    onClick={handleExpandAddressClose}
                    className="btn btn-outline-success"
                  >
                    Close
                  </button>
                )}
              </div>

              {addressExpand && (
                <>
                  {addressess.length > 0 &&
                    addressess.map((item, i) => {
                      return (
                        <div className="card" key={i}>
                          <div className="card-header d-flex justify-content-between align-item-center">
                            <h6 className="card-title">
                              Subscription Security
                            </h6>
                          </div>
                          <div className="md:tw-grid tw-grid-cols-4 py-1 px-3 tw-gap-x-6 tw-gap-y-2 mt-2">
                            <div className="sm:tw-col-span-2">
                              <label
                                htmlFor="city"
                                className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                              >
                                Template Name
                              </label>
                              <div className="">
                                <select
                                  name="SubscriptionTemplate"
                                  value={addressess[i].SubscriptionTemplate}
                                  onChange={(e) => handleAddress(e, i)}
                                  className="form-select rounded-3"
                                >
                                  <option>Select</option>
                                  {templates?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>

                            <div className="sm:tw-col-span-2">
                              <label
                                htmlFor="city"
                                className="form-check-label tw-ml-[20px]"
                              >
                                <input
                                  type="radio"
                                  name={`AccountType_${i}`}
                                  autoComplete="user"
                                  onChange={() => handleUserAccount("user", i)}
                                  checked={addressess[i].type === "user"}
                                  className="form-check-input"
                                />
                                User Account &nbsp; &nbsp; &nbsp; &nbsp;
                                <span>
                                  <input
                                    type="radio"
                                    name={`AccountType_${i}`}
                                    onChange={() =>
                                      handleUserAccount("group", i)
                                    }
                                    checked={addressess[i].type === "group"}
                                    className="form-check-input"
                                  />
                                  Group Account
                                </span>
                              </label>
                              <div className="mt-1">
                                <select
                                  name="GroupAccount"
                                  value={addressess[i].GroupAccount}
                                  onChange={(e) =>
                                    handleAddress(e, i, category)
                                  }
                                  className="form-select rounded-3"
                                >
                                  <option>Select</option>
                                  {addressess[i].type === "user" &&
                                    dataUser?.map((item, ij) => {
                                      return (
                                        <option key={ij} value={item.id}>
                                          {item.id +
                                            ". " +
                                            item.FirstName +
                                            " " +
                                            item.LastName}
                                        </option>
                                      );
                                    })}
                                  {addressess[i].type === "group" &&
                                    dataGroup?.map((item, ijk) => {
                                      return (
                                        <option key={ijk} value={item.id}>
                                          {item.id + ". " + item.GroupName}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                          {addressess.length - 1 === i && (
                            <div className="tw-flex tw-justify-end tw-mr-3 tw-mt-5 tw-gap-x-4 mb-2">
                              <button
                                type="button"
                                onClick={() => handleExpand()}
                                className="btn btn-outline-success"
                              >
                                + Add More
                              </button>
                              {addressess.length - 1 === i && (
                                <div>
                                  <button
                                    type="button"
                                    onClick={() => handleDelete(i)}
                                    className="btn btn-outline-danger"
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </>
              )}

              <footer className="custom_modal-footer">
                <button
                  className="btn submit-btn d-inline ms-3"
                  onClick={closeModalBox}
                >
                  Cancel
                </button>
                <button className="btn submit-btn d-inline ms-3" type="submit">
                  Accept
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSubScription;
