import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import $ from "jquery";
import "datatables.net-dt/css/dataTables.dataTables.css";
import axios from "axios";
import Chart1 from "./Chart/Chart1";
import Chart4 from "./Chart/Chart4";
import Chart6 from "./Chart/Chart6";
import Chart8 from "./Chart/Chart8";
import Chart9 from "./Chart/Chart9";
import Chart14 from "./Chart/Chart14";
import Chart15 from "./Chart/Chart15";
import Chart16 from "./Chart/Chart16";
import Chart18 from "./Chart/Chart18";
import Chart22 from "./Chart/Chart22";
import Chart23 from "./Chart/Chart23";
import Chart24 from "./Chart/Chart24";
import Chart26 from "./Chart/Chart26";
import Chart28 from "./Chart/Chart28";
import Chart30 from "./Chart/Chart30";
import Chart32 from "./Chart/Chart32";
import Chart33 from "./Chart/Chart33";
import Chart35 from "./Chart/Chart35";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import "./templateCustom.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import Chart36 from "./Chart/Chart36";
import Group from "./Chart/Group";
import CryptoJS from "crypto-js";

function EditTemplate() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const [data, setData] = useState([]);
  const [rowMain, setRowMain] = useState([]);
  const [open, setOpen] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const previewBtnClick = async (id) => {
    setPreloaderOn(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}userrole/template?templateId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        setRowMain(res?.data?.data);
        setOpen(true);
      })
      .catch((error) => {
        setPreloaderOn(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}userRole/template?stage=all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const formattedData = response.data.data.map((item, index) => ({
        sno: index + 1,
        tName: item.name,
        description: item.Description,
        TemplateBackgroundTheme: (
          <span
            className="d-block tw-w-[100%] tw-h-[20px]"
            style={{
              background: `${item.TemplateBackgroundTheme}`,
            }}
          >
            {item.TemplateBackgroundTheme === "" ? "None" : ""}
          </span>
        ),
        pagetitleColorC: (
          <span
            className="d-block tw-w-[100%] tw-h-[20px]"
            style={{
              background: `${item.pagetitleColorC}`,
            }}
          >
            {item.pagetitleColorC === "" ? "None" : ""}
          </span>
        ),
        status: item.Active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">In-Active</span>
        ),
        created: convertTimestampToDateFormat(item?.createdAt),
        action: (
          <div className="action-btn-box">
            <Link to={`/edit-template-main/${item.id}`} className="edit">
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              id={previewBtnClick + item.id}
              className="view"
              onClick={() => {
                previewBtnClick(item.id);
              }}
            >
              <i className="fa fa-eye"></i>
            </Link>
          </div>
        ),
      }));
      setData(formattedData);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Template Name",
        accessor: "tName",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Template BG. Color",
        accessor: "TemplateBackgroundTheme",
      },
      {
        Header: "Title BG. Color",
        accessor: "pagetitleColorC",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const closeModalBox = () => {
    setOpen(false);
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Template Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Template List</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck mt-3">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          open === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%] p-4 previewChartModal">
          <button className="custom_close" onClick={closeModalBox}></button>
          <header className="custom_modal-header">
            <h2>Preview Template</h2>
          </header>
          <div className="modalBody tw-w-full">
            <div
              className="card"
              style={{ background: `${rowMain.TemplateBackgroundTheme}` }}
            >
              <div
                className="card-header"
                style={{ background: `${rowMain.pagetitleColorC}` }}
              >
                <div className="row">
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pageTitlel,
                      }}
                    ></span>
                  </div>
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pageTitlec,
                      }}
                    ></span>
                  </div>
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pageTitler,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {rowMain?.templateContainers?.map(
                  (container, containerIndex) => (
                    <div
                      key={containerIndex}
                      id={"container_" + containerIndex}
                      className="card mb-3"
                      style={{ background: "transparent" }}
                    >
                      <div className="card-body d-flex flex-wrap relative containersBodysPeview p-0">
                        {container?.containerHeaders?.map((item3, hti) => (
                          <>
                            {item3?.position === "top" && (
                              <div
                                key={hti}
                                className="mb-2 ql-editor boxHeadingContainer"
                                style={{
                                  width: `${item3.width}%`,
                                  backgroundColor: item3.HeadlineBackground,
                                }}
                              >
                                <span
                                  className="boxHeading"
                                  dangerouslySetInnerHTML={{
                                    __html: item3?.quillData,
                                  }}
                                ></span>
                              </div>
                            )}
                          </>
                        ))}
                        <span className="d-block tw-w-full"></span>
                        {container?.containerBoxes.length > 0 &&
                          container?.containerBoxes?.map((box, boxIndex) => (
                            <div
                              className="box-body mb-2 mx-1"
                              key={boxIndex}
                              style={{ width: `calc(${box.width}% - 8px)` }}
                            >
                              <div
                                className="box-header position-relative"
                                style={{
                                  background: `${box.titleBackgroundColorBG}`,
                                }}
                              >
                                <p
                                  className="mb-0"
                                  style={{
                                    color: `${box.titleBackgroundColor}`,
                                  }}
                                >
                                  {box.title || ""}
                                </p>
                              </div>
                              <div
                                className="chart-box"
                                style={{
                                  height: `${box.height}px`,
                                  width: "100%",
                                }}
                              >
                                {box.boxChartType === "Chart1" ? (
                                  <div className="chartOrTableType Chart1">
                                    <Chart1
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      Color={JSON.parse(box?.Color)}
                                      DataLabels={box?.DataLabels}
                                      LegendDisplay={box?.LegendDisplay}
                                      LegendTextColor={box?.LegendTextColor}
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart4" ? (
                                  <div className="chartOrTableType Chart4">
                                    <Chart4
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      DataLabels={box?.DataLabels}
                                      Color={JSON.parse(box?.Color)}
                                      LegendTextColor={box?.LegendTextColor}
                                      LegendDisplay={box?.LegendDisplay}
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart6" ? (
                                  <div className="chartOrTableType Chart6">
                                    <Chart6
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      DataLabels={box?.DataLabels}
                                      Color={JSON.parse(box?.Color)}
                                      LegendTextColor={box?.LegendTextColor}
                                      LegendDisplay={box?.LegendDisplay}
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart8" ? (
                                  <div className="chartOrTableType Chart8">
                                    <Chart8
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart9" ? (
                                  <div className="chartOrTableType Chart9">
                                    <Chart9
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart14" ? (
                                  <div className="chartOrTableType Chart14">
                                    <Chart14
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart15" ? (
                                  <div className="chartOrTableType Chart15">
                                    <Chart15
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      Stacked={
                                        box?.Stacked === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart16" ? (
                                  <div className="chartOrTableType Chart16">
                                    <Chart16
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart18" ? (
                                  <div className="chartOrTableType Chart18">
                                    <Chart18
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      Stacked={
                                        box?.Stacked === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart22" ? (
                                  <div className="chartOrTableType Chart22">
                                    <Chart22
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      Stacked={
                                        box?.Stacked === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart23" ? (
                                  <div className="chartOrTableType Chart23">
                                    <Chart23
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart24" ? (
                                  <div className="chartOrTableType Chart24">
                                    <Chart24
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      Stacked={
                                        box?.Stacked === 1 ? true : false
                                      }
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart26" ? (
                                  <div className="chartOrTableType Chart26">
                                    <Chart26
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}

                                {box.boxChartType === "Chart28" ? (
                                  <div className="chartOrTableType Chart28">
                                    <Chart28
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart30" ? (
                                  <div className="chartOrTableType Chart30">
                                    <Chart30
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart32" ? (
                                  <div className="chartOrTableType Chart32">
                                    <Chart32
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      Stacked={
                                        box?.Stacked === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart33" ? (
                                  <div className="chartOrTableType Chart33">
                                    <Chart33
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart35" ? (
                                  <div className="chartOrTableType Chart35">
                                    <Chart35
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Chart36" ? (
                                  <div className="chartOrTableType Chart36">
                                    <Chart36
                                      position={box?.position}
                                      BackgroundColor={box?.BackgroundColor}
                                      FontSize={box?.FontSize}
                                      YAxis={box?.YAxis}
                                      XAxis={box?.XAxis}
                                      Color={JSON.parse(box?.Color)}
                                      LegendDisplay={box?.LegendDisplay}
                                      gridX={box?.gridX}
                                      gridY={box?.gridY}
                                      axisX={box?.axisX}
                                      axisY={box?.axisY}
                                      TextColor={box?.textColor}
                                      PeakValueC={box?.PeakValueC}
                                      TargetValueLineColor={
                                        box.TargetValueLineColor
                                      }
                                      LableTextColorTarget={
                                        box.LableTextColorTarget
                                      }
                                      FontSizeTarget={box?.FontSizeTarget}
                                      TargetValue={box?.TargetValue}
                                      LowestValueC={box?.LowestValueC}
                                      TargetValueTitle={box?.TargetValueTitle}
                                      PeakValueTitle={box?.PeakValueTitle}
                                      LowestValueTitle={box?.LowestValueTitle}
                                      Tooltip={box?.Tooltip}
                                      TargetDisplay={
                                        box?.TargetDisplay === 1 ? true : false
                                      }
                                      PeakDisplay={
                                        box?.PeakDisplay === 1 ? true : false
                                      }
                                      LowestDisplay={
                                        box?.LowestDisplay === 1 ? true : false
                                      }
                                      LowestLineDisplay={
                                        box?.LowestLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      TargetLineDisplay={
                                        box?.TargetLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PeakLineDisplay={
                                        box?.PeakLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      FontSizeLowest={box?.FontSizeLowest}
                                      FontSizePeak={box?.FontSizePeak}
                                      PositionLowestTB={box?.PositionLowestTB}
                                      PositionLowestLR={box?.PositionLowestLR}
                                      PositionTargetTB={box?.PositionTargetTB}
                                      PositionTargetLR={box?.PositionTargetLR}
                                      PositionPeakLR={box?.PositionPeakLR}
                                      PositionPeakTB={box?.PositionPeakTB}
                                      DataLabels={box?.DataLabels}
                                      LebalX={box?.LebalX === 1 ? true : false}
                                      LebalY={box?.LebalY === 1 ? true : false}
                                      AverageDisplay={
                                        box?.AverageDisplay === 1 ? true : false
                                      }
                                      AverageLineDisplay={
                                        box?.AverageLineDisplay === 1
                                          ? true
                                          : false
                                      }
                                      PositionAverageLR={box?.PositionAverageLR}
                                      PositionAverageTB={box?.PositionAverageTB}
                                      AverageValueTitle={box?.AverageValueTitle}
                                      AverageValueC={box?.AverageValueC}
                                      FontSizeAverage={box?.FontSizeAverage}
                                      MovingDisplay={box?.MovingDisplay}
                                      MovingValueC={box?.MovingValueC}
                                      LavelYColor={box?.LavelYColor}
                                      LavelXColor={box?.LavelXColor}
                                      StrokeLine={box?.StrokeLine}
                                      LineTpyeSD={box?.LineTpyeSD}
                                      LineWidth={box?.LineWidth}
                                      LegendTextColor={box?.LegendTextColor}
                                      ValuePeak={
                                        box?.ValuePeak === 1 ? true : false
                                      }
                                      ValueTarget={
                                        box?.ValueTarget === 1 ? true : false
                                      }
                                      ValueLowest={
                                        box?.ValueLowest === 1 ? true : false
                                      }
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {box.boxChartType === "Group" ? (
                                  <div className="chartOrTableType Group">
                                    <Group
                                      category={box.boxCategoryValue}
                                      subcategory={box.boxSubCategoryValue}
                                      metrics={box.boxMetricsValue}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        <span className="d-block tw-w-[100%]"></span>
                        {container?.containerHeaders?.map((item3, hbIndex) => (
                          <>
                            {item3?.position === "bottom" && (
                              <div
                                key={hbIndex}
                                className="ql-editor boxHeadingContainer"
                                style={{
                                  width: `${item3.width}%`,
                                  backgroundColor: item3.HeadlineBackground,
                                }}
                              >
                                <span
                                  className="boxHeading"
                                  dangerouslySetInnerHTML={{
                                    __html: item3?.quillData,
                                  }}
                                ></span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTemplate;
