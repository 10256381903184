import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function RejectedRequest() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [records, setRecords] = useState([]);
  const [modalData, setModalData] = useState();
  const [open1, setOpen1] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const fetchData = async () => {
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users?stage=rejected`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        setPreloaderOn(false);
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          request: new Date(item?.createdAt).toLocaleDateString("en-US"),
          name:
            item?.FirstName +
            " " +
            item?.MiddleName +
            " " +
            item?.LastName +
            " ",
          phone: item?.PhoneNumber,
          email: item?.E_Mail,
          system: item?.Title_Type?.type,
          action: (
            <div className="action-btn-box d-flex tw-justify-center tw-items-center">
              <Link
                to=""
                className="view"
                onClick={() => handleClick(item?.id)}
              >
                <i className="fa fa-eye"></i>
              </Link>
            </div>
          ),
        }));
        setRecords(formattedData);
      })
      .catch(function (error) {
        // handle error
        setPreloaderOn(false);
        console.log(error);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Request Date",
        accessor: "request",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Phone No",
        accessor: "phone",
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Title",
        accessor: "system",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}users?stage=newRequest&UserId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Update state here
      setModalData(response.data.data);
      setOpen1(true);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                User Access Request
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Rejected Request</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={records} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          open1 === true ? "custom_modal open openModal" : "custom_modal"
        }
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button
            className="custom_close"
            onClick={() => setOpen1(false)}
          ></button>
          <header className="custom_modal-header">
            <h2>Rejected</h2>
          </header>

          {/* <form> */}
          <form className="form-box w-100" id="basic-form">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-item-center">
                <h6 className="card-title">Form</h6>
              </div>
              {open1 && (
                <div className="card-body">
                  <div className="md:tw-grid tw-grid-cols-8 tw-px-5 tw-gap-x-6 tw-gap-y-2 tw-mt-2">
                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="FirstName"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        First Name :
                      </label>
                      <div className="">
                        <input
                          id="FirstName"
                          name="FirstName"
                          autoComplete="FirstName"
                          defaultValue={modalData?.FirstName || "NA"}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="FirstName"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Middle Name :
                      </label>
                      <div className="">
                        <input
                          id="FirstName"
                          name="FirstName"
                          autoComplete="FirstName"
                          defaultValue={modalData?.MiddleName || "NA"}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="FirstName"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Last Name :
                      </label>
                      <div className="">
                        <input
                          id="FirstName"
                          name="FirstName"
                          autoComplete="FirstName"
                          defaultValue={modalData?.LastName || "NA"}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="FirstName"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Sex:
                      </label>
                      <div className="">
                        <input
                          id="FirstName"
                          name="FirstName"
                          autoComplete="FirstName"
                          defaultValue={modalData?.Sex_Type?.type || "NA"}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="FirstName"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Date of Birth :
                      </label>
                      <div className="">
                        <input
                          id="FirstName"
                          name="FirstName"
                          autoComplete="FirstName"
                          defaultValue={modalData?.DOB || "NA"}
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    {modalData?.Demographic && (
                      <>
                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="city"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            NPI Number :
                          </label>
                          <div className="">
                            <input
                              name="NPINumber"
                              autoComplete="FirstName"
                              defaultValue={modalData?.NPINumber || "NA"}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="city"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Medical License Number :
                          </label>
                          <div className="">
                            <input
                              name="MedicalLicenseNumber"
                              autoComplete="FirstName"
                              defaultValue={
                                modalData?.MedicalLicenseNumber || "NA"
                              }
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="city"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Title :
                          </label>
                          <div className="">
                            <input
                              name="TitleType"
                              autoComplete="FirstName"
                              defaultValue={modalData?.Title_Type?.type || "NA"}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="tw-col-span-2">
                          <label
                            htmlFor="country"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Provider Type :
                          </label>
                          <div className="">
                            <input
                              name="ProviderType"
                              autoComplete="FirstName"
                              defaultValue={
                                modalData?.Provider_Type?.type || "NA"
                              }
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="city"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Speciality :
                          </label>
                          <div className="">
                            <input
                              name="SpecialityType"
                              autoComplete="FirstName"
                              defaultValue={
                                modalData?.Speciality_Type?.type || "NA"
                              }
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="country"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Preferred Communication
                          </label>
                          <div className="">
                            <input
                              name="CommunicationType"
                              autoComplete="FirstName"
                              defaultValue={
                                modalData?.Communication_Type?.type || "NA"
                              }
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="sm:tw-col-span-2">
                          <label
                            htmlFor="city"
                            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                          >
                            Practice Name :
                          </label>
                          <div className="">
                            <input
                              name="PracticeName"
                              autoComplete="FirstName"
                              defaultValue={modalData?.PracticeName || "NA"}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Address Line 1 :
                      </label>
                      <div className="">
                        <input
                          name="AddressLine1"
                          defaultValue={modalData?.AddressLine1 || "NA"}
                          autoComplete="FirstName"
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Address Line 2 :
                      </label>
                      <div className="">
                        <input
                          name="AddressLine2"
                          defaultValue={modalData?.AddressLine2 || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        City :
                      </label>
                      <div className="">
                        <input
                          name="City"
                          defaultValue={modalData?.City || "NA"}
                          autoComplete="FirstName"
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Zip Code :
                      </label>
                      <div className="">
                        <input
                          name="ZipCode"
                          defaultValue={modalData?.ZipCode || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        County :
                      </label>
                      <div className="">
                        <input
                          name="County"
                          defaultValue={modalData?.County || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Country :
                      </label>
                      <div className="">
                        <input
                          name="Country"
                          defaultValue={modalData?.Country || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Phone Number :
                      </label>
                      <div className="">
                        <input
                          name="PhoneNumber"
                          defaultValue={modalData?.PhoneNumber || "NA"}
                          autoComplete="FirstName"
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Fax Number :
                      </label>
                      <div className="">
                        <input
                          name="FaxNumber"
                          defaultValue={modalData?.FaxNumber || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        E-mail :
                      </label>
                      <div className="">
                        <input
                          name="E_Mail"
                          defaultValue={modalData?.E_Mail || "NA"}
                          autoComplete="FirstName"
                          disabled
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="sm:tw-col-span-2">
                      <label
                        htmlFor="city"
                        className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-900"
                      >
                        Preferred Contact :
                      </label>
                      <div className="">
                        <input
                          name="PreferredContact"
                          defaultValue={modalData?.PreferredContact || "NA"}
                          disabled
                          autoComplete="FirstName"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="flex justify-end gap-6 pb-5 mt-5 w-[100%]">
              <div className="">
                <Button onClick={() => handleApproved(modalData.id)} variant="outlined" color="success">
                  Approved
                </Button>
              </div>
              <div className="">
                <Button onClick={() => handleReject(modalData.id)} variant="outlined" color="error">
                  Reject
                </Button>
              </div>
            </div> */}
            </div>
            {/* </form> */}
          </form>
        </div>
      </div>
    </>
  );
}

export default RejectedRequest;
