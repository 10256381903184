import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import "../Authentication/signup.css";
import Preloader from "../CommonPage/Preloader";

function Signup() {
  const [providerList, setProviders] = useState();
  const [sexList, setSex] = useState();
  const [titleList, setTitle] = useState();
  const [specialityTypeList, setSpecialityType] = useState();
  const [communicationTypeList, setCommunicationType] = useState();
  const [referralSourceList, setReferralSource] = useState();
  const [disabledTrue, setDisabledTrue] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [signupData, setSignup] = useState({
    ProviderAccount: false,
    UserAccount: false,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SexType: "",
    DOB: { selectedDate },
    NPINumber: "",
    MedicalLicenseNumber: "",
    TitleType: "",
    ProviderType: "",
    SpecialityType: "",
    ReferralSource: "",
    CommunicationType: "",
    Shared: "",
    PracticeName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    County: "",
    ZipCode: "",
    Country: "",
    PhoneNumber: "",
    PreferredContact: "",
    FaxNumber: "",
    E_Mail: "",
  });
  const [validationState, setValidationState] = useState({
    NPINumber: null,
    MedicalLicenseNumber: null,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=provider`)
      .then((response) => {
        // console.log(response.data.data);
        setProviders(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=sex`)
      .then((response) => {
        // console.log(response.data.data);
        setSex(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=speciality`)
      .then((response) => {
        // console.log(response.data.data);
        setSpecialityType(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=title`)
      .then((response) => {
        // console.log(response.data.data);
        setTitle(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=communication`)
      .then((response) => {
        // console.log(response.data.data);
        setCommunicationType(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=referralSource`)
      .then((response) => {
        // console.log(response.data.data);
        setReferralSource(response.data.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(signupData.E_Mail)) {
      toast("Please enter a valid email address");
    } else if (signupData.FirstName === "") {
      toast.error("Please provide a value for First Name");
    } else if (signupData.DOB === "") {
      toast.error("Please enter your Date of Birth");
    } else if (
      signupData.ProviderAccount === true ||
      signupData.UserAccount === true
    ) {
      if (signupData.ProviderAccount === true) {
        if (
          signupData.NPINumber === "" ||
          signupData.MedicalLicenseNumber === "" ||
          signupData.PracticeName === ""
        ) {
          toast.error("Please fill in all the required fields");
        } else {
          setPreloaderOn(true);
          axios
            .post(`${process.env.REACT_APP_BASE_URL}users/newrequest`, {
              ...signupData,
              DOB: formatDate(selectedDate),
            })
            .then((res) => {
              setPreloaderOn(false);
              toast.success(res?.data?.message);
              setSignup({
                ProviderAccount: false,
                UserAccount: false,
                FirstName: "",
                MiddleName: "",
                LastName: "",
                SexType: "",
                DOB: { selectedDate },
                NPINumber: "",
                MedicalLicenseNumber: "",
                TitleType: "",
                ProviderType: "",
                SpecialityType: "",
                ReferralSource: "",
                CommunicationType: "",
                Shared: "",
                PracticeName: "",
                AddressLine1: "",
                AddressLine2: "",
                City: "",
                State: "",
                County: "",
                ZipCode: "",
                Country: "",
                PhoneNumber: "",
                PreferredContact: "",
                FaxNumber: "",
                E_Mail: "",
              });
            })
            .catch((err) => {
              toast.error(err?.response?.data?.message);
              setPreloaderOn(false);
            });
        }
      } else {
        setPreloaderOn(true);
        axios
          .post(`${process.env.REACT_APP_BASE_URL}users/newrequest`, {
            ...signupData,
            DOB: formatDate(selectedDate),
          })
          .then((res) => {
            setPreloaderOn(false);
            toast.success(res?.data?.message);
            setSignup({
              ProviderAccount: false,
              UserAccount: false,
              FirstName: "",
              MiddleName: "",
              LastName: "",
              SexType: "",
              DOB: { selectedDate },
              NPINumber: "",
              MedicalLicenseNumber: "",
              TitleType: "",
              ProviderType: "",
              SpecialityType: "",
              ReferralSource: "",
              CommunicationType: "",
              Shared: "",
              PracticeName: "",
              AddressLine1: "",
              AddressLine2: "",
              City: "",
              State: "",
              County: "",
              ZipCode: "",
              Country: "",
              PhoneNumber: "",
              PreferredContact: "",
              FaxNumber: "",
              E_Mail: "",
            });
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            setPreloaderOn(false);
          });
      }
    } else {
      toast.error("Please select a user role!");
    }
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "FirstName" || name === "MiddleName" || name === "LastName") {
      const re = /^[A-Za-z]+$/;
      if (value === "" || re.test(value)) {
        setSignup({ ...signupData, [name]: value });
      } else {
        toast.error("Invalid Input: Enter Alphabet only!");
      }
    } else if (
      name === "PracticeName" ||
      name === "City" ||
      name === "State" ||
      name === "County" ||
      name === "Country"
    ) {
      const res = /^[A-Za-z /s]+$/;
      if (value === "" || res.test(value)) {
        setSignup({ ...signupData, [name]: value });
      } else {
        toast.error("Invalid Input: Only Accept Alphabet and space");
      }
    } else if (
      name === "FaxNumber" ||
      name === "PreferredContact" ||
      name === "PhoneNumber" ||
      name === "ZipCode" ||
      name === "NPINumber"
    ) {
      const red = /^[0-9]+$/;
      if (value === "" || red.test(value)) {
        setSignup({ ...signupData, [name]: value });
      } else {
        toast.error("Invalid Input: Enter Numeric value only!");
      }
    } else if (name === "ProviderAccount") {
      setSignup({ ...signupData, [name]: checked });
    } else if (name === "UserAccount") {
      setSignup({ ...signupData, [name]: checked });
    } else if (name === "Shared") {
      setSignup({ ...signupData, [name]: checked });
    } else {
      setSignup({ ...signupData, [name]: value });
    }
  };
  const handleChangeDOB = (date) => {
    setSelectedDate(date);
  };
  const handleChangeNumber = (e) => {
    if (signupData.PhoneNumber !== signupData.PreferredContact) {
      setDisabledTrue(false);
    } else {
      toast.error("Enter Different Number!");
      setDisabledTrue(true);
    }
  };
  const handleChangeEmail = (e) => {
    const { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      setDisabledTrue(false);
    } else {
      toast.error("Invalid Email Address!");
      setDisabledTrue(true);
    }
  };
  const formatDate = (date) => {
    if (!date) {
      return "";
    } else {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}-${year}`;
      return formattedDate;
    }
  };
  const handleKeyUp = (id) => {
    if (id.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checknumber?NPINumber=${id}`
        )
        .then((response) => {
          if (response.data.message === "Proceed") {
            setValidationState((prevState) => ({
              ...prevState,
              NPINumber: true,
            }));
          } else {
            setValidationState((prevState) => ({
              ...prevState,
              NPINumber: false,
            }));
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setValidationState((prevState) => ({
            ...prevState,
            NPINumber: false,
          }));
          // toast.error(error.response.data.message);
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        NPINumber: "Invalid",
      }));
    }
  };
  const handleMLNumber = (id) => {
    if (id.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checknumber?MedicalLicenseNumber=${id}`
        )
        .then((response) => {
          if (response.data.message === "Proceed") {
            setValidationState((prevState) => ({
              ...prevState,
              MedicalLicenseNumber: true,
            }));
          } else {
            setValidationState((prevState) => ({
              ...prevState,
              MedicalLicenseNumber: false,
            }));
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setValidationState((prevState) => ({
            ...prevState,
            MedicalLicenseNumber: false,
          }));
          // toast.error(error.response.data.message);
        });
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        MedicalLicenseNumber: "Invalid",
      }));
    }
  };
  useEffect(() => {
    document.title = "sleap: Sign-up";
  }, []);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div id="app" style={{ minHeight: "100vh" }}>
        <section
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 mx-auto">
                <div className="card card-primary my-4">
                  <div className="card-header">
                    <h3 className="text-center">Request For New Account</h3>
                  </div>
                  <div className="card-body">
                    <form
                      method="POST"
                      action="#"
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <div className="row px-3 pb-1">
                        <div className="check-box-containers">
                          <div className="checkbox-wrappers">
                            <input
                              type="checkbox"
                              id="cbtest1"
                              name="ProviderAccount"
                              onChange={handleChange}
                            />
                            <label htmlFor="cbtest1" className="check-box" />
                          </div>
                          Provider Account
                        </div>
                        <div className="check-box-containers mx-3">
                          <div className="checkbox-wrappers">
                            <input
                              type="checkbox"
                              id="cbtest2"
                              name="UserAccount"
                              onChange={handleChange}
                            />
                            <label htmlFor="cbtest2" className="check-box" />
                          </div>
                          User Account
                        </div>
                      </div>
                      {signupData.UserAccount === true ||
                      signupData.ProviderAccount === true ? (
                        <>
                          <div>
                            <div className="card">
                              <div
                                className="card-header py-1"
                                style={{ background: "lightblue" }}
                              >
                                <h4 className="mb-0">
                                  Demographic Information
                                </h4>
                              </div>

                              <div className="card-body pb-0 pt-2">
                                <div className="row">
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="FirstName">
                                      First Name
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="FirstName"
                                      type="text"
                                      className="form-control"
                                      name="FirstName"
                                      onChange={handleChange}
                                      placeholder="Enter Alphabet Characters only"
                                      value={
                                        signupData.FirstName.charAt(
                                          0
                                        ).toUpperCase() +
                                        signupData.FirstName.substring(1)
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="MiddleName">
                                      Middle Name
                                    </label>
                                    <input
                                      id="MiddleName"
                                      type="text"
                                      className="form-control"
                                      name="MiddleName"
                                      placeholder="Enter Alphabet Characters only"
                                      onChange={handleChange}
                                      value={
                                        signupData.MiddleName.charAt(
                                          0
                                        ).toUpperCase() +
                                        signupData.MiddleName.substring(1)
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="LastName">
                                      Last Name
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="LastName"
                                      type="text"
                                      className="form-control"
                                      name="LastName"
                                      required
                                      placeholder="Enter Alphabet Characters only"
                                      onChange={handleChange}
                                      value={
                                        signupData.LastName.charAt(
                                          0
                                        ).toUpperCase() +
                                        signupData.LastName.substring(1)
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="frist_name">
                                      Sex<span className="asterisk">*</span>
                                    </label>
                                    <select
                                      id="sexType"
                                      type="text"
                                      className="form-select"
                                      name="SexType"
                                      onChange={handleChange}
                                      value={signupData.SexType}
                                      required
                                    >
                                      <option value="">Select</option>
                                      {sexList?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {" "}
                                            {item.Type}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="DOB" className="d-block">
                                      Date of Birth
                                      <span className="asterisk">*</span>
                                    </label>

                                    <DatePicker
                                      onChange={handleChangeDOB}
                                      selected={selectedDate}
                                      className="form-control"
                                      id="DOB"
                                      dateFormat="MM-dd-yyyy"
                                      placeholderText="MM-DD-YYYY"
                                      maxDate={new Date()}
                                      yearDropdown
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={2000}
                                      required
                                    />
                                  </div>
                                  {signupData.ProviderAccount === true ? (
                                    <>
                                      <div className="col-sm-6 col-md-4 col-lg-3">
                                        <label className="form-label">
                                          NPI Number
                                          <span className="AsteriskSymbol">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            validationState.NPINumber === true
                                              ? "is-valid"
                                              : validationState.NPINumber ===
                                                false
                                              ? "is-invalid"
                                              : validationState.NPINumber ===
                                                "Invalid"
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name="NPINumber"
                                          value={signupData.NPINumber}
                                          required
                                          onChange={handleChange}
                                          onBlur={() =>
                                            handleKeyUp(signupData.NPINumber)
                                          }
                                          maxLength={15}
                                          minLength={5}
                                          style={{
                                            WebkitAppearance: "none",
                                            MozAppearance: "textfield",
                                          }}
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                        {validationState.NPINumber === false ? (
                                          <div className="invalid-feedback">
                                            NPI Number Already Taken.
                                          </div>
                                        ) : (
                                          <div className="invalid-feedback">
                                            NPI Number Invalid.
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-4 col-lg-3">
                                        <label className="form-label">
                                          Medical License Number
                                          <span className="AsteriskSymbol">
                                            *
                                          </span>
                                        </label>
                                        <input
                                          className={`form-control ${
                                            validationState.MedicalLicenseNumber ===
                                            true
                                              ? "is-valid"
                                              : validationState.MedicalLicenseNumber ===
                                                false
                                              ? "is-invalid"
                                              : validationState.MedicalLicenseNumber ===
                                                "Invalid"
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          name="MedicalLicenseNumber"
                                          value={
                                            signupData.MedicalLicenseNumber
                                          }
                                          required
                                          onChange={handleChange}
                                          maxLength={15}
                                          minLength={10}
                                          onBlur={() =>
                                            handleMLNumber(
                                              signupData.MedicalLicenseNumber
                                            )
                                          }
                                          onInput={(e) => {
                                            if (e.target.value.length > 15) {
                                              e.target.value =
                                                e.target.value.slice(0, 15);
                                            }
                                          }}
                                        />
                                        <div className="valid-feedback">
                                          Looks good!
                                        </div>
                                        {validationState.MedicalLicenseNumber ===
                                        false ? (
                                          <div className="invalid-feedback">
                                            Medical License Number Already
                                            Taken.
                                          </div>
                                        ) : (
                                          <div className="invalid-feedback">
                                            Medical License Number Invalid.
                                          </div>
                                        )}
                                      </div>
                                      <div className="form-group col-xl-3 col-lg-4 col-sm-6  ">
                                        <label htmlFor="TitleType">Title</label>
                                        <select
                                          id="TitleType"
                                          type="text"
                                          className="form-select"
                                          name="TitleType"
                                          value={signupData.TitleType}
                                          onChange={handleChange}
                                        >
                                          <option>Select</option>
                                          {titleList?.map((item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {" "}
                                                {item.Type}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div className="form-group col-xl-3 col-lg-4 col-sm-6 ">
                                        <label htmlFor="ProviderType">
                                          Provider Type
                                        </label>
                                        <select
                                          id="ProviderType"
                                          type="text"
                                          className="form-select"
                                          name="ProviderType"
                                          onChange={handleChange}
                                          value={signupData.ProviderType}
                                        >
                                          <option>Select</option>
                                          {providerList?.map((item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {" "}
                                                {item.Type}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                        <label htmlFor="SpecialityType">
                                          Speciality
                                        </label>
                                        <select
                                          id="SpecialityType"
                                          type="text"
                                          className="form-select"
                                          name="SpecialityType"
                                          onChange={handleChange}
                                          value={signupData.SpecialityType}
                                        >
                                          <option>Select</option>
                                          {specialityTypeList?.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {" "}
                                                  {item.Type}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      <div className="form-group col-xl-3 col-lg-4 col-sm-6 ">
                                        <label htmlFor="ReferralSource">
                                          Referral Source
                                        </label>
                                        <select
                                          id="CommunicationType"
                                          type="text"
                                          className="form-select"
                                          value={signupData.ReferralSource}
                                          name="ReferralSource"
                                          onChange={handleChange}
                                        >
                                          <option>Select</option>
                                          {referralSourceList?.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {" "}
                                                  {item.Type}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      <div className="form-group col-xl-3 col-lg-4 col-sm-6  ">
                                        <label htmlFor="CommunicationType">
                                          Preferred Communication
                                        </label>
                                        <select
                                          id="CommunicationType"
                                          type="text"
                                          className="form-select"
                                          name="CommunicationType"
                                          onChange={handleChange}
                                          value={signupData.CommunicationType}
                                        >
                                          <option>Select</option>
                                          {communicationTypeList?.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {" "}
                                                  {item.Type}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="card ">
                              <div
                                className="card-header py-1"
                                style={{ background: "lightblue" }}
                              >
                                <div className="div">
                                  <h4 className="mb-0">Contact Information</h4>
                                </div>
                              </div>
                              <div className="card-body pb-0 pt-2">
                                <div className="row px-0">
                                  <div className="check-box-containers">
                                    <div className="checkbox-wrappers">
                                      <input
                                        id="cbtest3"
                                        type="checkbox"
                                        name="Shared"
                                        onChange={handleChange}
                                      />
                                      <label
                                        htmlFor="cbtest3"
                                        className="check-box"
                                      />
                                    </div>
                                    Shared
                                  </div>
                                </div>
                                <div className="row">
                                  {signupData.ProviderAccount === true ? (
                                    <div className="form-group col-xl-3 col-lg-4 col-sm-6 ">
                                      <label htmlFor="PracticeName">
                                        Practice Name
                                        <span className="asterisk">*</span>
                                      </label>
                                      <input
                                        id="PracticeName"
                                        type="text"
                                        className="form-control"
                                        name="PracticeName"
                                        onChange={handleChange}
                                        value={signupData.PracticeName}
                                        placeholder="Enter Alphabet Characters with space only"
                                        required
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="AddressLine1">
                                      Address Line 1
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="AddressLine1"
                                      type="text"
                                      className="form-control"
                                      name="AddressLine1"
                                      onChange={handleChange}
                                      value={signupData.AddressLine1}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="AddressLine2">
                                      Address Line 2
                                    </label>
                                    <input
                                      id="AddressLine2"
                                      type="text"
                                      className="form-control"
                                      name="AddressLine2"
                                      onChange={handleChange}
                                      value={signupData.AddressLine2}
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="City">
                                      City<span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="City"
                                      type="text"
                                      className="form-control"
                                      name="City"
                                      placeholder="Enter Alphabet Characters with space only"
                                      onChange={handleChange}
                                      value={signupData.City}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="State">
                                      State<span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="State"
                                      type="text"
                                      className="form-control"
                                      name="State"
                                      placeholder="Enter Alphabet Characters with space only"
                                      onChange={handleChange}
                                      value={signupData.State}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="County">
                                      County
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="County"
                                      type="text"
                                      className="form-control"
                                      name="County"
                                      placeholder="Enter Alphabet Characters with space only"
                                      onChange={handleChange}
                                      value={signupData.County}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="ZipCode">
                                      Zip Code
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="ZipCode"
                                      type="text"
                                      className="form-control"
                                      name="ZipCode"
                                      value={signupData.ZipCode}
                                      onChange={handleChange}
                                      maxLength={8}
                                      minLength={5}
                                      required
                                    />
                                  </div>

                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="Country">
                                      Country
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="Country"
                                      type="text"
                                      className="form-control"
                                      name="Country"
                                      placeholder="Enter Alphabet Characters with space only"
                                      value={signupData.Country}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="PhoneNumber">
                                      Phone Number
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="PhoneNumber"
                                      type="text"
                                      className="form-control"
                                      name="PhoneNumber"
                                      onChange={handleChange}
                                      onBlur={handleChangeNumber}
                                      maxLength={10}
                                      placeholder="Enter Numeric value"
                                      value={signupData.PhoneNumber}
                                      minLength={10}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="PreferredContact">
                                      Alternate Contact
                                    </label>
                                    <input
                                      id="PreferredContact"
                                      type="text"
                                      className="form-control"
                                      name="PreferredContact"
                                      onChange={handleChange}
                                      onBlur={handleChangeNumber}
                                      placeholder="Enter Numeric value"
                                      value={signupData.PreferredContact}
                                      minLength={10}
                                      maxLength={10}
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="FaxNumber">
                                      Fax Number
                                      <span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="FaxNumber"
                                      type="text"
                                      className="form-control"
                                      name="FaxNumber"
                                      onChange={handleChange}
                                      value={signupData.FaxNumber}
                                      required
                                      maxLength={10}
                                      placeholder="Enter Numeric value"
                                      minLength={10}
                                    />
                                  </div>
                                  <div className="form-group col-xl-3 col-lg-4 col-sm-6">
                                    <label htmlFor="E_Mail">
                                      Email<span className="asterisk">*</span>
                                    </label>
                                    <input
                                      id="E_Mail"
                                      type="email"
                                      required
                                      className="form-control"
                                      value={signupData.E_Mail}
                                      name="E_Mail"
                                      onChange={handleChange}
                                      onBlur={handleChangeEmail}
                                    />
                                    <div className="invalid-feedback"></div>
                                  </div>
                                </div>
                                <p>
                                  * This Contact information will be primary
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="d-flex tw-justify-center ">
                              <button
                                type="submit"
                                className="submit-btn-primary"
                                style={{ maxWidth: "150px" }}
                                disabled={disabledTrue}
                              >
                                Register
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                  <div className="mb-4 text-muted text-center">
                    Already Registered? <Link to="/">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Signup;
