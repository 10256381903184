import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function AllTemplateRecords() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [preloaderOn, setPreloaderOn] = useState(false);
  // Function to convert timestamp to date format
  const convertTimestampToDateFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // State to hold template data
  const [data, setData] = useState([]);

  // Function to fetch template data from the server
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}userRole/template?stage=all`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // Format data for display in the table
      const formattedData = response.data.data.map((item, index) => ({
        sno: index + 1,
        tName: item.name,
        description: item.Description,
        status: item.Active ? (
          <span className="badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger">In-Active</span>
        ),
        created: convertTimestampToDateFormat(item?.createdAt),
        action: (
          <div className="action-btn-box">
            <Link
              to=""
              className="delete"
              onClick={() => handleDeleteClick(item.id)}
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        ),
      }));
      setData(formattedData);
    } catch (error) {
      // console.log(error);
    }
  };

  // Fetch template data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Columns definition for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Template Name",
        accessor: "tName",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created On",
        accessor: "created",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  // Function to handle delete action
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}userRole/template?id=${id}`, {
        headers: {
          Authorization: token,
        },
        data: {
          id,
        },
      })
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        {/* Header section with navigation */}
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">All Template Records</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        {/* Main content area with data table */}
        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTemplateRecords;
