import React, { useEffect, useRef, useState, version } from "react";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { ReactSortable } from "react-sortablejs";
import "./templateCustom.css";
import $ from "jquery";
import { Link, useParams } from "react-router-dom";
import Chart1 from "./Chart/Chart1";
import Chart4 from "./Chart/Chart4";
import Chart6 from "./Chart/Chart6";
import Chart8 from "./Chart/Chart8";
import Chart9 from "./Chart/Chart9";
import Chart14 from "./Chart/Chart14";
import Chart15 from "./Chart/Chart15";
import Chart16 from "./Chart/Chart16";
import Chart18 from "./Chart/Chart18";
import Chart22 from "./Chart/Chart22";
import Chart23 from "./Chart/Chart23";
import Chart24 from "./Chart/Chart24";
import Chart26 from "./Chart/Chart26";
import Chart28 from "./Chart/Chart28";
import Chart30 from "./Chart/Chart30";
import Chart32 from "./Chart/Chart32";
import Chart33 from "./Chart/Chart33";
import Chart35 from "./Chart/Chart35";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import * as htmlToImage from "html-to-image";
import Chart36 from "./Chart/Chart36";
import Group from "./Chart/Group";
import CryptoJS from "crypto-js";

// React Quill
var toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }],
  [{ align: [] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  ["table"],
  [{ script: "sub" }, { script: "super" }],
  [{ color: [] }, { background: [] }],
];

const EditTemplateSingle = () => {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [preloaderOn, setPreloaderOn] = useState(false);
  const { dataId } = useParams();
  // All type Use State
  const [Legend, setLegend] = useState();
  const [StrokeLine, setStrokeLine] = useState();
  const [Stacked, setStacked] = useState();
  const [DataLabels, setDataLabels] = useState();
  const [PreviewChartSelect, setPreviewChartSelect] = useState(null);
  const [LegendDisplay, setLegendDisplay] = useState(null);
  const [BackgroundColor, setBackgroundColor] = useState();
  const [LegendTextColor, setLegendTextColor] = useState();
  const [LavelYColor, setLavelYColor] = useState(null);
  const [LavelXColor, setLavelXColor] = useState(null);
  const [PeakValueC, setPeakValueC] = useState(null);
  const [LowestValueC, setLowestValueC] = useState(null);
  const [MovingValueC, setMovingValueC] = useState(null);
  const [AverageValueC, setAverageValueC] = useState(null);
  const [TargetValueLineColor, setTargetValueLineColor] = useState(null);
  const [LableTextColorTarget, setLableTextColorTarget] = useState(null);
  const [FontSizeTarget, setFontSizeTarget] = useState(null);
  const [FontSizeLowest, setFontSizeLowest] = useState(null);
  const [FontSizeAverage, setFontSizeAverage] = useState(null);
  const [FontSizePeak, setFontSizePeak] = useState(null);
  const [PositionLowestTB, setPositionLowestTB] = useState(null);
  const [PositionLowestLR, setPositionLowestLR] = useState(null);
  const [PositionAverageLR, setPositionAverageLR] = useState(null);
  const [PositionAverageTB, setPositionAverageTB] = useState(null);
  const [LineTpyeSD, setLineTpyeSD] = useState(null);
  const [LineWidth, setLineWidth] = useState(null);
  const [PositionTargetTB, setPositionTargetTB] = useState(null);
  const [PositionTargetLR, setPositionTargetLR] = useState(null);
  const [PositionPeakLR, setPositionPeakLR] = useState(null);
  const [PositionPeakTB, setPositionPeakTB] = useState(null);
  const [TargetValue, setTargetValue] = useState(null);
  const [TargetValueTitle, setTargetValueTitle] = useState();
  const [PeakValueTitle, setPeakValueTitle] = useState();
  const [LowestValueTitle, setLowestValueTitle] = useState();
  const [AverageValueTitle, setAverageValueTitle] = useState();
  const [Tooltip, setTooltip] = useState();
  const [TargetDisplay, setTargetDisplay] = useState();
  const [PeakDisplay, setPeakDisplay] = useState();
  const [AverageDisplay, setAverageDisplay] = useState();
  const [LowestDisplay, setLowestDisplay] = useState();
  const [MovingDisplay, setMovingDisplay] = useState();
  const [LowestLineDisplay, setLowestLineDisplay] = useState();
  const [AverageLineDisplay, setAverageLineDisplay] = useState();
  const [TargetLineDisplay, setTargetLineDisplay] = useState();
  const [PeakLineDisplay, setPeakLineDisplay] = useState();
  const [FontSize, setFontSize] = useState(null);
  const [YAxis, setYAxis] = useState();
  const [Color, setColor] = useState([]);
  const [XAxis, setXAxis] = useState();
  const [gridY, setGridY] = useState();
  const [LebalX, setLebalX] = useState();
  const [LebalY, setLebalY] = useState();
  const [boxTitleColor, setBoxTitleColor] = useState("#000000");
  const [BoxTitleColorBG, setBoxTitleColorBG] = useState("#ffffff");
  const [boxTitle, setBoxTitle] = useState();
  const [gridX, setGridX] = useState();
  const [axisX, setAxisX] = useState(true);
  const [axisY, setAxisY] = useState(true);
  const [textColor, setTextColor] = useState();
  const [containers, setContainers] = useState([]);
  const [ValuePeak, setValuePeak] = React.useState();
  const [ValueTarget, setValueTarget] = React.useState();
  const [ValueLowest, setValueLowest] = React.useState();

  // For modal 1
  const [modalData, setModalData] = useState(null);
  const [modalCData, setModalCData] = useState(null);
  const [modalBData, setModalBData] = useState(null);

  // KPI
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [categoryValue, setCategoryValue] = useState(null);
  const [subCategoryValue, setSubCategoryValue] = useState(null);
  const [metricsValue, setMetricsValue] = useState(null);
  const [chartTypeData, setChartTypeData] = useState(null);
  const [chartType, setChartType] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [TemplateCat, setTemplateCat] = useState([]);
  const [rowMain, setRowMain] = useState({
    TemplateName: "",
    Description: "",
    TemplateBackgroundTheme: "",
    TemplateCat: "",
    pagetitleColorC: "",
    pagetitlec: "",
    pagetitlel: "",
    pagetitler: "",
    tempId: "",
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}types?type=template&category=all`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setTemplateCat(res?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  // All type Onclick Event
  // Add Container Event
  const addContainer = () => {
    setContainers([...containers, { boxes: [], theadbox: [], bheadbox: [] }]);
  };
  // Add Box in container

  const addBox = (containerIndex) => {
    const updatedContainers = [...containers];
    updatedContainers[containerIndex].boxes.push({});
    setContainers(updatedContainers);
  };
  // Add heading in Top

  const addHeadlineTop = (containerIndex) => {
    const updatedContainers = [...containers];
    updatedContainers[containerIndex].theadbox.push({});
    setContainers(updatedContainers);
  };

  // Add heading in Bottom
  const addHeadlineBottom = (containerIndex) => {
    const updatedContainers = [...containers];
    updatedContainers[containerIndex].bheadbox.push({});
    setContainers(updatedContainers);
  };

  // Remove Content
  const removeContent = (i) => {
    const newArr = containers.filter((item, index) => index !== i);
    setContainers(newArr);
  };

  // Open Chart for Add new or replace Chart
  const openChart = (boxId, c, b) => {
    setModalData(boxId);
    setModalCData(c);
    setModalBData(b);
    setOpen(true);
    setCategoryValue("");
    setSubCategoryValue("");
    setMetricsValue("");
    setChartTypeData("");
  };

  const openrChart = (boxId, c, b) => {
    setModalData(boxId);
    setModalCData(c);
    setModalBData(b);
    setOpen(true);
    setCategoryValue(containers[c].boxes[b].boxCategoryValue);
    setSubCategoryValue(containers[c].boxes[b].boxSubCategoryValue);
    setMetricsValue(containers[c].boxes[b].boxMetricsValue);
    setChartTypeData(containers[c].boxes[b].boxChartType);
  };

  const openrChart2 = (boxId, cid, bid) => {
    setModalData(boxId);
    setModalCData(cid);
    setModalBData(bid);
    setPreviewChartSelect(containers[cid]?.boxes[bid]?.boxChartType);
    setBoxTitleColor(containers[cid]?.boxes[bid]?.titleBackgroundColor);
    setBoxTitleColorBG(containers[cid]?.boxes[bid]?.titleBackgroundColorBG);
    setBoxTitle(containers[cid]?.boxes[bid]?.title);
    setLegendDisplay(containers[cid]?.boxes[bid]?.LegendDisplay || false);
    setLegend(containers[cid]?.boxes[bid]?.position || "top");
    setLegendTextColor(containers[cid]?.boxes[bid]?.LegendTextColor);
    setBackgroundColor(containers[cid]?.boxes[bid]?.BackgroundColor);
    setColor([]);
    containers[cid]?.boxes[bid]?.Color?.forEach((item, col) => {
      setColor((prevColor) => [...prevColor, item]);
    });
    setLavelYColor(containers[cid]?.boxes[bid]?.LavelYColor);
    setLavelXColor(containers[cid]?.boxes[bid]?.LavelXColor);
    setDataLabels(containers[cid]?.boxes[bid]?.DataLabels || "all");
    setLebalX(containers[cid]?.boxes[bid]?.LebalX || false);
    setLebalY(containers[cid]?.boxes[bid]?.LebalY || false);
    setAxisX(containers[cid]?.boxes[bid]?.AxisX || false);
    setAxisY(containers[cid]?.boxes[bid]?.AxisY || false);
    setXAxis(containers[cid]?.boxes[bid]?.XAxis);
    setYAxis(containers[cid]?.boxes[bid]?.YAxis);
    setFontSize(containers[cid]?.boxes[bid]?.FontSize);
    setTextColor(containers[cid]?.boxes[bid]?.TextColor);
    setGridY(containers[cid]?.boxes[bid]?.GridY || false);
    setGridX(containers[cid]?.boxes[bid]?.GridX || false);
    setTooltip(containers[cid]?.boxes[bid]?.Tooltip || false);
    setPeakDisplay(containers[cid]?.boxes[bid]?.PeakDisplay || false);
    setPeakLineDisplay(containers[cid]?.boxes[bid]?.PeakLineDisplay || false);
    setPositionPeakTB(containers[cid]?.boxes[bid]?.PositionPeakTB || 0);
    setPositionPeakLR(containers[cid]?.boxes[bid]?.PositionPeakLR || 0);
    setPeakValueTitle(containers[cid]?.boxes[bid]?.PeakValueTitle);
    setPeakValueC(containers[cid]?.boxes[bid]?.PeakValueC);
    setFontSizePeak(containers[cid]?.boxes[bid]?.FontSizePeak);
    setValuePeak(containers[cid]?.boxes[bid]?.ValuePeak || false);
    setTargetDisplay(containers[cid]?.boxes[bid]?.TargetDisplay || false);
    setTargetLineDisplay(
      containers[cid]?.boxes[bid]?.TargetLineDisplay || false
    );
    setTargetValue(containers[cid]?.boxes[bid]?.TargetValue);
    setTargetValueLineColor(containers[cid]?.boxes[bid]?.TargetValueLineColor);
    setPositionTargetTB(containers[cid]?.boxes[bid]?.PositionTargetTB);
    setPositionTargetLR(containers[cid]?.boxes[bid]?.PositionTargetLR);
    setTargetValueTitle(containers[cid]?.boxes[bid]?.TargetValueTitle);
    setFontSizeTarget(containers[cid]?.boxes[bid]?.FontSizeTarget);
    setLableTextColorTarget(containers[cid]?.boxes[bid]?.LableTextColorTarget);
    setValueTarget(containers[cid]?.boxes[bid]?.ValueTarget || false);
    setLowestDisplay(containers[cid]?.boxes[bid]?.LowestDisplay || false);
    setLowestLineDisplay(
      containers[cid]?.boxes[bid]?.LowestLineDisplay || false
    );
    setPositionLowestTB(containers[cid]?.boxes[bid]?.PositionLowestTB || 0);
    setPositionLowestLR(containers[cid]?.boxes[bid]?.PositionLowestLR || 0);
    setLowestValueTitle(containers[cid]?.boxes[bid]?.LowestValueTitle);
    setLowestValueC(containers[cid]?.boxes[bid]?.LowestValueC);
    setFontSizeLowest(containers[cid]?.boxes[bid]?.FontSizeLowest);
    setValueLowest(containers[cid]?.boxes[bid]?.ValueLowest || false);
    setAverageDisplay(containers[cid]?.boxes[bid]?.AverageDisplay || false);
    setAverageLineDisplay(
      containers[cid]?.boxes[bid]?.AverageLineDisplay || false
    );
    setPositionAverageTB(containers[cid]?.boxes[bid]?.PositionAverageTB);
    setPositionAverageLR(containers[cid]?.boxes[bid]?.PositionAverageLR);
    setAverageValueTitle(containers[cid]?.boxes[bid]?.AverageValueTitle);
    setAverageValueC(containers[cid]?.boxes[bid]?.AverageValueC);
    setFontSizeAverage(containers[cid]?.boxes[bid]?.FontSizeAverage);
    setMovingDisplay(containers[cid]?.boxes[bid]?.MovingDisplay || false);
    setMovingValueC(containers[cid]?.boxes[bid]?.MovingValueC);
    setStrokeLine(containers[cid]?.boxes[bid]?.StrokeLine || "smooth");
    setLineTpyeSD(containers[cid]?.boxes[bid]?.LineTpyeSD || "solid");
    setLineWidth(containers[cid]?.boxes[bid]?.LineWidth);
    setStacked(containers[cid]?.boxes[bid]?.Stacked || false);
    setCategoryValue(containers[cid].boxes[bid]?.boxCategoryValue);
    setSubCategoryValue(containers[cid].boxes[bid]?.boxSubCategoryValue);
    setMetricsValue(containers[cid].boxes[bid]?.boxMetricsValue);
    setOpen2(true);
  };

  // All Type of KPI
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}users/getkeymetrics?kpi=1`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        if (!category) {
          setCategory(res?.data?.message);
        }
      })
      .catch((error) => {});
  }, []);
  const handleChangeCategory = (value) => {
    setCategoryValue(value);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}users/getkeymetrics?category=${value}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setSubCategory(res?.data?.message);
      })
      .catch((error) => {});
  };
  const handleChangeSubCategory = (subvalue) => {
    setSubCategoryValue(subvalue);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}users/getkeymetrics?category=${categoryValue}&subCategory=${subvalue}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setMetrics(res?.data?.message);
      })
      .catch((error) => {});
  };
  const handleChangeMatrics = (subvalue) => {
    setMetricsValue(subvalue);
  };
  // Add Chart
  const handleChartType = () => {
    if (!category || !subCategory || !metricsValue) {
      toast.error("Please select KPI");
    } else if (chartTypeData === "") {
      toast.error("Please select any Chart or Table");
    } else {
      var chartObject = {
        boxContainerIndex: modalCData,
        boxBoxIndex: modalBData,
        boxDataId: modalData,
        boxCategoryValue: categoryValue,
        boxSubCategoryValue: subCategoryValue,
        boxMetricsValue: metricsValue,
        boxChartType: chartTypeData,
        boxChartTypeSelect: chartType,
        Color: Color,
      };
      var updatedContainers = [...containers];
      var updatedBox = {
        ...updatedContainers[modalCData].boxes[modalBData],
        ...chartObject,
      };
      updatedContainers[modalCData].boxes[modalBData] = updatedBox;
      setContainers(updatedContainers);
      setOpen(false);
      $("#" + modalData + " .addChartBox").hide();
      $("#" + modalData + " .editChartBox").show();
      $("#" + modalData + " .removeChartBox").show();
      $("#" + modalData + " .removeChartBox2").show();
    }
  };

  const takeScreenShot = async (node) => {
    try {
      setPreloaderOn(true);
      const dataURI = await htmlToImage.toJpeg(node, { quality: 0.7 });
      return dataURI;
    } catch (error) {
      console.error("Screenshot error:", error);
    } finally {
      setPreloaderOn(false);
    }
  };

  const finalSubmit = async () => {
    setPreloaderOn(true);
    try {
      const img = await takeScreenShot(ref.current);
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}userRole/template`,
          {
            ...rowMain,
            allContainer: containers,
            image: img,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          toast.success(res?.data?.message);
          setOpen3(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } catch (error) {
      toast.error(error?.response?.data?.messsage);
    } finally {
      setPreloaderOn(false);
    }
  };

  const colorSet = [
    "#ef476f",
    "#ffd166",
    "#06d6a0",
    "#118ab2",
    "#073b4c",
    "#27187e",
    "#000000",
    "#FEFEFE",
    "#6db4f2",
    "#a31c40",
  ];

  const handleSelectChart = (chartType, chartid, dataSeries) => {
    setColor([]);
    setChartTypeData(chartType);
    setChartType(chartid);
    setColor((prevColor) => {
      const newArray = [];
      for (let i = 0; i < dataSeries; i++) {
        newArray.push(colorSet[i % colorSet.length]);
      }
      return newArray;
    });
  };

  const handleEditChart = () => {
    var chartObject = {
      title: boxTitle,
      titleBackgroundColor: boxTitleColor,
      titleBackgroundColorBG: BoxTitleColorBG,
      StrokeLine: StrokeLine,
      position: Legend,
      BackgroundColor: BackgroundColor,
      FontSize: FontSize,
      YAxis: YAxis,
      XAxis: XAxis,
      Color: Color,
      LegendDisplay: LegendDisplay,
      gridX: gridX,
      gridY: gridY,
      axisX: axisX,
      axisY: axisY,
      TextColor: textColor,
      PeakValueC: PeakValueC,
      TargetValueLineColor: TargetValueLineColor,
      LableTextColorTarget: LableTextColorTarget,
      FontSizeTarget: FontSizeTarget,
      TargetValue: TargetValue,
      LowestValueC: LowestValueC,
      TargetValueTitle: TargetValueTitle,
      PeakValueTitle: PeakValueTitle,
      LowestValueTitle: LowestValueTitle,
      Tooltip: Tooltip,
      TargetDisplay: TargetDisplay,
      PeakDisplay: PeakDisplay,
      LowestDisplay: LowestDisplay,
      LowestLineDisplay: LowestLineDisplay,
      TargetLineDisplay: TargetLineDisplay,
      PeakLineDisplay: PeakLineDisplay,
      FontSizeLowest: FontSizeLowest,
      FontSizePeak: FontSizePeak,
      PositionLowestTB: PositionLowestTB,
      PositionLowestLR: PositionLowestLR,
      PositionTargetTB: PositionTargetTB,
      PositionTargetLR: PositionTargetLR,
      PositionPeakLR: PositionPeakLR,
      PositionPeakTB: PositionPeakTB,
      DataLabels: DataLabels,
      LebalX: LebalX,
      LebalY: LebalY,
      AverageDisplay: AverageDisplay,
      AverageLineDisplay: AverageLineDisplay,
      PositionAverageLR: PositionAverageLR,
      PositionAverageTB: PositionAverageTB,
      AverageValueTitle: AverageValueTitle,
      AverageValueC: AverageValueC,
      FontSizeAverage: FontSizeAverage,
      MovingDisplay: MovingDisplay,
      MovingValueC: MovingValueC,
      LavelYColor: LavelYColor,
      LavelXColor: LavelXColor,
      LineTpyeSD: LineTpyeSD,
      LineWidth: LineWidth,
      LegendTextColor: LegendTextColor,
      ValuePeak: ValuePeak,
      ValueTarget: ValueTarget,
      ValueLowest: ValueLowest,
      Stacked: Stacked,
    };
    var updatedContainers = [...containers];
    var updatedBox = {
      ...updatedContainers[modalCData].boxes[modalBData],
      ...chartObject,
    };
    updatedContainers[modalCData].boxes[modalBData] = updatedBox;
    setContainers(updatedContainers);
    setOpen2(false);
  };

  const handleLegend = (e) => {
    setLegend(e);
  };

  const handleStacked = (e) => {
    setStacked(e);
  };

  const handleStrokeLine = (e) => {
    setStrokeLine(e);
  };

  const handleDataLabels = (e) => {
    setDataLabels(e);
  };

  const handleBackgroundColor = (color) => {
    setBackgroundColor(color);
  };

  const handleLegendTextColor = (color) => {
    setLegendTextColor(color);
  };

  const handleLavelXColor = (color) => {
    setLavelXColor(color);
  };

  const handleLavelYColor = (color) => {
    setLavelYColor(color);
  };

  const handlePeakValueColor = (color) => {
    setPeakValueC(color);
  };

  const handleLowestValueC = (color) => {
    setLowestValueC(color);
  };

  const handleMovingValueC = (color) => {
    setMovingValueC(color);
  };

  const handleAverageValueC = (color) => {
    setAverageValueC(color);
  };

  const handleTargetValueLineColor = (color) => {
    setTargetValueLineColor(color);
  };

  const handleLableTextColorTarget = (color) => {
    setLableTextColorTarget(color);
  };

  const handleFontSizeTarget = (e) => {
    setFontSizeTarget(e);
  };

  const handleFontSizeLowest = (e) => {
    setFontSizeLowest(e);
  };

  const handleFontSizeAverage = (e) => {
    setFontSizeAverage(e);
  };

  const handleFontSizePeak = (e) => {
    setFontSizePeak(e);
  };

  const handlePositionPeakTB = (e) => {
    setPositionPeakTB(e);
  };

  const handlePositionPeakLR = (e) => {
    setPositionPeakLR(e);
  };

  const handlePositionAverageTB = (e) => {
    setPositionAverageTB(e);
  };

  const handleLineTpyeSD = (e) => {
    setLineTpyeSD(e);
  };

  const handleLineWidth = (e) => {
    setLineWidth(e);
  };

  const handlePositionAverageLR = (e) => {
    setPositionAverageLR(e);
  };

  const handlePositionLowestTB = (e) => {
    setPositionLowestTB(e);
  };

  const handlePositionLowestLR = (e) => {
    setPositionLowestLR(e);
  };

  const handlePositionTargetTB = (e) => {
    setPositionTargetTB(e);
  };

  const handlePositionTargetLR = (e) => {
    setPositionTargetLR(e);
  };
  const handleTargetValue = (e) => {
    setTargetValue(e);
  };
  const handleTargetValueTitle = (e) => {
    setTargetValueTitle(e);
  };
  const handlePeakValueTitle = (e) => {
    setPeakValueTitle(e);
  };
  const handleLowestValueTitle = (e) => {
    setLowestValueTitle(e);
  };
  const handleAverageValueTitle = (e) => {
    setAverageValueTitle(e);
  };
  const handleFontSize = (e) => {
    setFontSize(e);
  };
  const handleYAxis = (e) => {
    setYAxis(e);
  };
  const handleXAxis = (e) => {
    setXAxis(e);
  };
  const handleColor = (value, i) => {
    const newArray = [...Color];
    newArray[i] = value;
    setColor(newArray);
  };
  const handleLegendDisplay = (e) => {
    setLegendDisplay(e);
  };
  const handleLableTextColor = (e) => {
    setTextColor(e);
  };
  const handleXGridDisplay = (e) => {
    setGridX(e);
  };
  const handleValuePeak = (e) => {
    setValuePeak(e);
  };
  const handleValueTarget = (e) => {
    setValueTarget(e);
  };
  const handleValueLowest = (e) => {
    setValueLowest(e);
  };
  const handleYGridDisplay = (e) => {
    setGridY(e);
  };
  const handleXLebalDisplay = (e) => {
    setLebalX(e);
  };
  const handleYLebalisplay = (e) => {
    setLebalY(e);
  };
  const handleXTooltipDisplay = (e) => {
    setTooltip(e);
  };
  const handleXTargetDisplay = (e) => {
    setTargetDisplay(e);
  };
  const handlePeakDisplay = (e) => {
    setPeakDisplay(e);
  };
  const handleLowestDisplay = (e) => {
    setLowestDisplay(e);
  };
  const handleMovingDisplay = (e) => {
    setMovingDisplay(e);
  };
  const handleAverageDisplay = (e) => {
    setAverageDisplay(e);
  };
  const handleAverageLineDisplay = (e) => {
    setAverageLineDisplay(e);
  };
  const handleLowestLineDisplay = (e) => {
    setLowestLineDisplay(e);
  };
  const handleTargetLineDisplay = (e) => {
    setTargetLineDisplay(e);
  };
  const handlePeakLineDisplay = (e) => {
    setPeakLineDisplay(e);
  };
  const handleXAxisDisplay = (e) => {
    setAxisX(e);
  };
  const handleYAxisDisplay = (e) => {
    setAxisY(e);
  };
  const handleQuil = (pos, quilData, cid, bid) => {
    var chartObject = {
      position: pos,
      quillData: quilData,
    };
    var updatedContainers = [...containers];
    if (pos === "top") {
      var updatedBox = {
        ...updatedContainers[cid].theadbox[bid],
        ...chartObject,
      };
      updatedContainers[cid].theadbox[bid] = updatedBox;
      setContainers(updatedContainers);
    } else if (pos === "bottom") {
      var updatedBox = {
        ...updatedContainers[cid].bheadbox[bid],
        ...chartObject,
      };
      updatedContainers[cid].bheadbox[bid] = updatedBox;
      setContainers(updatedContainers);
    }
  };
  const boxRef = useRef(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 20 }, () => React.createRef())
    )
  );
  const boxRefHead = useRef(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 20 }, () => React.createRef())
    )
  );
  const boxRefBottom = useRef(
    Array.from({ length: 10 }, () =>
      Array.from({ length: 20 }, () => React.createRef())
    )
  );
  const handleSubmitTemplate = (e) => {
    e.preventDefault();
    if (rowMain.TemplateCat === "") {
      toast.info("Please Select Template Category!");
      return;
    }
    if (!containers.length) {
      toast.error("Please Create Chart template!");
      return;
    }
    if (rowMain.TemplateName === "") {
      toast.warning("Template Name field is mandatory, please fill");
      return;
    }
    let errorHeading = false;
    let errorBox = false;
    let errorEmptyContainer = false;
    containers.forEach((container, containerIndex) => {
      if (
        container.theadbox?.length > 0 ||
        container.boxes?.length > 0 ||
        container.bheadbox?.length > 0
      ) {
        container.theadbox.forEach((box) => {
          if (!box.quillData) errorHeading = true;
        });
        container.boxes.forEach((box) => {
          if (!box.boxChartType) errorBox = true;
        });
        container.bheadbox.forEach((box) => {
          if (!box.quillData) errorHeading = true;
        });
      } else {
        errorEmptyContainer = true;
      }
    });

    if (errorHeading) {
      toast.warning("Please fill heading or remove");
      return;
    }
    if (errorBox) {
      toast.warning("Please select any chart or table or remove");
      return;
    }
    if (errorEmptyContainer) {
      toast.warning("At least one Container should not be empty");
      return;
    }

    previewModalOpen();
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const removeContentQuillTop = (cIndex, hIndex) => {
    const updatedContainers = [...containers];
    updatedContainers[cIndex].theadbox.splice(hIndex, 1);
    setContainers(updatedContainers);
  };
  const removeContentQuillBottom = (cIndex, hIndex) => {
    const updatedContainers = [...containers];
    updatedContainers[cIndex].bheadbox.splice(hIndex, 1);
    setContainers(updatedContainers);
  };
  const removeContentBox = (cIndex, hIndex) => {
    const updatedContainers = containers.map((container, index) => {
      if (index === cIndex) {
        return {
          ...container,
          boxes: container.boxes.filter((_, boxIndex) => boxIndex !== hIndex),
        };
      }
      return container;
    });
    setContainers(updatedContainers);
  };
  const boxTitleColorBGFnc = (e) => {
    setBoxTitleColorBG(e);
  };
  const boxTitleColorFnc = (e) => {
    setBoxTitleColor(e);
  };
  const boxTitleFnc = (e) => {
    setBoxTitle(e);
  };
  const handleTemplateDataMain = (e) => {
    const { name, value } = e.target;
    if (name === "TemplateName" && !/^[A-Za-z\s]*$/.test(value)) {
      toast.warning("Accept Only Alphabets ", { autoClose: 1000 });
      return;
    }
    setRowMain((prev) => ({
      ...prev,
      [name]: name === "TemplateName" ? capitalizeFirstLetter(value) : value,
    }));
  };
  const handleTemplateDataMainTitle = (pos, quilData) => {
    setRowMain((prev) => ({
      ...prev,
      [`pagetitle${pos.charAt(0)}`]: quilData,
    }));
  };
  const handleHeadlineBg = (e, p, c, b, v) => {
    var chartObject = {
      HeadlineBackground: v,
    };
    var updatedContainers = [...containers];
    if (p === "top") {
      var updatedBox = {
        ...updatedContainers[c].theadbox[b],
        ...chartObject,
      };
      updatedContainers[c].theadbox[b] = updatedBox;
      setContainers(updatedContainers);
    } else if (p === "bottom") {
      var updatedBox = {
        ...updatedContainers[c].bheadbox[b],
        ...chartObject,
      };
      updatedContainers[c].bheadbox[b] = updatedBox;
      setContainers(updatedContainers);
    }
  };
  const previewModalOpen = () => {
    var w = $("#cardBody").width();
    // console.log(w, "ww");
    containers?.map((container, containerIndex) => {
      container.theadbox.forEach((box, htIndex) => {
        var htwv = $("#top_headline_box_" + containerIndex + htIndex).width();
        htwv = ((htwv * 100) / w).toFixed(4);
        // console.log("top heading", htwv);
        var chartObject = {
          width: htwv,
        };
        var updatedContainers = [...containers];
        var updatedBox = {
          ...updatedContainers[containerIndex].theadbox[htIndex],
          ...chartObject,
        };
        updatedContainers[containerIndex].theadbox[htIndex] = updatedBox;
        setContainers(updatedContainers);
      });
      container.boxes.forEach((box, boxIndex) => {
        var bwv = $("#tempboxId_" + containerIndex + boxIndex).width();
        var bhv = $("#tempboxId_" + containerIndex + boxIndex).height();
        // console.log("Box", bwv, bhv);
        bwv = (((bwv + 8) * 100) / w).toFixed(4);
        // console.log("Box", bwv, bhv);
        var chartObject = {
          width: bwv,
          height: bhv + 8,
        };
        var updatedContainers = [...containers];
        var updatedBox = {
          ...updatedContainers[containerIndex].boxes[boxIndex], // Copy the existing box
          ...chartObject, // Update with new details
        };
        updatedContainers[containerIndex].boxes[boxIndex] = updatedBox; // Update the box at the specified index
        setContainers(updatedContainers);
      });
      container.bheadbox.forEach((box, hbIndex) => {
        var hbwv = $(
          "#bottom_headline_box_" + containerIndex + hbIndex
        ).width();

        hbwv = ((hbwv * 100) / w).toFixed(4);
        var chartObject = {
          width: hbwv,
        };
        var updatedContainers = [...containers];
        var updatedBox = {
          ...updatedContainers[containerIndex].bheadbox[hbIndex],
          ...chartObject,
        };
        updatedContainers[containerIndex].bheadbox[hbIndex] = updatedBox;
        setContainers(updatedContainers);
      });
    });
    setOpen3(true);
  };
  const closeModalBox1 = () => {
    setOpen(false);
  };
  const closeModalBox2 = () => {
    setOpen2(false);
  };
  useEffect(() => {
    if (dataId) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}userrole/template?templateId=${dataId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          const data = res.data.data;
          setRowMain({
            TemplateName: data?.name || "",
            Description: data?.Description || "",
            TemplateBackgroundTheme: data?.TemplateBackgroundTheme || "",
            TemplateCat: data?.TemplateTypeId || "",
            pagetitleColorC: data?.pagetitleColorC || "",
            pagetitlec: data?.pageTitlec || "",
            pagetitlel: data?.pageTitlel || "",
            pagetitler: data?.pageTitler || "",
            tempId: data?.id || "",
          });
          setContainers(() => {
            const updatedContainers = [];
            res?.data?.data?.templateContainers?.forEach((items, a) => {
              updatedContainers.push({
                boxes: [],
                theadbox: [],
                bheadbox: [],
              });
              // Update theadbox or bheadbox based on position
              items?.containerHeaders?.forEach((thead, th) => {
                if (thead.position === "top") {
                  var chartObject = {
                    position: thead.position,
                    quillData: thead.quillData,
                    width: thead.width,
                    HeadlineBackground: thead.HeadlineBackground,
                  };
                  updatedContainers[a].theadbox.push({ ...chartObject });
                } else if (thead.position === "bottom") {
                  var chartObject = {
                    position: thead.position,
                    quillData: thead.quillData,
                    width: thead.width,
                    HeadlineBackground: thead.HeadlineBackground,
                  };
                  updatedContainers[a].bheadbox.push({ ...chartObject });
                }
              });

              // Update boxes
              items?.containerBoxes?.forEach((box) => {
                const chartObject = {
                  title: box.title,
                  titleBackgroundColor: box.titleBackgroundColor,
                  titleBackgroundColorBG: box.titleBackgroundColorBG,
                  boxContainerIndex: box.boxContainerIndex,
                  boxBoxIndex: box.boxBoxIndex,
                  boxDataId: box.boxDataId,
                  boxCategoryValue: box.boxCategoryValue,
                  boxSubCategoryValue: box.boxSubCategoryValue,
                  boxMetricsValue: box.boxMetricsValue,
                  boxChartType: box.boxChartType,
                  width: box.width,
                  height: box.height,
                  position: box.position,
                  BackgroundColor: box.BackgroundColor,
                  FontSize: box.FontSize,
                  YAxis: box.YAxis,
                  XAxis: box.XAxis,
                  Color: JSON.parse(box.Color),
                  LegendDisplay: box.LegendDisplay,
                  gridX: box.gridX,
                  gridY: box.gridY,
                  axisX: box.axisX,
                  axisY: box.axisY,
                  textColor: box.textColor,
                  PeakValueC: box.PeakValueC,
                  TargetValueLineColor: box.TargetValueLineColor,
                  LableTextColorTarget: box.LableTextColorTarget,
                  FontSizeTarget: box.FontSizeTarget,
                  TargetValue: box.TargetValue,
                  LowestValueC: box.LowestValueC,
                  TargetValueTitle: box.TargetValueTitle,
                  PeakValueTitle: box.PeakValueTitle,
                  LowestValueTitle: box.LowestValueTitle,
                  Tooltip: box.Tooltip,
                  TargetDisplay: box.TargetDisplay,
                  PeakDisplay: box.PeakDisplay,
                  LowestDisplay: box.LowestDisplay,
                  LowestLineDisplay: box.LowestLineDisplay,
                  TargetLineDisplay: box.TargetLineDisplay,
                  PeakLineDisplay: box.PeakLineDisplay,
                  FontSizeLowest: box.FontSizeLowest,
                  FontSizePeak: box.FontSizePeak,
                  PositionLowestTB: box.PositionLowestTB,
                  PositionLowestLR: box.PositionLowestLR,
                  PositionTargetTB: box.PositionTargetTB,
                  PositionTargetLR: box.PositionTargetLR,
                  PositionPeakLR: box.PositionPeakLR,
                  PositionPeakTB: box.PositionPeakTB,
                  DataLabels: box.DataLabels,
                  LebalX: box.LebalX,
                  LebalY: box.LebalY,
                  LavelYColor: box.LavelYColor,
                  LavelXColor: box.LavelXColor,
                  LegendTextColor: box.LegendTextColor,
                  ValuePeak: box.ValuePeak,
                  ValueTarget: box.ValueTarget,
                  ValueLowest: box.ValueLowest,
                  Stacked: box.Stacked,
                };
                updatedContainers[a].boxes.push({ ...chartObject });
              });
            });
            return updatedContainers;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [dataId]);

  const ref = useRef(null);

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Template Configuration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Edit Template</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck AllContainers">
          <div className="col-sm-12">
            <form
              className="form-box"
              onSubmit={handleSubmitTemplate}
              style={{ width: "100%" }}
            >
              <div className="col-sm-12">
                <div className="card mb-4 tw-w-[100%]">
                  <div className="py-2 card-header custom-header d-flex align-items-center justify-content-between">
                    <h6 className="card-title">Template Information</h6>
                  </div>
                  <div className="card-body">
                    <div className="row g-3 ">
                      <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="form-label">
                          Template Name
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={rowMain.TemplateName}
                          name="TemplateName"
                          autoComplete="TemplateName"
                          onChange={handleTemplateDataMain}
                          placeholder="Enter Template Title"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="form-label">
                          Template Description
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={rowMain.Description}
                          name="Description"
                          autoComplete="Description"
                          onChange={handleTemplateDataMain}
                          placeholder="Enter template description"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4">
                        <label className="form-label">
                          Choose Template Category
                        </label>
                        <select
                          className="form-select"
                          name="TemplateCat"
                          value={rowMain.TemplateCat}
                          onChange={handleTemplateDataMain}
                          required
                        >
                          <option>Select</option>
                          {TemplateCat?.map((item, iere) => (
                            <option key={iere} value={item.id}>
                              {item.Type}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label"
                        >
                          Title in Left
                        </label>
                        <ReactQuill
                          theme="snow"
                          name="pagetitlel"
                          onChange={(content) =>
                            handleTemplateDataMainTitle("left", content)
                          }
                          value={rowMain.pagetitlel}
                          modules={{ toolbar: toolbarOptions }}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label"
                        >
                          Title in Center
                        </label>
                        <ReactQuill
                          theme="snow"
                          name="pagetitlec"
                          onChange={(content) =>
                            handleTemplateDataMainTitle("center", content)
                          }
                          value={rowMain.pagetitlec}
                          modules={{ toolbar: toolbarOptions }}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label"
                        >
                          Title in Right
                        </label>
                        <ReactQuill
                          theme="snow"
                          name="pagetitler"
                          onChange={(content) =>
                            handleTemplateDataMainTitle("right", content)
                          }
                          value={rowMain.pagetitler}
                          modules={{ toolbar: toolbarOptions }}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-2">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label"
                        >
                          Background Color
                        </label>
                        <input
                          type="color"
                          className="form-control form-control-color"
                          id="exampleColorInput"
                          name="TemplateBackgroundTheme"
                          autoComplete="TemplateBackgroundTheme"
                          onChange={handleTemplateDataMain}
                          value={rowMain.TemplateBackgroundTheme}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-2">
                        <label
                          htmlFor="exampleColorInput"
                          className="form-label"
                        >
                          Title Background Color
                        </label>
                        <input
                          type="color"
                          className="form-control form-control-color"
                          id="exampleColorInput"
                          name="pagetitleColorC"
                          autoComplete="pagetitleColorC"
                          onChange={handleTemplateDataMain}
                          value={rowMain.pagetitleColorC}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Add Chart And Heading */}
              <div className="col-sm-12">
                <div className="card tw-w-[100%]">
                  <div className="card-header d-flex align-items-center justify-content-end">
                    <Link onClick={addContainer} className="templateBtn">
                      <i className="fa fa-plus" aria-hidden="true"></i> Add
                      Container
                    </Link>
                  </div>
                  <div className="card-body p-2">
                    <ReactSortable
                      shared
                      list={containers}
                      setList={setContainers}
                    >
                      {containers.map((container, containerIndex) => (
                        <div
                          key={containerIndex}
                          id={"container_" + containerIndex}
                          className="card mb-3"
                        >
                          <div className="card-header d-flex justify-content-between align-items-center position-relative ">
                            <span>Container {containerIndex + 1}</span>
                            <span>
                              <Link
                                onClick={() => addHeadlineTop(containerIndex)}
                                className="templateBtnTwo"
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Heading Top
                              </Link>

                              <Link
                                onClick={() => addBox(containerIndex)}
                                className="templateBtnTwo mx-1"
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Box
                              </Link>
                              <Link
                                onClick={() =>
                                  addHeadlineBottom(containerIndex)
                                }
                                className="templateBtnTwo "
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Heading Bottom
                              </Link>
                              <Link
                                title="Close Container"
                                className="removeContent closeBtn mx-1"
                                onClick={() => removeContent(containerIndex)}
                              >
                                <i
                                  className="fa fa-times-circle"
                                  aria-hidden="true"
                                ></i>
                              </Link>
                            </span>
                          </div>
                          <div
                            className="card-body d-flex flex-wrap relative containersBodys p-0"
                            id="cardBody"
                          >
                            {container?.theadbox?.map((box, htIndex) => (
                              <>
                                <div
                                  key={htIndex}
                                  id={
                                    "top_headline_box_" +
                                    containerIndex +
                                    htIndex
                                  }
                                  className="Headline-box tw-resize-x overflow-auto mb-2 relative"
                                  ref={
                                    boxRefHead.current[containerIndex][htIndex]
                                  }
                                  style={{ width: `${box.width}%` }}
                                >
                                  <ReactQuill
                                    theme="snow"
                                    className="Headline-box-top"
                                    modules={{ toolbar: toolbarOptions }}
                                    placeholder="Add text on top"
                                    value={box.quillData}
                                    onChange={(
                                      content,
                                      delta,
                                      source,
                                      editor
                                    ) =>
                                      handleQuil(
                                        "top",
                                        content,
                                        containerIndex,
                                        htIndex
                                      )
                                    }
                                  />
                                  <span className="HeadlineIcon">
                                    <input
                                      className="form-control form-control-color"
                                      type="color"
                                      onChange={(e) =>
                                        handleHeadlineBg(
                                          e,
                                          "top",
                                          containerIndex,
                                          htIndex,
                                          e.target.value
                                        )
                                      }
                                      value={box.HeadlineBackground}
                                    />
                                    <span
                                      className="removeIcon"
                                      onClick={() =>
                                        removeContentQuillTop(
                                          containerIndex,
                                          htIndex
                                        )
                                      }
                                    >
                                      <i
                                        className="fa fa-times-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </span>
                                </div>
                              </>
                            ))}
                            <span className="block w-[100%]"></span>
                            <ReactSortable
                              list={container.boxes}
                              setList={(sortedBoxes) =>
                                setContainers((prevContainers) => {
                                  const updatedContainers = [...prevContainers];
                                  updatedContainers[containerIndex].boxes =
                                    sortedBoxes;
                                  return updatedContainers;
                                })
                              }
                              className="d-flex flex-wrap"
                              style={{ width: "100%" }}
                            >
                              {container?.boxes?.map((box, boxIndex) => (
                                <div
                                  key={boxIndex}
                                  id={"tempboxId_" + containerIndex + boxIndex}
                                  ref={boxRef.current[containerIndex][boxIndex]}
                                  className="boxes boxes-container tw-resize overflow-auto my-2"
                                  data-show="1"
                                  style={{
                                    width: `${box.width}%`,
                                    height: `${box.height}px`,
                                  }}
                                >
                                  <div className="box-body">
                                    <div
                                      className="box-header position-relative"
                                      style={{
                                        background: `${box.titleBackgroundColorBG}`,
                                      }}
                                    >
                                      <p
                                        className="mb-0"
                                        style={{
                                          color: `${box.titleBackgroundColor}`,
                                        }}
                                      >
                                        {box.title || ""}
                                      </p>
                                      <span>
                                        {box.boxChartType === undefined ? (
                                          <span
                                            className="addChartBoxIcon"
                                            title="Select Chart & KPI"
                                            onClick={() =>
                                              openChart(
                                                "tempboxId_" +
                                                  containerIndex +
                                                  boxIndex,
                                                containerIndex,
                                                boxIndex
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <>
                                            <span
                                              className="editChartBoxIcon"
                                              title="Edit Chart & KPI"
                                              onClick={() =>
                                                openrChart(
                                                  "tempboxId_" +
                                                    containerIndex +
                                                    boxIndex,
                                                  containerIndex,
                                                  boxIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-pencil-square-o"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                            <span
                                              className="filterChartIcon"
                                              title="Customized Chart"
                                              onClick={() =>
                                                openrChart2(
                                                  "tempboxId_" +
                                                    containerIndex +
                                                    boxIndex,
                                                  containerIndex,
                                                  boxIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-filter"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </>
                                        )}

                                        <span
                                          className="removeCartBoxIcon"
                                          title="Remove Box"
                                          onClick={() =>
                                            removeContentBox(
                                              containerIndex,
                                              boxIndex
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="chart-box">
                                      {box.boxChartType === "Chart1" ? (
                                        <div className="chartOrTableType Chart1">
                                          <Chart1
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            Color={box.Color}
                                            DataLabels={box.DataLabels}
                                            LegendDisplay={box.LegendDisplay}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart4" ? (
                                        <div className="chartOrTableType Chart4">
                                          <Chart4
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            DataLabels={box.DataLabels}
                                            Color={box.Color}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            LegendDisplay={box.LegendDisplay}
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart6" ? (
                                        <div className="chartOrTableType Chart6">
                                          <Chart6
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            DataLabels={box.DataLabels}
                                            Color={box.Color}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            LegendDisplay={box.LegendDisplay}
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart8" ? (
                                        <div className="chartOrTableType Chart8">
                                          <Chart8
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart9" ? (
                                        <div className="chartOrTableType Chart9">
                                          <Chart9
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart14" ? (
                                        <div className="chartOrTableType Chart14">
                                          <Chart14
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart15" ? (
                                        <div className="chartOrTableType Chart15">
                                          <Chart15
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            Stacked={
                                              box.Stacked === 1 ? true : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart16" ? (
                                        <div className="chartOrTableType Chart16">
                                          <Chart16
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart18" ? (
                                        <div className="chartOrTableType Chart18">
                                          <Chart18
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            Stacked={
                                              box.Stacked === 1 ? true : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart22" ? (
                                        <div className="chartOrTableType Chart22">
                                          <Chart22
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            Stacked={
                                              box.Stacked === 1 ? true : false
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart23" ? (
                                        <div className="chartOrTableType Chart23">
                                          <Chart23
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart24" ? (
                                        <div className="chartOrTableType Chart24">
                                          <Chart24
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            Stacked={
                                              box.Stacked === 1 ? true : false
                                            }
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart26" ? (
                                        <div className="chartOrTableType Chart26">
                                          <Chart26
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Chart28" ? (
                                        <div className="chartOrTableType Chart28">
                                          <Chart28
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart30" ? (
                                        <div className="chartOrTableType Chart30">
                                          <Chart30
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart32" ? (
                                        <div className="chartOrTableType Chart32">
                                          <Chart32
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            Stacked={
                                              box.Stacked === 1 ? true : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart33" ? (
                                        <div className="chartOrTableType Chart33">
                                          <Chart33
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart35" ? (
                                        <div className="chartOrTableType Chart35">
                                          <Chart35
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {box.boxChartType === "Chart36" ? (
                                        <div className="chartOrTableType Chart36">
                                          <Chart36
                                            position={box.position}
                                            BackgroundColor={
                                              box.BackgroundColor
                                            }
                                            FontSize={box.FontSize}
                                            YAxis={box.YAxis}
                                            XAxis={box.XAxis}
                                            Color={box.Color}
                                            LegendDisplay={box.LegendDisplay}
                                            gridX={box.gridX}
                                            gridY={box.gridY}
                                            axisX={box.axisX}
                                            axisY={box.axisY}
                                            TextColor={box.textColor}
                                            PeakValueC={box.PeakValueC}
                                            TargetValueLineColor={
                                              box.TargetValueLineColor
                                            }
                                            LableTextColorTarget={
                                              box.LableTextColorTarget
                                            }
                                            FontSizeTarget={box.FontSizeTarget}
                                            TargetValue={box.TargetValue}
                                            LowestValueC={box.LowestValueC}
                                            TargetValueTitle={
                                              box.TargetValueTitle
                                            }
                                            PeakValueTitle={box.PeakValueTitle}
                                            LowestValueTitle={
                                              box.LowestValueTitle
                                            }
                                            Tooltip={box.Tooltip}
                                            TargetDisplay={
                                              box.TargetDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakDisplay={
                                              box.PeakDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestDisplay={
                                              box.LowestDisplay === 1
                                                ? true
                                                : false
                                            }
                                            LowestLineDisplay={
                                              box.LowestLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            TargetLineDisplay={
                                              box.TargetLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PeakLineDisplay={
                                              box.PeakLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            FontSizeLowest={box.FontSizeLowest}
                                            FontSizePeak={box.FontSizePeak}
                                            PositionLowestTB={
                                              box.PositionLowestTB
                                            }
                                            PositionLowestLR={
                                              box.PositionLowestLR
                                            }
                                            PositionTargetTB={
                                              box.PositionTargetTB
                                            }
                                            PositionTargetLR={
                                              box.PositionTargetLR
                                            }
                                            PositionPeakLR={box.PositionPeakLR}
                                            PositionPeakTB={box.PositionPeakTB}
                                            DataLabels={box.DataLabels}
                                            LebalX={
                                              box.LebalX === 1 ? true : false
                                            }
                                            LebalY={
                                              box.LebalY === 1 ? true : false
                                            }
                                            AverageDisplay={
                                              box?.AverageDisplay === 1
                                                ? true
                                                : false
                                            }
                                            AverageLineDisplay={
                                              box?.AverageLineDisplay === 1
                                                ? true
                                                : false
                                            }
                                            PositionAverageLR={
                                              box.PositionAverageLR
                                            }
                                            PositionAverageTB={
                                              box.PositionAverageTB
                                            }
                                            AverageValueTitle={
                                              box.AverageValueTitle
                                            }
                                            AverageValueC={box.AverageValueC}
                                            FontSizeAverage={
                                              box.FontSizeAverage
                                            }
                                            MovingDisplay={
                                              box.MovingDisplay === 1
                                                ? true
                                                : false
                                            }
                                            MovingValueC={box.MovingValueC}
                                            LavelYColor={box.LavelYColor}
                                            LavelXColor={box.LavelXColor}
                                            StrokeLine={box.StrokeLine}
                                            LineTpyeSD={box.LineTpyeSD}
                                            LineWidth={box.LineWidth}
                                            LegendTextColor={
                                              box.LegendTextColor
                                            }
                                            ValuePeak={
                                              box.ValuePeak === 1 ? true : false
                                            }
                                            ValueTarget={
                                              box.ValueTarget === 1
                                                ? true
                                                : false
                                            }
                                            ValueLowest={
                                              box.ValueLowest === 1
                                                ? true
                                                : false
                                            }
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {box.boxChartType === "Group" ? (
                                        <div className="chartOrTableType Group">
                                          <Group
                                            category={box.boxCategoryValue}
                                            subcategory={
                                              box.boxSubCategoryValue
                                            }
                                            metrics={box.boxMetricsValue}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </ReactSortable>
                            <span className="block w-[100%]"></span>
                            {container?.bheadbox?.map((box, hbIndex) => (
                              <div
                                key={hbIndex}
                                ref={
                                  boxRefBottom.current[containerIndex][hbIndex]
                                }
                                id={
                                  "bottom_headline_box_" +
                                  containerIndex +
                                  hbIndex
                                }
                                className="Headline-box mt-2 tw-resize-x overflow-auto relative"
                                style={{ width: `${box.width}%` }}
                              >
                                <ReactQuill
                                  theme="snow"
                                  className="Headline-box-bottom"
                                  modules={{ toolbar: toolbarOptions }}
                                  placeholder="Add text on bottom"
                                  value={box.quillData}
                                  onChange={(content, delta, source, editor) =>
                                    handleQuil(
                                      "bottom",
                                      content,
                                      containerIndex,
                                      hbIndex
                                    )
                                  }
                                />

                                <span className="HeadlineIcon">
                                  <input
                                    className="form-control form-control-color"
                                    type="color"
                                    onChange={(e) => {
                                      handleHeadlineBg(
                                        e,
                                        "bottom",
                                        containerIndex,
                                        hbIndex,
                                        e.target.value
                                      );
                                    }}
                                    value={box.HeadlineBackground}
                                  />
                                  <span
                                    className="removeIcon"
                                    onClick={() =>
                                      removeContentQuillBottom(
                                        containerIndex,
                                        hbIndex
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-times-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </ReactSortable>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="ModalBtnBox">
                  <button className="SubmitModalBtn2" type="submit">
                    Preview & Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/*  */}
      <div
        className={
          open === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%] p-4">
          <button className="custom_close" onClick={closeModalBox1}></button>
          <header className="custom_modal-header">
            <h2>Select Chart and KPI</h2>
          </header>
          <div className="kpibox">
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Choose Category</label>
                <select
                  className="form-select"
                  name="Category"
                  onChange={(e) => handleChangeCategory(e.target.value)}
                >
                  <option>Select</option>
                  {category?.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.category}
                        selected={
                          item.category === categoryValue ? true : false
                        }
                      >
                        {item.category}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Sub Category</label>
                <select
                  className="form-select"
                  name="SubCategory"
                  onChange={(e) => handleChangeSubCategory(e.target.value)}
                >
                  <option value="">Select</option>
                  {subCategory?.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.Sub_Category}
                        selected={
                          item.Sub_Category === subCategoryValue ? true : false
                        }
                      >
                        {item.Sub_category}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-6 col-md-4">
                <label className="form-label">Choose Metrics</label>
                <select
                  className="form-select"
                  name="Metrics"
                  onChange={(e) => handleChangeMatrics(e.target.value)}
                >
                  <option value="">Select</option>
                  {metrics?.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.KPI_Measurements}
                        selected={
                          item.KPI_Measurements === metricsValue ? true : false
                        }
                      >
                        {item.KPI_Measurements}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="tab-pan-body">
            <ul className="nav nav-pills" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#Pie"
                >
                  Pie Chart
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="pill"
                  href="#Horizontal"
                >
                  Horizontal Chart
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#Line">
                  Line Chart{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#Area">
                  Area Chart{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#Mix">
                  Mix Chart{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#Vertical">
                  Vertical Chart{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="pill" href="#Other">
                  Other
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div id="Pie" className="containers tab-pane active">
                <section
                  id="selectChart1"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_1"
                      data-id="Chart1"
                      data-name="VerticalChart"
                      name="select"
                      value="Chart1"
                      checked={chartTypeData === "Chart1"}
                      onChange={() => handleSelectChart("Chart1", "pie", 5)}
                    />
                    <label htmlFor="control_1">
                      <div className="modalSelectChartBox">
                        <Chart1 />
                      </div>
                    </label>
                  </div>

                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_4"
                      data-id="Chart4"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart4"}
                      value="Chart4"
                      onChange={() => handleSelectChart("Chart4", "pie", 5)}
                    />
                    <label htmlFor="control_4">
                      <div className="modalSelectChartBox">
                        <Chart4 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_6"
                      data-id="Chart6"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart6"}
                      value="Chart6"
                      onChange={() => handleSelectChart("Chart6", "pie", 5)}
                    />
                    <label htmlFor="control_6">
                      <div className="modalSelectChartBox">
                        <Chart6 />
                      </div>
                    </label>
                  </div>
                </section>
              </div>
              <div id="Horizontal" className="containers tab-pane ">
                <section
                  id="selectChart2"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_14"
                      data-id="Chart14"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart14"}
                      value="Chart14"
                      onChange={() =>
                        handleSelectChart("Chart14", "horizontal", 1)
                      }
                    />
                    <label htmlFor="control_14">
                      <div className="modalSelectChartBox">
                        <Chart14 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_15"
                      data-id="Chart15"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart15"}
                      value="Chart15"
                      onChange={() =>
                        handleSelectChart("Chart15", "horizontal", 2)
                      }
                    />
                    <label htmlFor="control_15">
                      <div className="modalSelectChartBox">
                        <Chart15 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_16"
                      data-id="Chart16"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart16"}
                      value="Chart16"
                      onChange={() =>
                        handleSelectChart("Chart16", "horizontal", 6)
                      }
                    />
                    <label htmlFor="control_16">
                      <div className="modalSelectChartBox">
                        <Chart16 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_18"
                      data-id="Chart18"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart18"}
                      value="Chart18"
                      onChange={() =>
                        handleSelectChart("Chart18", "horizontal", 4)
                      }
                    />
                    <label htmlFor="control_18">
                      <div className="modalSelectChartBox">
                        <Chart18 />
                      </div>
                    </label>
                  </div>
                </section>
              </div>
              <div id="Line" className="containers tab-pane ">
                <section
                  id="selectChart3"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_8"
                      data-id="Chart8"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart8"}
                      value="Chart8"
                      onChange={() => handleSelectChart("Chart8", "line", 1)}
                    />
                    <label htmlFor="control_8">
                      <div className="modalSelectChartBox">
                        <Chart8 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_9"
                      data-id="Chart9"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart9"}
                      value="Chart9"
                      onChange={() => handleSelectChart("Chart9", "line", 2)}
                    />
                    <label htmlFor="control_9">
                      <div className="modalSelectChartBox">
                        <Chart9 />
                      </div>
                    </label>
                  </div>

                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_26"
                      data-id="Chart26"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart26"}
                      value="Chart26"
                      onChange={() => handleSelectChart("Chart26", "line", 1)}
                    />
                    <label htmlFor="control_26">
                      <div className="modalSelectChartBox">
                        <Chart26 />
                      </div>
                    </label>
                  </div>
                </section>
              </div>
              <div id="Area" className="containers tab-pane ">
                <section
                  id="selectChart4"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_30"
                      data-id="Chart30"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart30"}
                      value="Chart30"
                      onChange={() => handleSelectChart("Chart30", "area", 3)}
                    />
                    <label htmlFor="control_30">
                      <div className="modalSelectChartBox">
                        <Chart30 />
                      </div>
                    </label>
                  </div>
                </section>
              </div>
              <div id="Mix" className="containers tab-pane ">
                <sectiton
                  id="selectChart5"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_28"
                      data-id="Chart28"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart28"}
                      value="Chart28"
                      onChange={() => handleSelectChart("Chart28", "mix", 2)}
                    />
                    <label htmlFor="control_28">
                      <div className="modalSelectChartBox">
                        <Chart28 />
                      </div>
                    </label>
                  </div>

                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_33"
                      data-id="Chart33"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart33"}
                      value="Chart33"
                      onChange={() => handleSelectChart("Chart33", "mix", 3)}
                    />
                    <label htmlFor="control_33">
                      <div className="modalSelectChartBox">
                        <Chart33 />
                      </div>
                    </label>
                  </div>
                </sectiton>
              </div>
              <div id="Vertical" className="containers tab-pane ">
                <section
                  id="selectTable"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_22"
                      data-id="Chart22"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart22"}
                      value="Chart22"
                      onChange={() =>
                        handleSelectChart("Chart22", "vertical", 3)
                      }
                    />
                    <label htmlFor="control_22">
                      <div className="modalSelectChartBox">
                        <Chart22 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_23"
                      data-id="Chart23"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart23"}
                      value="Chart23"
                      onChange={() =>
                        handleSelectChart("Chart23", "vertical", 1)
                      }
                    />
                    <label htmlFor="control_23">
                      <div className="modalSelectChartBox">
                        <Chart23 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_24"
                      data-id="Chart24"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart24"}
                      value="Chart24"
                      onChange={() =>
                        handleSelectChart("Chart24", "vertical", 3)
                      }
                    />
                    <label htmlFor="control_24">
                      <div className="modalSelectChartBox">
                        <Chart24 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_32"
                      data-id="Chart32"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart32"}
                      value="Chart32"
                      onChange={() =>
                        handleSelectChart("Chart32", "vertical", 3)
                      }
                    />
                    <label htmlFor="control_32">
                      <div className="modalSelectChartBox">
                        <Chart32 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_35"
                      data-id="Chart35"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart35"}
                      value="Chart35"
                      onChange={() =>
                        handleSelectChart("Chart35", "vertical", 5)
                      }
                    />
                    <label htmlFor="control_35">
                      <div className="modalSelectChartBox">
                        <Chart35 />
                      </div>
                    </label>
                  </div>
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_36"
                      data-id="Chart36"
                      data-name="VerticalChart"
                      name="select"
                      checked={chartTypeData === "Chart36"}
                      value="Chart36"
                      onChange={() =>
                        handleSelectChart("Chart36", "vertical", 4)
                      }
                    />
                    <label htmlFor="control_36">
                      <div className="modalSelectChartBox">
                        <Chart36 />
                      </div>
                    </label>
                  </div>
                </section>
              </div>
              <div id="Other" className="containers tab-pane ">
                <sectiton
                  id="selectChart6"
                  className="selectChart flex flex-wrap"
                >
                  <div className="inModalChartBox">
                    <input
                      type="radio"
                      id="control_group"
                      data-id="Group"
                      data-name="otherChart"
                      name="select"
                      checked={chartTypeData === "Group"}
                      value="Chart28"
                      onChange={() => handleSelectChart("Group", "other", 0)}
                    />
                    <label htmlFor="control_group">
                      <div className="modalSelectChartBox">
                        <Group />
                      </div>
                    </label>
                  </div>
                </sectiton>
              </div>
            </div>
          </div>
          <div className="ModalBtnBox">
            <button
              onClick={() => setOpen(false)}
              type="button"
              className="CloseModalBtn"
            >
              Close
            </button>
            <button onClick={handleChartType} className="SubmitModalBtn">
              Submit
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          open2 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%]">
          <button className="custom_close" onClick={closeModalBox2}></button>
          <header className="custom_modal-header">
            <h2>Modify Chart</h2>
          </header>
          <div className="modify-chart-body">
            <div className="row">
              <div className="col-sm-4 p-2 preview-chart-box pt-0">
                {PreviewChartSelect === "Chart1" ? (
                  <div className="chartOrTableType2 Chart1">
                    <Chart1
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      DataLabels={DataLabels}
                      LegendTextColor={LegendTextColor}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart4" ? (
                  <div className="chartOrTableType2 Chart4">
                    <Chart4
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      DataLabels={DataLabels}
                      LegendTextColor={LegendTextColor}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart6" ? (
                  <div className="chartOrTableType2 Chart6">
                    <Chart6
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      DataLabels={DataLabels}
                      LegendTextColor={LegendTextColor}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart8" ? (
                  <div className="chartOrTableType2 Chart8">
                    <Chart8
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      AverageDisplay={AverageDisplay}
                      AverageLineDisplay={AverageLineDisplay}
                      PositionAverageLR={PositionAverageLR}
                      PositionAverageTB={PositionAverageTB}
                      AverageValueTitle={AverageValueTitle}
                      AverageValueC={AverageValueC}
                      FontSizeAverage={FontSizeAverage}
                      MovingDisplay={MovingDisplay}
                      MovingValueC={MovingValueC}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      StrokeLine={StrokeLine}
                      LineTpyeSD={LineTpyeSD}
                      LineWidth={LineWidth}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart9" ? (
                  <div className="chartOrTableType2 Chart9">
                    <Chart9
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      AverageDisplay={AverageDisplay}
                      AverageLineDisplay={AverageLineDisplay}
                      PositionAverageLR={PositionAverageLR}
                      PositionAverageTB={PositionAverageTB}
                      AverageValueTitle={AverageValueTitle}
                      AverageValueC={AverageValueC}
                      FontSizeAverage={FontSizeAverage}
                      MovingDisplay={MovingDisplay}
                      MovingValueC={MovingValueC}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      StrokeLine={StrokeLine}
                      LineTpyeSD={LineTpyeSD}
                      LineWidth={LineWidth}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart14" ? (
                  <div className="chartOrTableType2 Chart14">
                    <Chart14
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart15" ? (
                  <div className="chartOrTableType2 Chart15">
                    <Chart15
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      Stacked={Stacked}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart16" ? (
                  <div className="chartOrTableType2 Chart16">
                    <Chart16
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart18" ? (
                  <div className="chartOrTableType2 Chart18">
                    <Chart18
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      Stacked={Stacked}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart22" ? (
                  <div className="chartOrTableType2 Chart22">
                    <Chart22
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      Stacked={Stacked}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart23" ? (
                  <div className="chartOrTableType2 Chart23">
                    <Chart23
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart24" ? (
                  <div className="chartOrTableType2 Chart24">
                    <Chart24
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      Stacked={Stacked}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart26" ? (
                  <div className="chartOrTableType2 Chart26">
                    <Chart26
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      StrokeLine={StrokeLine}
                      LineTpyeSD={LineTpyeSD}
                      LineWidth={LineWidth}
                      MovingDisplay={MovingDisplay}
                      MovingValueC={MovingValueC}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart28" ? (
                  <div className="chartOrTableType2 Chart28">
                    <Chart28
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart30" ? (
                  <div className="chartOrTableType2 Chart30">
                    <Chart30
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart32" ? (
                  <div className="chartOrTableType2 Chart32">
                    <Chart32
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      Stacked={Stacked}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Chart33" ? (
                  <div className="chartOrTableType2 Chart33">
                    <Chart33
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart35" ? (
                  <div className="chartOrTableType2 Chart35">
                    <Chart35
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}

                {PreviewChartSelect === "Chart36" ? (
                  <div className="chartOrTableType2 Chart36">
                    <Chart36
                      position={Legend}
                      BackgroundColor={BackgroundColor}
                      FontSize={FontSize}
                      YAxis={YAxis}
                      XAxis={XAxis}
                      Color={Color}
                      LegendDisplay={LegendDisplay}
                      gridX={gridX}
                      gridY={gridY}
                      axisX={axisX}
                      axisY={axisY}
                      TextColor={textColor}
                      PeakValueC={PeakValueC}
                      TargetValueLineColor={TargetValueLineColor}
                      LableTextColorTarget={LableTextColorTarget}
                      FontSizeTarget={FontSizeTarget}
                      TargetValue={TargetValue}
                      LowestValueC={LowestValueC}
                      TargetValueTitle={TargetValueTitle}
                      PeakValueTitle={PeakValueTitle}
                      LowestValueTitle={LowestValueTitle}
                      Tooltip={Tooltip}
                      TargetDisplay={TargetDisplay}
                      PeakDisplay={PeakDisplay}
                      LowestDisplay={LowestDisplay}
                      LowestLineDisplay={LowestLineDisplay}
                      TargetLineDisplay={TargetLineDisplay}
                      PeakLineDisplay={PeakLineDisplay}
                      FontSizeLowest={FontSizeLowest}
                      FontSizePeak={FontSizePeak}
                      PositionLowestTB={PositionLowestTB}
                      PositionLowestLR={PositionLowestLR}
                      PositionTargetTB={PositionTargetTB}
                      PositionTargetLR={PositionTargetLR}
                      PositionPeakLR={PositionPeakLR}
                      PositionPeakTB={PositionPeakTB}
                      DataLabels={DataLabels}
                      LebalX={LebalX}
                      LebalY={LebalY}
                      LavelYColor={LavelYColor}
                      LavelXColor={LavelXColor}
                      LegendTextColor={LegendTextColor}
                      ValuePeak={ValuePeak}
                      ValueTarget={ValueTarget}
                      ValueLowest={ValueLowest}
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
                {PreviewChartSelect === "Group" ? (
                  <div className="chartOrTableType2 Group">
                    <Group
                      category={categoryValue}
                      subcategory={subCategoryValue}
                      metrics={metricsValue}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-8">
                <div className="card" style={{ height: "99.5%" }}>
                  <div className="card-header custom-header d-flex align-items-center justify-content-between">
                    <span>Change Chart Properties</span>
                  </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs mt-1" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#chartBox"
                        >
                          Chart Box
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Legend"
                        >
                          Legend
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Color"
                        >
                          Color
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#DataLabel"
                        >
                          Data Label
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Axis"
                        >
                          Axis Edit
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#Grid"
                        >
                          Grid
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content mt-3">
                      <div id="chartBox" className="container tab-pane active">
                        <div className="row">
                          <div className="col-sm-12 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Box Title
                              </label>
                              <input
                                className="form-control"
                                id="input1"
                                type="text"
                                name="LowestValue"
                                value={boxTitle || ""}
                                onChange={(e) => boxTitleFnc(e.target.value)}
                                placeholder="Enter Box Title Name!"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input2" className="form-label">
                                Box Title Color
                              </label>
                              <input
                                className="form-control form-control-color"
                                title="Choose your color"
                                type="color"
                                name="boxTitleColor"
                                value={boxTitleColor || "#000000"}
                                onChange={(e) =>
                                  boxTitleColorFnc(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Box Title Background Color :{" "}
                              </label>
                              <input
                                className="form-control form-control-color"
                                type="color"
                                name="BoxTitleColorBG"
                                value={BoxTitleColorBG || "#FFFFFF"}
                                onChange={(e) =>
                                  boxTitleColorBGFnc(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Legend" className="container tab-pane fade">
                        <div className="row">
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Legend Display
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegendDisplay"
                                  onChange={() => handleLegendDisplay(true)}
                                  checked={
                                    LegendDisplay === true ? true : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegendDisplay"
                                  onChange={() => handleLegendDisplay(false)}
                                  checked={
                                    LegendDisplay === false ? true : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Legend Text Color
                              </label>
                              <input
                                className="form-control form-control-color"
                                type="color"
                                name="LegendTextColor"
                                value={LegendTextColor || "#555555"}
                                onChange={(e) =>
                                  handleLegendTextColor(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Legend Position
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegend"
                                  onChange={() => handleLegend("top")}
                                  checked={Legend === "top" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Top
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegend"
                                  onChange={() => handleLegend("bottom")}
                                  checked={Legend === "bottom" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Bottom
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegend"
                                  onChange={() => handleLegend("left")}
                                  checked={Legend === "left" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Left
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="handleLegend"
                                  onChange={() => handleLegend("right")}
                                  checked={Legend === "right" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Right
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Color" className="container tab-pane fade">
                        <div className="row">
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Chart Background Color
                              </label>
                              <input
                                className="form-control form-control-color"
                                type="color"
                                name="BackgroundColor"
                                value={BackgroundColor || "#FFFFFF"}
                                onChange={(e) =>
                                  handleBackgroundColor(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Label Background Color
                              </label>
                              <div className="d-flex flex-wrap">
                                {containers[modalCData]?.boxes[
                                  modalBData
                                ]?.Color?.map((c, ci) => (
                                  <>
                                    <input
                                      key={ci}
                                      type="color"
                                      className="form-control form-control-color mx-1 mt-1"
                                      name="handleColor"
                                      value={c}
                                      onChange={(e) =>
                                        handleColor(e.target.value, ci)
                                      }
                                    />
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                          {containers[modalCData]?.boxes[modalBData]
                            ?.boxChartTypeSelect !== "pie" && (
                            <>
                              <div className="col-sm-6 modifyInputBox">
                                <div className="mb-3">
                                  <label
                                    htmlFor="input1"
                                    className="form-label"
                                  >
                                    Chart Label X-axis Color :{" "}
                                  </label>
                                  <input
                                    type="color"
                                    name="LavelXColor"
                                    value={LavelXColor || "#999999"}
                                    onChange={(e) =>
                                      handleLavelXColor(e.target.value)
                                    }
                                    className="form-control form-control-color"
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 modifyInputBox">
                                <div className="mb-3">
                                  <label
                                    htmlFor="input1"
                                    className="form-label"
                                  >
                                    Chart Label Y-axis Color :{" "}
                                  </label>
                                  <input
                                    type="color"
                                    name="LavelYColor"
                                    value={LavelYColor || "#999999"}
                                    onChange={(e) =>
                                      handleLavelYColor(e.target.value)
                                    }
                                    className="form-control form-control-color"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div id="DataLabel" className="container tab-pane fade">
                        <div className="row">
                          <div className="col-sm-12 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                DataLabels Display
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="DataLabels"
                                  onChange={() => handleDataLabels("min")}
                                  checked={DataLabels === "min" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Minimum
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="DataLabels"
                                  onChange={() => handleDataLabels("max")}
                                  checked={DataLabels === "max" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Maximum
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="DataLabels"
                                  onChange={() => handleDataLabels("both")}
                                  checked={DataLabels === "both" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Both
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="DataLabels"
                                  onChange={() => handleDataLabels("all")}
                                  checked={DataLabels === "all" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  All
                                </label>
                              </div>
                            </div>
                          </div>
                          {containers[modalCData]?.boxes[modalBData]
                            ?.boxChartTypeSelect !== "pie" && (
                            <>
                              <div className="col-sm-6 modifyInputBox">
                                <div className="mb-3">
                                  <label
                                    htmlFor="input1"
                                    className="form-label d-block"
                                  >
                                    X-axis Label Diaplay
                                  </label>
                                  <div className="form-check form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="Label-d-x"
                                      onChange={(e) =>
                                        handleXLebalDisplay(true)
                                      }
                                      checked={LebalX === true ? true : false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    >
                                      Show
                                    </label>
                                  </div>
                                  <div className="form-check form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="Label-d-x"
                                      onChange={(e) =>
                                        handleXLebalDisplay(false)
                                      }
                                      checked={LebalX === false ? true : false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    >
                                      Hide
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 modifyInputBox">
                                <div className="mb-3">
                                  <label
                                    htmlFor="input1"
                                    className="form-label d-block"
                                  >
                                    Y-axis Label Diaplay
                                  </label>
                                  <div className="form-check form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="Label-d-y"
                                      onChange={(e) => handleYLebalisplay(true)}
                                      checked={LebalY === true ? true : false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    >
                                      Show
                                    </label>
                                  </div>
                                  <div className="form-check form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="Label-d-y"
                                      onChange={(e) =>
                                        handleYLebalisplay(false)
                                      }
                                      checked={LebalY === false ? true : false}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleRadios1"
                                    >
                                      Hide
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div id="Axis" className="container tab-pane fade">
                        <div className="row">
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Display X-axis Label
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="axis-d-x"
                                  onChange={(e) => handleXAxisDisplay(true)}
                                  checked={axisX === true ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="axis-d-x"
                                  onChange={(e) => handleXAxisDisplay(false)}
                                  checked={axisX === false ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Display Y-axis Label
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="axis-d-y"
                                  onChange={(e) => handleYAxisDisplay(true)}
                                  checked={axisY === true ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="axis-d-y"
                                  onChange={(e) => handleYAxisDisplay(false)}
                                  checked={axisY === false ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                X-axis Text
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => handleXAxis(e.target.value)}
                                value={XAxis || ""}
                                placeholder="Enter X-Axis Text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Y-axis Text
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => handleYAxis(e.target.value)}
                                value={YAxis || ""}
                                placeholder="Enter Y-Axis Text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Font Size (px)
                              </label>
                              <input
                                className="form-control"
                                type="number"
                                name="handleFontSize"
                                onChange={(e) => handleFontSize(e.target.value)}
                                value={FontSize || ""}
                                placeholder="Enter Font Size in px"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label htmlFor="input1" className="form-label">
                                Text Color
                              </label>
                              <input
                                className="form-control form-control-color"
                                type="color"
                                name="handleLableTextColor"
                                onChange={(e) =>
                                  handleLableTextColor(e.target.value, 4)
                                }
                                value={textColor || "#999999"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="Grid" className="container tab-pane fade">
                        <div className="row">
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Grid Display X-axis
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="grid-d-x"
                                  onChange={(e) => handleXGridDisplay(true)}
                                  checked={gridX === true ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="grid-d-x"
                                  onChange={(e) => handleXGridDisplay(false)}
                                  checked={gridX === false ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Grid Display Y-axis
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="grid-d-y"
                                  onChange={(e) => handleYGridDisplay(true)}
                                  checked={gridY === true ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="grid-d-y"
                                  onChange={(e) => handleYGridDisplay(false)}
                                  checked={gridY === false ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 modifyInputBox">
                            <div className="mb-3">
                              <label
                                htmlFor="input1"
                                className="form-label d-block"
                              >
                                Chart Tooltip Display
                              </label>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="Tooltip"
                                  onChange={(e) => handleXTooltipDisplay(true)}
                                  checked={Tooltip === true ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Show
                                </label>
                              </div>
                              <div className="form-check form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="Tooltip"
                                  onChange={(e) => handleXTooltipDisplay(false)}
                                  checked={Tooltip === false ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Hide
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 pt-2 border">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#Peak"
                    >
                      Peak
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#Target">
                      Target
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#Lowest">
                      Lowest
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#Average"
                    >
                      Average
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#MovingLine"
                    >
                      Moving Line
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#LineChartEditor"
                    >
                      Line Chart Editor
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#BarChartEditor"
                    >
                      Bar Chart Editor
                    </a>
                  </li>
                </ul>

                <div className="tab-content mt-2">
                  <div id="Peak" className="container tab-pane active">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Peak Value Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="PeakLine-x"
                              onChange={(e) => handlePeakDisplay(true)}
                              checked={PeakDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="PeakLine-x"
                              onChange={(e) => handlePeakDisplay(false)}
                              checked={PeakDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Peak Value Line Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="PeakLineDisplay-x"
                              onChange={(e) => handlePeakLineDisplay(true)}
                              checked={PeakLineDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="PeakLineDisplay-x"
                              onChange={(e) => handlePeakLineDisplay(false)}
                              checked={PeakLineDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Top to Bottom ({PositionPeakTB} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionPeakTB"
                            value={PositionPeakTB || 0}
                            onChange={(e) =>
                              handlePositionPeakTB(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Left to Right ({PositionPeakLR} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            value={PositionPeakLR || 0}
                            name="handlePositionPeakLR"
                            onChange={(e) =>
                              handlePositionPeakLR(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Peak Value Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PeakValueTitle"
                            value={PeakValueTitle || ""}
                            onChange={(e) =>
                              handlePeakValueTitle(e.target.value)
                            }
                            placeholder="Peak Value Title"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Peak Value Color</label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            name="PeakValueC"
                            value={PeakValueC || "#FFFFFF"}
                            onChange={(e) =>
                              handlePeakValueColor(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Font Size (px)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FontSizePeak"
                            value={FontSizePeak || ""}
                            onChange={(e) => handleFontSizePeak(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label d-block">
                            Display Peak Average Value
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValuePeak-x"
                              onChange={(e) => handleValuePeak(true)}
                              checked={ValuePeak === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValuePeak-x"
                              onChange={(e) => handleValuePeak(false)}
                              checked={ValuePeak === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              False
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Target" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Target Value Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="TargetLine-x"
                              onChange={(e) => handleXTargetDisplay(true)}
                              checked={TargetDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="TargetLine-x"
                              onChange={(e) => handleXTargetDisplay(false)}
                              checked={TargetDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Target Value Line Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="TargetLineDisplay-x"
                              onChange={(e) => handleTargetLineDisplay(true)}
                              checked={
                                TargetLineDisplay === true ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="TargetLineDisplay-x"
                              onChange={(e) => handleTargetLineDisplay(false)}
                              checked={
                                TargetLineDisplay === false ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Target Value</label>
                          <input
                            type="number"
                            className="form-control"
                            name="TargetValue"
                            value={TargetValue || ""}
                            onChange={(e) => handleTargetValue(e.target.value)}
                            placeholder="Target Value"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">
                            Target Value Color
                          </label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            name="TargetValueLineColor"
                            value={TargetValueLineColor || "#edabc7"}
                            onChange={(e) =>
                              handleTargetValueLineColor(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Top to Bottom ({PositionTargetTB} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionTargetTB"
                            value={PositionTargetTB || 0}
                            onChange={(e) =>
                              handlePositionTargetTB(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Left to Right ({PositionTargetLR} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionTargetLR"
                            value={PositionTargetLR || 0}
                            onChange={(e) =>
                              handlePositionTargetLR(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">
                            Target Value Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="TargetValue"
                            value={TargetValueTitle || ""}
                            onChange={(e) =>
                              handleTargetValueTitle(e.target.value)
                            }
                            placeholder="Target Value Title"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Font Size (px)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FontSizeTarget"
                            value={FontSizeTarget || ""}
                            onChange={(e) =>
                              handleFontSizeTarget(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Text Color</label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            name="LableTextColorTarget"
                            value={LableTextColorTarget || "#edabab"}
                            onChange={(e) =>
                              handleLableTextColorTarget(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label d-block">
                            Display Target Average Value
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueTarget-x"
                              onChange={(e) => handleValueTarget(true)}
                              checked={ValueTarget === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueTarget-x"
                              onChange={(e) => handleValueTarget(false)}
                              checked={ValueTarget === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              False
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Lowest" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Lowest Value Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="LowestLine-x"
                              onChange={(e) => handleLowestDisplay(true)}
                              checked={LowestDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="LowestLine-x"
                              onChange={(e) => handleLowestDisplay(false)}
                              checked={LowestDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Lowest Value Line Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="LowestLineDisplay-x"
                              onChange={(e) => handleLowestLineDisplay(true)}
                              checked={
                                LowestLineDisplay === true ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="LowestLineDisplay-x"
                              onChange={(e) => handleLowestLineDisplay(false)}
                              checked={
                                LowestLineDisplay === false ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Top to Bottom ({PositionLowestTB} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionLowestTB"
                            value={PositionLowestTB || 0}
                            onChange={(e) =>
                              handlePositionLowestTB(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Left to Right ({PositionPeakLR} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionLowestLR"
                            value={PositionLowestLR || 0}
                            onChange={(e) =>
                              handlePositionLowestLR(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">
                            Lowest Value Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="LowestValue"
                            value={LowestValueTitle || ""}
                            onChange={(e) =>
                              handleLowestValueTitle(e.target.value)
                            }
                            placeholder="Peak Value Title"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Peak Value Color</label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            name="LowestValueC"
                            value={LowestValueC || "#f09869"}
                            onChange={(e) => handleLowestValueC(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Font Size (px)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FontSizeLowest"
                            value={FontSizeLowest || ""}
                            onChange={(e) =>
                              handleFontSizeLowest(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label d-block">
                            Display Lowest Average Value
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueLowest-x"
                              onChange={(e) => handleValueLowest(true)}
                              checked={ValueLowest === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueLowest-x"
                              onChange={(e) => handleValueLowest(false)}
                              checked={ValueLowest === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              False
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Average" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Average Value Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="AverageLine-x"
                              onChange={(e) => handleAverageDisplay(true)}
                              checked={AverageDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="AverageLine-x"
                              onChange={(e) => handleAverageDisplay(false)}
                              checked={AverageDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Average Value Line Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="AverageLineDisplay-x"
                              onChange={(e) => handleAverageLineDisplay(true)}
                              checked={
                                AverageLineDisplay === true ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="AverageLineDisplay-x"
                              onChange={(e) => handleAverageLineDisplay(false)}
                              checked={
                                AverageLineDisplay === false ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Top to Bottom ({PositionAverageTB} px) :
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionAverageTB"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Tooltip on top"
                            value={PositionAverageTB || 0}
                            onChange={(e) =>
                              handlePositionAverageTB(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label htmlFor="customRange3" className="form-label">
                            Position Left to Right ({PositionAverageLR} px) :{" "}
                          </label>
                          <input
                            type="range"
                            className="form-range"
                            min="-200"
                            max="200"
                            name="PositionAverageLR"
                            value={PositionAverageLR || 0}
                            onChange={(e) =>
                              handlePositionAverageLR(e.target.value)
                            }
                            step="0.5"
                            id="customRange3"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">
                            Average Value Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="AverageValue"
                            value={AverageValueTitle || ""}
                            onChange={(e) =>
                              handleAverageValueTitle(e.target.value)
                            }
                            placeholder="Peak Value Title"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">
                            Average Value Color
                          </label>
                          <input
                            type="color"
                            className="form-control form-control-color"
                            name="AverageValueC"
                            value={AverageValueC || "#f09869"}
                            onChange={(e) =>
                              handleAverageValueC(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label">Font Size (px)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FontSizeAverage"
                            value={FontSizeAverage || ""}
                            onChange={(e) =>
                              handleFontSizeAverage(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="form-label d-block">
                            Display Lowest Average Value
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueLowest-x"
                              value={ValueLowest || true}
                              onChange={(e) => handleValueLowest(true)}
                              checked={
                                PeakDisplay === true ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="ValueLowest-x"
                              value={ValueLowest || false}
                              onChange={(e) => handleValueLowest(false)}
                              checked={
                                PeakDisplay === true ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              False
                            </label>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div id="MovingLine" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Moving Line Value Line Display
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="MovingLine-x"
                              onChange={(e) => handleMovingDisplay(true)}
                              checked={MovingDisplay === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Show
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="MovingLine-x"
                              onChange={(e) => handleMovingDisplay(false)}
                              checked={MovingDisplay === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Hide
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="label-control d-block">
                            Moving Line Color
                          </label>
                          <input
                            type="color"
                            name="MovingValueC"
                            value={MovingValueC || "#f09869"}
                            onChange={(e) => handleMovingValueC(e.target.value)}
                            className="form-control form-control-color"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="LineChartEditor" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Line Stroke Type
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="stroke1"
                              onChange={() => handleStrokeLine("smooth")}
                              checked={StrokeLine === "smooth" ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Smooth Line
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="stroke1"
                              onChange={() => handleStrokeLine("straight")}
                              checked={StrokeLine === "straight" ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Straight Line
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Line Type [Solid or Dashed]
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="LineTpyeSD"
                              onChange={() => handleLineTpyeSD("solid")}
                              checked={LineTpyeSD === "solid" ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Solid
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="LineTpyeSD"
                              onChange={() => handleLineTpyeSD("dashed")}
                              checked={LineTpyeSD === "dashed" ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Dashed
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label className="label-control d-block">
                            Line Height ({LineWidth}px)
                          </label>
                          <input
                            type="number"
                            name="LineWidth"
                            value={LineWidth || ""}
                            onChange={(e) => handleLineWidth(e.target.value)}
                            className="form-control"
                            placeholder="Enter Line Height (eg. 1)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="BarChartEditor" className="container tab-pane fade">
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3 modifyInputBox">
                        <div className="mb-3">
                          <label
                            htmlFor="input1"
                            className="form-label d-block"
                          >
                            Bar Chart Type
                          </label>
                          <div className="form-check form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="stacked1"
                              onChange={() => handleStacked(true)}
                              checked={Stacked === true ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Stacked
                            </label>
                          </div>
                          <div className="form-check form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="stacked1"
                              onChange={() => handleStacked(false)}
                              checked={Stacked === false ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Non Stacked
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ModalBtnBox">
              <button
                onClick={() => setOpen2(false)}
                type="button"
                className="CloseModalBtn"
              >
                Close
              </button>
              <button onClick={handleEditChart} className="SubmitModalBtn">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          open3 === true ? "custom_modal open openModal" : "custom_modal"
        }
      >
        <div className="custom_content-wrapper tw-w-[90%] p-4 previewChartModal">
          <button
            className="custom_close"
            onClick={() => setOpen3(false)}
          ></button>
          <header className="custom_modal-header">
            <h2>Preview Template</h2>
          </header>
          <div className="modalBody tw-w-full">
            <div
              className="card"
              style={{ background: `${rowMain.TemplateBackgroundTheme}` }}
              ref={ref}
            >
              <div
                className="card-header"
                style={{ background: `${rowMain.pagetitleColorC}` }}
              >
                <div className="row">
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pagetitlel,
                      }}
                    ></span>
                  </div>
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pagetitlec,
                      }}
                    ></span>
                  </div>
                  <div className="col-md-4 ql-editor py-0">
                    <span
                      className="pageTitle"
                      dangerouslySetInnerHTML={{
                        __html: rowMain?.pagetitler,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
              <div className="card-body">
                {containers.map((container, containerIndex) => (
                  <div
                    key={containerIndex}
                    id={"container_" + containerIndex}
                    className="card mb-3"
                    style={{ background: "transparent" }}
                  >
                    <div className="card-body d-flex flex-wrap relative containersBodysPeview p-0">
                      {container?.theadbox?.map((item3, hti) => (
                        <>
                          {item3?.position === "top" && (
                            <div
                              key={hti}
                              className="mb-2 ql-editor boxHeadingContainer"
                              style={{
                                width: `${item3.width}%`,
                                backgroundColor: item3.HeadlineBackground,
                              }}
                            >
                              <span
                                className="boxHeading"
                                dangerouslySetInnerHTML={{
                                  __html: item3?.quillData,
                                }}
                              ></span>
                            </div>
                          )}
                        </>
                      ))}
                      <span className="d-block tw-w-full"></span>
                      {container?.boxes?.map((box, boxIndex) => (
                        <div
                          className="box-body mb-2 mx-1"
                          key={boxIndex}
                          style={{ width: `${box.width - 1}%` }}
                        >
                          <div
                            className="box-header position-relative"
                            style={{
                              background: `${box.titleBackgroundColorBG}`,
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ color: `${box.titleBackgroundColor}` }}
                            >
                              {box.title || ""}
                            </p>
                          </div>
                          <div
                            className="chart-box"
                            style={{ height: `${box.height}px`, width: "100%" }}
                          >
                            {box.boxChartType === "Chart1" ? (
                              <div className="chartOrTableType Chart1">
                                <Chart1
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  Color={box.Color}
                                  DataLabels={box.DataLabels}
                                  LegendDisplay={box.LegendDisplay}
                                  LegendTextColor={box.LegendTextColor}
                                  dataSeries={box.dataSeries}
                                  dataLabel={box.dataLabel}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart4" ? (
                              <div className="chartOrTableType Chart4">
                                <Chart4
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  DataLabels={box.DataLabels}
                                  Color={box.Color}
                                  LegendTextColor={box.LegendTextColor}
                                  LegendDisplay={box.LegendDisplay}
                                  dataSeries={box.dataSeries}
                                  dataLabel={box.dataLabel}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart6" ? (
                              <div className="chartOrTableType Chart6">
                                <Chart6
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  DataLabels={box.DataLabels}
                                  Color={box.Color}
                                  LegendTextColor={box.LegendTextColor}
                                  LegendDisplay={box.LegendDisplay}
                                  dataSeries={box.dataSeries}
                                  dataLabel={box.dataLabel}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart8" ? (
                              <div className="chartOrTableType Chart8">
                                <Chart8
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart9" ? (
                              <div className="chartOrTableType Chart9">
                                <Chart9
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart14" ? (
                              <div className="chartOrTableType Chart14">
                                <Chart14
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart15" ? (
                              <div className="chartOrTableType Chart15">
                                <Chart15
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  Stacked={box.Stacked === 1 ? true : false}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart16" ? (
                              <div className="chartOrTableType Chart16">
                                <Chart16
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart18" ? (
                              <div className="chartOrTableType Chart18">
                                <Chart18
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  Stacked={box.Stacked === 1 ? true : false}
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart22" ? (
                              <div className="chartOrTableType Chart22">
                                <Chart22
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  Stacked={box.Stacked === 1 ? true : false}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart23" ? (
                              <div className="chartOrTableType Chart23">
                                <Chart23
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart24" ? (
                              <div className="chartOrTableType Chart24">
                                <Chart24
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  Stacked={box.Stacked === 1 ? true : false}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart26" ? (
                              <div className="chartOrTableType Chart26">
                                <Chart26
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {box.boxChartType === "Chart28" ? (
                              <div className="chartOrTableType Chart28">
                                <Chart28
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart30" ? (
                              <div className="chartOrTableType Chart30">
                                <Chart30
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart32" ? (
                              <div className="chartOrTableType Chart32">
                                <Chart32
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  Stacked={box.Stacked === 1 ? true : false}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart33" ? (
                              <div className="chartOrTableType Chart33">
                                <Chart33
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={
                                    box.TargetDisplay === 1 ? true : false
                                  }
                                  PeakDisplay={
                                    box.PeakDisplay === 1 ? true : false
                                  }
                                  LowestDisplay={
                                    box.LowestDisplay === 1 ? true : false
                                  }
                                  LowestLineDisplay={
                                    box.LowestLineDisplay === 1 ? true : false
                                  }
                                  TargetLineDisplay={
                                    box.TargetLineDisplay === 1 ? true : false
                                  }
                                  PeakLineDisplay={
                                    box.PeakLineDisplay === 1 ? true : false
                                  }
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX === 1 ? true : false}
                                  LebalY={box.LebalY === 1 ? true : false}
                                  AverageDisplay={
                                    box?.AverageDisplay === 1 ? true : false
                                  }
                                  AverageLineDisplay={
                                    box?.AverageLineDisplay === 1 ? true : false
                                  }
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={
                                    box.MovingDisplay === 1 ? true : false
                                  }
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak === 1 ? true : false}
                                  ValueTarget={
                                    box.ValueTarget === 1 ? true : false
                                  }
                                  ValueLowest={
                                    box.ValueLowest === 1 ? true : false
                                  }
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart35" ? (
                              <div className="chartOrTableType Chart35">
                                <Chart35
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={box.TargetDisplay}
                                  PeakDisplay={box.PeakDisplay}
                                  LowestDisplay={box.LowestDisplay}
                                  LowestLineDisplay={box.LowestLineDisplay}
                                  TargetLineDisplay={box.TargetLineDisplay}
                                  PeakLineDisplay={box.PeakLineDisplay}
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX}
                                  LebalY={box.LebalY}
                                  AverageDisplay={box.AverageDisplay}
                                  AverageLineDisplay={box.AverageLineDisplay}
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={box.MovingDisplay}
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak}
                                  ValueTarget={box.ValueTarget}
                                  ValueLowest={box.ValueLowest}
                                  dataSeries={box.dataSeries}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Chart36" ? (
                              <div className="chartOrTableType Chart36">
                                <Chart36
                                  position={box.position}
                                  BackgroundColor={box.BackgroundColor}
                                  FontSize={box.FontSize}
                                  YAxis={box.YAxis}
                                  XAxis={box.XAxis}
                                  Color={box.Color}
                                  LegendDisplay={box.LegendDisplay}
                                  gridX={box.gridX}
                                  gridY={box.gridY}
                                  axisX={box.axisX}
                                  axisY={box.axisY}
                                  TextColor={box.textColor}
                                  PeakValueC={box.PeakValueC}
                                  TargetValueLineColor={
                                    box.TargetValueLineColor
                                  }
                                  LableTextColorTarget={
                                    box.LableTextColorTarget
                                  }
                                  FontSizeTarget={box.FontSizeTarget}
                                  TargetValue={box.TargetValue}
                                  LowestValueC={box.LowestValueC}
                                  TargetValueTitle={box.TargetValueTitle}
                                  PeakValueTitle={box.PeakValueTitle}
                                  LowestValueTitle={box.LowestValueTitle}
                                  Tooltip={box.Tooltip}
                                  TargetDisplay={box.TargetDisplay}
                                  PeakDisplay={box.PeakDisplay}
                                  LowestDisplay={box.LowestDisplay}
                                  LowestLineDisplay={box.LowestLineDisplay}
                                  TargetLineDisplay={box.TargetLineDisplay}
                                  PeakLineDisplay={box.PeakLineDisplay}
                                  FontSizeLowest={box.FontSizeLowest}
                                  FontSizePeak={box.FontSizePeak}
                                  PositionLowestTB={box.PositionLowestTB}
                                  PositionLowestLR={box.PositionLowestLR}
                                  PositionTargetTB={box.PositionTargetTB}
                                  PositionTargetLR={box.PositionTargetLR}
                                  PositionPeakLR={box.PositionPeakLR}
                                  PositionPeakTB={box.PositionPeakTB}
                                  DataLabels={box.DataLabels}
                                  LebalX={box.LebalX}
                                  LebalY={box.LebalY}
                                  AverageDisplay={box.AverageDisplay}
                                  AverageLineDisplay={box.AverageLineDisplay}
                                  PositionAverageLR={box.PositionAverageLR}
                                  PositionAverageTB={box.PositionAverageTB}
                                  AverageValueTitle={box.AverageValueTitle}
                                  AverageValueC={box.AverageValueC}
                                  FontSizeAverage={box.FontSizeAverage}
                                  MovingDisplay={box.MovingDisplay}
                                  MovingValueC={box.MovingValueC}
                                  LavelYColor={box.LavelYColor}
                                  LavelXColor={box.LavelXColor}
                                  StrokeLine={box.StrokeLine}
                                  LineTpyeSD={box.LineTpyeSD}
                                  LineWidth={box.LineWidth}
                                  LegendTextColor={box.LegendTextColor}
                                  ValuePeak={box.ValuePeak}
                                  ValueTarget={box.ValueTarget}
                                  ValueLowest={box.ValueLowest}
                                  dataSeries={box.dataSeries}
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {box.boxChartType === "Group" ? (
                              <div className="chartOrTableType Group">
                                <Group
                                  category={box.boxCategoryValue}
                                  subcategory={box.boxSubCategoryValue}
                                  metrics={box.boxMetricsValue}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                      <span className="d-block tw-w-[100%]"></span>
                      {container?.bheadbox?.map((item3, hbIndex) => (
                        <>
                          {item3?.position === "bottom" && (
                            <div
                              key={hbIndex}
                              className="ql-editor boxHeadingContainer"
                              style={{
                                width: `${item3.width}%`,
                                backgroundColor: item3.HeadlineBackground,
                              }}
                            >
                              <span
                                className="boxHeading"
                                dangerouslySetInnerHTML={{
                                  __html: item3?.quillData,
                                }}
                              ></span>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-2 d-flex tw-justify-end">
              <button className="templateBtnp" onClick={finalSubmit}>
                Final Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplateSingle;
