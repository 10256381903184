import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import _ from "lodash";
import CryptoJS from "crypto-js";

function AddProvider() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [selectedDate, setSelectedDate] = useState(null);
  const [providerData, setProviderData] = useState();
  const [sexData, setSexData] = useState();
  const [titleData, setTitleData] = useState();
  const [referralSourceData, setReferralSourceData] = useState();
  const [communicationData, setCommunicationData] = useState();
  const [specialityData, setSpecialityData] = useState();
  const [addressess, setAddressess] = useState([
    {
      PracticeName: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      ZipCode: "",
      County: "",
      Country: "",
      PhoneNumber: "",
      FaxNumber: "",
      E_mail: "",
      PreferredContact: "",
      Primary: true,
      Shared: false,
      State: "",
    },
  ]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [preloaderOn, setPreloaderOn] = useState(false);

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;

    return formattedDate;
  };

  const [row, setRow] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    SexType: "",
    DOB: { selectedDate },
    NPINumber: "",
    MedicalLicenseNumber: "",
    TitleType: "",
    ProviderType: "",
    SpecialityType: "",
    ReferralSourceType: "",
    CommunicationType: "",
    resmedPhysicianID: "",
  });

  const debouncedSave = useCallback(
    _.debounce((nextValue, type) => handleSearch(nextValue, type), 500),
    []
  );

  const [validationState, setValidationState] = useState({
    NPINumber: null,
    MedicalLicenseNumber: null,
    resmedPhysicianID: null,
  });

  const handleSearch = (value, type) => {
    if (value.length >= 10) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}users/checkNumber?${type}=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          setIsValidEmail(true);
          setValidationState({ ...validationState, [type]: true });
        })
        .catch((error) => {
          // console.log("ER", error);/
          // toast.error(error.response.data.message);
          setIsValidEmail(false);
          setValidationState({ ...validationState, [type]: false });
        });
    } else {
      setIsValidEmail(false);
      setValidationState({ ...validationState, [type]: "Invalid" });
    }
  };

  const handleBlur = (e, index) => {
    const { name, value } = e.target;
    if (name === "E_mail") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value !== "") {
        toast.warning("Please enter a valid email.", { autoClose: 1000 });
        setIsValidEmail(false);
        var updatedAddresses = [...addressess];
        updatedAddresses[index].E_mail = value;
        setAddressess(updatedAddresses);
      } else {
        setIsValidEmail(true);
        var updatedAddresses = [...addressess];
        updatedAddresses[index].E_mail = value;
        setAddressess(updatedAddresses);
      }
    }
  };

  const fetchTypeData = (type, setter) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}types?type=${type}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setter(res?.data?.data);
      })
      .catch((error) => {
        console.error(`Error fetching ${type} data:`, error);
      });
  };

  useEffect(() => {
    fetchTypeData("provider", setProviderData);
    fetchTypeData("sex", setSexData);
    fetchTypeData("title", setTitleData);
    fetchTypeData("referralSource", setReferralSourceData);
    fetchTypeData("communication", setCommunicationData);
    fetchTypeData("speciality", setSpecialityData);
  }, [token]);

  // Add More Address
  const addmoreAddress = () => {
    setAddressess((prevAddresses) => [
      ...prevAddresses,
      {
        PracticeName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        ZipCode: "",
        County: "",
        Country: "",
        PhoneNumber: "",
        FaxNumber: "",
        E_mail: "",
        PreferredContact: "",
        Primary: false,
        Shared: false,
        State: "",
      },
    ]);
  };

  const handleChangePost = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    if (name === "FirstName" || name === "MiddleName" || name === "LastName") {
      if (/^[A-Za-z]+$/.test(value) || value === "") {
        setRow({
          ...row,
          [name]: capitalizeFirstLetter(value),
        });
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
        toast.warning("Accept Only Alphabet ", { autoClose: 1000 });
      }
    } else if (name === "NPINumber") {
      if (/^[0-9]+$/.test(value) || value === "") {
        setIsValidEmail(true);
        setRow({ ...row, [name]: value });
        debouncedSave(value, "NPINumber");
      } else {
        setIsValidEmail(false);
        toast.warning("Accept Only Number", { autoClose: 1000 });
      }
    } else if (name === "MedicalLicenseNumber") {
      if (/^[^\s]+$/.test(value) || value === "") {
        setIsValidEmail(true);
        setRow({ ...row, [name]: value });
        debouncedSave(value, "MedicalLicenseNumber");
      } else {
        setIsValidEmail(false);
        toast.warning("No Spaces Allowed", { autoClose: 1000 });
      }
    } else {
      setIsValidEmail(true);
      setRow({ ...row, [name]: value });
    }
  };

  const handlePrimaryClick = (e, index) => {
    const NewPrimary = [...addressess];
    NewPrimary.forEach((item, i) => {
      i === index
        ? (NewPrimary[i].Primary = true)
        : (NewPrimary[i].Primary = false);
    });
    setAddressess(NewPrimary);
  };

  const handalShared = (e, index) => {
    const NewPrimary = [...addressess];
    if (e.target.checked) {
      NewPrimary[index].Shared = true;
    } else {
      NewPrimary[index].Shared = false;
    }
    setAddressess(NewPrimary);
  };

  const handleAddress = (e, i) => {
    const { name, value } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const isAlphabetic = /^[A-Za-z\s]+$/;

    const updatedAddresses = addressess.map((address, index) => {
      if (index === i) {
        if (
          name === "City" ||
          name === "State" ||
          name === "County" ||
          name === "Country" ||
          name === "PracticeName"
        ) {
          if (isAlphabetic.test(value) || value === "") {
            const newValue = capitalizeFirstLetter(value);
            return {
              ...address,
              [name]: newValue,
            };
          } else {
            toast.warning("Only Alphabets are allowed!", { autoClose: 1000 });
          }
        } else if (
          name === "ZipCode" ||
          name === "PhoneNumber" ||
          name === "PreferredContact" ||
          name === "FaxNumber"
        ) {
          if (/^[0-9]+$/.test(value) || value === "") {
            setIsValidEmail(true);
            return {
              ...address,
              [name]: value,
            };
          } else {
            setIsValidEmail(false);
            toast.warning("Only Numeric values are allowed!", {
              autoClose: 1000,
            });
          }
        } else {
          return {
            ...address,
            [name]: value,
          };
        }
      }
      return address;
    });

    const phoneNumber = updatedAddresses[i].PhoneNumber;
    const preferredContact = updatedAddresses[i].PreferredContact;
    if (
      phoneNumber === preferredContact &&
      phoneNumber !== "" &&
      preferredContact !== ""
    ) {
      toast.error("Alternate Contact cannot be same as Phone Number.");
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
    }
    setAddressess(updatedAddresses);
  };

  const handlePostData = (e) => {
    e.preventDefault();
    const inputElements = document.querySelectorAll("input");
    inputElements.forEach((input) => {
      input.classList.remove("error-border");
    });

    const newData = addressess.map((item, i) => ({
      ...item,
    }));

    const isAddressValid = addressess.every(
      (address) =>
        address.PracticeName.trim() !== "" &&
        address.AddressLine1.trim() !== "" &&
        address.City.trim() !== "" &&
        address.PhoneNumber.trim() !== "" &&
        address.PreferredContact.trim() !== "" &&
        address.ZipCode.trim() !== "" &&
        address.FaxNumber.trim() !== "" &&
        address.State.trim() !== "" &&
        address.County.trim() !== "" &&
        address.Country.trim() !== "" &&
        address.E_mail.trim() !== ""
    );
    const isPhoneNumberValid = addressess.every(
      (address) => address.PhoneNumber.trim().length >= 8
    );
    const arePrimaryAndSharedValid = newData.every(
      (address) => address.Primary !== ""
    );

    if (
      row.FirstName.trim() === "" ||
      row.NPINumber === "" ||
      row.LastName === "" ||
      row.MedicalLicenseNumber === "" ||
      !isAddressValid ||
      !isPhoneNumberValid ||
      !arePrimaryAndSharedValid
    ) {
      // Add red border to empty required input fields
      inputElements.forEach((input) => {
        const fieldName = input.getAttribute("name");
        if (!fieldName || !row[fieldName.trim()]) {
          input.classList.add("error-border");
        }
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all the required fields in the form.",
      });
    } else {
      setPreloaderOn(true);
      Swal.fire({
        text: "Are you sure you want to add a New Provider?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}users/addprovider`,
              {
                newData,
                ...row,
                DOB: formatDate(selectedDate),
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((res) => {
              // Set loading state to false
              // Reset form fields
              setRow({
                FirstName: "",
                MiddleName: "",
                LastName: "",
                SexType: "",
                DOB: "",
                NPINumber: "",
                MedicalLicenseNumber: "",
                TitleType: "",
                ProviderType: "",
                SpecialityType: "",
                ReferralSourceType: "",
                CommunicationType: "",
                resmedPhysicianID: "",
              });
              setAddressess([
                {
                  PracticeName: "",
                  AddressLine1: "",
                  AddressLine2: "",
                  City: "",
                  ZipCode: "",
                  County: "",
                  Country: "",
                  PhoneNumber: "",
                  FaxNumber: "",
                  E_mail: "",
                  PreferredContact: "",
                  Primary: "",
                  Shared: "",
                  State: "",
                },
              ]);
              setValidationState({});
              setPreloaderOn(false);
              Swal.fire({
                title: "Success!",
                text: res?.data?.message,
                icon: "success",
              });
            })
            .catch((error) => {
              // Set loading state to false
              setPreloaderOn(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setPreloaderOn(false);
          Swal.fire({
            title: "Cancelled",
            text: "You have cancelled the request to add new Provider",
            icon: "error",
          });
        }
      });
    }
  };

  const handleDelete = (i) => {
    if (i !== 0) {
      const newArr = addressess.filter((item, index) => index !== i);
      setAddressess(newArr);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setRow({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      SexType: "",
      DOB: "",
      NPINumber: "",
      MedicalLicenseNumber: "",
      TitleType: "",
      ProviderType: "",
      SpecialityType: "",
      ReferralSourceType: "",
      CommunicationType: "",
      resmedPhysicianID: "",
    });
    setAddressess([
      {
        PracticeName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        ZipCode: "",
        County: "",
        Country: "",
        PhoneNumber: "",
        FaxNumber: "",
        E_mail: "",
        PreferredContact: "",
        Primary: "",
        Shared: "",
        State: "",
      },
    ]);
  };

  const handlePhysician = async (value) => {
    if (value.length >= 5) {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}resmedData/resmedPhysician?id=${value}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // console.log("Resmed,", res);
        if (res.data.valid === true) {
          setValidationState((prevState) => ({
            ...prevState,
            resmedPhysicianID: true,
          }));
          setIsValidEmail(true);
        } else {
          setValidationState((prevState) => ({
            ...prevState,
            resmedPhysicianID: false,
          }));
          setIsValidEmail(false);
        }
      } catch (error) {
        setValidationState((prevState) => ({
          ...prevState,
          resmedPhysicianID: "Invalid",
        }));
        setIsValidEmail(false);
      }
    } else {
      setValidationState((prevState) => ({
        ...prevState,
        resmedPhysicianID: "Invalid",
      }));
      setIsValidEmail(false);
    }
  };

  return (
    <>
      {preloaderOn === true ? (
        <Preloader text="Please wait while form is uploading..." />
      ) : (
        ""
      )}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Provider Administration
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Add Provider</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>
        <div className="row g-2 clearfix row-deck">
          <div className="col-12">
            <form
              className="form-box"
              id="yourFormId"
              onSubmit={handlePostData}
            >
              <div className="card">
                <div className="card-header">
                  <h6 className="card-title">Demographic Information</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3 ">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        First Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="FirstName"
                        value={row.FirstName}
                        onChange={handleChangePost}
                        autoComplete="FirstName"
                        required
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Middle Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="MiddleName"
                        value={row.MiddleName}
                        autoComplete="MiddleName"
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Last Name
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        className="form-control"
                        name="LastName"
                        value={row.LastName}
                        autoComplete="LastName"
                        onChange={handleChangePost}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Gender</label>
                      <select
                        className="form-select"
                        name="SexType"
                        value={row?.SexType}
                        onChange={(e) => handleChangePost(e)}
                      >
                        <option>Select</option>
                        {sexData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Date of Birth
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon11">
                          <i className="fa fa-calendar"></i>
                        </span>
                        <DatePicker
                          selected={selectedDate}
                          yearDropdownItemNumber={2000}
                          onChange={handleChange}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="MM-DD-YYYY"
                          maxDate={new Date()}
                          yearDropdown
                          showYearDropdown
                          scrollableYearDropdown
                          className="form-control date"
                          value={row.DOB}
                          aria-label="date"
                          aria-describedby="basic-addon11"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        NPI Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationState.NPINumber === true
                            ? "is-valid"
                            : validationState.NPINumber === false
                            ? "is-invalid"
                            : validationState.NPINumber === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="NPINumber"
                        value={row.NPINumber}
                        required
                        onChange={handleChangePost}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        maxLength={15}
                        minLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > 15) {
                            e.target.value = e.target.value.slice(0, 15);
                          }
                        }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.NPINumber === false ? (
                        <div className="invalid-feedback">
                          NPI Number Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          NPI Number Invalid.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Medical License Number
                        <span className="AsteriskSymbol">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          validationState.MedicalLicenseNumber === true
                            ? "is-valid"
                            : validationState.MedicalLicenseNumber === false
                            ? "is-invalid"
                            : validationState.MedicalLicenseNumber === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="MedicalLicenseNumber"
                        id="MedicalLicenseNumber"
                        value={row.MedicalLicenseNumber}
                        onChange={handleChangePost}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                        maxLength={15}
                        minLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > 15) {
                            e.target.value = e.target.value.slice(0, 15);
                          }
                        }}
                        required
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.MedicalLicenseNumber === false ? (
                        <div className="invalid-feedback">
                          Medical License Number Already Taken.
                        </div>
                      ) : (
                        <div className="invalid-feedback">
                          Medical License Number Invalid.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Resmed Physician ID</label>
                      <input
                        type="text"
                        className={`form-control ${
                          validationState.resmedPhysicianID === true
                            ? "is-valid"
                            : validationState.resmedPhysicianID === false ||
                              validationState.resmedPhysicianID === "Invalid"
                            ? "is-invalid"
                            : ""
                        }`}
                        name="resmedPhysicianID"
                        id="resmedPhysicianID"
                        value={row.resmedPhysicianID}
                        onChange={handleChangePost}
                        onBlur={(e) => handlePhysician(e.target.value)}
                        style={{
                          WebkitAppearance: "none",
                          MozAppearance: "textfield",
                        }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                      {validationState.resmedPhysicianID === false && (
                        <div className="invalid-feedback">
                          Resmed Physician ID Already Taken.
                        </div>
                      )}
                      {validationState.resmedPhysicianID === "Invalid" && (
                        <div className="invalid-feedback">
                          Resmed Physician ID Invalid.
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Title</label>
                      <select
                        className="form-select"
                        id="TitleType"
                        name="TitleType"
                        value={row.TitleType}
                        onChange={handleChangePost}
                      >
                        <option selected>Select</option>
                        {titleData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Provider Type</label>
                      <select
                        id="ProviderType"
                        name="ProviderType"
                        value={row.ProviderType}
                        onChange={handleChangePost}
                        className="form-select"
                      >
                        <option selected>Select</option>
                        {providerData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">Choose Speciality</label>
                      <select
                        id="SpecialityType"
                        name="SpecialityType"
                        value={row.SpecialityType}
                        onChange={handleChangePost}
                        className="form-select"
                      >
                        <option selected>Select</option>
                        {specialityData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Choose Referral Source
                      </label>
                      <select
                        className="form-select"
                        id="ReferralSourceType"
                        name="ReferralSourceType"
                        value={row.ReferralSourceType}
                        onChange={handleChangePost}
                      >
                        <option selected>Select</option>
                        {referralSourceData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                      <label className="form-label">
                        Choose Preferred Communication
                      </label>
                      <select
                        className="form-select"
                        id="CommunicationType"
                        name="CommunicationType"
                        value={row.CommunicationType}
                        onChange={handleChangePost}
                      >
                        <option selected>Select</option>
                        {communicationData?.map((item, i) => {
                          return (
                            <option key={i} value={item.id}>
                              {item.Type}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {addressess.map((item, i) => (
                <div className="card mt-3" key={i}>
                  <div className="card-header d-flex justify-content-between">
                    <h6 className="card-title">Contact Information</h6>
                    {i !== 0 && addressess.length - 1 === i && (
                      <span>
                        <button
                          type="button"
                          onClick={() => handleDelete(i)}
                          className="pwoviderclosebtn"
                        >
                          X
                        </button>
                      </span>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row g-3 ">
                      <div className="col-12 d-flex">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="primary"
                            id="primary"
                            autoComplete="primary"
                            defaultValue={addressess[i].Primary}
                            onChange={(e) => {
                              handlePrimaryClick(e, i);
                            }}
                            checked={addressess[i].Primary}
                          />
                          <label className="form-check-label" htmlFor="primary">
                            Primary
                          </label>
                        </div>
                        <div className="form-check form-switch mx-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={`shared${i}`}
                            id={`shared${i}`}
                            autoComplete="shared"
                            defaultValue={addressess[i].Shared}
                            onChange={(e) => {
                              handalShared(e, i);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`shared${i}`}
                          >
                            Shared
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Practice Name
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="PracticeName"
                          autoComplete="PracticeName"
                          value={addressess[i].PracticeName}
                          onChange={(e) => handleAddress(e, i)}
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Address Line 1
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="AddressLine1"
                          autoComplete="AddressLine1"
                          onChange={(e) => handleAddress(e, i)}
                          value={addressess[i].AddressLine1}
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">Address Line 2</label>
                        <input
                          className="form-control"
                          type="text"
                          name="AddressLine2"
                          autoComplete="AddressLine2"
                          value={addressess[i].AddressLine2}
                          onChange={(e) => handleAddress(e, i)}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          City<span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="City"
                          autoComplete="City"
                          value={addressess[i].City}
                          onChange={(e) => handleAddress(e, i)}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          State<span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="State"
                          autoComplete="State"
                          onChange={(e) => handleAddress(e, i)}
                          value={addressess[i].State}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          County
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="County"
                          autoComplete="County"
                          value={addressess[i].County}
                          onChange={(e) => handleAddress(e, i)}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Zip Code
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="ZipCode"
                          autoComplete="Zipcode"
                          value={addressess[i].ZipCode}
                          onChange={(e) => handleAddress(e, i)}
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          max={99999999}
                          min={100000}
                          onInput={(e) => {
                            if (e.target.value.length > 8) {
                              e.target.value = e.target.value.slice(0, 8);
                            }
                          }}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Country
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="Country"
                          autoComplete="Country"
                          value={addressess[i].Country}
                          onChange={(e) => handleAddress(e, i)}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Phone Number
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon16">
                            <i className="fa fa-phone"></i>
                          </span>
                          <input
                            type="text"
                            value={addressess[i].PhoneNumber}
                            name="PhoneNumber"
                            autoComplete="PhoneNumber"
                            style={{
                              WebkitAppearance: "none",
                              MozAppearance: "textfield",
                            }}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                            onChange={(e) => handleAddress(e, i)}
                            max={9999999999}
                            min={1000000000}
                            className="form-control phone-number"
                            placeholder="Ex: +00 (000) 000-00-00"
                            aria-label="phone-number"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Alternate Number
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon16">
                            <i className="fa fa-phone"></i>
                          </span>
                          <input
                            type="text"
                            required
                            name="PreferredContact"
                            autoComplete="PreferredContact"
                            value={addressess[i].PreferredContact}
                            onChange={(e) => handleAddress(e, i)}
                            style={{
                              WebkitAppearance: "none",
                              MozAppearance: "textfield",
                            }}
                            max={9999999999}
                            min={1000000000}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                            className="form-control phone-number"
                            placeholder="Ex: +00 (000) 000-00-00"
                            aria-label="phone-number"
                            aria-describedby="basic-addon16"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          Fax Number
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <input
                          type="text"
                          name="FaxNumber"
                          autoComplete="FaxNumber"
                          value={addressess[i].FaxNumber}
                          onChange={(e) => handleAddress(e, i)}
                          style={{
                            WebkitAppearance: "none",
                            MozAppearance: "textfield",
                          }}
                          max={9999999999}
                          min={1000000000}
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10);
                            }
                          }}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <label className="form-label">
                          E-mail
                          <span className="AsteriskSymbol">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text" id="basic-addon20">
                            <i className="fa fa-envelope-o"></i>
                          </span>
                          <input
                            className="form-control email"
                            placeholder="Ex: example@example.com"
                            aria-label="email"
                            aria-describedby="basic-addon20"
                            type="email"
                            name="E_mail"
                            autoComplete="E_mail"
                            value={addressess[i].E_mail}
                            onBlur={(e) => handleBlur(e, i)}
                            onChange={(e) => handleAddress(e, i)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-12 mt-4 text-end">
                <button
                  type="button"
                  className="btn add-more-btn d-inline "
                  onClick={addmoreAddress}
                  disabled={!isValidEmail}
                >
                  Add More Addresss
                </button>
                <button
                  type="reset"
                  onClick={handleClear}
                  className="btn submit-btn d-inline ms-3"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="btn submit-btn d-inline ms-3"
                  disabled={!isValidEmail}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProvider;
