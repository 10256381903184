import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import axios from "../../../node_modules/axios/index";
import { toast } from "react-toastify";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import Preloader from "../../CommonPage/Preloader";
import CryptoJS from "crypto-js";

function ArchiveCommunicationType() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  const [records, setRecords] = useState(); // State to store records
  const [open1, setOpen1] = useState(false); // State to control modal visibility
  const [data, setData] = useState([]); // State to store fetched data
  const [preloaderOn, setPreloaderOn] = useState(false);

  // Function to fetch archived user role data from the server
  const fetchData = async () => {
    // Sending GET request to fetch archived user role data
    setPreloaderOn(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}userrole?archive=1`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        // Mapping fetched data to required format
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          UserRoleName: item?.UserRoleName,
          Description: item?.Description,
          status: item?.Active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box">
              {/* View Archive-restore status button */}
              <Link
                to=""
                className="view"
                onClick={() => handleClickData(item?.id)}
                title="View Archive-restore status"
              >
                <i className="fa fa-eye"></i>
              </Link>
              {/* Restore Data button */}
              <Link
                to=""
                className="restore"
                onClick={() => handleClick(item?.id)}
                title="Restore Data"
              >
                <i class="fa fa-window-restore" aria-hidden="true"></i>
              </Link>
            </div>
          ),
        }));
        // Updating state with formatted data
        setData(formattedData);
        setPreloaderOn(false);
      })
      .catch(function (error) {
        // Handling error
        // console.log(error);
        setPreloaderOn(false);
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle restore action
  const handleClick = (id) => {
    let aid = null; // Variable to store archive ID
    // Fetching archive history for the user role
    setPreloaderOn(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}users/getArchiveHistory?type=user-role&productId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // console.log(res?.data?.message);
        // Iterating through archive history
        res?.data?.message.forEach((element) => {
          if (element?.productId === id) {
            if (
              element?.ArchivedDate != null &&
              element?.RestoreDate === null
            ) {
              aid = element.id; // Assigning archive ID
              // Making PUT request to restore user role
              axios
                .put(
                  `${process.env.REACT_APP_BASE_URL}userRole/restore`,
                  {
                    id,
                    archiveId: aid, // Using archive ID for restoration
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                )
                .then((res) => {
                  toast.success("Restore data successfully.", 2000); // Toast notification for successful restoration
                  fetchData(); // Refetching data
                })
                .catch((error) => {
                  // console.log(error);
                });
            }
          } else {
            toast.info("Invalid Request", 2000); // Toast notification for invalid request
          }
        });
        setPreloaderOn(false);
      })
      .catch((error) => {
        // console.log(error);
        setPreloaderOn(false);
      });
  };

  // Function to view archive-restore status
  const handleClickData = (id) => {
    // Fetching archive history for the user role
    setPreloaderOn(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}users/getArchiveHistory?type=user-role&productId=${id}`,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setRecords(res?.data?.message); // Setting archive history records
        setOpen1(true); // Opening modal
        setPreloaderOn(false);
      })
      .catch((error) => {
        // console.log(error);
        setPreloaderOn(false);
      });
  };

  // Memoized columns configuration for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "User Role Name",
        accessor: "UserRoleName",
      },
      {
        Header: "Description",
        accessor: "Description",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  return (
    <>
      {preloaderOn === true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Archive History
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  Archive Communication Type
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          open1 === true ? "custom_modal open openModal" : "custom_modal"
        }
        open={open1}
        onClose={() => setOpen1(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="custom_content-wrapper tw-w-[90%] ">
          <button
            className="custom_close"
            onClick={() => setOpen1(false)}
          ></button>
          <header className="custom_modal-header">
            <h2> Archive And Restoration History</h2>
          </header>
          <form action="" className=" form-box w-100" id="basic-form">
            <div className="card">
              <div className="card-header px-1">
                <h6 className="card-title">
                  Item ID: {records?.length > 0 ? records[0]?.productId : ""}
                </h6>
              </div>
              <div className="row g-0 archive-box">
                {records?.length > 0 &&
                  records?.map((item, dt) => {
                    return (
                      <div
                        key={dt}
                        className="col-md-4 col-lg-3 col-xl-3 p-1 p-sm-2 p-md-3"
                      >
                        <div className="col-12 border p-0">
                          <p className="mb-1 p-1 border-bottom">
                            Archive/Restore ID: <span>{item?.id || "NA"}</span>
                          </p>
                          <p className="mb-2 px-1 ">
                            Archive Date:{" "}
                            <span>
                              {item?.ArchivedDate ? (
                                <>
                                  {new Date(item?.ArchivedDate).toLocaleString(
                                    "en-US"
                                  )}
                                </>
                              ) : (
                                "NA"
                              )}
                            </span>
                          </p>
                          <p className="mb-1 px-1 ">
                            Restore Date:{" "}
                            <span>
                              {item?.RestoreDate ? (
                                <>
                                  {new Date(item?.RestoreDate).toLocaleString(
                                    "en-US"
                                  )}
                                </>
                              ) : (
                                "NA"
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ArchiveCommunicationType;
