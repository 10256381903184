import React from "react";
import { Link } from "react-router-dom";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import AllUserRecords from "../../CommonPage/AllUserRecords";

function AlertTrigger() {
  return (
    <>
      <div className="container-fluid">
        {/* Page header */}
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">Alert Trigger</li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>
        {/* Placeholder for last login information */}
        Last Login :
      </div>
    </>
  );
}

export default AlertTrigger;
