import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const Chart9 = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const backgroundColors = (
    props?.Color?.length ? props.Color : ["#008FFB"]
  ).map((color) => color);
  const [chartAllData, setChartAllData] = useState([
    10, 41, 35, 51, 10, 41, 35, 10, 29, 51, 53, 32, 58, 21, 32, 45,
  ]);
  const [chartAllLabels, setChartAllLabels] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Data",
      data: chartAllData,
      color: backgroundColors[0],
    },
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: true,
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
  });
  const userId = sessionStorage.getItem("UserMainID");

  useEffect(() => {
    const fetchData = (url) => {
      // const username = "Servusys";
      // const password = "Admin@123";
      const username = process.env.REACT_APP_USERNAME;
      const password = process.env.REACT_APP_PASSWORD;
      const basicAuth = "Basic " + btoa(`${username}:${password}`);

      axios
        .get(url, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((res) => {
          const responseData = res.data.line[0];
          setChartAllData(responseData?.data || []);
          setChartAllLabels(responseData?.labelName || []);
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        });
    };

    if (props.metrics && props.metrics !== undefined) {
      if (chartFilter != null) {
        const url = `https://kpiapi.sleapdata.com/api/keymetrics/?physicianID=${userId}&metrics=${props.metrics}&agerange=${chartFilter}&clicdatatype=${chartMeteic}`;
        fetchData(url);
      } else {
        const url = `https://kpiapi.sleapdata.com/api/keymetrics/?metrics=${props.metrics}&physicianID=${userId}`;
        fetchData(url);
      }
    }
  }, [props.metrics, chartFilter, chartMeteic]);

  const latestStateRef = useRef({ series, chartAllLabels });

  useEffect(() => {
    latestStateRef.current = { series, chartAllLabels };
  }, [series, chartAllLabels]);

  useEffect(() => {
    const maxDataValue = Math.max(...chartAllData);
    const minDataValue = Math.min(...chartAllData);

    function handleChart1Click(event, chartContext, { dataPointIndex }) {
      if (dataPointIndex !== undefined) {
        const latestSeries = latestStateRef.current.series;
        const latestLabels = latestStateRef.current.chartAllLabels;
        if (latestSeries && latestSeries.length > 0 && latestSeries[0].data) {
          const clickedDataValue = latestSeries[0].data[dataPointIndex];
          const clickedLabel = latestLabels[dataPointIndex];
          updateChartFilter(clickedLabel);
          updateChartMetrics(props.metrics);
        }
      }
    }

    const sum = chartAllData.reduce((acc, val) => acc + val, 0);
    const avgValue = sum / chartAllData.length;

    const newAnnotations = [
      props.TargetDisplay === true && {
        y: props.TargetValue || 20,
        borderColor: props.TargetLineDisplay
          ? props.TargetValueLineColor || "#0073ff"
          : "",
        label: {
          borderColor: props.TargetValueLineColor || "#0073ff",
          offsetY: +props.PositionTargetTB || 0,
          offsetX: +props.PositionTargetLR || 0,
          style: {
            color: props.LabelTextColorTarget || "#fff",
            background: props.TargetValueLineColor || "#0073ff",
            fontSize: props.FontSizeTarget || "14px",
          },
          text:
            props.ValueTarget === true
              ? (props.TargetValueTitle &&
                  props.TargetValueTitle + " - " + props.TargetValue) ||
                "Target" + " - " + props.TargetValue
              : props.TargetValueTitle || "Target",
        },
      },
      props.AverageDisplay === true && {
        y: avgValue,
        borderColor: props.AverageLineDisplay
          ? props.AverageValueC || "#0073ff"
          : "",
        label: {
          borderColor: props.AverageValueC || "#0073ff",
          offsetY: +props.PositionAverageTB || 0,
          offsetX: +props.PositionAverageLR || 0,
          style: {
            color: props.LabelTextColorTarget || "#fff",
            background: props.AverageValueC || "#0073ff",
            fontSize: props.FontSizeAverage || "14px",
          },
          text: props.AverageValueTitle || "Avg",
        },
      },
      props.PeakDisplay === true && {
        y: maxDataValue,
        borderColor: props.PeakLineDisplay ? props.PeakValueC || "#ff0000" : "",
        label: {
          borderColor: props.PeakValueC || "#ff0000",
          offsetY: +props.PositionPeakTB || 0,
          offsetX: +props.PositionPeakLR || 0,
          style: {
            color: "#fff",
            background: props.PeakValueC || "#ff0000",
            fontSize: props.FontSizePeak || "12px",
          },
          text:
            props.ValuePeak === true
              ? (props.PeakValueTitle &&
                  props.PeakValueTitle + " - " + maxDataValue) ||
                "Peak" + " - " + maxDataValue
              : props.PeakValueTitle || "Peak",
        },
      },
      props.LowestDisplay === true && {
        y: minDataValue,
        borderColor: props.LowestLineDisplay
          ? props.LowestValueC || "#00ff00"
          : "",
        label: {
          borderColor: props.LowestValueC || "#00ff00",
          offsetY: +props.PositionLowestTB || 0,
          offsetX: +props.PositionLowestLR || 0,
          style: {
            color: "#fff",
            background: props.LowestValueC || "#00ff00",
            fontSize: props.FontSizeLowest || "12px",
          },
          text:
            props.ValueLowest === true
              ? (props.LowestValueTitle &&
                  props.LowestValueTitle + " - " + minDataValue) ||
                "Lowest" + " - " + minDataValue
              : props.LowestValueTitle || "Lowest",
        },
      },
    ].filter((annotation) => annotation);

    const data = chartAllData;
    const period = 5;
    const movingAverage = [];
    for (let i = 0; i < data.length; i++) {
      if (i >= period - 1) {
        let sum = 0;
        for (let j = i; j > i - period; j--) {
          sum += data[j];
        }
        movingAverage.push(sum / period);
      } else {
        movingAverage.push(null);
      }
    }

    const updatedSeries = [
      {
        name: "Data",
        data: chartAllData,
        color: backgroundColors[0],
      },
    ];

    if (props.MovingDisplay === true) {
      updatedSeries.push({
        name: "Moving Average",
        data: movingAverage,
        color: props.MovingValueC || "#FF0000",
      });
    }

    setSeries(updatedSeries);

    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        events: {
          markerClick: handleChart1Click,
        },
      },
      annotations: {
        yaxis: newAnnotations,
      },
      xaxis: {
        categories: chartAllLabels || [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "t",
        ],
        title: {
          text: props.axisX === true ? props.XAxis : "",
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalX === false ? false : true,
          style: {
            colors: props.LavelXColor || "black",
          },
        },
      },
      yaxis: {
        title: {
          text: props.axisY === true ? props.YAxis : "",
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalY === false ? false : true,
          style: {
            colors: props.LavelYColor || "black",
          },
        },
      },
      stroke: {
        curve: props.StrokeLine || "straight",
        dashArray: props.LineTpyeSD || 0,
        width: props.LineWidth || 5,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0],
        formatter: function (val, opts) {
          if (props.DataLabels === "all") {
            return val.toFixed(0);
          } else if (props.DataLabels === "min") {
            if (opts.seriesIndex === 0) {
              if (val === Math.min(...chartAllData)) {
                return val.toFixed(0);
              } else {
                return "";
              }
            }
          } else if (props.DataLabels === "max") {
            if (opts.seriesIndex === 0) {
              if (val === Math.max(...chartAllData)) {
                return val.toFixed(0);
              } else {
                return "";
              }
            }
          } else if (props.DataLabels === "both") {
            if (opts.seriesIndex === 0) {
              if (
                val === Math.max(...chartAllData) ||
                val === Math.min(...chartAllData)
              ) {
                return val.toFixed(0);
              } else {
                return "";
              }
            }
          }
        },
      },
    }));
  }, [
    props.TargetDisplay,
    props.DataLabels,
    props.TargetValue,
    props.dataSeries,
    props.TargetValueLineColor,
    props.LabelTextColorTarget,
    props.TargetValueTitle,
    props.FontSizeTarget,
    props.dataLabel,
    props.PeakDisplay,
    props.PeakValueC,
    props.PeakValueTitle,
    props.LowestDisplay,
    props.LowestValueC,
    props.LowestValueTitle,
    props.PositionLowestTB,
    props.PositionLowestLR,
    props.LowestLineDisplay,
    props.FontSizeLowest,
    props.PeakLineDisplay,
    props.PositionPeakTB,
    props.PositionPeakLR,
    props.FontSizePeak,
    props.TargetLineDisplay,
    props.PositionTargetTB,
    props.PositionTargetLR,
    props.MovingDisplay,
    props.MovingValueC,
    props.StrokeLine,
    props.LineTpyeSD,
    props.LineWidth,
    props.LavelYColor,
    props.LebalY,
    props.TextColor,
    props.FontSize,
    props.YAxis,
    props.axisY,
    props.LavelXColor,
    props.LebalX,
    props.gridY,
    props.gridX,
    chartAllData,
    chartAllLabels,
  ]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart9;
