import { Outlet } from "react-router-dom";
import UserSideBar from "./UserPage/SideBar/UserSideBar";
import SideBar from "./CommonPage/SideBar";
import TopBar from "./CommonPage/TopBar";
import UserTopBar from "./UserPage/TopBar/UserTopBar";

let type = sessionStorage.getItem("type");

const PageLayout = () => (
  <div id="layout">
    <div>{type === "admin" ? <TopBar /> : <UserTopBar />}</div>
    <div>
      <div>{type === "admin" ? <SideBar /> : <UserSideBar />}</div>
      <div id="main-content">
        <Outlet />
      </div>
    </div>
  </div>
);

export default PageLayout;
