import React, { useEffect, useState } from "react";
import Preloader from "../../CommonPage/Preloader";
import ToggleNavBar from "../../CommonPage/ToggleNavBar";
import "datatables.net-dt/css/dataTables.dataTables.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AllUserRecords from "../../CommonPage/AllUserRecords";
import { Table } from "../../CommonPage/TableContent";
import CryptoJS from "crypto-js";

function AllSubscriptionRecords() {
  const secretKey = "Secured Key";
  const encryptedToken = sessionStorage.getItem("token");
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);
  // State to hold subscription data
  const [data, setData] = useState([]);
  const [preloaderOn, setPreloaderOn] = useState(false);

  // Columns definition for the table
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "sno",
      },
      {
        Header: "Subscription ID",
        accessor: "id",
      },
      {
        Header: "Subscription Name",
        accessor: "name",
      },
      {
        Header: "Default E-mail",
        accessor: "email",
      },
      {
        Header: "General Description",
        accessor: "generalDescription",
      },
      {
        Header: "Email Message",
        accessor: "eMailMessage",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  // Function to fetch data from API
  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}subscription`, {
        headers: {
          Authorization: token,
        },
      })
      .then(function (res) {
        // console.log("admi", res.data.data);
        const formattedData = res.data.data.map((item, index) => ({
          sno: index + 1,
          id: item?.id,
          name: item?.name,
          email: item?.email,
          generalDescription: item?.generalDescription,
          eMailMessage: item?.eMailMessage,
          status: item?.active ? (
            <span className="badge bg-success">Active</span>
          ) : (
            <span className="badge bg-danger">In-Active</span>
          ),
          action: (
            <div className="action-btn-box">
              <Link
                to=""
                className="delete"
                onClick={() => handleDeleteClick(item?.id)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ),
        }));

        setData(formattedData);
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle delete action
  const handleDeleteClick = (id) => {
    setPreloaderOn(true);
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}subscription/archive`,
        {
          id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        setPreloaderOn(false);
        toast.success(res.data.message, { autoClose: 1000 });
        fetchData();
      })
      .catch((error) => {
        setPreloaderOn(false);
        // console.error(error);
      });
  };

  return (
    <>
      {preloaderOn == true ? <Preloader text="Please wait..." /> : ""}
      <div className="container-fluid">
        {/* Header section with navigation */}
        <div className="block-header py-lg-4 py-3">
          <div className="row g-3">
            <div className="col-md-6 col-sm-12">
              <h2 className="m-0 fs-5">
                <ToggleNavBar />
                Record Viewer
              </h2>
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">sLEAP</Link>
                </li>
                <li className="breadcrumb-item active">
                  All Subscription Records
                </li>
              </ul>
            </div>
            <AllUserRecords />
          </div>
        </div>

        {/* Main content area with data table */}
        <div className="row g-2 clearfix row-deck">
          <div className="col-lg-12 col-md-12">
            <div className="card mb-4">
              <div className="card-body" style={{ overflowX: "scroll" }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllSubscriptionRecords;
