import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSharedContext } from "../../../CommonPage/SharedDataSet";

const colors = ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"];

const Chart35 = (props) => {
  const { chartFilter, updateChartFilter } = useSharedContext();
  const { chartMeteic, updateChartMetrics } = useSharedContext();
  const [chartAllData, setChartAllData] = useState([
    { data: [10, 20, 30, 15, 23, 28, 35] },
  ]);
  const [labels, setLabels] = useState([]);
  const backgroundColors = props?.Color?.length ? props.Color : colors;

  const userId = sessionStorage.getItem("UserMainID");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const username = "Servusys";
        // const password = "Admin@123";
        const username = process.env.REACT_APP_USERNAME;
        const password = process.env.REACT_APP_PASSWORD;
        const basicAuth = "Basic " + btoa(`${username}:${password}`);

        const response = await axios.get(
          `https://kpiapi.sleapdata.com/api/keymetrics/`,
          {
            params: {
              physicianID: userId,
              metrics: props.metrics,
              agerange: chartFilter,
              clicdatatype: chartMeteic,
            },
            headers: {
              Authorization: basicAuth,
            },
          }
        );

        if (
          response.data &&
          response.data.column &&
          response.data.column.length > 0
        ) {
          const responseData = response.data.column[0];
          setChartAllData([{ data: responseData.data || [] }]);
          setLabels(responseData.labelName || []);
        } else {
          console.error("Unexpected response data structure: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (props.metrics) {
      fetchData();
    }
  }, [props.metrics, chartFilter, chartMeteic, userId]);

  const [series, setSeries] = useState(chartAllData);

  useEffect(() => {
    setSeries(chartAllData);
  }, [chartAllData]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: backgroundColors,
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: colors,
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          return `${w.globals.labels[dataPointIndex]}: ${val}`;
        },
        title: {
          formatter: () => "",
        },
      },
    },
  });

  const latestStateRef = useRef({ series, options });

  useEffect(() => {
    latestStateRef.current = { series, options };
  }, [series, options]);

  function handleChart1Click(event, chartContext, { dataPointIndex }) {
    const currentOptions = latestStateRef.current;
    if (dataPointIndex !== undefined) {
      const clickedCategory =
        currentOptions.options.xaxis.categories[dataPointIndex];
      updateChartFilter(clickedCategory);
      updateChartMetrics(props.metrics);
    }
  }

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: {
        ...prevOptions.chart,
        events: {
          dataPointSelection: handleChart1Click,
        },
      },
      dataLabels: {
        ...prevOptions.dataLabels,
        formatter: function (val, opts) {
          if (props.DataLabels === "all") {
            return val.toFixed(0);
          } else if (props.DataLabels === "min") {
            if (
              opts.dataPointIndex ===
              series[0].data.indexOf(Math.min(...series[0].data))
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "max") {
            if (
              opts.dataPointIndex ===
              series[0].data.indexOf(Math.max(...series[0].data))
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          } else if (props.DataLabels === "both") {
            const minValueIndex = series[0].data.indexOf(
              Math.min(...series[0].data)
            );
            const maxValueIndex = series[0].data.indexOf(
              Math.max(...series[0].data)
            );
            if (
              opts.dataPointIndex === minValueIndex ||
              opts.dataPointIndex === maxValueIndex
            ) {
              return val.toFixed(0);
            } else {
              return "";
            }
          }
        },
      },
      legend: {
        show: props.LegendDisplay !== false,
        position: props.position || "top",
        labels: {
          colors: props.LegendTextColor || "black",
        },
      },
      tooltip: {
        ...prevOptions.tooltip,
        enabled: props.Tooltip === false ? false : true,
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            return `${w.globals.labels[dataPointIndex]}: ${val}`;
          },
          title: {
            formatter: () => "",
          },
        },
      },
      xaxis: {
        categories: labels,
        title: {
          text: props.axisX ? props.XAxis : "",
          style: {
            fontSize: props.FontSize || "14px",
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalX !== false,
          style: {
            colors: props.LavelXColor || "black",
          },
        },
      },
      yaxis: {
        title: {
          text: props.axisY ? props.YAxis : "",
          style: {
            fontSize: props.FontSize || 14,
            color: props.TextColor || "black",
          },
        },
        labels: {
          show: props.LebalY !== false,
          style: {
            colors: props.LavelYColor || "black",
          },
        },
      },
      grid: {
        show: true,
        borderColor: "#f0f0f0",
        strokeDashArray: 4,
        position: "back",
        xaxis: {
          lines: {
            show: props.gridX !== false,
          },
        },
        yaxis: {
          lines: {
            show: props.gridY !== false,
          },
        },
      },
    }));
  }, [props, series]);

  useEffect(() => {
    const maxDataValue = Math.max(
      ...series.map((series) => Math.max(...series.data))
    );
    const minDataValue = Math.min(
      ...series.map((series) => Math.min(...series.data))
    );
    const newAnnotations = [
      props.TargetDisplay && {
        y: props.TargetValue || 20,
        borderColor: props.TargetLineDisplay
          ? props.TargetValueLineColor || "#0073ff"
          : "",
        label: {
          borderColor: props.TargetValueLineColor || "#0073ff",
          offsetY: +props.PositionTargetTB || 0,
          offsetX: +props.PositionTargetLR || 0,
          style: {
            color: props.LabelTextColorTarget || "#fff",
            background: props.TargetValueLineColor || "#0073ff",
            fontSize: props.FontSizeTarget || "14px",
          },
          text: props.ValueTarget
            ? (props.TargetValueTitle &&
                props.TargetValueTitle + " - " + props.TargetValue) ||
              "Target" + " - " + props.TargetValue
            : props.TargetValueTitle || "Target",
        },
      },
      props.PeakDisplay && {
        y: maxDataValue,
        borderColor: props.PeakLineDisplay ? props.PeakValueC || "#ff0000" : "",
        label: {
          borderColor: props.PeakValueC || "#ff0000",
          offsetY: +props.PositionPeakTB || 0,
          offsetX: +props.PositionPeakLR || 0,
          style: {
            color: "#fff",
            background: props.PeakValueC || "#ff0000",
            fontSize: props.FontSizePeak || "12px",
          },
          text: props.ValuePeak
            ? (props.PeakValueTitle &&
                props.PeakValueTitle + " - " + maxDataValue) ||
              "Peak" + " - " + maxDataValue
            : props.PeakValueTitle || "Peak",
        },
      },
      props.LowestDisplay && {
        y: minDataValue,
        borderColor: props.LowestLineDisplay
          ? props.LowestValueC || "#00ff00"
          : "",
        label: {
          borderColor: props.LowestValueC || "#00ff00",
          offsetY: +props.PositionLowestTB || 0,
          offsetX: +props.PositionLowestLR || 0,
          style: {
            color: "#fff",
            background: props.LowestValueC || "#00ff00",
            fontSize: props.FontSizeLowest || "12px",
          },
          text: props.ValueLowest
            ? (props.LowestValueTitle &&
                props.LowestValueTitle + " - " + minDataValue) ||
              "Lowest" + " - " + minDataValue
            : props.LowestValueTitle || "Lowest",
        },
      },
    ].filter((annotation) => annotation);

    setOptions((prevState) => ({
      ...prevState,
      annotations: {
        yaxis: newAnnotations,
      },
    }));
  }, [props, series]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={"100%"}
      style={{ backgroundColor: props.BackgroundColor || "transparent" }}
    />
  );
};

export default Chart35;
